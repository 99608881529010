import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { project } from './companyCamApi';
import { CompanyCamAlert } from './companyCamAlert';
import ccLogo from '../../../assets/images/company-cam-logo.png';

export function CompanyCamUpdate() {

    const { 
        data: { _raw: {ccProjectId} = {} } = {},
        data: { client: {property} = {} } = {},
        data: { client: {residence} = {} } = {}
    } = useSelector((state) => state.claims.claim);
    
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // const dispatch = useDispatch();
    const hideAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    }
    
    const update = async () => {
        // TODO make sure fields exist
        let name = `${residence.client1.lastName}, ${residence.client1.firstName}`;
        if (residence.client2.lastName || residence.client2.firstName) {
            name = `${name} & ${residence.client2.lastName}, ${residence.client2.firstName}`
        }
        const projectData = {
            name,
            address: {
                street_address_1: property.streetAddress1,
                street_address_2: property.streetAddress2,
                city: property.city,
                state: property.state,
                postal_code: property.zipcode,
                country: 'US'
            }
        };
        
        // update
        const response = await project.update(ccProjectId, projectData);
        if (response.data.id === ccProjectId) {
            // success
            setAlertMessage('CompanyCam project updated successfully.');
            setShowAlert(true);
        }
       
    };

    if (!ccProjectId) return null;

    return (
        <>
        <button className="tw-transition tw-bg-[#0967d2] tw-rounded-[4px] tw-text-white tw-px-[10px] tw-py-[4px] tw-text-[13px] tw-mx-[2px] tw-flex tw-content-center tw-relative tw-mr-4
                    hover:tw-bg-[#085cbd]"
                    onClick={update} type="button"><img src={ccLogo} className='tw-w-5 tw-mr-1' alt="CompanyCam Logo" /><span>Update CompanyCam Project</span></button>
        <CompanyCamAlert isOpen={showAlert} onClose={hideAlert} title="Success" body={alertMessage} action={hideAlert} actionText="OK" cancelButton={false} />
        </>
    )

}