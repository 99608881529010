import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const variants = {
  initial: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
  },
};

const SideImage = ({ bgImage }) => {
  const [innerHeight, setInnerHeight] = useState("100vh");
  useEffect(() => {
    const { scrollHeight, offsetHeight } = document.body;
    const html = document.documentElement;

    const height = Math.max(
      scrollHeight,
      offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    setInnerHeight(height);
  }, []);

  return (
    <div className="login-bg">
      <div
        className="backstretch"
        style={{
          left: 0,
          top: 0,
          overflow: "hidden",
          margin: 0,
          padding: 0,
          width: "50vw", 
          zIndex: -999998,
          position: "absolute",
          height: innerHeight,
        }}
      >
        <AnimatePresence>
          <motion.img
            initial="initial"
            animate="visible"
            exit="exit"
            variants={variants}
            style={{
              position: "absolute",
              margin: 0,
              padding: 0,
              border: "none",
              width: "50vw",
              maxHeight: "none",
              maxWidth: "none",
              zIndex: -99999,
              left: 0,
              height: innerHeight,
              objectFit: "cover",
            }}
            alt="lightening over the city skyline "
            src={bgImage}
            key={bgImage}
          />
        </AnimatePresence>
      </div>
    </div>
  );
};

export { SideImage };
