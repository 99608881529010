import React from "react";
import { useSelector } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { Col } from "../../../../_common/layout/Layout";
import { CurrencyMask } from "../../../../_common/MaskedInput";

export const CoverageItem = ({ name, label, value, onChange, md = 6 }) => {
  const { isSharedView } = useSelector((state) => state.claims.claim);

  return (
    <Col md={md}>
      <Form.Group style={{ marginBottom: 5 }}>
        <Form.Label>{label}</Form.Label>
        <div className="input-group">
          <div className="input-group-addon">$</div>
          <CurrencyMask
            className="form-control"
            placeholder="0"
            value={value}
            handleChange={(e) => {
              onChange({
                [name]: e.target.value,
              });
            }}
            disabled={isSharedView}
            data-testid={label.toLowerCase().split(" ").join("-")}
          />
        </div>
      </Form.Group>
    </Col>
  );
};
