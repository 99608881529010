import { all } from "redux-saga/effects";

import { AuthSagas } from "./auth";
import { ClaimSagas, ClaimAdjusterSagas, SharedClaimSagas } from "./claims";
import { CompanySagas } from "./company";
import { CustomFieldSagas } from "./custom-fields";
import { InsurerSagas } from "./insurers";
import { ProfileSagas } from "./profile";
import { StatusSagas } from "./statuses";
import { VendorSagas } from "./vendors";
import { AttorneySagas } from "./attorneys";

export function* rootSaga() {
  yield all([
    ...AuthSagas,
    ...ClaimSagas,
    ...ClaimAdjusterSagas,
    ...CompanySagas,
    ...CustomFieldSagas,
    ...InsurerSagas,
    ...ProfileSagas,
    ...SharedClaimSagas,
    ...StatusSagas,
    ...VendorSagas,
    ...AttorneySagas
  ]);
}
