import React, {useCallback, useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Pagebar} from '../layout/Pagebar';
import {AttorneyDatatable} from './tables';
import {AddNewAndEdit} from './addNewAndEdit';
import {DeleteSweetAlertConfirmation} from '../_common/Sweetalert';
import {Dropdown} from '../_common/Dropdown';
import DownloadCSV from '../_common/DownloadCSV';
import DownloadPDF from '../_common/DownloadPDF';
import DownloadImage from '../_common/DownloadImage';

const DOWNLOAD_CSV_HEADERS = [
  {label: 'Attorney Name', key: 'name'},
  {label: 'Email Address', key: 'email'},
  {label: 'Street', key: 'streetAddress'},
  {label: 'City', key: 'city'},
  {label: 'State', key: 'state'},
  {label: 'Zipcode', key: 'zipcode'},
  {label: 'Phone #', key: 'phone'},
  {label: 'Extension', key: 'phoneExt'},
  {label: 'Fax #', key: 'fax'}
];

const DOWNLOAD_PDF_HEADERS = [
  {header: 'Attorney Name', dataKey: 'name'},
  {header: 'Email Address', dataKey: 'email'},
  {header: 'Street', dataKey: 'streetAddress'},
  {header: 'City', dataKey: 'city'},
  {header: 'State', dataKey: 'state'},
  {header: 'Zipcode', dataKey: 'zipcode'},
  {header: 'Phone #', dataKey: 'phone'},
  {header: 'Extension', dataKey: 'phoneExt'},
  {header: 'Fax #', dataKey: 'fax'}
];

const BREADCRUMB = [
  {title: 'Home', link: '/app/claims'},
  {title: 'Attorneys', link: ''}
];

export function Attorneys() {
  const [isLoading, setIsLoading] = useState(true);
  const [attorneys, setAttorneys] = useState([]);
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteSelectedAttorney, setDeleteSelectedAttorney] = useState({});
  const [opensidePanel, setOpensidePanel] = useState(false);
  const [attorneyDetails, setAttorneyDetails] = useState({});

  const fetchAttorneys = useCallback(async function _fetchAttorneys() {
    setIsLoading(true);
    try {
      const data = await Api.Attorneys.list();
      if (data.data && data.data.attorneys) {
        setAttorneys(data.data.attorneys);
      }
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setAttorneys, setError]);

  useEffect(() => {fetchAttorneys();}, [fetchAttorneys]);

  function confirmDelete(selectedAttorney) {
    setShowDeleteConfirmation(true);
    setDeleteSelectedAttorney(selectedAttorney);
  }

  function saveAsPdf() {
    return DownloadPDF({
      data: attorneys,
      headers: DOWNLOAD_PDF_HEADERS,
      filename: 'attorneys.pdf'
    });
  }

  async function deleteAttorney(confirm) {
    if (confirm) {
      try {
        await Api.Attorneys.delete({id: deleteSelectedAttorney.id});
        fetchAttorneys();
      } catch (err) {
        // intentionally empty
      } finally {
        setDeleteSelectedAttorney({});
        setShowDeleteConfirmation(false);
      }
    } else {
      setShowDeleteConfirmation(false);
    }
  }

  return (
    <div>
      {<Pagebar breadcrumbs={BREADCRUMB}/>}
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              ATTORNEYS
            </span>
          </div>
          <div className="actions"/>
        </div>
        <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={deleteAttorney}/>
        <AddNewAndEdit openPanel={opensidePanel} setOpenPanel={setOpensidePanel} attorneyDetails={attorneyDetails}
                       reloadTable={fetchAttorneys}/>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group">
                  <button className="btn btn-outline blue-primary add_new"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpensidePanel(true);
                            setAttorneyDetails({});
                          }}>
                    Add New <i className="fa fa-plus"/>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="btn-group pull-right">
                  <Dropdown title="Tools" variant="red">
                    <Dropdown.ListItem onClick={() => false}>
                      <DownloadImage Data={attorneys} headers={DOWNLOAD_PDF_HEADERS} TableName="Attorneys"/>
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={saveAsPdf}>
                      Save as PDF
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={() => false}
                                       onlyLI={
                                         <DownloadCSV data={attorneys} filename="attorneys.csv"
                                                      headers={DOWNLOAD_CSV_HEADERS} target="_blank"/>
                                       }
                    />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="table-scrollable">
            <AttorneyDatatable attorneys={attorneys} isLoading={isLoading} error={error}
                               setOpensidePanel={setOpensidePanel} setAttorneyDetails={setAttorneyDetails}
                               confirmDelete={confirmDelete}/>
          </div>
        </div>
      </div>
    </div>
  );
}
