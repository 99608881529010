import React, {useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';

import * as Api from '../../Api';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {LoadingButton} from '../_common/Button';
import {Notify} from '../../services/Notify.service';

const {REACT_APP_TINYMCE_API_KEY} = process.env;

export function SendMail({openPanel, setOpenPanel}) {
  const [message, setMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');

  async function sendMessage() {
    try {
      setButtonLoading(true);
      await Api.Support.sendMessage({RequestData: {message}});
      setOpenPanel(false);
      Notify.success('Mail sent successfully!');
    } catch (err) {
      Notify.error('Error in sending mail.');
    } finally {
      setButtonLoading(false);
    }
  }

  function handleRemove(e) {
    e.preventDefault();
    setOpenPanel(false);
  }

  function handleCancel(e) {
    e.preventDefault();
    setOpenPanel(false);
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                Support Request
              </span>
            </div>
            <div className="tools">
              <a className="remove" href="#!" title="" data-original-title="" onClick={handleRemove}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            <Editor apiKey={REACT_APP_TINYMCE_API_KEY} initialValue={message}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                      // eslint-disable-next-line no-multi-str
                        'undo redo | formatselect | bold italic backcolor | \
                                  alignleft aligncenter alignright alignjustify | \
                                  bullist numlist outdent indent | removeformat'
                    }}
                    onEditorChange={setMessage}
            />
            <div className="form-actions">
              <div className="row">
                <div className="col-md-offset-3 col-md-9">
                  {buttonLoading
                    ? (<LoadingButton/>)
                    : (
                      <button className="btn btn-outline blue-primary" style={{margin: 5}} type="submit"
                              onClick={sendMessage}>
                        Submit
                      </button>
                    )}

                  <button className="btn default" style={{margin: 5}} type="button" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
