import jsPDF from 'jspdf'
import 'jspdf-autotable'

const createPdfData = ({
    data
}) => {
    return new Promise(async (resolve, reject) => { 
        const NewData = await data.map((d, i) => {
            return {
                fileNumber: d.fileNumber,
                createdAt: d.createdAt,
                clientName:{
                    content: ((d.name) ? d.name + "," : "") + ((d.clientPhone) ? d.clientPhone + ',' : "") + ((d.claimNumber) ? d.claimNumber : ""),
                },
                adjusterNames: d.adjusterNames,
                dateOfLoss: d.dateOfLoss,
                propertyAddress: d.propertyAddress,
                insurerName: ((d.insurer.name) ? d.insurer.name : "") +" "+ ((d.insAdjuster1Name) ? d.insAdjuster1Name : "") +" "+ ((d.adjusterClaimPerc) ? d.adjusterClaimPerc : "") +" "+ ((d.contractFee) ? d.contractFee :''),
                status: d.status
            };
        });
        return resolve(NewData);
    });
}

const ClaimsPdf = async ({
    data,
}) => {
    const headers = [
        { header: 'File #', dataKey: 'fileNumber' },
        { header: 'Date', dataKey: 'createdAt' },
        { header: 'Client', dataKey: 'clientName' },
        { header: 'Assigned Adjuster', dataKey: 'adjusterNames' },
        { header: 'DOL', dataKey: 'dateOfLoss' },
        { header: 'Loss Address', dataKey: 'propertyAddress' },
        { header: 'Insurer', dataKey: 'insurerName' },
        { header: 'Status', dataKey: 'status' },
    ];
    const filename = "Claims.pdf";

    const doc = new jsPDF();
    
    doc.autoTable({
        columns: headers,
        body: await createPdfData({
            data
        }),
    });
    doc.save(filename);
    return "";
}

export default ClaimsPdf;