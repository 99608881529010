import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { Form } from "../../../../_common/form/Form";
import MultiSelectDropDown from "../../../../_common/MultiSelectDropDown";
import { ClaimActions } from "../../../../../store/claims";

function getAssignableAdjusters(members = []) {
  const { activeAdjusters, admins, superadmin } = members;
  const adjusters = [
    ...(activeAdjusters ? [...activeAdjusters] : []),
    ...(admins ? [...admins] : []),
    ...(superadmin ? [...superadmin] : []),
  ];

  const sortedAdjusters = _.orderBy(
    adjusters,
    [(adjuster) => adjuster.name.toLowerCase()],
    ["asc"]
  );
  return sortedAdjusters;
}

export const AssignedAdjusters = () => {
  const dispatch = useDispatch();

  const members = useSelector((state) => state.company.members.list);

  const assignedAdjusters = useSelector((state) => state.claims.claim.data?.assignedAdjusters);

  const { isSharedView } = useSelector((state) => state.claims.claim);

  const assignableAdjusters = getAssignableAdjusters(members);

  const handleChange = (adjusters) =>
    dispatch(ClaimActions.SetOnClaim.assignedAdjusters(adjusters));

  return (
    <Form.Group>
      {isSharedView ? (
        <>
          <Form.Label>Assigned Adjuster</Form.Label>
          <Form.Input
            disabled
            value={assignedAdjusters?.map((adjuster) => adjuster.name).join(", ")}
            data-testid="assigned-adjuster"
          />
        </>
      ) : (
        <MultiSelectDropDown
          label={"Assigned Adjuster"}
          options={assignableAdjusters}
          optionsLabelField={"name"}
          selectedFields={assignedAdjusters}
          optionsValueField={"id"}
          handleChange={handleChange}
          disabled={isSharedView}
          dataTestId="assigned-adjuster"
        />
      )}
    </Form.Group>
  );
};
