import React from "react";

import { useLogout } from "../auth/login/useLogin.hooks";

export function Logout() {
  useLogout();

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
}
