import React, { useState } from "react";
import { FullPageSpinner } from "../../../_common/Spinner";
import { AddAndEditNote } from "./AddAndEditNote";
import { useClaimNotesData } from "./NotesHook";
import { Dropdown } from "../../../_common/Dropdown";
import { DeleteSweetAlertConfirmation } from "../../../_common/Sweetalert";

import Moment from "moment";

export const NotesTab = ({ claimId }) => {
  const [openPanel, setOpenPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {
    isSharedView,
    notes,
    EditNote,
    DeleteNote,
    getInitials,
    noteDetails,
    addNewNote,
    reloadNotes,
    deleteConfirm,
  } = useClaimNotesData({
    claimId,
    setIsLoading,
    setOpenPanel,
    setShowDeleteConfirmation,
  });

  return (
    <div>
      {isLoading && <FullPageSpinner />}
      <div>
        <AddAndEditNote
          claimId={claimId}
          openPanel={openPanel}
          setOpenPanel={setOpenPanel}
          NoteDetails={noteDetails}
          reloadNotes={reloadNotes}
        />
        <DeleteSweetAlertConfirmation
          show={showDeleteConfirmation}
          clickHandler={deleteConfirm}
        />
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">Notes</div>
            {!isSharedView && (
              <div className="actions">
                <button onClick={addNewNote} className="btn blue-primary bold">
                  Add New
                </button>
              </div>
            )}
          </div>
          <div className="portlet-body">
            {!isLoading && notes.length === 0 && (
              <div>
                {!isSharedView && (
                  <span>
                    All your Notes will show here. Use the 'Add New' button to
                    add a Note.
                  </span>
                )}
                {isSharedView && (
                  <span>All Notes of claim will show here. </span>
                )}
              </div>
            )}
            {notes.length !== 0 && (
              <div>
                <div className="timeline  notes-main-div">
                  {notes.map((note) => {
                    const userName = note.userName.split(" ");
                    const firstName = userName[0];
                    const lastName = userName[1];
                    let mDate = Moment.utc(note.lastUpdated);
                    return (
                      <div className="timeline-item" key={note.id}>
                        <div className="timeline-badge">
                          <div className="avatar avatar-circle-notes">
                            {note.userName === " You " ? (
                              <span className="initials-notes">
                                <i
                                  className="fa fa-user"
                                  style={{ color: "#666" }}
                                ></i>
                              </span>
                            ) : (
                              <span className="initials-notes">
                                ${getInitials(firstName, lastName)}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="timeline-body">
                          <div className="timeline-body-arrow"></div>
                          <div className="timeline-body-head">
                            <div className="timeline-body-head-caption">
                              <a
                                href="#!"
                                className="timeline-body-title font-blue-madison"
                              >
                                {" "}
                                {note.userName}{" "}
                              </a>
                              <span className="timeline-body-time font-grey-cascade">
                                {mDate.local().format("MM/DD/YYYY hh:mm A")}
                              </span>
                            </div>
                            {!isSharedView && (
                              <div className="timeline-body-head-actions">
                                <div className="btn-group">
                                  <Dropdown
                                    title="Actions"
                                    variant="blue-primary"
                                  >
                                    <Dropdown.ListItem
                                      onClick={() =>
                                        EditNote({
                                          note,
                                          setOpenPanel,
                                        })
                                      }
                                    >
                                      Edit
                                    </Dropdown.ListItem>
                                    <Dropdown.ListItem
                                      onClick={() => DeleteNote(note.id)}
                                    >
                                      Delete
                                    </Dropdown.ListItem>
                                  </Dropdown>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="timeline-body-content"
                            id="timeline-body-content-notes_10551"
                          >
                            <span style={{ color: "#000000" }} className="">
                              <div
                                dangerouslySetInnerHTML={{ __html: note.note }}
                              ></div>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
