import axios from 'axios';

const oauth = axios.create({
    baseURL: 'https://api.companycam.com/oauth'
});

const api = axios.create({
    baseURL: 'https://api.companycam.com/v2',
    headers: {'Content-Type': 'application/json'}
});

const { REACT_APP_COMPANY_CAM_CLIENT_ID, REACT_APP_COMPANY_CAM_CLIENT_SECRET } = process.env;

api.interceptors.request.use(async (config) => {
    const savedCompanyCamToken = JSON.parse(localStorage.getItem('companyCamToken'));
    if (savedCompanyCamToken) {
        const expirationTime = savedCompanyCamToken.created_at + savedCompanyCamToken.expires_in;
        const currentTime = Math.floor(Date.now() / 1000);
        if (expirationTime > currentTime) {
            // console.log('good token');
            config.headers.Authorization = `Bearer ${savedCompanyCamToken.access_token}`;
            return config;
        } else {
            // refresh token
            const response = await auth.refreshToken(savedCompanyCamToken.refresh_token);
            if (response.data?.access_token) {
                localStorage.setItem('companyCamToken', JSON.stringify(response.data));
                config.headers.Authorization = `Bearer ${response.data.access_token}`;
                return config;
            } else {
                return Promise.reject('Token refresh failed.');
            }
        }
    } else {
        return Promise.reject('No saved token.');
    }
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('companyCamToken');
        }
        return Promise.reject(error);
    }
);

const grantType = 'authorization_code';

export const auth = {
    getToken(code, redirectUri) {
        return oauth.post(`token?client_id=${REACT_APP_COMPANY_CAM_CLIENT_ID}&client_secret=${REACT_APP_COMPANY_CAM_CLIENT_SECRET}&code=${code}&grant_type=${grantType}&redirect_uri=${encodeURIComponent(redirectUri)}`);
    },
    refreshToken(refreshToken) {
        return oauth.post(`token?client_id=${REACT_APP_COMPANY_CAM_CLIENT_ID}&client_secret=${REACT_APP_COMPANY_CAM_CLIENT_SECRET}&refresh_token=${refreshToken}&grant_type=refresh_token`);
    }
}

export const company = {
    get() {
        return api.get(`company`);
    }
}

export const project = {
    create(data) {
        return api.post('projects', data);
    },
    get(projectId) {
        return api.get(`projects/${projectId}`);
    },
    update(id, data) {
        return api.put(`projects/${id}`, data);
    },
    photos: {
        get(projectId) {
            return api.get(`projects/${projectId}/photos`);
        },
    },
    documents: {
        get(projectId) {
            return api.get(`projects/${projectId}/documents`);
        },
    }
}
