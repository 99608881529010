// noinspection HtmlUnknownAnchorTarget

import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {formatAddress} from './ClaimsTable.utils';
import {Spinner} from '../_common/Spinner';

export function Preview({openPanel, setOpenPanel, selectedClaimId, selectedClaimData, selectedClaimNotes}) {
  let history = useHistory();
  const [clientInfo, setClientInfo] = useState(false);
  const [policyInfo, setPolicyInfo] = useState(false);
  const [lossInfo, setLossInfo] = useState(false);
  const [deskAdjuster, setDeskAdjuster] = useState(false);
  const [adjusterAssigned, setAdjusterAssigned] = useState(false);
  const [payments, setPayments] = useState(false);
  const [vendors, setVendors] = useState(false);
  const [attorney, setAttorney] = useState(false);
  const [appointment, setAppointment] = useState(false);
  const [notes, setNotes] = useState(false);
  const checkNulls = {
    clientInfo: 0,
    policyInfo: 0,
    lossInfo: 0,
    deskAdjuster: 0,
    adjusterAssigned: 0,
    payments: 0,
    vendors: 0,
    attorney: 0,
    appointment: 0,
    notes: 0
  };

  function redirectOnclientEdit(e) {
    e.preventDefault();
    history.push(`/claims/${selectedClaimId}`);
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        {
          (!selectedClaimData.id) ? <Spinner/> :
            <div className="portlet light">
              <div className="portlet-title">
                <div className="caption">
                  <span className="caption-subject bold font-yellow-casablanca uppercase">
                      Preview
                  </span>
                </div>
                <div className="tools">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenPanel(false);
                    }}
                    href="?#"
                    className="remove"
                    data-original-title=""
                    title=""
                  >
                  </a>
                </div>
              </div>
              <div className="portlet-body">
                <div className="portlet light portlet-fit">
                  <div className="portlet-body">
                    <div className="mt-element-list">
                      <div className="mt-list-head list-todo dark">
                        <a href="#!" onClick={redirectOnclientEdit} className="editClientFile">
                          <div className="list-head-title-container">
                            <h3 className="list-title">
                              {(selectedClaimData.clientType)
                                ?
                                (
                                  (selectedClaimData.clientType === 'business')
                                    ?
                                    selectedClaimData.businessName
                                    :
                                    `${selectedClaimData.client1FirstName} ${selectedClaimData.client1LastName}`
                                )
                                :
                                ''
                              }
                            </h3>
                            <div className="list-head-count">
                              <div className="list-head-count-item" style={{textTransform: 'uppercase!important'}}>
                                <i className="fa fa-cog"/> {(selectedClaimData.status)
                                ? selectedClaimData.status
                                : ''}
                              </div>
                            </div>
                          </div>
                          <div className="list-count pull-right red-mint" style={{padding: '26px!important'}}>
                            <i className="fa fa-pencil editClientFileIcon"/> Open File
                          </div>
                        </a>
                      </div>
                      <div className="mt-list-container list-todo" role="tablist" aria-multiselectable="true">
                        <div className="list-todo-line"/>
                        <ul>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-user"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(clientInfo) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 onClick={() => {
                                   if (clientInfo) {
                                     setClientInfo(false);
                                   } else {
                                     setClientInfo(true);
                                   }
                                 }} href="#!">
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">
                                    Client Info
                                  </div>
                                </div>
                              </a>
                              <div className={(clientInfo)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'} data-toggle="collapse">
                                <ul>
                                  {selectedClaimData.clientPhone && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href={(selectedClaimData.clientPhone)
                                        ? `tel=+1${selectedClaimData.clientPhone}`
                                        : '#!'}>
                                        <i className="fa fa-phone"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <p className="bold">
                                      <span style={{display: 'none'}}>{checkNulls.clientInfo = checkNulls.clientInfo
                                        + 1}</span>
                                        <a href={(selectedClaimData.clientPhone)
                                          ? `tel=+1${selectedClaimData.clientPhone}`
                                          : '#!'}>{selectedClaimData.clientPhone}</a>
                                      </p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.clientEmail && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href={(selectedClaimData.clientEmail)
                                        ? `mailto=${selectedClaimData.clientEmail}`
                                        : '#!'}>
                                        <i className="fa fa-at"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <p className="bold">
                                      <span style={{display: 'none'}}>{checkNulls.clientInfo = checkNulls.clientInfo
                                        + 1}</span>
                                        <a href={(selectedClaimData.clientEmail)
                                          ? `mailto=${selectedClaimData.clientEmail}`
                                          : '#!'}>{selectedClaimData.clientEmail}</a>
                                      </p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.propertyStreetAddress && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-home"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <p className="bold">
                                        <a href="#!">
                                        <span style={{display: 'none'}}>{checkNulls.clientInfo = checkNulls.clientInfo
                                          + 1}</span>
                                          Property Address <br/>{
                                          formatAddress({
                                                          street: selectedClaimData.propertyStreetAddress,
                                                          city: selectedClaimData.propertyCity,
                                                          state: selectedClaimData.propertyState,
                                                          country: selectedClaimData.propertyCountry,
                                                          zip: selectedClaimData.propertyZipcode
                                                        })
                                        }
                                        </a>
                                      </p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.mailingStreetAddress && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-envelope"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <p className="bold">
                                        <a href="#!">
                                        <span style={{display: 'none'}}>{checkNulls.clientInfo = checkNulls.clientInfo
                                          + 1}</span>
                                          Mailing Address: <br/>{
                                          formatAddress({
                                                          street: selectedClaimData.mailingStreetAddress,
                                                          city: selectedClaimData.mailingCity,
                                                          state: selectedClaimData.mailingState,
                                                          country: '',
                                                          zip: selectedClaimData.mailingZip
                                                        })
                                        }
                                        </a>
                                      </p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.dateCalledIn && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-calendar"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Date Called In</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.clientInfo = checkNulls.clientInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.dateCalledIn}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.contractSigned && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-calendar"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Contract Signed</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.clientInfo = checkNulls.clientInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.contractSigned}</p>
                                    </div>
                                  </li>}
                                </ul>
                                {(checkNulls.clientInfo === 0)
                                  ?
                                  <div className="task-list panel-collapse collapse in">
                                    <div className="task-list panel-collapse collapse in">
                                      <div className="alert alert-info" style={{margin: '0px', fontSize: '1.4rem'}}>
                                        <i className="icon-info"/> Client Info will be shown here. Edit the file to
                                        add
                                        it.
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  ''}
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-list"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(policyInfo) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 href="#!" onClick={() => {
                                if (policyInfo) {
                                  setPolicyInfo(false);
                                } else {
                                  setPolicyInfo(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Policy Info</div>
                                </div>
                              </a>
                              <div className={(policyInfo)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  {selectedClaimData.insuranceCompanyName && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-building-o"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Insurer</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.policyInfo = checkNulls.policyInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.insuranceCompanyName}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.policyNumber && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-barcode"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Policy Number</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.policyInfo = checkNulls.policyInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.policyNumber}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.deductible && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-money"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Deductible</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.policyInfo = checkNulls.policyInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.deductible}</p>
                                    </div>
                                  </li>}

                                </ul>
                                {(checkNulls.policyInfo === 0) ?
                                  <div className="alert alert-info" style={{margin: '0px', fontSize: '1.4rem'}}>
                                    <i className="icon-info"/> Policy Info will be shown here. Example Insurer,
                                    Policy
                                    Number, Deductible. Edit the file to add it.
                                  </div>
                                  :
                                  ''}

                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-list"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(lossInfo) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 href="#!" onClick={() => {
                                if (lossInfo) {
                                  setLossInfo(false);
                                } else {
                                  setLossInfo(true);
                                }
                              }}>
                                <div className="list-toggle done uppercase">
                                  <div className="list-toggle-title bold">LOSS Info</div>
                                </div>
                              </a>
                              <div className={(lossInfo)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  {selectedClaimData.causesOfLoss && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-arrow-circle-right"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Cause of Loss</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.lossInfo = checkNulls.lossInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.causesOfLoss}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.claimNumber && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-arrow-circle-right"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Claim Number</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.lossInfo = checkNulls.lossInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.claimNumber}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.dateOfLoss && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-calendar"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Date of Loss</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.lossInfo = checkNulls.lossInfo
                                        + 1}</span>
                                      <p>{selectedClaimData.dateOfLoss}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.propertyStreetAddress && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-map-marker"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <p className="bold">
                                        <a href="#!">
                                        <span style={{display: 'none'}}>{checkNulls.lossInfo = checkNulls.lossInfo
                                          + 1}</span>
                                          Loss Address <br/>{
                                          formatAddress({
                                                          street: selectedClaimData.propertyStreetAddress,
                                                          city: selectedClaimData.propertyCity,
                                                          state: selectedClaimData.propertyState,
                                                          country: selectedClaimData.propertyCountry,
                                                          zip: selectedClaimData.propertyZipcode
                                                        })
                                        }
                                        </a>
                                      </p>
                                    </div>
                                  </li>}
                                </ul>
                                {(checkNulls.lossInfo === 0) ? <div className="alert alert-info"
                                                                    style={{margin: '0px', fontSize: '1.4rem'}}>
                                  <i className="icon-info"/> Loss Info will be shown here. Example Cause of Loss,
                                  Claim
                                  Number, Date of Loss. Edit the file to add it.
                                </div> : ''}
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item ">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-user"/>
                            </div>
                            <div className="list-todo-item dark deskAdjuster">
                              <a className={(deskAdjuster)
                                ? 'task-list list-toggle-container'
                                : 'task-list list-toggle-container collapsed'} href="#!" onClick={() => {
                                if (deskAdjuster) {
                                  setDeskAdjuster(false);
                                } else {
                                  setDeskAdjuster(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Desk Adjuster</div>
                                </div>
                              </a>
                              <div className={(deskAdjuster)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  <li className="task-list-item">
                                    {selectedClaimData.insAdjuster1Name && <div className="task-icon">
                                      <a href="#!">
                                      <span style={{display: 'none'}}>{checkNulls.deskAdjuster = checkNulls.deskAdjuster
                                        + 1}</span>
                                        <i className="fa fa-user"/>
                                      </a>
                                    </div>}
                                    <div className="task-content">
                                      {selectedClaimData.insAdjuster1Name && <h4 className="uppercase bold">
                                        <a href="#!"><span style={{display: 'none'}}>{checkNulls.deskAdjuster
                                          = checkNulls.deskAdjuster + 1}</span> {selectedClaimData.insAdjuster1Name}</a>
                                      </h4>}
                                      <p>
                                        {selectedClaimData.insuranceCompanyName && <span>
                                                                                    <i className="fa fa-building"/>  <span
                                          style={{display: 'none'}}>{checkNulls.deskAdjuster = checkNulls.deskAdjuster
                                          + 1}</span> {selectedClaimData.insuranceCompanyName}
                                                                                    </span>}
                                      </p>
                                      {selectedClaimData.insAdjuster1Phone && <p><i className="fa fa-phone"/>
                                        <span style={{display: 'none'}}>{checkNulls.deskAdjuster
                                          = checkNulls.deskAdjuster
                                          + 1}</span> {selectedClaimData.insAdjuster1Phone}
                                      </p>}
                                      {selectedClaimData.insAdjuster1Email && <p><i className="fa fa-at"/> <span
                                        style={{display: 'none'}}>{checkNulls.deskAdjuster = checkNulls.deskAdjuster
                                        + 1}</span> {selectedClaimData.insAdjuster1Email}</p>}
                                    </div>
                                  </li>
                                </ul>
                                {checkNulls.deskAdjuster === 0 && <div className="alert alert-info"
                                                                       style={{margin: '0px', fontSize: '1.4rem'}}>
                                  <i className="icon-info"/> Desk Adjuster will be shown here. Example Insurer,
                                  Policy
                                  Number, Deductible. Edit the file to add it.
                                </div>}

                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-user"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(adjusterAssigned)
                                ? 'list-toggle-container'
                                : 'list-toggle-container collapsed'} href="#!" onClick={() => {
                                if (adjusterAssigned) {
                                  setAdjusterAssigned(false);
                                } else {
                                  setAdjusterAssigned(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Adjuster Assigned</div>
                                </div>
                              </a>
                              <div className={(adjusterAssigned)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  {selectedClaimData.adjusterId.length > 0 && <div>
                                    {selectedClaimData.adjusterId.map((a, i) => {
                                      return (
                                        <li key={i} className="task-list-item">
                                          <div className="task-icon">
                                            <a href="#!">
                                              <i className="fa fa-user"/>
                                            </a>
                                          </div>
                                          <div className="task-content">
                                            <h4 className="uppercase bold">
                                              <a href="#!">{a.name}</a>
                                            </h4>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </div>}
                                </ul>
                                {selectedClaimData.adjusterId.length === 0 && <div className="alert alert-info" style={{
                                  margin: '0px',
                                  fontSize: '1.4rem'
                                }}>
                                  <i className="icon-info"/> Adjuster Assigned will be shown here. Edit the file to
                                  add
                                  it.
                                </div>
                                }
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-money"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(payments) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 href="#!" onClick={() => {
                                if (payments) {
                                  setPayments(false);
                                } else {
                                  setPayments(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Payments</div>
                                </div>
                              </a>
                              <div className={(payments) ? ' panel-collapse collapse in' : ' panel-collapse collapse'}>
                                <ul/>
                                <div className="alert alert-info" style={{margin: '0px', fontSize: '1.4rem'}}>
                                  <i className="icon-info"/> Payments will be shown here. Edit the file to add it.
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-user"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(vendors)
                                ? 'task-list list-toggle-container'
                                : 'task-list list-toggle-container collapsed'} href="#!" onClick={() => {
                                if (vendors) {
                                  setVendors(false);
                                } else {
                                  setVendors(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Vendor</div>
                                </div>
                              </a>
                              <div className={(vendors)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  {selectedClaimData.vendorName && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-user"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Vendor</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.vendors = checkNulls.vendors
                                        + 1}</span>
                                      <p>{selectedClaimData.vendorName}</p>
                                    </div>
                                  </li>}
                                  {selectedClaimData.vendorFees && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-dollar"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Vendor Fees</a>
                                      </h4>
                                      <span style={{display: 'none'}}>{checkNulls.vendors = checkNulls.vendors
                                        + 1}</span>
                                      <p>${selectedClaimData.vendorFees}</p>
                                    </div>
                                  </li>}
                                </ul>
                                {(checkNulls.vendors === 0) ? <div className="alert alert-info"
                                                                   style={{margin: '0px', fontSize: '1.4rem'}}>
                                  <i className="icon-info"/> Vendor will be shown here. Edit the file to add it.
                                </div> : ''}
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-user"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(attorney) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 href="#!" onClick={() => {
                                if (attorney) {
                                  setAttorney(false);
                                } else {
                                  setAttorney(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Attorney</div>
                                </div>
                              </a>
                              <div className={(attorney)
                                ? ' task-list panel-collapse collapse in'
                                : ' task-list panel-collapse collapse'}>
                                <ul>
                                  {selectedClaimData.attorney !== '' && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-user"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a href="#!">Attorney</a>
                                      </h4>
                                      <p>{selectedClaimData.attorneyName}</p>
                                    </div>
                                  </li>}
                                </ul>
                                {
                                  selectedClaimData.attorney === '' && <div className="alert alert-info"
                                                                            style={{margin: '0px', fontSize: '1.4rem'}}>
                                    <i className="icon-info"/> Attorney Info will be shown here. Edit the file to add
                                    it.
                                  </div>
                                }
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-calendar"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(appointment) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 href="#!" onClick={() => {
                                if (appointment) {
                                  setAppointment(false);
                                } else {
                                  setAppointment(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Appointment</div>
                                </div>
                              </a>
                              <div className={(appointment)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  {selectedClaimData.upcomingAppointmentDate && <li className="task-list-item">
                                    <div className="task-icon">
                                      <a href="#!">
                                        <i className="fa fa-clock-o"/>
                                      </a>
                                    </div>
                                    <div className="task-content">
                                      <h4 className="uppercase bold">
                                        <a
                                          href="#!">{selectedClaimData.upcomingAppointmentDate} {selectedClaimData.upcomingAppointmentTime}</a>
                                      </h4>
                                    </div>
                                  </li>}
                                </ul>
                                {(selectedClaimData.upcomingAppointmentDate === '') ? <div className="alert alert-info"
                                                                                           style={{
                                                                                             margin: '0px',
                                                                                             fontSize: '1.4rem'
                                                                                           }}>
                                  <i className="icon-info"/> Appointment will be shown here. Edit the file to add it.
                                </div> : ''}
                              </div>
                            </div>
                          </li>
                          <li className="mt-list-item">
                            <div className="list-todo-icon bg-white">
                              <i className="fa fa-sticky-note-o"/>
                            </div>
                            <div className="list-todo-item dark">
                              <a className={(notes) ? 'list-toggle-container' : 'list-toggle-container collapsed'}
                                 href="#!" onClick={() => {
                                if (notes) {
                                  setNotes(false);
                                } else {
                                  setNotes(true);
                                }
                              }}>
                                <div className="list-toggle uppercase">
                                  <div className="list-toggle-title bold">Notes</div>
                                </div>
                              </a>
                              <div className={(notes)
                                ? 'task-list panel-collapse collapse in'
                                : 'task-list panel-collapse collapse'}>
                                <ul>
                                  {(selectedClaimNotes.length > 0) ? <div>
                                    {selectedClaimNotes.map((n, index) => {
                                      return (
                                        <li key={index} className="task-list-item">
                                          <div className="task-icon">
                                            <a href="#!">
                                              <i className="fa fa-building-o"/>
                                            </a>
                                          </div>
                                          <div className="task-content">
                                            <h4 className="uppercase bold">
                                              <a href="#!">{n.userName} at {n.formatedDate}</a>
                                            </h4>
                                            <div dangerouslySetInnerHTML={{__html: n.note}}/>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </div> : ''}
                                </ul>
                                {(selectedClaimNotes.length === 0) &&
                                  <div className="alert alert-info" style={{margin: '0px', fontSize: '1.4rem'}}>
                                    <i className="icon-info"/> Notes will be shown here. Edit the file to add it.
                                  </div>
                                }
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="task-footer bg-grey">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </SlidingSidePanel>
    </div>
  );
}