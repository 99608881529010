import React from "react";
import {
  ToastContainer as ReactToastifyContainer,
  toast,
  Slide,
} from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

import "./ToastContainer.css";

export const ToastContainer = () => (
  <ReactToastifyContainer
    position={toast.POSITION.BOTTOM_LEFT}
    autoClose={5000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    transition={Slide}
    limit={10}
  />
);
