import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {Error} from '../_common/Error';
import {PhoneNumberMask} from '../_common/MaskedInput';
import {SubmitButton} from '../profile/SubmitButton';

const INITIAL_ATTORNEY_STATE = {
  name: '', email: '', streetAddress: '', city: '', state: '', zipcode: '', phone: '', phoneExt: '', fax: ''
};

function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function AddNewAndEdit({openPanel, setOpenPanel, attorneyDetails, reloadTable}) {
  const [attorneyState, setAttorneyState] = useState(attorneyDetails || INITIAL_ATTORNEY_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {setAttorneyState(attorneyDetails || INITIAL_ATTORNEY_STATE);}, [setAttorneyState, attorneyDetails]);

  const {
    name = '',
    email = '',
    streetAddress = '',
    city = '',
    state = '',
    zipcode = '',
    phone = '',
    phoneExt = '',
    fax = ''
  } = attorneyState;

  async function handleSubmit(event) {
    event.preventDefault();
    if (!name) {
      setError('Name is required');
      return false;
    }
    if (!email) {
      setError('Email is required');
      return false;
    }
    if (!validateEmail(email)) {
      setError('Email Should be valid.');
      return false;
    }
    setIsLoading(true);
    try {
      if (attorneyState.id) {
        await Api.Attorneys.edit({id: attorneyState.id, attorney: attorneyState});
      } else {
        await Api.Attorneys.create({attorney: attorneyState});
      }
      reloadTable();
      setOpenPanel(false);
      setError('');
      setAttorneyState(INITIAL_ATTORNEY_STATE);
    } catch (err) {
      if (err.response
        && err.response.data
        && err.response.data.errors[0]
        && err.response.data.errors[0].msg) {
        setError(err.response.data.errors[0].msg);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleUpdate(field) {
    return ({target}) =>
      setAttorneyState({...attorneyState, [field]: target.value});
  }

  const caption = attorneyState.id ? 'Edit Attorney Details' : 'Add New Attorney';

  function closePanel(e) {
    e.preventDefault();
    setOpenPanel(false);
    setError('');
    setAttorneyState(INITIAL_ATTORNEY_STATE);
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {caption}
              </span>
            </div>
            <div className="tools">
              <a className="remove" title="" href="#!" data-original-title="" onClick={closePanel}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error}/> : ''}
            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Attorney Name</label>
                <input className="form-control" type="text" name="attorneyName" placeholder="Attorney Name" value={name}
                       onChange={handleUpdate('name')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Email Address</label>
                <input className="form-control" type="text" name="attorneyEmail" placeholder="Email Address"
                       value={email} onChange={handleUpdate('email')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Street</label>
                <input className="form-control" type="text" name="attorneyStreet" placeholder="Street"
                       value={streetAddress} onChange={handleUpdate('streetAddress')}/>
              </div>
              <div className="form-group">
                <label className="control-label">City</label>
                <input className="form-control" type="text" name="attorneyCity" placeholder="City" value={city}
                       onChange={handleUpdate('city')}/>
              </div>
              <div className="form-group">
                <label className="control-label">State</label>
                <input className="form-control" type="text" name="attorneyState" placeholder="State" value={state}
                       onChange={handleUpdate('state')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Zip Code</label>
                <input className="form-control" type="text" name="attorneyZip" placeholder="Zip Code" value={zipcode}
                       onChange={handleUpdate('zipcode')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Phone #</label>
                <PhoneNumberMask placeholderText="Phone #" value={phone} handleChange={handleUpdate('phone')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Extension</label>
                <input type="text" className="form-control" name="attorneyExtension" placeholder="Extension"
                       value={phoneExt} onChange={handleUpdate('phoneExt')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Fax #</label>
                <PhoneNumberMask placeholderText="Fax #" value={fax} handleChange={handleUpdate('fax')}/>
              </div>

              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading}/>

                    <button className="btn default" style={{margin: 5}} type="button" name="cancel" value="cancel"
                            onClick={closePanel}>
                      <i className="fa fa-times"/> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
