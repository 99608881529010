import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as Api from "../../../Api";
import { AuthActions } from "../../../store/auth";

function useLogin() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const [activeAccountError, setActiveAccountError] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "username") {
      setUsername(value);
    }

    if (name === "password") {
      setPassword(value);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    try {
      const { data } = await Api.Auth.login({ email: username, password });
      localStorage.setItem("token", data.token);

      const { data: user } = await Api.Auth.getAuthUser();
      dispatch(AuthActions.GetAuthUser.success(user));

      history.push("/");
    } catch (err) {
      if(err && err.response && err.response.status === 401){
        setActiveAccountError(true);
      }
      if(err && err.response && err.response.status !== 401){
        setError(Api.getError(err));
      }
      // 
      
    } finally {
      setIsLoggingIn(false);
    }
  };

  const resendVerificationEmail = async() => {
    setActiveAccountError(false);
    try{
      await Api.Auth.resendverificationEmail({
        email: username
      });
      setSuccessMessage(true);
    }catch(err){
      setError(Api.getError(err));
    }
  };

  return {
    isLoggingIn,
    error,
    username,
    password,
    handleChange,
    handleLogin,
    activeAccountError,
    resendVerificationEmail,
    successMessage
  };
}

function useLogout() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("token");

    dispatch(AuthActions.GetAuthUser.clear());

    history.push("/login");
  }, [dispatch, history]);
}

export { useLogin, useLogout };
