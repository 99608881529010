import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {Error} from '../_common/Error';
import {PhoneNumberMask} from '../_common/MaskedInput';
import {SubmitButton} from '../profile/SubmitButton';

function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const INITIAL_VENDOR_STATE = {
  name: '', email: '', streetAddress: '', city: '', state: '', zipcode: '', phone: '', phoneExt: '', fax: ''
};

export function NewAndEditVendors({openPanel, setOpenPanel, vendorDetails, reloadTable}) {
  const [vendorState, setVendorState] = useState(vendorDetails || INITIAL_VENDOR_STATE);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => setVendorState(vendorDetails || INITIAL_VENDOR_STATE), [setVendorState, vendorDetails]);

  async function handleSubmit(event) {
    event.preventDefault();
    const {name, email} = vendorState;
    if (name === '') {
      setError('Name is required');
      return false;
    }
    if (email === '') {
      setError('Email is required');
      return false;
    }
    if (!validateEmail(email)) {
      setError('Email Should be valid.');
      return false;
    }
    try {
      setIsLoading(true);
      if (vendorState.id) {
        await Api.Vendors.edit({id: vendorState.id, vendor: vendorState});
      } else {
        await Api.Vendors.create({vendor: vendorState});
      }
      reloadTable();
      setOpenPanel(false);
      setVendorState(INITIAL_VENDOR_STATE);
    } catch (error) {
      if (error.response
        && error.response.data
        && error.response.data.errors[0]
        && error.response.data.errors[0].msg) {
        setError(error.response.data.errors[0].msg);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose(e) {
    e.preventDefault();
    setOpenPanel(false);
    setVendorState(INITIAL_VENDOR_STATE);
  }

  function handleUpdate(field) {
    return ({target}) => setVendorState({...vendorState, [field]: target.value});
  }

  const {
    name = '',
    email = '',
    streetAddress = '',
    city = '',
    state = '',
    zipcode = '',
    phone = '',
    phoneExt = '',
    fax = ''
  } = vendorState;
  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {vendorState.id ? 'Edit Vendor Details' : 'Add New Vendor'}
              </span>
            </div>
            <div className="tools">
              <a className="remove" href="#!" data-original-title="" title="" onClick={handleClose}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error}/> : ''}

            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Vendor Name</label>
                <input className="form-control" type="text" name="vendorName" placeholder="Vendor Name" value={name}
                       onChange={handleUpdate('name')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Email Address</label>
                <input className="form-control" type="text" name="vendorEmail" placeholder="Email Address"
                       value={email} onChange={handleUpdate('email')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Street</label>
                <input className="form-control" type="text" name="vendorStreet" placeholder="Street"
                       value={streetAddress} onChange={handleUpdate('streetAddress')}/>
              </div>
              <div className="form-group">
                <label className="control-label">City</label>
                <input className="form-control" type="text" name="vendorCity" placeholder="City" value={city}
                       onChange={handleUpdate('city')}/>
              </div>
              <div className="form-group">
                <label className="control-label">State</label>
                <input className="form-control" type="text" name="vendorState" placeholder="State" value={state}
                       onChange={handleUpdate('state')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Zipcode</label>
                <input className="form-control" type="text" name="vendorZipcode" placeholder="Zipcode" value={zipcode}
                       onChange={handleUpdate('zipcode')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Phone #</label>
                <PhoneNumberMask inputClass="form-control" placeholderText="Phone #" value={phone}
                                 handleChange={handleUpdate('phone')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Extension</label>
                <input className="form-control" type="text" name="vendorExtension" placeholder="Extension"
                       value={phoneExt} onChange={handleUpdate('phoneExt')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Fax #</label>
                <PhoneNumberMask inputClass="form-control" placeholderText="Fax #" value={fax}
                                 handleChange={handleUpdate('fax')}/>
              </div>
              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading}/>
                    <button className="btn default" style={{margin: 5}} type="button" name="cancel" value="cancel"
                            onClick={handleClose}>
                      <i className="fa fa-times"/> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
