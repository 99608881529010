import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

function DeleteSweetAlertConfirmation({ show, clickHandler }) {
  return (
    <div>
      <SweetAlert
        show={Boolean(show)}
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          clickHandler(true);
        }}
        onCancel={() => {
          clickHandler(false);
        }}
        focusCancelBtn
      >
        You will not be able to recover this!
      </SweetAlert>
    </div>
  );
}

function InfoMessage({ show, clickHandler, heading, message }) {
  return (
    <div>
      <SweetAlert
        show={show}
        info
        confirmBtnText="OK"
        title={heading}
        onConfirm={() => {
          clickHandler(false);
        }}
      >
        {message}
      </SweetAlert>
    </div>
  );
}

function SuccessMessage({ show, clickHandler, heading, message }) {
  return (
    <div>
      <SweetAlert
        show={show}
        success
        confirmBtnText="OK"
        title={heading ? heading : ""}
        onConfirm={() => {
          clickHandler(false);
        }}
      >
        {message}
      </SweetAlert>
    </div>
  );
}

function WarningMessage({
    show,
    clickHandler,
    cancelHandler,
    heading,
    message,
    type
}){
    return (
        <div>
            {(type === "warning") && 
                <SweetAlert
                    show = {show}
                    danger
                    confirmBtnText="OK"
                    title= {heading}
                    onConfirm={() => {
                        clickHandler()
                    }}
                >
                    {message}
                </SweetAlert>
            }

            {!type && 
                <SweetAlert
                    show = {show}
                    info
                    confirmBtnText="OK"
                    title= {heading}
                    showCancel 
                    onConfirm={() => {
                        clickHandler()
                    }}
                    onCancel={() => {
                        cancelHandler();
                    }}
                >
                    {message}
                </SweetAlert>
            }
            
        </div>
    );
}

function ErrorMessage({
    show,
    clickHandler,
    msg
}){
    return (
        <div>
            <SweetAlert
                show = {show}
                warning
                confirmBtnText="Ok"
                confirmBtnBsStyle="danger"
                title=""
                onConfirm={() => {
                    clickHandler(true);
                }}
            >
            {msg}
            </SweetAlert>
        </div>
    );
}

export { DeleteSweetAlertConfirmation, InfoMessage, SuccessMessage, ErrorMessage, WarningMessage };
