import React from 'react';
import {useAsyncDebounce, useGlobalFilter, useSortBy, useTable} from 'react-table';
import {Error} from '../_common/Error';
import {FullPageSpinner} from '../_common/Spinner';

function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input className="form-control input-sm input-small input-inline" value={value || ''} type="search"
             onChange={(e) => {
               setValue(e.target.value);
               onChange(e.target.value);
             }}/>
    </span>
  );
}

function AdjustersDatatable({adjusters, isLoading, error, setOpensidePanel, setAdjusterDetails, confirmDelete}) {
  const columns = [
    {Header: 'Adjuster Name', accessor: 'name', sortType: 'basic'},
    {Header: 'Email Address', accessor: 'email', sortType: 'basic'},
    {Header: 'Phone #', accessor: 'phone', sortType: 'basic'},
    {Header: 'Extension', accessor: 'phoneExt', sortType: 'basic'},
    {Header: 'Fax #', accessor: 'fax', sortType: 'basic'},
    {Header: 'License Number #', accessor: 'license', sortType: 'basic'},
    {Header: 'Status', accessor: 'status', sortType: 'basic'},
    {
      Header: 'Action',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({row}) => (
        <div data-testid={`adjuster-actions-${row?.original?.id}`}>
          <a className="edit" style={{marginRight: '8px', color: '#1bbc9b'}} href="#!" data-testid="edit-adjuster"
             onClick={(e) => {
               e.preventDefault();
               setOpensidePanel(true);
               setAdjusterDetails(row.original);
             }}>
            <i className="fa fa-pencil"/>
          </a>
          <a className="delete" style={{color: '#e7505a'}} href="#!" data-testid="delete-adjuster"
             onClick={(e) => {
               e.preventDefault();
               confirmDelete(row.original);
             }}>
            <i className="fa fa-trash-o"/>
          </a>
        </div>
      ),
      editRow({id}) {
        console.log(id);
        return false;
      }
    }
  ];
  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (error) {
    return <Error message={error}/>;
  }

  if (!(adjusters && adjusters.length)) {
    return (
      <div>
        <h6 style={{textAlign: 'center', fontWeight: 'bold'}}>
          All your Adjusters will show here. Use the 'Add New +' button to add
          an Adjuster.
        </h6>
      </div>
    );
  }

  return <AdjustersTable adjustersData={adjusters} columns={columns}/>;
}

function AdjustersTable({adjustersData, columns, updateMyData}) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: adjustersData,
      updateMyData,
      initialState: {sortBy: [{id: 'name', desc: false}]}
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className="dataTables_wrapper no-footer">
      <div className="dataTables_filter">
        <label>
          Search:
          {
            <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter}
                          setGlobalFilter={setGlobalFilter}/>
          }
        </label>
      </div>
      <table id="Admin-Data-Table" data-testid="adjusters-table"
             className="table table-striped table-hover table-bordered dataTable no-footer"
             {...getTableProps()}>
        <thead>
        {
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map((column) => {
                  let headerClassName = '';
                  if (!column.disableSortBy) {
                    headerClassName = 'sorting';
                    if (column.isSorted) {
                      headerClassName = ' sorting_asc';
                      if (column.isSortedDesc) {
                        headerClassName = 'sorting_desc';
                      }
                    }
                  }
                  return (
                    <th className={headerClassName}{...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  );
                })
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>))}
              </tr>
            );
          })
        }
        </tbody>
      </table>
    </div>
  );
}

export {AdjustersDatatable};
