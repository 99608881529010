import React, {useEffect, useState} from 'react';
import {Icon} from '../_common/Icons';

import useClaimTableQueryParams from './useClaimTableQueryParams';

const options = [10, 20, 50, 100, 150, 200];

export function PaginationPanel({total}) {
  const [query, setQuery] = useClaimTableQueryParams();
  const {currentPage, limitPerPage} = query;
  const [localCurrentPage, setLocalCurrentPage] = useState(currentPage || 1);

  useEffect(() => {
    setLocalCurrentPage(currentPage || 1);
  }, [currentPage, setLocalCurrentPage]);

  if (!(total && limitPerPage)) {
    return null;
  }

  const numberOfPages = Math.max(1, Math.ceil(total / limitPerPage));

  function goToNextPage() {
    setQuery({...query, currentPage: Math.min(currentPage + 1, numberOfPages)});
  }

  function goToPreviousPage() {
    setQuery({...query, currentPage: Math.max(currentPage - 1, 1)});
  }

  function handleLimitChange(e) {
    const newLimitPerPage = Number(e.target.value);
    const newPageCount = Math.ceil(total / newLimitPerPage);
    const newCurrentPage = Math.min(currentPage, newPageCount);
    setQuery({...query, limitPerPage: newLimitPerPage, currentPage: newCurrentPage});
  }

  function handlePageInputChange() {
    setQuery({...query, currentPage: Math.max(1, Math.min(localCurrentPage, numberOfPages))});
  }

  function handleInputKeyPress(e) {
    if (e.key === 'Enter') {
      handlePageInputChange();
    }
  }

  return (
    <div style={{display: 'flex'}}>
      <div style={{paddingLeft: 10, paddingRight: 10}}>
        <label>
          Show{' '}
          <select className="form-control input-sm input-xsmall input-inline" name="sample_5_length"
                  value={limitPerPage} aria-controls="sample_5" onChange={handleLimitChange}>
            {options.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </label>
      </div>
      <div>
        Page{' '}
        <a className={`btn btn-sm default prev ${currentPage === 1 ? 'disabled' : ''}`}
           style={{marginTop: '0px', position: 'initial'}} href="#!" onClick={goToPreviousPage}>
          <Icon.AngleLeft/>
        </a>
        <input className="pagination-panel-input form-control input-sm input-inline input-mini"
               style={{textAlign: 'center !important'}} type="number" value={localCurrentPage} maxLength="5"
               min="1" max={numberOfPages}
               onChange={e => setLocalCurrentPage(Number(e.target.value))} onBlur={handlePageInputChange}
               onKeyPress={handleInputKeyPress}/>
        <a className={`btn btn-sm default next ${currentPage === numberOfPages ? 'disabled' : ''}`}
           style={{marginTop: '0px', position: 'initial'}} href="#!" onClick={goToNextPage}>
          <Icon.AngleRight/>
        </a>{' '}
        of <span className="pagination-panel-total">{numberOfPages}</span>
      </div>
    </div>
  );
}
