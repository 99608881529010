import { CustomFieldTypes as Types } from "./index";

const INITIAL_CUSTOM_FIELDS_STATE = {
  isLoading: false,
  list: [],
};

export default function CustomFieldsReducer(
  state = INITIAL_CUSTOM_FIELDS_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.GET_CUSTOM_FIELDS.START:
      return {
        ...state,
        isLoading: true,
      };
    case Types.GET_CUSTOM_FIELDS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      };

    case Types.GET_CUSTOM_FIELDS.ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
