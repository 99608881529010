import React, { useState, useEffect } from "react";
// import {useSelector} from 'react-redux';
import { DocumentsTab } from "./DocumentsTab";
import { EstimatesTab } from "./EstimatesTab";
import { PhotosTab } from "./PhotosTab";
import { EmailsTab } from "./EmailsTab";
import { ReportsTab } from "./ReportsTab";
import { UploadsTab } from "./UploadsTab";
import { CompanyCamTab } from "./CompanyCamTab";
import { RenameFolder } from "./RenameFolders";
import * as Api from "../../../../Api";
import { SharedClaim } from "../../../../api/sharedClaim";
// import { useClaim } from "../claim.hooks";

import "./AttachmentsFolders.css";

export const AttachmentFolders = ({
  claimId,
  setSelectedFolderId,
  selectedFilesId,
  setSelectedFilesId,
  isSharedView,
}) => {
  // const { 
  //   data: { _raw: {ccProjectId} = {} } = {},
  // } = useSelector((state) => state.claims.claim);

  // const { isLoading } = useClaim();
  // const claim = useSelector((state) => state.claims.claim);
  // const ccProjectId = claim?.data?._raw?.ccProjectId;

  const [folderName, setFolderName] = useState([]);
  const [documentFolderTabView, setDocumentFolderTabView] = useState(false);
  const [estimateFolderTabView, setEstimateFolderTabView] = useState(false);
  const [photosFolderTabView, setPhotosFolderTabView] = useState(false);
  const [reportsFolderTabView, setReportsFolderTabView] = useState(false);
  const [emailsFolderTabView, setEmailsFolderTabView] = useState(false);
  const [uploadsFolderTabView, setUploadsFolderTabView] = useState(false);
  const [companyCamFolderTabView, setCompanyCamFolderTabView] = useState(false);
  const [renameFolderSection, setRenameFolderSection] = useState(false);

  const resetAllTab = () => {
    setDocumentFolderTabView(false);
    setEstimateFolderTabView(false);
    setPhotosFolderTabView(false);
    setReportsFolderTabView(false);
    setEmailsFolderTabView(false);
    setUploadsFolderTabView(false);
    setCompanyCamFolderTabView(false);
  };

  const loadFolderName = () => {
    Api.Claims.Attachments.folder
      .get()
      .then((data) => {
        setFolderName(data.data.folders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSharedClaimFolderName = () => {
    SharedClaim.getAttachmentFolders()
      .then((data) => {
        setFolderName(data.data.folders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFolderNameWithId = ({ id }) => {
    let obj = folderName.find((o) => o.defaultFolderId === id);
    if (obj && obj.folderName) {
      return obj.folderName;
    }
    return "";
  };

  const capitalizeFLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  useEffect(() => {
    if (isSharedView) {
      loadSharedClaimFolderName();
    }
    if (!isSharedView) {
      loadFolderName();
    }
    setDocumentFolderTabView(true);
  }, [isSharedView/*, ccProjectId*/]);

  // if (isLoading) {
  //   return <div>Loading</div>;
  // }

  return (
    <div>
      <RenameFolder
        openPanel={renameFolderSection}
        setOpenPanel={setRenameFolderSection}
        allFoldersName={folderName}
        setAllFolderName={setFolderName}
      />

      <div className="tw-flex tw-space-x-2 tw-text-base tw-px-5">

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${documentFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setDocumentFolderTabView(true);
            setSelectedFolderId(1);
            setSelectedFilesId([]);
          }}
        >
          {capitalizeFLetter(
            getFolderNameWithId({
              id: 1,
            })
          )}
        </a>

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${estimateFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setEstimateFolderTabView(true);
            setSelectedFolderId(2);
            setSelectedFilesId([]);
          }}
        >
          {capitalizeFLetter(
            getFolderNameWithId({
              id: 2,
            })
          )}
        </a>

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${photosFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setPhotosFolderTabView(true);
            setSelectedFolderId(3);
            setSelectedFilesId([]);
          }}
        >
          {capitalizeFLetter(
            getFolderNameWithId({
              id: 3,
            })
          )}
        </a>

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${reportsFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setReportsFolderTabView(true);
            setSelectedFolderId(4);
            setSelectedFilesId([]);
          }}
        >
          {capitalizeFLetter(
            getFolderNameWithId({
              id: 4,
            })
          )}
        </a>

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${emailsFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setEmailsFolderTabView(true);
            setSelectedFolderId(5);
            setSelectedFilesId([]);
          }}
        >
          {capitalizeFLetter(
            getFolderNameWithId({
              id: 5,
            })
          )}
        </a>

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${uploadsFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setUploadsFolderTabView(true);
            setSelectedFolderId(6);
            setSelectedFilesId([]);
          }}
        >
          {capitalizeFLetter(
            getFolderNameWithId({
              id: 6,
            })
          )}
        </a>

        <a className={`tw-rounded-md tw-py-1 tw-px-3 tw-font-bold hover:tw-no-underline hover:tw-bg-gray-100 focus:tw-no-underline focus:tw-text-blue ${companyCamFolderTabView ? "tw-bg-blue-300 tw-text-blue" : "tw-text-gray-500 hover:tw-text-gray-700"}`} 
          href="#attachments"
          onClick={() => {
            resetAllTab();
            setCompanyCamFolderTabView(true);
            setSelectedFolderId(7);
            setSelectedFilesId([]);
          }}
        >
          CompanyCam
        </a>
      </div>

      <div className="tab-content client-edit-mid-tab-content">
        <div className={documentFolderTabView ? "tab-pane active" : "tab-pane"}>
          {documentFolderTabView && (
            <DocumentsTab
              claimId={claimId}
              folderId={1}
              folderName={getFolderNameWithId({
                id: 1,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={estimateFolderTabView ? "tab-pane active" : "tab-pane"}>
          {estimateFolderTabView && (
            <EstimatesTab
              claimId={claimId}
              folderId={2}
              folderName={getFolderNameWithId({
                id: 2,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={photosFolderTabView ? "tab-pane active" : "tab-pane"}>
          {photosFolderTabView && (
            <PhotosTab
              claimId={claimId}
              folderId={3}
              folderName={getFolderNameWithId({
                id: 3,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={reportsFolderTabView ? "tab-pane active" : "tab-pane"}>
          {reportsFolderTabView && (
            <ReportsTab
              claimId={claimId}
              folderId={4}
              folderName={getFolderNameWithId({
                id: 4,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={emailsFolderTabView ? "tab-pane active" : "tab-pane"}>
          {emailsFolderTabView && (
            <EmailsTab
              claimId={claimId}
              folderId={5}
              folderName={getFolderNameWithId({
                id: 5,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={uploadsFolderTabView ? "tab-pane active" : "tab-pane"}>
          {uploadsFolderTabView && (
            <UploadsTab
              claimId={claimId}
              folderId={6}
              folderName={getFolderNameWithId({
                id: 6,
              })}
              selectedFiles={selectedFilesId}
              setSelectedFiles={setSelectedFilesId}
              isSharedView={isSharedView}
            />
          )}
        </div>
        <div className={companyCamFolderTabView ? "tab-pane active" : "tab-pane"}>
          {companyCamFolderTabView && (
          <CompanyCamTab
            claimId={claimId} /*ccProjectId={ccProjectId}*/
          />
        )}
        </div>
      </div>
    </div>
  );
};
