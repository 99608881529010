import { ProfileTypes as Types } from "./index";

const INITIAL_PROFILE_STATE = {
  isLoading: false,
  settings: {},
};

export default function ProfileReducer(
  state = INITIAL_PROFILE_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.GET_PROFILE.START:
      return {
        ...state,
        isLoading: true,
      };

    case Types.GET_PROFILE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: payload,
      };

    case Types.GET_PROFILE.ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case Types.UPDATE_PROFILE.START:
      return {
        ...state,
        isLoading: true,
        settings: {
          ...state.settings,
          ...payload,
        },
      };

    case Types.UPDATE_PROFILE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: payload,
      };

    case Types.UPDATE_PROFILE.ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
