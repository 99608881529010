import { useState } from "react";

import bg1 from "../../assets/images/login-bg1.jpg";
import bg2 from "../../assets/images/login-bg2.jpg";

function useBackgroundImage() {
  const [bgImage, setBgImage] = useState(bg1);

  setTimeout(() => {
    if (bgImage === bg1) {
      setBgImage(bg2);
    } else {
      setBgImage(bg1);
    }
  }, 8000);

  return bgImage;
}

export { useBackgroundImage };
