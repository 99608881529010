import { combineReducers } from "redux";

import { AuthReducer } from "./auth";
import { ClaimsReducer } from "./claims";
import { CompanyReducer } from "./company";
import { CustomFieldsReducer } from "./custom-fields";
import { InsurersReducer } from "./insurers";
import { ProfileReducer } from "./profile";
import { StatusReducer } from "./statuses";
import { ThemeReducer } from "./theme";
import { VendorsReducer } from "./vendors";
import { AttorneysReducer } from "./attorneys";

export default combineReducers({
  auth: AuthReducer,
  claims: ClaimsReducer,
  company: CompanyReducer,
  customFields: CustomFieldsReducer,
  insurers: InsurersReducer,
  profile: ProfileReducer,
  statuses: StatusReducer,
  theme: ThemeReducer,
  vendors: VendorsReducer,
  attorneys: AttorneysReducer
});
