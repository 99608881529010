import { takeLatest, put, fork } from "redux-saga/effects";

import {
  ClaimActions as Actions,
  ClaimTypes as Types,
  ClaimUtils as Utils,
  ClaimDataMapper,
} from "./index";

import * as Api from "../../api/index";

function* getSharedClaim() {
  try {
    const {
      data: { claim },
    } = yield Api.SharedClaim.getClaim();

    const formattedClaim = Utils.formatClaimFromApi(claim);

    const {
      data: { customFields },
    } = yield Api.SharedClaim.getCustomFields();

    const { data: claimAdjusters } = yield Api.SharedClaim.getClaimAdjusters();

    yield put(
      Actions.GetSharedClaim.success({ claim: formattedClaim, claimAdjusters })
    );

    const mergedFields = ClaimDataMapper.CustomFieldMappers.mergeCustomFields({
      allCustomFields: customFields,
      claimCustomFields: claim.customFields,
    });

    yield put(Actions.GetSharedClaim.customFieldsSuccess(mergedFields));
  } catch (err) {
    console.error("Error in getSharedClaim", err.message);
    const { error, code } = Api.getDetailedError(err);
    yield put(
      Actions.GetSharedClaim.error({
        code,
        message: error,
        description: err.message,
      })
    );
  }
}

function* watchGetSharedClaimStart() {
  yield takeLatest(Types.GET_SHARED_CLAIM.START, getSharedClaim);
}

const SharedClaimSagas = [fork(watchGetSharedClaimStart)];

export default SharedClaimSagas;
