import { AttorneyTypes as Types } from "./index";

const INITIAL_ATTORNEY_STATE = {
  isLoading: false,
  list: [],
  error: "",
};

export default function AttorneysReducer(
  state = INITIAL_ATTORNEY_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.GET_ATTORNEYS.START:
      return {
        ...state,
        isLoading: true,
      };

    case Types.GET_ATTORNEYS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      };

    case Types.GET_ATTORNEYS.ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
}
