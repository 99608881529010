import {formatDate} from '../../../utils/dateTime';
import {CLIENT_TYPES} from '../claims.constants';

export const ClientType = {
  format(type) {
    switch (type) {
      case 'individual':
      case 'residence':
        return CLIENT_TYPES.residence;

      case 'business':
        return CLIENT_TYPES.business;

      case !type:
      case '':
        return '';

      default:
        throw new Error('Invalid client type');
    }
  },
  mapForApi(type) {
    switch (type) {
      case CLIENT_TYPES.residence:
        return 'individual';

      case CLIENT_TYPES.business:
        return 'business';

      case !type:
        return '';

      default:
        throw new Error('Invalid client type');
    }
  }
};

export const FeeSchedule = {
  format(feeShare = []) {
    return feeShare.map((share) => ({
      userId: share.adjusterId,
      sharePercentage: share.sharePerc
    }));
  },
  mapForApi(feeSchedule = []) {
    return feeSchedule.filter(({userId, sharePercentage}) => userId && sharePercentage)
      .map(({userId, sharePercentage}) => ({adjusterId: userId, sharePerc: sharePercentage}));
  }
};

export const AssignedAdjusters = {
  mapForApi(adjusters = []) {
    return adjusters.map((adjuster) => adjuster.id);
  }
};

export const OtherAdjusterAccess = {
  mapForApi(adjusters = []) {
    return adjusters.map((adjuster) => adjuster.id);
  }
};

export const NewDollarsReceived = {
  format(newDollarReceived = []) {
    return (
      newDollarReceived?.map((received) => ({
        receivedAmount: received.additionalChecksReceived,
        feeAmount: received.feeAmount,
        checkDate: received.NewReceivedPaymentDate
      })) || [{}]
    );
  },
  mapForApi(newDollarReceived = []) {
    return (
      newDollarReceived?.map((received) => ({
        additionalChecksReceived: received.receivedAmount,
        feeAmount: received.feeAmount,
        NewReceivedPaymentDate: formatDate(received.checkDate, 'YYYY-MM-DD')
      })) || []
    );
  }
};

export const ClaimAdjusters = {
  mapForApi(claimAdjusters = []) {
    // due to nightmarish database reasons
    // there should be at least 1 adjuster with id 'legacy-compatible'
    const LEGACY_ID = 'legacy-compatible';

    const legacyIdAdjuster = claimAdjusters.find((adj) => adj.id === LEGACY_ID);

    if (legacyIdAdjuster) {
      return claimAdjusters.filter((adj) => Object.values(adj).length);
    }

    // add legacy id to the first item in the array
    return claimAdjusters
      .map((adj, idx) =>
        idx === 0
          ? {
            ...adj,
            id: LEGACY_ID
          }
          : adj
      )
      .filter((adj) => Object.values(adj).length);
  }
};
