import React, { useEffect, useState } from "react";
import { PopupModal } from "../../_common/PopupModal";

export function ConfirmUnMapped({
  openPanel,
  setOpenPanel,
  setShowMapView,
  setReviewImport,
  fieldsInFile,
  mappedData,
}) {
  const gotoNext = () => {
    setShowMapView(false);
    setOpenPanel(false);
    setReviewImport(true);
  };
  const [totalUnmappedColomns, setTotalUnmappedColomns] = useState(0);

  useEffect(() => {
    const totalUnmapped =
      fieldsInFile.length -
      (typeof mappedData !== "undefined" ? mappedData.length : 0);
    setTotalUnmappedColomns(totalUnmapped);
  }, [fieldsInFile, mappedData]);

  return (
    <div>
      <PopupModal isOpen={openPanel}>
        <div className="modal-dialog" style={{ margin: "0px" }}>
          <div className="modal-content">
            <div className="modal-body" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "18px" }}>
                <b>
                  {totalUnmappedColomns === 1
                    ? "1 column"
                    : `${totalUnmappedColomns} columns`}{" "}
                </b>{" "}
                are not mapped to any field columns.
              </p>
              <p>Are you sure want to continue without mapping them?</p>
              <button
                type="button"
                onClick={() => gotoNext()}
                className="btn btn-outline blue-primary"
                style={{
                  padding: "12px 49px 12px 49px",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              >
                Yes, continue
              </button>
              <button
                type="button"
                onClick={() => setOpenPanel(false)}
                className="btn grey-salsa btn-outline"
                style={{
                  padding: "12px 29px 12px 29px",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
