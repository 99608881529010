const getUserRole = (role) => {
  if (role === "1") {
    return "Admin";
  }

  if (role === "2") {
    return "Adjuster";
  }

  if (role === "Super Admin") {
    return "SuperAdmin";
  }

  if (role === "0") {
    return "SuperAdmin";
  }

  return "Unspecified role";
};

export const mapAuthUser = (user) => {
  return {
    ...user,
    role: getUserRole(user.role),
  };
};
