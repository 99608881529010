import React, { useState, useEffect, useCallback } from "react";
import "../../../../assets/pages/css/error.min.css";
import * as Api from "../../../../Api";

export function SharedClaimAttachment() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const [downloadUrl, setDownloadUrl] = useState()
    document.body.classList.add('page-404-3');

    const getShareAttachmentUrl = useCallback(() => {
        Api.Claims.Attachments.share.getDownloadUrl({
            token
        }).then((data) => {
            setDownloadUrl(data.data.url);
            window.location.href = data.data.url;
        }).catch((err) => {
            console.log(err);
        })
    },[token]);
    
    

    useEffect(() => {
        getShareAttachmentUrl();
    },[getShareAttachmentUrl]);
    

    return (
        <div className="">
            <div className="page-inner">
                <img src="https://staging.organizedadjusting.com/assets/pages/media/pages/earth.jpg" className="img-responsive" alt=""/> 
            </div>
            <div className="container error-404">
                <h1>Hi!</h1>
                {
                    downloadUrl &&
                    <div>
                        <p id="down_para">Download didn't start yet?</p>
                        <p>
                            <a href="#!" onClick={() => {
                                window.location.href = downloadUrl;
                            }} className="btn red btn-outline"> Try Again</a>
                            <br/> 
                        </p>
                    </div>
                }
                {
                    !downloadUrl && 
                    <div>
                        <p>Your file will automatically start downloading in a few seconds..... </p>
                    </div>
                }
            </div>
        </div>
    )

}