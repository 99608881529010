import moment from "moment";

export const formatDate = (date, format = "MM/DD/YY") => {
  if (!date) return "";

  return moment(date).format(format);
};

export const formatTime = (date) => {
  if (!date) return "";

  return moment(date).format("h:mm A");
};

export const formatFromNow = (date) => {
  if (!date) return "";

  return moment(date).fromNow();
};
