import React from "react";
import { useDispatch } from "react-redux";

import logo from "../../assets/images/SVGLogo.svg";
import "./Header.css";

import { ThemeActions } from "../../store/theme";

const Header = ({ toggleResponsiveShow, user }) => {
  const {fullName, role, profileImage } = user || {};
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(ThemeActions.Sidebar.toggleCollapse());
  };

  return (
    <div className="page-header navbar navbar-fixed-top">
      <div className="page-header-inner ">
        <div className="page-logo">
          <a href="/">
            <img src={logo} alt="logo" className="logo-default logo" />
          </a>
          <div className="menu-toggler sidebar-toggler" onClick={handleClick}>
            <span></span>
          </div>
        </div>
        <a
          href="/"
          className="menu-toggler responsive-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse"
          onClick={(e) => {
            e.preventDefault();
            toggleResponsiveShow();
          }}
        >
          <span></span>
        </a>
        {user && (
          <div className="top-menu">
            <ul className="nav navbar-nav pull-right">
              <li className="dropdown dropdown-user">
                <button
                  type="button"
                  className="btn h-50 dark dropdown-toggle pt-12 pb-12 pr-10 pl-10"
                  data-hover="dropdown"
                  style={{ paddingRight: 10 }}
                >
                  {profileImage ? (
                    <img alt="" className="img-circle" src={profileImage}></img>
                  ) : (
                    <div
                      className="img-circle avatar-header-circle"
                      style={{
                        display: "inline-block",
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginRight: 10,
                        background: "#fff1f3",
                        color: "black",
                      }}
                    >
                      <span className="initials-header">{fullName?.[0]}</span>
                    </div>
                  )}
                  <span className="username username-hide-on-mobile">
                    {fullName} {role && `(${role})`}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export { Header };
