import React from "react";

import { Check } from "./Check";
import { FormGroup } from "./FormGroup";
import { FormLabel } from "./FormLabel";
import { Input } from "./Input";
import { InputGroup, InputGroupAddon } from "./InputGroup";
import { SectionHeading } from "./SectionHeading";
import { Select } from "./Select";

export const Form = ({ children, ...props }) => {
  return (
    <form {...props}>
      <div className="form-body">{children}</div>
    </form>
  );
};

Form.Group = FormGroup;
Form.Label = FormLabel;
Form.Input = Input;
Form.InputGroup = InputGroup;
Form.InputGroupAddon = InputGroupAddon;
Form.Select = Select;
Form.Check = Check;
Form.SectionHeading = SectionHeading;
