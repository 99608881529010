import React, { useState } from "react";
import * as Api from "../../../Api";
import { FullPageSpinner } from "../../_common/Spinner";

export function SaveFileAfterConfirm({
  askConfirmUpload,
  uplodedFile,
  setUplodedFileId,
  setShowMapView,
  setaskConfirmUpload,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const cancelNext = () => {
    window.location.reload();
  };

  const gotoMapping = () => {
    setIsLoading(true);
    Api.Claims.Import.saveAndGetImportId({
      file: uplodedFile,
    })
      .then((data) => {
        setUplodedFileId(data.data.id);
        setaskConfirmUpload(false);
        setShowMapView(true);
      })
      .catch((err) => {
        Api.getError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      {isLoading && <FullPageSpinner />}
      {askConfirmUpload && (
        <div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "22px",
              marginTop: "200px",
              marginBottom: "182px",
            }}
          >
            <i className="fa fa-check-circle" style={{ color: "#4caf50", paddingRight: "10px" }}/>
            <b>Your file has been uploaded!</b>
            <br />
            {uplodedFile.name}
          </div>
          <form className="form-horizontal" method="post">
            <div className="form-actions" style={{ marginBottom: "-20px" }}>
              <div className="col-md-offset-2 col-md-9">
                <button
                  type="button"
                  onClick={() => gotoMapping()}
                  className="btn btn-outline blue-primary"
                  style={{
                    padding: "12px 49px 12px 49px",
                    borderRadius: "4px",
                  }}
                >
                  Next
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => cancelNext()}
                  className="btn grey-salsa btn-outline"
                  style={{
                    padding: "12px 29px 12px 29px",
                    borderRadius: "4px",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
