import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import useClaimTableQueryParams from './useClaimTableQueryParams';

import {Button} from '../_common/Button';
import {Colors} from '../../colors';

const Bar = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 10px 5px;
`;

const Letter = styled.div`
  padding: 0 6px 0 9.2px;
  display: inline-block;
  width: auto;
  line-height: 24px;
  text-align: center;
  color: #314555;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    color: ${Colors.primary};
  }
`;

function Label({title, value, onChange}) {
  return (
    <label className="mt-checkbox" style={{fontSize: 11, fontWeight: 700, paddingTop: 1}}>
      <input type="checkbox" checked={value} onChange={onChange}/>
      {title}
      <span/>
    </label>
  );
}

const options = [
  {value: 'A'}, {value: 'B'}, {value: 'C'}, {value: 'D'}, {value: 'E'}, {value: 'F'}, {value: 'G'}, {value: 'H'},
  {value: 'I'}, {value: 'J'}, {value: 'K'}, {value: 'L'}, {value: 'M'}, {value: 'N'}, {value: 'O'}, {value: 'P'},
  {value: 'Q'}, {value: 'R'}, {value: 'S'}, {value: 'T'}, {value: 'U'}, {value: 'V'}, {value: 'W'}, {value: 'X'},
  {value: 'Y'}, {value: 'Z'}
];

export function LetterToolbar() {
  const [query, setQuery] = useClaimTableQueryParams();
  const {clientNameStartsWithLetter, isIncludeArchives: qIsIncludeArchives} = query;
  const [isIncludeArchives, setIsIncludeArchives] = useState(qIsIncludeArchives)

  useEffect(() => {
    setIsIncludeArchives(qIsIncludeArchives);
  }, [qIsIncludeArchives, setIsIncludeArchives]);

  function searchForLetter(l) {
    setQuery({...query, isIncludeArchives, clientNameStartsWithLetter:l, currentPage:1});
  }

  function handleReset(){
    setIsIncludeArchives(false);
    setQuery({...query, clientNameStartsWithLetter:'', isIncludeArchives:false, currentPage:1})
  }

  function handleSearchSubmit(){
    setQuery({...query, isIncludeArchives});
  }

  return (
    <Bar>
      {options.map((option) => {
        const letterStyle = clientNameStartsWithLetter === option.value
          ? {color: Colors.primary}
          : {};
        return (
          <Letter key={option.value} style={letterStyle} onClick={() => searchForLetter(option.value)}>
            {option.value}
          </Letter>
        );
      })}

      <div className="form-group" style={{paddingLeft: 7, marginBottom: 0}}>
        <div className="mt-checkbox-inline" style={{paddingBottom: 0, marginBottom: 0}}>
          <Label title="Include Archived Files" value={isIncludeArchives}
                 onChange={() => setIsIncludeArchives(!isIncludeArchives)}/>

          <Button variant="blue-primary" classes="margin-right-10" size="small" onClick={handleSearchSubmit} isOutline>
            SEARCH
          </Button>
          <Button isOutline variant="red" onClick={handleReset} size="small">
            RESET
          </Button>
        </div>
      </div>
    </Bar>
  );
}
