import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { ReceivedItem } from "./ReceivedItem";
import { ClaimActions, ClaimBuilders } from "../../../../../store/claims";

export const DollarReceived = () => {
  const dispatch = useDispatch();

  const receivedDollars = useSelector(
    (state) => state.claims.claim.data?.claimInfo?.newDollarReceived
  ) || [{}];

  const onClaimInfoChange = (data) =>
    dispatch(
      ClaimActions.SetOnClaim.claimInfo({
        name: "newDollarReceived",
        value: data,
      })
    );

  const handleChange = ({ index, name, value }) => {
    const updatedReceivedDollars = receivedDollars.map((received, idx) => {
      if (idx === index) {
        return {
          ...received,
          [name]: value,
        };
      }
      return received;
    });

    onClaimInfoChange(updatedReceivedDollars);
  };

  const handleAddClick = () => {
    const newReceived = ClaimBuilders.buildNewDollarReceived();
    console.log({ newReceived });
    onClaimInfoChange([...receivedDollars, newReceived]);
  };

  const handleDeleteClick = (idx) => {
    const updatedReceivedItems = receivedDollars.filter((received, index) => index !== idx);

    onClaimInfoChange(updatedReceivedItems);
  };

  return (
    <Form.Group style={{ marginBottom: 0 }}>
      {receivedDollars?.map((received, idx) => (
        <ReceivedItem
          received={received}
          isShowAdd={idx === 0}
          isShowDelete={idx !== 0}
          onChange={(data) => handleChange({ index: idx, ...data })}
          onAddClick={handleAddClick}
          onDeleteClick={() => handleDeleteClick(idx)}
          key={idx}
          index={idx}
        />
      ))}
    </Form.Group>
  );
};
