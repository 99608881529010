import React from "react";

export function SubmitButton({ isLoading }) {
  return (
    <button
      type="submit"
      className="btn btn-outline blue-primary"
      name="submit"
      value="submit"
      disabled={isLoading}
    >
      {isLoading ? (
        "Saving Changes . . ."
      ) : (
        <>
          <i className="fa fa-check"></i> Save Changes
        </>
      )}
    </button>
  );
}
