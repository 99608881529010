import React from 'react';
import { useSelector } from 'react-redux';

export function CompanyCamLinks() {

    const { 
        data: { _raw: {ccProjectId} = {} } = {},
    } = useSelector((state) => state.claims.claim);

    if (!ccProjectId) return null;
    
    return (
        <>
        <a className="tw-text-[#0967d2] focus:tw-text-[#0967d2] focus:no-underline hover:tw-text-[#085cbd]" href={'https://app.companycam.com/projects/' + ccProjectId} _target="blank">Open Project</a><span className="tw-mx-3 tw-text-[#0967d2]">|</span>
        <a className="tw-text-[#0967d2] focus:tw-text-[#0967d2] focus:no-underline hover:tw-text-[#085cbd]" href={`https://app.companycam.com/projects/${ccProjectId}/reports`} _target="blank">Open Project Reports</a>
        </>
    )
}