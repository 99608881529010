import React from 'react';
import MappingConfirmation from '../../assets/importDocument/mapping-confirmation.svg';
import * as Api from '../../Api';

const ROW_CONTAINER_STYLE = {padding: '30px', border: 'none', margin: '2rem'};
const COL_CONTAINER_STYLE = {margin: '20px', border: 'none', background: 'none'};
const PAD_RIGHT_5_STYLE = {paddingRight: '5px'};

const INSTRUCTIONS_LABEL_STYLE = {
  textAlign: 'left',
  paddingBottom: '12px',
  backgroundColor: '#f2f2f2'
};

const BASE_BUTTON_STYLE = {
  borderRadius: '4px!important',
  marginRight: '10px'
};

const BUTTON_STYLE = {
  padding: '12px 29px 12px 29px',
  ...BASE_BUTTON_STYLE
};

const FINISH_BUTTON_STYLE = {
  padding: '12px 49px 12px 49px',
  ...BASE_BUTTON_STYLE
};

export function PreFinalStep({currentMappedData, uploadedFileId, onPrevious, onFinish}) {

  async function finishImport() {
    try {
      const data = await Api.Document.legacy
        .saveMappedData({id: uploadedFileId, data: currentMappedData});
      onFinish(data);
    } catch (err) {
      console.log(err);
    }
  }

  function cancelImport() {
    window.location.href = '/app/templates/upload';
  }

  return (
    <div className="portlet light">
      <div className="portlet-title">
        <div className="caption">
          <i className="fa fa-check"/>Review Import
        </div>
      </div>
      <div className="portlet-body form">
        <div>
          <label className="col-md-12 control-label" style={INSTRUCTIONS_LABEL_STYLE}>
            <h3 style={{lineHeight: '4.5rem'}}>
              <span className="label label-sm label-danger">Instructions</span>
              <br/>
            </h3>
            <h3>
              Confirm the Import?<br/>
              <br/>
              Use <span className="bold">Previous</span> button to make any changes.
              Use <span className="bold">Finish</span> to complete import.
            </h3>
          </label>
        </div>
        <div className="m-grid m-grid-responsive-md m-grid-demo">
          <div className="m-grid-row">
            <div className="col-md-11" style={ROW_CONTAINER_STYLE}>
              <div className="m-grid m-grid-full-height">
                <div className="m-grid-col m-grid-col-middle m-grid-col-center " style={COL_CONTAINER_STYLE}>
                  <img src={MappingConfirmation} alt="Mapping Confirmation" style={{textAlign: 'center'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-horizontal">
          <div className="form-actions">
            <div className="col-md-offset-2 col-md-9">
              <button type="button" className="btn grey-salsa btn-outline" style={BUTTON_STYLE}
                      onClick={onPrevious}>
                <i className="fa fa-arrow-left" style={PAD_RIGHT_5_STYLE}/>
                Previous
              </button>
              <button type="button" className="btn btn-outline blue-primary" style={FINISH_BUTTON_STYLE}
                      onClick={finishImport}>
                <i className="fa fa-check" style={PAD_RIGHT_5_STYLE}/>
                Finish
              </button>
              <button type="button" className="btn grey-salsa btn-outline" style={BUTTON_STYLE}
                      onClick={cancelImport}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
