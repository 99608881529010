import React, {useCallback, useEffect, useState} from 'react';
import {Pagebar} from '../layout/Pagebar';
import {AdjustersDatatable} from './tables';
import {AddNewAndEdit} from './AddNewAndEdit';
import {DeleteSweetAlertConfirmation} from '../_common/Sweetalert';
import {Dropdown} from '../_common/Dropdown';
import DownloadCSV from '../_common/DownloadCSV';
import DownloadPDF from '../_common/DownloadPDF';
import DownloadImage from '../_common/DownloadImage';
import Modal from "react-modal";
import * as Api from '../../Api';

const DOWNLOAD_CSV_HEADERS = [
  {label: 'Adjuster Name', key: 'name'},
  {label: 'Email Address', key: 'email'},
  {label: 'Phone #', key: 'phone'},
  {label: 'Extension', key: 'phoneExt'},
  {label: 'Fax #', key: 'fax'},
  {label: 'License Number #', key: 'license'},
  {label: 'Status', key: 'status'}
];

const DOWNLOAD_PDF_HEADERS = [
  {header: 'Adjuster Name', dataKey: 'name'},
  {header: 'Email Address', dataKey: 'email'},
  {header: 'Phone #', dataKey: 'phone'},
  {header: 'Extension', dataKey: 'phoneExt'},
  {header: 'Fax #', dataKey: 'fax'},
  {header: 'License Number #', dataKey: 'license'},
  {header: 'Status', dataKey: 'status'}
];

const BREADCRUMB = [
  {
    title: 'Home',
    link: '/app/claims'
  },
  {
    title: 'Adjusters',
    link: ''
  }
];

export function Adjusters() {
  const [isLoading, setIsLoading] = useState(true);
  const [adjusters, setAdjusters] = useState([]);
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteSelectedAdjuster, setDeleteSelectedAdjuster] = useState({});
  const [opensidePanel, setOpensidePanel] = useState(false);
  const [adjusterDetails, setAdjusterDetails] = useState({});
  const [modal, setModal] = useState('');
  const [modalIsWaiting, setModalIsWaiting] = useState(false);

  const fetchAdjusters = useCallback(async function _fetchAdjusters() {
    try {
      setIsLoading(true);
      const data = await Api.Adjusters.list();
      if (data.data && data.data.adjusters) {
        setAdjusters(data.data.adjusters);
      }
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally { setIsLoading(false);}
  }, [setIsLoading, setAdjusters, setError]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchAdjusters();
  }, [fetchAdjusters]);

  function confirmDelete(selectedAdjuster) {
    setShowDeleteConfirmation(true);
    setDeleteSelectedAdjuster(selectedAdjuster);
  }

  async function deleteAdjuster(confirm) {
    if (confirm) {
      try {
        await Api.Adjusters.delete({id: deleteSelectedAdjuster.id});
        await fetchAdjusters();
      } finally {
        setDeleteSelectedAdjuster({});
        setShowDeleteConfirmation(false);
        updateSubscription();
      }
    } else {
      setShowDeleteConfirmation(false);
    }
  }

  async function didUpdateOrAdd() {
    await updateSubscription();
    await fetchAdjusters();
  }

  async function updateSubscription() {
    setIsLoading(true);
    const subscriptionResponse = await Api.Billing.updateSubscription(null);
    if (subscriptionResponse.data.message !== 'no change') {
      console.log(subscriptionResponse.data);
      setModal('subscriptionChanged');
    }
  }

  function saveAsPdf() {
    return DownloadPDF({
      data: adjusters,
      headers: DOWNLOAD_PDF_HEADERS,
      filename: 'adjusters.pdf'
    });
  }

  function onAddNew(e) {
    e.preventDefault();
    setAdjusterDetails({});
    setOpensidePanel(true);
  }

  const hideModal = () => {
    setModalIsWaiting(false);
    setModal('')
  }

  const messages = {
    subscriptionChanged: {
      title: 'Subscription Changed',
      body: (<>
          <p>
              Your subscription has changed and will be prorated accordingly. Please see the <a href="/billing">billing page</a> for more details.
          </p>
      </>),
      buttons: [
          {
              text:'OK',
              action: hideModal,
              isPrimary: true,
          }
      ]
    }
  }

  return (
    <div>
      {<Pagebar breadcrumbs={BREADCRUMB}/>}
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase ">
              Adjusters
            </span>
          </div>
          <div className="actions"/>
        </div>
        <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={deleteAdjuster}/>
        <AddNewAndEdit openPanel={opensidePanel} setOpenPanel={setOpensidePanel} adjusterDetails={adjusterDetails}
                       reloadTable={didUpdateOrAdd}/>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group">
                  <button className="btn btn-outline blue-primary add_new" onClick={onAddNew}>
                    Add New <i className="fa fa-plus"/>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="btn-group pull-right">
                  <Dropdown title="Tools" variant="red">
                    <Dropdown.ListItem onClick={() => false}>
                      <DownloadImage Data={adjusters} headers={DOWNLOAD_PDF_HEADERS} TableName="Adjusters"/>
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={saveAsPdf}>
                      Save as PDF
                    </Dropdown.ListItem>
                    <Dropdown.ListItem
                      onlyLI={
                        <DownloadCSV data={adjusters} filename="adjusters.csv" headers={DOWNLOAD_CSV_HEADERS}
                                     target="_blank"/>
                      }
                      onClick={() => false}/>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="table-scrollable">
            <AdjustersDatatable adjusters={adjusters} isLoading={isLoading} error={error}
                                setOpensidePanel={setOpensidePanel} setAdjusterDetails={setAdjusterDetails}
                                confirmDelete={confirmDelete}/>
          </div>
        </div>
      </div>
      <Modal
        isOpen={(modal !== '')}
        className="Modal__Bootstrap modal-dialog"
        ariaHideApp={false}
    >
        {(modal !== '') && <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title tw-text-2xl" style={{float: 'left'}}>{messages[modal].title}</h4>
                <button type="button" className="close" disabled={modalIsWaiting} onClick={hideModal}
                    ref={(node) => node?.style.setProperty("background-image", "none", "important")}
                    style={{fontSize: 24, float: 'right', width: 'auto', textIndent: 0}}>
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
            <div className="modal-body">
                {messages[modal].body}
            </div>
            <div className="modal-footer">
                {messages[modal].buttons.map((button, i) => 
                    <button key={i} type="button" 
                        className={'btn btn-' + ((button.isPrimary) ? 'primary tw-bg-primary' : 'secondary tw-bg-gray-300')}
                        onClick={button.action} disabled={modalIsWaiting || button.isDisabled}>{button.text}</button>
                )}
            </div>
        </div>}
    </Modal>
    </div>
  );
}
