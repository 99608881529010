import { StatusTypes } from "./index";

export const GetStatuses = {
  start: () => ({ type: StatusTypes.GET_STATUSES.START }),
  success: (statuses) => ({
    type: StatusTypes.GET_STATUSES.SUCCESS,
    payload: statuses,
  }),
  error: (err) => ({
    type: StatusTypes.GET_STATUSES.ERROR,
    payload: err,
  }),
  clear: () => ({
    type: StatusTypes.GET_STATUSES.CLEAR,
  }),
};
