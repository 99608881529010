import { toast } from "react-toastify";

/**
 * @see Options: https://fkhadra.github.io/react-toastify/api/toast
 * here's what options look like
 * const options = {
    onOpen: props => console.log(props.foo),
    onClose: props => console.log(props.foo),
    autoClose: 6000,
    closeButton: FontAwesomeCloseButton,
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_LEFT,
    pauseOnHover: true,
    transition: MyCustomTransition,
    progress: 0.2
  // and so on ...
};
 */
export const Notify = {
  dark: (message, opts) =>
    toast.success(message, {
      ...opts,
      type: toast.TYPE.DARK,
      limit: 5,
      transition: null,
    }),
  success: (message, opts) => toast.success(message, opts),
  error: (message, opts = {}) =>
    toast.error(message, {
      autoClose: 20000,
      position: toast.POSITION.TOP_CENTER,
      ...opts,
    }),
  warn: (message) => toast.warn(message),
  info: (message) => toast.info(message),
};

export const NotificationTypes = toast.TYPE;
export const NotificationPositions = toast.POSITION;
