import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Pagebar } from "../layout/Pagebar";
import { Portlet } from "../_common/portlets/Portlet";
import { ClientType } from "../claims/claim/claim-form/ClientType";
import { useClaim } from "../claims/claim/claim.hooks";
import { ClientContact } from "../claims/claim/claim-form/ClientContact";
import { PropertyAddress } from "../claims/claim/claim-form/PropertyAddress";
import { MailingAddress } from "../claims/claim/claim-form/MailingAddress";
import { Form } from "../_common/form/Form";
import { ClaimAdjusters } from "../claims/claim/claim-form/adjusters/ClaimAdjusters";
import { ClaimInfo } from "../claims/claim/claim-form/claim-fields/ClaimInfo";
import { ClaimActions } from "../../store/claims";
import { ArchiveClaimAction } from "./ArchiveClaimAction";
import "../claims/claim/claim-form/ClaimForm.css";
import { CustomFields } from "../claims/claim/claim-form/custom-fields/CustomFields";

export const ArchiveFileForm = () => {
  const { handleSubmit, isLoading } = useClaim();

  const dispatch = useDispatch();
  const breadcrumb = [
    {
      title: "Home",
      link: "/app/claims",
    },
    {
      title: "Client Files",
      link: "",
    },
  ];

  useEffect(() => {
    dispatch(ClaimActions.ClaimRoute.setIsSharedView(true));
  }, [dispatch]);

  return (
    <div>
      {<Pagebar breadcrumbs={breadcrumb} />}
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              Archive File
            </span>
          </div>
          <div className="actions"></div>
        </div>
        <div className="portlet-body">
          <Portlet isLoading={isLoading} isBox classColor="light mt-10">
            <ArchiveClaimAction onSubmit={handleSubmit} />
            <Form.SectionHeading>Client Info</Form.SectionHeading>
            <ClientType onSubmit={() => {}} />
            <ClientContact />
            <PropertyAddress />
            <MailingAddress />
            <Form.SectionHeading>Claim Info</Form.SectionHeading>
            <ClaimAdjusters />
            <Form.SectionHeading>&nbsp;&nbsp;</Form.SectionHeading>
            <ClaimInfo />
            <CustomFields />
          </Portlet>
        </div>
      </div>
    </div>
  );
};
