export const OPTION_TYPES = {
  externalLink: "externalLink",
  internalLink: "internalLink",
};

export const options = [
  {
    id: "claims",
    title: "All Claim Files ",
    link: "/claims",
    isActive: false,
    groupTitle: "",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },

  {
    id: "customFields",
    title: "Custom Options",
    link: "/custom-options/custom-fields",
    isActive: false,
    groupTitle: "Features",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "Calendar",
    title: "Calendar",
    link: "/calendar",
    isActive: false,
    groupTitle: "Features",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "archives",
    title: "Archives",
    link: "/archive-files",
    isActive: false,
    groupTitle: "Features",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "documents",
    title: "Templates",
    link: "/templates",
    isActive: false,
    groupTitle: "Features",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "support",
    title: "Support",
    link: "/support",
    isActive: false,
    groupTitle: "Features",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },

  {
    id: "admins",
    title: "Admins",
    link: "/admins",
    isActive: false,
    groupTitle: "People",
    isAuthRequired: true,
    allowedRoles: ["Admin", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "adjusters",
    title: "Adjusters",
    link: "/adjusters",
    isActive: false,
    groupTitle: "People",
    isAuthRequired: true,
    allowedRoles: ["Admin", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "vendors",
    title: "Vendors",
    link: "/vendors",
    isActive: false,
    groupTitle: "People",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "attorney",
    title: "Attorneys",
    link: "/attorneys",
    isActive: false,
    groupTitle: "People",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "insurers",
    title: "Insurers",
    link: "/insurers",
    isActive: false,
    groupTitle: "People",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },

  {
    id: "profile",
    title: "Profile",
    link: "/profile",
    isActive: false,
    groupTitle: "Companies",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "billing",
    title: "Billing",
    link: "/billing",
    isActive: false,
    groupTitle: "Account",
    isAuthRequired: true,
    allowedRoles: ["SuperAdmin", "Admin"],
    type: OPTION_TYPES.internalLink,
  },
  {
    id: "logout",
    title: "Logout",
    link: "/logout",
    isActive: false,
    groupTitle: "Account",
    isAuthRequired: true,
    allowedRoles: ["Admin", "Adjuster", "SuperAdmin"],
    type: OPTION_TYPES.internalLink,
    dataTestId: "logout",
  },
];
