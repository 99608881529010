import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import Autocomplete from 'react-google-autocomplete';

export default function LocationAutocomplete(
  {handleChange, label, placeholder, value, disabled, dataTestIdPrefix, ...otherProps}
) {
  const [val, setVal] = useState(value);

  function setAddress(place) {
    const {address_components:addr = [], geometry} = place || {};
    const streetNumberObj = addr.find((item) => item.types.indexOf('street_number') > -1);
    const streetNumberField = _.get(streetNumberObj, 'long_name', '');
    const routeObj = addr.find((item) => item.types.indexOf('route') > -1);
    const routeField = _.get(routeObj, 'long_name', '');
    let cityObj;
    cityObj = addr.find((item) => item.types.indexOf('locality') > -1);
    if (!cityObj) {
      const sublocality = addr.find((item) => item.types.includes('sublocality'));
      if (sublocality) {
        cityObj = sublocality;
      }
    }
    const city = _.get(cityObj, 'long_name', '');
    const stateObj = addr.find((item) => item.types.indexOf('administrative_area_level_1') > -1);
    const state = _.get(stateObj, 'short_name', '');
    const zipcodeObj = addr.find((item) => item.types.indexOf('postal_code') > -1);
    const zipcode = _.get(zipcodeObj, 'long_name', '');
    const countryObj = addr.find((item) => item.types.indexOf('country') > -1);
    const country = _.get(countryObj, 'short_name', '');

    const streetAddress1 = _.compact([streetNumberField, routeField])
      .join(' ');

    const {location} = geometry;
    handleChange({
      streetAddress1, city, zipcode, state, country,
      lat: location.lat(),
      lng: location.lng()
    });
  }


  useEffect(() => {
    if (value) {
      setVal(value);
    }
  }, [value, setVal]);

  const dataTestID = dataTestIdPrefix
    ? `${dataTestIdPrefix}-address-autocomplete`
    : 'address-autocomplete';

  return (
    <div className="form-group">
      <label className="control-label">{label}</label>

      <Autocomplete className="form-control" value={val} placeholder={placeholder} types={['geocode']}
                    componentRestrictions={{country: 'us'}} disabled={disabled} onPlaceSelected={setAddress}
                    onChange={(e) => setVal(e.target.value)} data-testid={dataTestID}{...otherProps}/>
    </div>
  );
}
