import React, {useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';

import * as Api from '../../Api';

import {LoadingButton} from '../_common/Button';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {SuccessMessage} from '../_common/Sweetalert';

const {REACT_APP_TINYMCE_API_KEY} = process.env;

export function Note({openPanel, setOpenPanel, selectedClaimId}) {
  const [note, setNote] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  async function saveNote() {
    try {
      setButtonLoading(true);
      await Api.Claims.Notes.add({requestData: {note}, claimId: selectedClaimId});
      setShowSuccessMessage(true);
      setSuccessMessage('Updated!');
      setOpenPanel(false);
    } finally {
      setButtonLoading(false);
    }
  }

  function handleRemoveClick(e) {
    e.preventDefault();
    setSuccessMessage('');
    setShowSuccessMessage(false);
    setOpenPanel(false);
  }

  function handleCancel(e) {
    e.preventDefault();
    setOpenPanel(false);
    setShowSuccessMessage(false);
    setSuccessMessage('');
  }

  return (
    <div>
      <SuccessMessage show={showSuccessMessage} clickHandler={setShowSuccessMessage} message={successMessage}/>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-yellow-casablanca uppercase">
                ADD QUICK NOTES
              </span>
            </div>
            <div className="tools">
              <a className="remove" href="#!" title="" data-original-title="" onClick={handleRemoveClick}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            <Editor apiKey={REACT_APP_TINYMCE_API_KEY} initialValue={note}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                // eslint-disable-next-line no-multi-str
                  'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat'
              }}
              onEditorChange={setNote}
            />
            <div className="form-actions">
              <div className="row">
                <div className="col-md-offset-3 col-md-9">
                  {
                    buttonLoading
                      ? (<LoadingButton/>)
                      : (
                        <button className="btn btn-outline blue-primary" style={{margin: 5}} type="submit"
                                onClick={saveNote}>
                          Submit
                        </button>
                      )
                  }
                  <button className="btn default" style={{margin: 5}} type="button" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
