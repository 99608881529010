import * as Api from '../../Api';
import useFetcher from '../useFetcher';

export default function useBillingStatus() {
  const {data, loading, error, reFetch} = useFetcher(Api.Billing.status);

  const {billingDetails:billingStatus, userType} = data || {};

  return {billingStatus, userType, loading, error, fetchBillingStatus:reFetch};
}
