import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "../../../../_common/form/Form";
import { ClaimActions } from "../../../../../store/claims";

export const Attorney = () => {
  const dispatch = useDispatch();

  const { isSharedView } = useSelector((state) => state.claims.claim);
  const { attorney } = useSelector((state) => state.claims.claim.data);

  const allAttorneies = useSelector((state) => state.attorneys.list);

  const onAttorneyChange = (data) => dispatch(ClaimActions.SetOnClaim.attorney(data));
  //

  return (
    <Form.Group>
      {isSharedView ? (
        <>
          <Form.Label>Attorney</Form.Label>
          <Form.Input disabled value={attorney?.name} />
        </>
      ) : (
        <>
          <Form.Label>Attorney</Form.Label>
          <Form.Select
            value={attorney?.id}
            onChange={(e) => onAttorneyChange({ name: "id", value: e.target.value })}
            disabled={isSharedView}
            data-testid="attorney"
          >
            <option value="">Select Attorney </option>
            {allAttorneies?.map((attr) => (
              <option key={attr.id} value={attr.id}>
                {attr.name}
              </option>
            ))}
          </Form.Select>
        </>
      )}
    </Form.Group>
  );
};
