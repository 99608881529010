import React from "react";
import { useHistory } from "react-router-dom";
import { Pagebar } from "../layout/Pagebar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useClaimCalander } from "./Calandar.hook";
import { FullPageSpinner } from "../_common/Spinner";
import {DatePickerForReminder} from '../_common/DatePickerForReminder';
import moment from "moment";

import "./Calendar.css";

export function Calendar() {
  let history = useHistory();
  const {
    allEvents,
    isLoading,
    getMyReminders,
    getReminders,
    initalLoading,
    isListView,
    setIsListView
  } = useClaimCalander();

  const handleDateSelect = (selectInfo) => {
    console.log(selectInfo);
  };
  const handleEventClick = (clickInfo) => {
    const clientId = clickInfo.event.extendedProps?.client?.id ?? "";
    history.push(`claims/${clientId}`);
  };
  const handleEvents = () => {};

  const handleEventListClick = (eventInfo) => {
    const clientId = eventInfo.client?.id ?? "";
    history.push(`claims/${clientId}`);
  }

  const breadcrumb = [
    {
      title: "Home",
      link: "/app/claims",
    },
    {
      title: "Appointments",
      link: "",
    },
  ];

  const renderEventContent = (eventInfo) => {
    const clientName = eventInfo.event.extendedProps?.client?.name ?? "";
    return (
      <>
        <div className="event">
          <div className="event-info">
            <div className="event-title">{eventInfo.event.title}</div>
            <div className="event-client">{clientName}</div>
          </div>

          <div className="event-time">
            <b>{eventInfo.timeText}</b>
          </div>
        </div>
      </>
    );
  };

  const [fromDate, setFromDate] = React.useState(moment());
  const [toDate, setToDate] = React.useState(moment().add(60, 'days'));
  const handleDatesChange = () => {
      return (from, to) => {
          setFromDate(from);
          setToDate(to);
      };
  }
  return (
    <div>
      {isLoading && <FullPageSpinner />}
      {<Pagebar breadcrumbs={breadcrumb} />}
      {!initalLoading && (
        <div className="portlet light portlet-fit portlet-datatable bordered">
          <div className="portlet-title">
            <div className="caption">
              <i className="fa fa-calendar"></i>
              <span className="caption-subject font-dark sbold uppercase">
                Appointments
              </span>
            </div>
            <div className="actions">
              <button
                className="btn blue-primary btn-outline  margin-right-10"
                onClick={() => getMyReminders()}
              >
                Export My Reminders
              </button>
              <button
                className="btn blue-primary btn-outline  margin-right-10"
                onClick={() => getReminders()}
              >
                Export All Reminders
              </button>
            </div>
          </div>
          <div className="portlet-body">

            <ul className="nav nav-tabs client-edit-mid-tab client-edit-tabs">
              <li className={isListView ? "" : "active"}><a href="#!" onClick={() => setIsListView(false)}>Calendar</a></li>
              <li className={isListView ? "active" : ""}><a href="#!" onClick={() => setIsListView(true)}>List</a></li>
            </ul>

            {!isListView && 
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12">
                <div className="portlet light calendar bordered">
                  <div className="portlet-title ">
                    <div className="caption">
                      <i className="icon-calendar font-dark hide"></i>
                      <span className="caption-subject font-dark bold uppercase">
                        Calendar View
                      </span>
                    </div>
                  </div>
                  <div className="portlet-body" >
                    <div>

                      <FullCalendar
                        timeZone = {'local'}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: "",
                          center: "title",
                          right:
                            "prev,next today, dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={false}
                        selectMirror
                        dayMaxEvents
                        weekends={true}
                        initialEvents={allEvents}
                        select={handleDateSelect}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        eventsSet={handleEvents}
                        contentHeight="auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
            {isListView &&
            <div className="row">
              <div className="col-lg-12 col-xs-12 col-sm-12">
                <div className="portlet light calendar bordered">
                  <div className="portlet-title ">
                    <div className="caption">
                      <i className="icon-calendar font-dark hide"></i>
                      <span className="caption-subject font-dark bold uppercase">
                        List View
                      </span>
                      <DatePickerForReminder fromDate={fromDate} toDate={toDate} onDatesChange={handleDatesChange()}/>
                    </div>
                  </div>
                  <div className="portlet-body" >
                    
                    <div>
                      <div class="list-group">
                      {allEvents.map(eventInfo => {
                        const dt = moment(eventInfo.start);
                        if (dt.isSameOrAfter(fromDate, 'day') && dt.isSameOrBefore(toDate, 'day')) { // 30 days
                          return (
                            <button type="button" class="list-group-item list-group-item-action" onClick={() => handleEventListClick(eventInfo)}>
                              <span style={{fontWeight: 'bold'}}>{eventInfo.title}</span><br />
                              {eventInfo.client?.name || ''}<br />
                              {dt.format('MMMM Do, YYYY h:mm A')}
                            </button>
                          );
                        } else {
                          return null;
                        }
                      })}  
                      </div>                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      )}
    </div>
  );
}
