import React from "react";

import { TermsWrapper } from "./TermsWrapper";
export const PrivacyPolicy = () => {
  return (
    <TermsWrapper title="Privacy Policy" lastUpdated="May 22, 2018">
      <section className="content bg-grey">
        <div className="container">
          <hr class="line"></hr>
          <div className="subheader">
            This policy covers how we use your personal information. We take
            your privacy SERIOUSLY and will take all measures to protect your
            personal information.
          </div>
          <hr class="line"></hr>
        </div>
      </section>

      <section className="content">
        <div className="container">
          What information do we collect? We only collect information that we
          need that is related to your order. This includes your:
          <ul>
            <li>Billing Address</li>
            <li>Email Address</li>
            <li>Credit Card Information</li>
            <li>Company Information</li>
          </ul>
          <br />
          In addition we also collect information on your IP address, browser
          type, and Refer URL data. We use this data to prevent hacking
          attempts, help us know what web browsers people are using, and find
          out where our visitors are coming from so that we can improve our
          marketing.
        </div>
      </section>

      <section className="content bg-grey">
        <div className="container">
          <h2 className="title">HOW IS MY INFORMATION USED?</h2>
          Your information is only used to fill your order and to ensure you get
          the best experience on our website . We do not sell or redistribute
          your information to ANYONE.
        </div>
      </section>

      <section className="content">
        <div className="container">
          <h2 className="title">HOW TO REQUEST A REFUND</h2>
          Please email {process.env.REACT_APP_SUPPORT_EMAIL} to request a refund
          and we will assign you a tracking #.
        </div>
      </section>

      <section className="content bg-grey">
        <div className="container">
          <h2 className="title">SECURITY AND STORAGE</h2>
          Only your order data billing, shipping, and order contents data is
          stored on our server. This information is encrypted using a Secure
          Sockets Layer before it is transmitted over a web server. We do not
          store your Credit Card data.
        </div>
      </section>

      <section className="content">
        <div className="container">
          <h2 className="title">COOKIES AND BROWSER INFORMATION</h2>
          Cookies are small files that reside on your computer and allow us to
          recognize you on your next visit or store your shopping cart contents.
          We use them only to track this information.
        </div>
      </section>

      <section className="content bg-grey">
        <div className="container">
          <h2 className="title">GOVERNING LAW</h2>© 2015 Bank of America
          Merchant Services, LLC. All rights reserved. All trademarks, service
          marks and trade names referenced in this material are the property of
          and licensed by their respective owners. Merchant Services are
          provided by Bank of America, N.A. and its representative Banc of
          America Merchant Services, LLC. Banc of America Merchant Services, LLC
          is not a bank, does not offer bank deposits, and its services are not
          guaranteed or insured by the FDIC or any other governmental agency.
          BAXXXX 512
        </div>
      </section>
    </TermsWrapper>
  );
};
