import axios from 'axios';

import config from '../config';
import {ClaimTypes} from '../store/claims';

const BASE_URL = config.apiBaseUrl;

// Use axios instance for Api calls where share token is  required
const $shareHttp = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

$shareHttp.interceptors.request.use((config) => {
  const token = localStorage.getItem(ClaimTypes.X_SHARE_TOKEN);
  config.headers[ClaimTypes.X_SHARE_TOKEN] = token;
  return config;
});

export const SharedClaim = {
  getClaim() {
    return $shareHttp.get(`/shared/claims`);
  },
  getCustomFields() {
    return $shareHttp.get(`/shared/custom-fields`);
  },
  getClaimAdjusters() {
    return $shareHttp.get(`/shared/claims/adjusters`);
  },
  getAttachmentFolders(){
    return $shareHttp.get(`/shared/claims/attachments/folders-name`);
  },
  getAttachmentFiles({
    folderId
  }){
    return $shareHttp.get(`/shared/claims/attachments/folder-files/${folderId}`);
  },
  getNotes(){
    return $shareHttp.get(`/shared/claims/notes`);
  },
  getReminders(){
    return $shareHttp.get(`/shared/claims/reminders`);
  },
  getActivity(){
    return $shareHttp.get(`/shared/claims/activity`);
  },
  getAttachmentShareToken({ requestData }){
    return $shareHttp.post(`/shared/claims/attachments/share-file-shorten-url`, requestData);
  },
  getMapCoordinates(){
    return $shareHttp.get(`/shared/claims/get-map-coordinates`);
  }
};
