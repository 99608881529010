import React, {useState} from 'react';

import * as Api from '../../../../Api';
import {Notify} from '../../../../services/Notify.service';
import {Dropdown} from '../../../_common/Dropdown';
import {FullPageSpinner} from '../../../_common/Spinner';
import {ArchiveClaim} from '../ArchiveClaim/ArchiveClaim';
import {SelectVendor} from './SelectVendor';
import {SelectAttorney} from './SelectAttorney';
import {ShareFileWithPolicyHolder} from './shareFileWithPolicyholders';
import {useShareClaimHook} from './ShareClaim.hook';
import ClaimPrint from '../ClaimPrint/ClaimPrint';

export const ClientEditActionButton = ({claimId, isSharedView}) => {
  const {shareLink, shareClaimToken} = useShareClaimHook({claimId, isSharedView});

  const [showArchiveClaimConfirmation, setShowArchiveClaimConfirmation] = useState(false);
  const [showShareVendorUI, setShowShareVendorUI] = useState(false);

  const [showShareAttorneyUI, setShowShareAttorneyUI] = useState(false);
  const [showSharePolicyHolderUI, setShowSharePolicyHolderUI] = useState(false);
  const [selectedClaimData, setSelectedClaimData] = useState({});
  const [showloader, setShowLoader] = useState(false);

  function archiveClaim() {
    setShowArchiveClaimConfirmation(true);
  }

  function shareWithVendor() {
    setShowShareVendorUI(true);
  }

  function shareWithAttorney() {
    setShowShareAttorneyUI(true);
  }

  function shareClaimWithPolicyholder() {
    if (claimId) {
      setShowLoader(true);
      console.log('ClientEditActionButton.js: shareClaimWithPolicyholder()');
      Api.Claims.getById({id: claimId})
        .then((data) => {
          if (data.data.claim.clientEmail) {
            setSelectedClaimData(data.data.claim);
            setShowSharePolicyHolderUI(true);
          }
          if (data.data.claim.clientEmail === '') {
            Notify.error(
              'Policyholder\'s Email is missing. To share with policyholder, please make sure this claim has Policyholder Type (Business/Residence), Name and  Email are updated and saved on the Claim. '
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
  }

  return (
    <>
      {showloader && <FullPageSpinner/>}
      <Dropdown variant="blue-primary bold" marginLeft={5} title="Actions" clientEdit={true} isNotOutline={false}>
        <Dropdown.ListItem onClick={() => archiveClaim()}>
          Archive
        </Dropdown.ListItem>
        <Dropdown.ListItem onClick={() => shareWithAttorney()}>
          Share File with Attorneys
        </Dropdown.ListItem>
        <Dropdown.ListItem onClick={() => shareClaimWithPolicyholder()}>
          Share File with Policyholder
        </Dropdown.ListItem>
        <Dropdown.ListItem onClick={() => shareWithVendor()}>
          Share File with Vendors
        </Dropdown.ListItem>
        <Dropdown.ListItem
          onClick={() => {
            return false;
          }}
        >
          <ClaimPrint claimId={claimId}/>
        </Dropdown.ListItem>
      </Dropdown>
      <ArchiveClaim claimId={claimId} showArchiveClaimConfirmation={showArchiveClaimConfirmation}
                    setShowArchiveClaimConfirmation={setShowArchiveClaimConfirmation}/>
      <SelectVendor claimId={claimId} shareLink={shareLink} shareClaimToken={shareClaimToken}
                    isSharedView={isSharedView} showShareVendorUI={showShareVendorUI}
                    setShowShareVendorUI={setShowShareVendorUI}/>
      <SelectAttorney claimId={claimId} shareLink={shareLink} shareClaimToken={shareClaimToken}
                      isSharedView={isSharedView} showShareAttorneyUI={showShareAttorneyUI}
                      setShowShareAttorneyUI={setShowShareAttorneyUI}/>
      <ShareFileWithPolicyHolder claimId={claimId} shareLink={shareLink} shareClaimToken={shareClaimToken}
                                 selectedClaimData={selectedClaimData} isSharedView={isSharedView}
                                 showSharePolicyHolderUI={showSharePolicyHolderUI}
                                 setShowSharePolicyHolderUI={setShowSharePolicyHolderUI}/>
    </>
  );
};
