import React, { useState } from 'react'
import Modal from "react-modal";

export const PhotoGallary = ({ 
    picturs,
    checkBoxEvent,
    selectedFiles
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImageName, setCurrentImageName] = useState("");
    const closeModal = () => {
        setOpenModal(false);
    }

    const openImageModal = (index) => {
        const currentImageData = picturs[index];
        setCurrentIndex(index);
        setCurrentImageUrl(currentImageData.signedUrl);
        setCurrentImageName(currentImageData.name);
        setOpenModal(true);
    }

    const showNextImage = () => {
        let newCurrentIndex = currentIndex + 1;
        if(picturs.length <= newCurrentIndex){
            newCurrentIndex = 0;
        }
        openImageModal(newCurrentIndex);
    }

    const showPreviousImage = () => {
        let newCurrentIndex = currentIndex - 1;
        if(newCurrentIndex < 0){
            newCurrentIndex = picturs.length - 1;
        }
        openImageModal(newCurrentIndex);
    }

    return (
        <div>
            <div className="portfolio-content" style={{minHeight:"400px"}}>
                <div className="gallery">
                    {
                        picturs.map((p, index) => {
                            return (
                                <div className="gallery-item" key={p.id}>
                                    <img src={p.signedUrl} className="hover-shadow cursor" alt={`Galary ${p.id}`} onClick={() => openImageModal(index)}/>
                                    <div className="desc">
                                        <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
                                        <input checked={(selectedFiles.indexOf(p.id) > -1) ? true:false } type="checkbox" style={{height:"18px"}} className="form-control" value={p.id} onChange={checkBoxEvent(p.id)}/>
                                            <span></span>
                                        </label> 
                                        <span>{p.name}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <Modal isOpen={openModal} ariaHideApp = {false} style={{position: "absolute"}} overlayClassName={"photo-galary-custom-overlay"}>
                        <div style={{
                            position: "fixed", 
                            zIndex: "9995", 
                            paddingTop: "50px", 
                            left: "0", 
                            top: "0", 
                            width: "100%", 
                            height: "100%", 
                            overflow: "auto", 
                            backgroundColor: "black",
                            display: "flex"
                        }}>
                            <span className="closeGallary cursor" onClick={() => closeModal()}>&times;</span>
                            <div className="gallery-slides-modal-content">
                                <div className="gallery-slide-wrapper">
                                    <img src={currentImageUrl} alt={"Current one"}/>
                                </div>
                                <button className="prev" style={{background: "none", border: "none"}} onClick={() => showPreviousImage()}>&#10094;</button>
                                <button className="next" style={{background: "none", border: "none"}} onClick={() => showNextImage()}>&#10095;</button>
                                <div className="caption-container">
                                    <p style={{color: "#fff"}}>{currentIndex + 1} / {picturs.length}</p>
                                    <p style={{color: "#fff"}}>{currentImageName}</p>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}