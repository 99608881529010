import { useState, useEffect, useCallback } from "react";
import * as Api from "../../../../Api";
import { SharedClaim } from "../../../../api/sharedClaim";

export function useAttachments({ 
    claimId,
    folderId,
    selectedFiles,
    setSelectedFiles,
    setIsloading,
    isSharedView
}) {
    
    const [folderFiles, SetFolderFiles] = useState([]);
    
    const uploadFiles = async ({files}) => {
        const RequestData = files.map((f) => {
            return {
                file: f.name,
                type: f.type,
                size: f.size,
                status: "uploadInProgress"
            }
        });
        const data = await Api.Claims.Attachments.Files.getSignedUrl({
            RequestData,
            claimId,
            folderId, 
        });
        const filesData = data.data.map((d) => {
            const filtreFile = files.filter((a) => (a.name === d.file));
            return {
                file: (filtreFile.length > 0) ? filtreFile[0] : '',
                accessId: d.id,
                signedUrl: d.signedUrl
            }
        });
        return filesData;
    }

    const changeStatusOfFile = ({
        status
    }) => {
        return new Promise((resolve) => {
            if(status.length > 0){
                Api.Claims.Attachments.Files.changeStatus({ 
                    claimId,
                    RequestData: status
                }).then(() => {
                    return resolve("");
                }).catch((err) => {
                    return resolve(err);
                })
            }
            if(status.length === 0){
                return resolve("");
            }
        });
    };
    
    const uploadOnAws = async ({signedUrl, file}) => {
        try {
            const response = await Api.Claims.Attachments.Files.uploadOnAws({
                signedUrl, 
                file
            });
            console.log(response);
            return {msg: 'success'};
        } catch(err) {
            return {msg: 'error'};
        }
    };
    
    const deleteFiles = () => {
        setIsloading(true);
        const requestData = {
            idsToDelete: selectedFiles
        };
        Api.Claims.Attachments.Files.delete({
            claimId,
            requestData
        }).then(() => {
            setSelectedFiles([]);
            getFoldersFiles();
        }).catch((err) => {
            console.log("Error in download files.");
            console.log(err);
        }).finally(() => {
            setIsloading(false);
        })
    }
    
    const downloadFile = () => {
        setIsloading(true);
        const requestData = {
            attachmentIds: selectedFiles
        };
        Api.Claims.Attachments.Files.download({
            claimId,
            requestData
        }).then((data) => {
            if(data.data.length > 0){
                data.data.map((a) => {
                    window.open(a.signedUrl, "_blank")
                    return a;
                });
            }
        }).catch((err) => {
            console.log("Error in download files.");
            console.log(err);
        }).finally(() => {
            setIsloading(false);
        })
    }

    const moveFiles = ({
        moveToFolder
    }) => {
        setIsloading(true);
        const requestData =  {
            idsToMove: selectedFiles,
            moveFrom: folderId,
            moveTo: moveToFolder
        };
        Api.Claims.Attachments.Files.move({
            claimId,
            requestData
        }).then((data) => {
            reloadFolderFiles();
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsloading(false);
            setSelectedFiles([]);
        });
    }

    const sharedClaimsFolderFiles = useCallback(() => {
        setIsloading(true);
        SharedClaim.getAttachmentFiles({
            folderId
        }).then((data) => {
            SetFolderFiles(data.data.attachmentsData);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsloading(false)
        });
    },[folderId, setIsloading]);
    
    const getFoldersFiles  = useCallback(() => {
        setIsloading(true);
        Api.Claims.Attachments.folder.getFiles({
            claimId,
            folderId
        }).then((data) => {
            SetFolderFiles(data.data.attachmentsData);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsloading(false)
        })
    }, [claimId, folderId, setIsloading]);

    const reloadFolderFiles = () => {
        getFoldersFiles()
    };

    useEffect(() => {
        if(!isSharedView){
            getFoldersFiles();
        }
        if(isSharedView){
            sharedClaimsFolderFiles();
        }
        
    }, [getFoldersFiles, isSharedView, sharedClaimsFolderFiles]);

    return {
        uploadFiles,
        uploadOnAws,
        changeStatusOfFile,
        folderFiles,
        deleteFiles,
        downloadFile,
        reloadFolderFiles,
        moveFiles
    }
}