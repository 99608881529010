import { takeEvery, put, fork } from "redux-saga/effects";

import { CompanyTypes, CompanyActions } from "./index";
import * as Api from "../../Api";

function* getCompanyMembers() {
  try {
    const {
      data: { members },
    } = yield Api.Company.getMembers();
    yield put(CompanyActions.GetCompanyMembers.success(members));
  } catch (err) {
    const error = Api.getError(err);
    yield put(CompanyActions.GetCompanyMembers.error(error));
  }
}

function* watchGetCompanyMembersStart() {
  yield takeEvery(CompanyTypes.GET_COMPANY_MEMBERS.START, getCompanyMembers);
}

const CompanySagas = [fork(watchGetCompanyMembersStart)];

export default CompanySagas;
