import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "../_common/buttons/Button";
import "../claims/claim/claim-form/ClaimActions.css";
import { ClaimActions } from "../../store/claims";
import { FullPageSpinner } from "../_common/Spinner";
import * as Api from "../../Api.js";
import { Notify } from "../../services/Notify.service";

export const ArchiveClaimAction = ({ onSubmit }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { id: claimId } = useSelector((state) => state.claims.claim);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showUnarchiveConfirm, setShowUnarchiveConfirm] = useState(false);
  const handleUnarchive = () => {
    setShowUnarchiveConfirm(true);
  };

  const DeleteArchive = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = () => {
    setShowDeleteConfirm(false);
    setIsLoading(true);
    Api.Claims.delete({
      id: claimId,
    })
      .then(() => {
        Notify.success("Deleted Successfully!");
        history.push(`/archive-files`);
      })
      .catch((error) => {
        Notify.success(
          "Error in deleting this file. Please contact to support."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmUrArchive = () => {
    setShowUnarchiveConfirm(false);
    setIsLoading(true);
    dispatch(
      ClaimActions.SetOnClaim.claimInfo({
        name: "status",
        value: "active",
      })
    );
    dispatch(ClaimActions.CreateOrUpdateClaim.setIsSaveAndClose(true));
    onSubmit();
  };

  return (
    <div className="row margin-bottom-20 claim__actions">
      {isLoading && <FullPageSpinner />}
      <SweetAlert
        show={showUnarchiveConfirm}
        warning
        showCancel
        confirmBtnText="Yes, Unarchive it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => confirmUrArchive()}
        onCancel={() => {
          setShowUnarchiveConfirm(false);
        }}
        focusCancelBtn
      >
        Unarchive this File?
      </SweetAlert>
      <SweetAlert
        show={showDeleteConfirm}
        warning
        showCancel
        confirmBtnText="Yes, Delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => confirmDelete()}
        onCancel={() => {
          setShowDeleteConfirm(false);
        }}
        focusCancelBtn
      >
        You will not be able to recover this!
      </SweetAlert>
      <Button
        type="submit"
        className="btn btn-outline blue-primary pull-right claim__action"
        onClick={handleUnarchive}
      >
        Unarchive
      </Button>
      <Button
        type="button"
        className="btn btn-outline red pull-right "
        onClick={DeleteArchive}
      >
        Delete
      </Button>
    </div>
  );
};
