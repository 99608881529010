import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';

import * as Api from '../../Api';
import BillingContext from './BillingContext';

import {Dropdown} from '../_common/Dropdown';
import {SubscriptionModal} from '../_common/showSubscriptionModal';

function Badge({bgColor}) {
  return (
    <span className="badge" style={{backgroundColor: bgColor, color: 'transparent'}}>
      0
    </span>
  );
}

export function Label(
  {labelsData, selectedClaimId, setSelectedClaimId, reloadClaims, setOpenManageLabelPanel, setFullPageSpinner}
) {
  const history = useHistory();
  const {fetchBillingStatus, userType} = useContext(BillingContext);
  const [openPanel, setOpenPanel] = useState(false);

  async function addToClaim({label}) {
    try {
      setFullPageSpinner(true);
      await Api.Claims.Label.addToClaim({claimId: selectedClaimId, labelData: label});
      reloadClaims();
    } catch (err) {
      /* intentionally empty */
    } finally {
      setFullPageSpinner(false);
    }
  }

  async function removeLabel() {
    try {
      setFullPageSpinner(true);
      await Api.Claims.Label.remove({claimId: selectedClaimId});
      reloadClaims();
    } catch (err) {
      /* intentionally empty */
    } finally {
      setFullPageSpinner(false);
    }
  }

  async function copyClaim() {
    try {
      setFullPageSpinner(true);
      const {data} = await Api.Claims.copy({id: selectedClaimId});
      setFullPageSpinner(false);
      history.push(`/claims/${data.id}`);
    } catch (err) {
      if (err.response.status === 403) {
        setOpenPanel(true);
        return fetchBillingStatus();
      }
      return Api.getError(err);
    } finally {
      setFullPageSpinner(false);
    }
  }

  return (
    <>
      <SubscriptionModal openPanel={openPanel} userType={userType} setOpenPanel={setOpenPanel}/>
      <Dropdown title="Tools" variant="blue-primary">
        <Dropdown.ListItem onClick={copyClaim}>
          Copy New Claim Form
        </Dropdown.ListItem>
        <Dropdown.Divider/>
        {
          labelsData.map((label) => (
            <Dropdown.ListItem key={label.id} onClick={() => {addToClaim({label});}}>
              {label.name}
              <Badge bgColor={label.bgColor}/>
            </Dropdown.ListItem>
          ))
        }

        <Dropdown.Divider/>
        <Dropdown.ListItem
          onClick={() => {
            setOpenManageLabelPanel(true);
            setSelectedClaimId(selectedClaimId);
          }}
        >
          Create & Manage Labels
        </Dropdown.ListItem>
        <Dropdown.Divider/>
        <Dropdown.ListItem onClick={removeLabel}>
          Remove Label
        </Dropdown.ListItem>
      </Dropdown>
    </>
  );
}
