import { takeEvery, put, fork } from "redux-saga/effects";

import { StatusTypes, StatusActions } from "./index";
import * as Api from "../../Api";

function* getStatuses() {
  try {
    const { data: statuses } = yield Api.Statuses.list();
    yield put(StatusActions.GetStatuses.success(statuses));
  } catch (err) {
    const error = Api.getError(err);
    yield put(StatusActions.GetStatuses.error(error));
  }
}

function* watchGetStatusesStart() {
  yield takeEvery(StatusTypes.GET_STATUSES.START, getStatuses);
}

const StatusSagas = [fork(watchGetStatusesStart)];

export default StatusSagas;
