import React, {useCallback, useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Pagebar} from '../layout/Pagebar';
import {PersonalInfo} from './PersonalInfo';
import {ChangeAvatar} from './ChangeAvatar';
import {ChangePassword} from './ChangePassword';
import {Settings} from './Settings';
import '../../assets/css/profile.min.css';

const BREADCRUMB = [
  {title: 'Home', link: '/app/claims'},
  {title: 'Edit Profile', link: ''}
];

export function Profile() {
  const [currentTab, setCurrentTab] = useState('personalInfo');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [profile, setProfile] = useState({});

  const {users} = profile || {};

  const fetchProfile = useCallback(async function _fetchProfile() {
    setIsLoading(true);
    try {
      const data = await Api.User.getProfile();
      if (data.data) {
        setProfile(data.data);
      }
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setProfile, setError]);

  useEffect(() => {fetchProfile();}, [fetchProfile]);

  function getProfileImage() {
    const {profileImage, profileImagePath} = users || {};

    return profileImage?.includes('gravatar')
      ? profileImage
      : profileImagePath;
  }

  const profileImage = getProfileImage();

  const {profileImagePath, name: userName = '', role: userRole = ''} = users || {};
  const hasProfileImage = !!profileImagePath;
  const userInitial = userName.charAt(0);
  return (
    <div>
      <Pagebar breadcrumbs={BREADCRUMB}/>
      <div className="profile-sidebar">
        <div className="portlet light profile-sidebar-portlet ">
          <div className="profile-userpic" style={{textAlign: 'center'}}>
            {
              hasProfileImage
                ? (<img id="profile_img" src={profileImage} className="img-responsive" alt=""/>)
                : (
                  <div id="profile_img_div" className="img-circle avatar-edit-profile-circle">
                    <span className="initials-edit-profile">
                      {userInitial}
                    </span>
                  </div>
                )
            }
          </div>
          <div className="profile-usertitle">
            <div className="profile-usertitle-name">
              {' '}{userName}{' '}
            </div>
            <div className="profile-usertitle-job">
              {' '}{userRole}{' '}
            </div>
          </div>
          <br/>
        </div>
      </div>
      <div className="profile-content">
        <div className="row">
          <div className="col-md-12">
            <div className="portlet light ">
              <div className="portlet-title tabbable-line">
                <div className="caption caption-md">
                  <i className="icon-globe theme-font hide"/>
                  <span className="caption-subject font-blue-madison bold uppercase">
                    Profile Account
                  </span>
                </div>
                <ul className="nav nav-tabs">
                  <li className={currentTab === 'personalInfo' ? 'active' : ''}>
                    <a href="#personalInfo" onClick={() => setCurrentTab('personalInfo')} data-toggle="tab">
                      Personal Info
                    </a>
                  </li>
                  <li className={currentTab === 'changeAvatar' ? 'active' : ''}>
                    <a href="#changeAvatar" onClick={() => setCurrentTab('changeAvatar')} data-toggle="tab">
                      Change Avatar
                    </a>
                  </li>
                  <li className={currentTab === 'changePassword' ? 'active' : ''}>
                    <a href="#changePassword" onClick={() => setCurrentTab('changePassword')} data-toggle="tab">
                      Change Password
                    </a>
                  </li>
                  <li className={currentTab === 'settings' ? 'active' : ''}>
                    <a href="#settings" onClick={() => setCurrentTab('settings')} data-toggle="tab">
                      Settings
                    </a>
                  </li>
                </ul>
              </div>
              <div className="portlet-body">
                <div className="tab-content">
                  <div id="personalInfo" className={`tab-pane ${currentTab === 'personalInfo' ? 'active' : ''}`}>
                    <PersonalInfo isLoading={isLoading} error={error} profile={profile}/>
                  </div>
                  <div id="changeAvatar" className={`tab-pane ${currentTab === 'changeAvatar' ? 'active' : ''}`}>
                    <ChangeAvatar isLoading={isLoading} error={error} profile={profile} reloadProfile={fetchProfile}
                                  profileInitialImage={profileImage}/>
                  </div>
                  <div id="changePassword" className={`tab-pane ${currentTab === 'changePassword' ? 'active' : ''}`}>
                    <ChangePassword isLoading={isLoading} error={error} profile={profile}/>
                  </div>
                  <div id="settings" className={`tab-pane ${currentTab === 'settings' ? 'active' : ''}`}>
                    <Settings isLoading={isLoading} error={error} profile={profile}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
