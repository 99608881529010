import React from "react";
import styled from "styled-components";

import { Button } from "../_common/Button";
import { Colors } from "../../colors";

const Bar = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 10px 5px;
`;

const Letter = styled.div`
  padding: 0 6px 0 9.2px;
  display: inline-block;
  width: auto;
  line-height: 24px;
  text-align: center;
  color: #314555;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    color: ${Colors.primary};
  }
`;

const options = [
  { value: "A" },
  { value: "B" },
  { value: "C" },
  { value: "D" },
  { value: "E" },
  { value: "F" },
  { value: "G" },
  { value: "H" },
  { value: "I" },
  { value: "J" },
  { value: "K" },
  { value: "L" },
  { value: "M" },
  { value: "N" },
  { value: "O" },
  { value: "P" },
  { value: "Q" },
  { value: "R" },
  { value: "S" },
  { value: "T" },
  { value: "U" },
  { value: "V" },
  { value: "W" },
  { value: "X" },
  { value: "Y" },
  { value: "Z" },
];

export function LetterToolbar({
  clientNameStartsWithLetter,
  setClientNameStartsWithLetter,
  onSearchSubmit,
  onResetClick,
  setSearchParams,
  searchParams,
}) {

  const  setSerachLetters = (l) => {
    searchParams.setClientNameStartsWithLetter(l);
    setSearchParams({...searchParams, clientNameStartsWithLetter: l});
  };

  return (
    <Bar>
      {options.map((option) => (
        <Letter
          key={option.value}
          style={
            clientNameStartsWithLetter === option.value
              ? { color: Colors.primary }
              : {}
          }
          onClick={() => setSerachLetters(option.value)}
        >
          {option.value}
        </Letter>
      ))}

      <div className="form-group" style={{ paddingLeft: 7, marginBottom: 0 }}>
        <div
          className="mt-checkbox-inline"
          style={{ paddingBottom: 0, marginBottom: 0 }}
        >
          <Button
            variant="blue-primary"
            classes="margin-right-10"
            onClick={onSearchSubmit}
            size="small"
            isOutline
          >
            SEARCH
          </Button>
          <Button isOutline variant="red" onClick={onResetClick} size="small">
            RESET
          </Button>
        </div>
      </div>
    </Bar>
  );
}
