import React from "react";

export const SelectTitle = ({
  value,
  handleChange,
  disabled,
  dataTestId = "select-title",
}) => {
  return (
    <>
      <label className="control-label">Select Title</label>
      <select
        className="form-control"
        disabled={disabled}
        value={value}
        onChange={handleChange}
        data-testid={dataTestId}
      >
        <option value="">Select Title</option>
        <option value="Mr">Mr</option>
        <option value="Ms">Ms</option>
        <option value="Mrs">Mrs</option>
        <option value="Prof.">Prof.</option>
        <option value="Dr.">Dr.</option>
        <option value="The Honorable">The Honorable</option>
      </select>
    </>
  );
};
