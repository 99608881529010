import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ClaimActions} from '../../../../store/claims';
import {AutoAddressFields} from './AutoAddressFields';

export function PropertyAddress() {
  const {isSharedView} = useSelector((state) => state.claims.claim);
  const dispatch = useDispatch();
  const {property, isMailingSameAsProperty} = useSelector((state) => state.claims.claim.data.client);

  function setProperty(data) {
    dispatch(ClaimActions.SetOnClaim.property({type: undefined, ...data}));

    if (isMailingSameAsProperty) {
      dispatch(ClaimActions.SetOnClaim.mailing({type: undefined, ...data}));
    }
  }

  function setPropertyField(data) {
    dispatch(ClaimActions.SetOnClaim.property({type: 'field', ...data}));

    if (isMailingSameAsProperty) {
      dispatch(ClaimActions.SetOnClaim.mailing({type: 'field', ...data}));
    }
  }

  return (
    <AutoAddressFields dataTestIdPrefix="property" address={property} label="Enter Property Address"
                       disabled={isSharedView} isShowCountry setAddress={setProperty}
                       setAddressField={setPropertyField}/>
  );
}
