import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {QueryParamProvider} from 'use-query-params';

import Routes from './components/routes/Routes';
import {store} from './store';
import {ToastContainer} from './components/_common/notifications/ToastContainer';

function App() {
  return (
    <Provider store={store}>
      <ToastContainer/>
      <Router basename="/app">
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
          <Route component={Routes}/>
        </Switch>
        </QueryParamProvider>
      </Router>
    </Provider>
  );
}

export default App;
