import {useCallback} from 'react';

import * as Api from '../../Api';
import useLazyFetcher from '../../hooks/useLazyFetcher';

export default function useClaim() {
  const [getClaim, {data: claimData, loading: claimLoading, error: claimError}]
    = useLazyFetcher(Api.Claims.getById);
  const [getNotes, {data: notesData, loading: notesLoading, error: notesError}]
    = useLazyFetcher(Api.Claims.Notes.getForClaims);

  const getClaimCallback = useCallback(async function _getClaim(id) {
    getClaim({id});
    getNotes({id});
  }, [getClaim, getNotes]);

  return [
    getClaimCallback,
    {
      data: {claim: claimData?.claim, notes: notesData},
      loading: claimLoading || notesLoading,
      error: claimError || notesError
    }
  ];
}