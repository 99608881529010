import React from 'react';
import moment from 'moment';

import {useDispatch, useSelector} from 'react-redux';

import {Form} from '../../../../_common/form/Form';
import {Col, Row} from '../../../../_common/layout/Layout';
import {DatePickerMask, TimePickerMask} from '../../../../_common/MaskedInput';
import {ClaimActions} from '../../../../../store/claims';
import {formatTime} from '../../../../../utils/dateTime';

export const AppointmentFields = () => {
  const dispatch = useDispatch();

  const {claimInfo: claim} = useSelector((state) => state.claims.claim.data);
  const {isSharedView} = useSelector((state) => state.claims.claim);

  const upcomingAppointmentTime = claim?.upcomingAppointmentTime;

  const time = upcomingAppointmentTime
    ? moment(upcomingAppointmentTime, 'h:m a').toISOString()
    : '';

  const onClaimInfoChange = (data) => dispatch(ClaimActions.SetOnClaim.claimInfo(data));

  return (
    <Form.Group>
      <Form.Label>Upcoming Appointments</Form.Label>
      <Row>
        <Col md={6}>
          <DatePickerMask
            placeholderText={'Format as MM/DD/YYYY'}
            selectedValue={claim?.upcomingAppointmentDate}
            handleChange={(date) =>
              onClaimInfoChange({
                name: 'upcomingAppointmentDate',
                value: date
              })
            }
            disabled={isSharedView}
          />
        </Col>
        <Col md={6}>
          <TimePickerMask
            placeholderText={'HH:MM'}
            selectedValue={time}
            handleChange={(date) => {
              console.log('date -> ', date);

              onClaimInfoChange({
                name: 'upcomingAppointmentTime',
                value: formatTime(date)
              });
            }}
            disabled={isSharedView}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};
