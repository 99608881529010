import React from 'react';
import { useSelector } from 'react-redux';
import { CompanyCamLogin } from '../../../integrations/company-cam/companyCamLogin';
import { CompanyCamCreate } from '../../../integrations/company-cam/companyCamCreate';
import { CompanyCamUpdate } from '../../../integrations/company-cam/companyCamUpdate';
import { CompanyCamLinks } from '../../../integrations/company-cam/companyCamLinks';
import useCompanyCamAuth from '../../../integrations/company-cam/useCompanyCamAuth';

export function CompanyCam() {
    const isLoggedIn = useCompanyCamAuth();
    const { 
        data: { _raw: {ccProjectId} = {} } = {},
    } = useSelector((state) => state.claims.claim);
    return (
        <div className="tw-flex tw-items-center">
            {!isLoggedIn && <CompanyCamLogin />}
            {isLoggedIn && <CompanyCamCreate />}
            {isLoggedIn && <CompanyCamUpdate />}

            {!ccProjectId && (<div className="tw-relative tw-inline-block tw-text-left tw-group">
                <button type="button" className="tw-text-gray-500 tw-bg-transparent group-hover:tw-text-gray-700 focus:tw-outline-none tw-mr-2">
                    <i className="fa fa-info-circle" />
                </button>
                <div className="tw-absolute tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-mt-2 tw-w-64 tw-px-4 tw-py-2 tw-bg-white tw-border tw-border-gray-300 tw-rounded tw-shadow-lg tw-z-[4] tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity tw-duration-300">
                    <div className="tw-text-gray-700 tw-text-sm">
                    You can now link CompanyCam projects to your claim files.{' '}
                    <a
                        href="/support/articles/company-cam"
                        className="tw-text-blue hover:tw-text-blue hover:tw-underline"
                    >Click here to learn more</a>.
                    </div>
                </div>
            </div>)}

            {isLoggedIn && <CompanyCamLinks />}
        </div>
    );
}