import React, { useState } from "react";
import { FullPageSpinner } from "../../../_common/Spinner";
import { DeleteSweetAlertConfirmation } from "../../../_common/Sweetalert";
import { AddAndEditReminder } from "./AddAndEditReminder";
import { useClaimFollowupReminderData } from "./FollowupReminderhook";
import moment from "moment";

export const FollowUpReminderTab = ({
  claimId,
  addFollowupReminder,
  setAddFollowupReminder,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const {
    isSharedView,
    reminders,
    editReminder,
    deleteReminder,
    deleteConfirm,
    reloadReminders,
    reminderDetails,
    addNewReminder
  } = useClaimFollowupReminderData({
    claimId,
    setIsLoading,
    setShowDeleteConfirmation,
    setOpenPanel,
    addFollowupReminder,
    setAddFollowupReminder,
  });

  return (
    <div>
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption">Follow Up Reminders</div>
          {!isSharedView && (
            <div className="actions">
              <button onClick={addNewReminder} className="btn blue-primary bold">
                Add New
              </button>
            </div>
          )}
        </div>
        <div className="portlet-body form">
          {isLoading && <FullPageSpinner />}
          <div className="portlet-body">
            {reminders.length === 0 && (
              <div
                className="form-body"
                style={{ padding: "20px 20px 20px 20px" }}
              >
                {!isSharedView && (
                  <span>
                    All your Reminders will show here. Use the 'Follow Up
                    Reminders' button to add a new Reminder.
                  </span>
                )}
                {isSharedView && (
                  <span>All your Reminders will show here.</span>
                )}
              </div>
            )}
            <AddAndEditReminder
              claimId={claimId}
              openPanel={openPanel}
              setOpenPanel={setOpenPanel}
              reminderDetails={reminderDetails}
              reloadReminders={reloadReminders}
            />
            <DeleteSweetAlertConfirmation
              show={showDeleteConfirmation}
              clickHandler={deleteConfirm}
            />
            {reminders.length !== 0 && (
              <div>
                <div
                  className="form-body"
                  style={{ padding: "0px 20px 0px 20px" }}
                >
                  <div className="scroller1">
                    <div className="mt-actions">
                      {reminders.map((r) => {
                        return (
                          <div key={r.id}>
                            <div className="mt-action">
                              <div className="mt-action-img">
                                <div className="avatar avatar-circle555">
                                  <span className="initials555">
                                    {r.createdBy[0]}
                                  </span>
                                </div>
                              </div>
                              <div className="mt-action-body">
                                <div className="mt-action-row">
                                  <div className="mt-action-info ">
                                    <div className="mt-action-icon ">
                                      <i className=" icon-bell"></i>
                                    </div>
                                    <div className="mt-action-details ">
                                      <span className="mt-action-author">
                                        {r.description}
                                      </span>
                                      <p
                                        className="mt-action-desc"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {r.createdBy}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="mt-action-datetime">
                                    <span
                                      style={{ marginLeft: "15px" }}
                                      className="mt-action-date"
                                    >
                                      {(r.specificDate) ? moment.utc(r.specificDate).local().format("MMM, DD YYYY hh:mm A") :''}
                                    </span>
                                  </div>
                                  {!isSharedView && r.isDeleteable && (
                                    <div className="mt-action-buttons ">
                                      <div className="btn-group btn-group-circle">
                                        <button
                                          onClick={() =>
                                            editReminder({
                                              reminder: r,
                                            })
                                          }
                                          className="btn btn-outline blue-primary btn-sm"
                                        >
                                          Edit
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            deleteReminder({
                                              reminder: r,
                                            })
                                          }
                                          className="btn btn-outline red btn-sm"
                                          data-id="534"
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
