import React from "react";

const columns = [
  { id: "fileNumber", name: "File #", width: "4%" },
  { id: "createdByDate", name: "Date", width: "10%" },
  { id: "clientName", name: "Client", width: "15%" },
  { id: "assignedAdjusterName", name: "Adjuster Assigned", width: "10%" },
  { id: "dateOfLoss", name: "DOL", width: "10%" },
  { id: "lossCity", name: "Loss Address", width: "15%" },
  { id: "insurer", name: "Insurer", width: "15%" },
  { id: "status", name: "Status", width: "7%" },
];

export function ClaimsTableHeader({ sortBy, setSortBy, orderBy, setOrderBy, checkAllCheckbox }) {
  return (
    <tr role="row" className="heading">
      <th style={{ width: "1%" }} rowSpan="1" colSpan="1">
        <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
          <input
            type="checkbox"
            className="group-checkable"
            data-set="#sample_2 .checkboxes"
            onChange={(e) => {
              checkAllCheckbox(e.target.checked);
            }}
          />
          <span></span>
        </label>
      </th>
      {columns.map((column) => (
        <th
          key={column.id}
          style={{ width: column.width }}
          className={`${
            sortBy === column.id ? "sorting_" + orderBy : "sorting"
          }`}
          rowSpan="1"
          colSpan="1"
          onClick={() => {
            setSortBy(column.id);
            setOrderBy(orderBy === "desc" ? "asc" : "desc");
          }}
        >
          {column.name}
        </th>
      ))}

      <th style={{ width: "10%" }} className="sorting" rowSpan="1" colSpan="1">
        Actions
      </th>
    </tr>
  );
}
