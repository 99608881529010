import React from 'react';

import * as Api from '../../../Api';
import useFetcher from '../../../hooks/useFetcher';

import {DatePickerWithOption} from '../../_common/DatePickerWithOption';
import {Icon} from '../../_common/Icons';
import {SearchableInputDropDown} from '../../_common/searchanbleInput';
import {LossAddressFilter} from './LossAddressFilter';

export function FilterHead({filter, setFilter, onReset}) {
  const {data: allInsurers} = useFetcher(Api.Claims.Insurers.get);
  const {data: statusData} = useFetcher(Api.Claims.Status.get);
  const {data: adjustersData} = useFetcher(Api.Claims.Adjusters.get);

  function set(key, value) {
    setFilter({...filter, [key]: value});
  }

  function handleDatesChange(prefix) {
    return (from, to) => setFilter({...filter, [`${prefix}FromDate`]: from, [`${prefix}ToDate`]: to});
  }

  function handleReset(e) {
    e.preventDefault();
    onReset();
  }

  const {
    fileNumber, createdAtFromDate, createdAtToDate, dolFromDate, dolToDate, clientName, claimNumber,
    assignedAdjusters, cities, zipcodes, street, insurers, status
  } = filter;

  let adjusters = [];
  if (adjustersData) {
    const {adjusters: mAdjusters, admins, superadmin} = adjustersData.members;
    adjusters = [...(mAdjusters || []), ...(admins || []), ...(superadmin || [])];
  }

  return (
    <tr role="row" className="filter">
      <td rowSpan="1" colSpan="1"/>
      <td rowSpan="1" colSpan="1">
        <input className="form-control form-filter input-sm" type="text" name="fileNumber" value={fileNumber || ''}
               onChange={(e) => set('fileNumber', e.target.value)}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <DatePickerWithOption fromDate={createdAtFromDate} toDate={createdAtToDate}
                              onDatesChange={handleDatesChange('createdAt')}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <input className="form-control form-filter input-sm margin-bottom-5" type="text"
               value={clientName || ''} placeholder="Search By Clients"
               onChange={(e) => set('clientName', e.target.value)}/>
        <input className="form-control form-filter input-sm" type="text" value={claimNumber || ''}
               placeholder="Search By Claim #"
               onChange={(e) => set('claimNumber', e.target.value)}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <List data={adjusters} value={assignedAdjusters} optionMapper={({id, name}) => ({value: id, content: name})}
              onChange={value => set('assignedAdjusters', value)}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <DatePickerWithOption fromDate={dolFromDate} toDate={dolToDate} onDatesChange={handleDatesChange('dol')}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <LossAddressFilter selectedCities={cities} selectedZipcodes={zipcodes} street={street}
                           setSelectedCities={value => set('cities', value)}
                           setSelectedZipcodes={value => set('zipcodes', value)}
                           setStreet={value => set('street', value)}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <SearchableInputDropDown data={allInsurers || []} value={insurers} onChange={value => set('insurers', value)}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <List data={statusData} value={status}
              optionMapper={({statusName}) => ({value: statusName, content: statusName})}
              onChange={value => set('status', value)}/>
      </td>
      <td rowSpan="1" colSpan="1">
        <div className="margin-bottom-5">
          <button className="btn btn-sm blue-primary btn-outline filter-submit margin-bottom" type="submit">
            <Icon.Search/> Search
          </button>
        </div>
        <button className="btn btn-sm red btn-outline filter-cancel" onClick={handleReset}>
          <Icon.Times/> Reset
        </button>
      </td>
    </tr>
  );
}

function List({data, value, optionMapper, onChange}) {
  if (!data?.length) {
    return null;
  }

  function handleChange(e) {
    onChange(Array.from(e.target.selectedOptions, (option) => option.value));
  }

  return (
    <select className="form-control form-filter input-sm" multiple="multiple" value={value || []}
            onChange={handleChange}>
      <option value="">Select...</option>
      {
        data.map((item, index) => {
          const {value, content} = optionMapper(item);
          return (
            <option key={index} value={value}>
              {content}
            </option>
          );
        })
      }
    </select>
  );
}
