import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./assets/css/global/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/css/global/css/animate.css";
import "./assets/plugins/font-awesome/css/font-awesome.min.css";
import "./assets/css/global/css/plugins.css";
import "./assets/css/layouts/css/layout.css";
import "./assets/css/layouts/css/themes/darkblue.css";
import "./assets/css/layouts/css/custom.css";
import "./assets/css/global/css/components-rounded.css";
import "./assets/css/utility.css";

import "./Utils.css";

import './tw-preflight.css'
import './tw.css';
import './tw-utilities.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
