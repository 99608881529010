import React, { useState } from "react";

export function LossAddressFilter({
  cities,
  zipcodes,
  selectedCities,
  setSelectedCities,
  selectedZipcodes,
  setSelectedZipcodes,
  street,
  setStreet,
}) {
  return (
    <>
      <div className="panel-group accordion" id="accordion1">
        <Panel
          title="Search By City"
          options={cities}
          selectedOptions={selectedCities}
          setSelectedOptions={setSelectedCities}
        />
        <Panel
          title="Search By Zipcode"
          options={zipcodes}
          selectedOptions={selectedZipcodes}
          setSelectedOptions={setSelectedZipcodes}
        />
      </div>
      <input
        type="text"
        className="form-control form-filter input-sm margin-bottom-5"
        placeholder="Search By Street"
        style={{ marginTop: -15, textAlign: "center" }}
        value={street}
        onChange={(e) => setStreet(e.target.value)}
      />
    </>
  );
}

function Panel({ title, options = [], selectedOptions, setSelectedOptions }) {
  const [expand, setExpand] = useState(false);

  const handleClick = () => setExpand((prev) => !prev);

  const handleChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptions(selectedValues);
  };

  return (
    <div
      className="panel panel-default"
      style={{
        background: "transparent",
        border: "1px solid #c2cad8!important",
        borderRadius: "0px !important",
      }}
    >
      <div
        className="panel-heading"
        style={{
          background: "transparent",
        }}
        onClick={handleClick}
      >
        <h4 className="panel-title">
          <a
            style={{
              fontSize: 12,
              color: "#555",
              textDecoration: "none",
              textAlign: "center",
              padding: "7px 0",
            }}
            className="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion1"
            href="#collapse_1"
            aria-expanded="true"
          >
            {title}
          </a>
        </h4>
      </div>
      <div
        id="collapse_1"
        className={`panel-collapse collapse ${expand ? "in" : ""}`}
        aria-expanded="true"
      >
        <div className="panel-body">
          <select
            multiple="multiple"
            className="form-control form-filter input-sm"
            value={selectedOptions}
            onChange={handleChange}
          >
            {options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
