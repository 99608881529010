import React, {useCallback, useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Pagebar} from '../layout/Pagebar';
import {InsurersDatatable} from './tables';
import {AddNewAndEdit} from './addNewAndEdit';
import {DeleteSweetAlertConfirmation} from '../_common/Sweetalert';
import {Dropdown} from '../_common/Dropdown';
import DownloadCSV from '../_common/DownloadCSV';
import DownloadPDF from '../_common/DownloadPDF';
import DownloadImage from '../_common/DownloadImage';
import {Notify} from '../../services/Notify.service';

const DOWNLOAD_CSV_HEADERS = [
  {label: 'Insurers Name', key: 'name'},
  {label: 'Email Address', key: 'email'},
  {label: 'Street', key: 'street'},
  {label: 'City', key: 'city'},
  {label: 'State', key: 'state'},
  {label: 'Zipcode', key: 'zip'},
  {label: 'Phone #', key: 'phone'},
  {label: 'Extension', key: 'extension'},
  {label: 'Fax #', key: 'fax'}
];

const DOWNLOAD_PDF_HEADERS = [
  {header: 'Insurers Name', dataKey: 'name'},
  {header: 'Email Address', dataKey: 'email'},
  {header: 'Street', dataKey: 'street'},
  {header: 'City', dataKey: 'city'},
  {header: 'State', dataKey: 'state'},
  {header: 'Zipcode', dataKey: 'zip'},
  {header: 'Phone #', dataKey: 'phone'},
  {header: 'Extension', dataKey: 'extension'},
  {header: 'Fax #', dataKey: 'fax'}
];

const BREADCRUMB = [
  {
    title: 'Home',
    link: '/app/claims'
  },
  {
    title: ' Insurers',
    link: ''
  }
];

export function Insurers() {
  const [isLoading, setIsLoading] = useState(true);
  const [insurers, setInsurers] = useState([]);
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteSelectedInsurer, setDeleteSelectedInsurer] = useState({});
  const [opensidePanel, setOpensidePanel] = useState(false);
  const [insurerDetails, setInsurerDetails] = useState({});

  const fetchInsurers = useCallback(async function _fetchInsurers() {
    setIsLoading(true);
    try {
      const data = await Api.Insurers.list();
      if (data.data) {
        setInsurers(data.data);
      }
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setInsurers, setError]);

  useEffect(() => {fetchInsurers();}, [fetchInsurers]);

  function confirmDelete(selectedInsurer) {
    setShowDeleteConfirmation(true);
    setDeleteSelectedInsurer(selectedInsurer);
  }

  function saveAsPdf() {
    return DownloadPDF({
      data: insurers,
      headers: DOWNLOAD_PDF_HEADERS,
      filename: 'insurers.pdf'
    });
  }

  async function deleteInsurers(confirm) {
    if (!confirm) {
      setShowDeleteConfirmation(false);
      return;
    }
    try {
      await Api.Insurers.delete({id: deleteSelectedInsurer.id, tableName: deleteSelectedInsurer.tableName});
      fetchInsurers();
      Notify.success('Deleted!');
    } catch (err) {
      // intentionally empty
    } finally {
      setDeleteSelectedInsurer({});
      setShowDeleteConfirmation(false);
    }
  }

  function handleOpenSidePanel(e) {
    e.preventDefault();
    setInsurerDetails({});
    setOpensidePanel(true);
  }

  return (
    <div>
      {<Pagebar breadcrumbs={BREADCRUMB}/>}
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              INSURERS
            </span>
          </div>
          <div className="actions"/>
        </div>
        <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={deleteInsurers}/>
        <AddNewAndEdit openPanel={opensidePanel} setOpenPanel={setOpensidePanel} insurerDetails={insurerDetails}
                       reloadTable={fetchInsurers}/>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group">
                  <button className="btn btn-outline blue-primary add_new" onClick={handleOpenSidePanel}>
                    Add New <i className="fa fa-plus"/>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="btn-group pull-right">
                  <Dropdown title="Tools" variant="red">
                    <Dropdown.ListItem onClick={() => false}>
                      <DownloadImage Data={insurers} headers={DOWNLOAD_PDF_HEADERS} TableName="Insurers"/>
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={saveAsPdf}>
                      Save as PDF
                    </Dropdown.ListItem>
                    <Dropdown.ListItem
                      onlyLI={
                        <DownloadCSV
                          data={insurers}
                          filename="insurers.csv"
                          headers={DOWNLOAD_CSV_HEADERS}
                          target="_blank"
                        />
                      }
                      onClick={() => false}
                    />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="table-scrollable">
            <InsurersDatatable insurers={insurers} isLoading={isLoading} error={error}
                               setOpensidePanel={setOpensidePanel} setInsurerDetails={setInsurerDetails}
                               confirmDelete={confirmDelete}/>
          </div>
        </div>
      </div>
    </div>
  );
}
