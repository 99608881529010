import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import {PrivateRoute} from './PrivateRoutes';

import AuthWrapper from '../auth/AuthWrapper';
import {SharedClaimAttachment} from '../claims/claim/SharedClaimAttachment/SharedClaimAttachment';
import {ClaimsTable} from '../claims/ClaimsTable';
import {EditClaim} from '../claims/claim/EditClaim';
import {SharedClaim} from '../claims/claim/SharedClaim';
import {Claim} from '../claims/claim/Claim';
import {ImportClaims} from '../claims/import/import';
import {ImportSummary} from '../claims/importSummary/importSummary';
import {Page404} from '../page404/Page404';
import {Calendar} from '../calendar/Calendar';
import {Templates} from '../templates/Templates';
import {UploadTemplates} from '../templates/uploadTemplate';
import {Admins} from '../admins/Admins';
import {Adjusters} from '../Adjusters/Adjusters';
import {Insurers} from '../Insurers/Insurers';
import {Profile} from '../profile/Profile';
import {NewBilling} from '../billing/newBilling';
import {NewPaymentMethods} from '../billing/newPaymentMethods'
import {Logout} from '../logout/Logout';
import {Attorneys} from '../attorneys/Attorneys';
import {AttorneyFiles} from '../attorney-files/AttorneyFiles';
import {ArchiveFileForm} from '../archive-files/ArchiveFileForm';
import {ArchiveFiles} from '../archive-files/ArchiveFiles';
import {Vendors} from '../vendors/Vendors';
import {Status} from '../status/Status';
import {Support} from '../support/support';
import {TermsAndConditions} from '../terms-and-privacy/TermsAndConditions';
import {PrivacyPolicy} from '../terms-and-privacy/PrivacyPolicy';
import {CustomOptions} from '../custom-options/CustomOptions';
// import {Test} from '../test/test';
import { ShareUpload } from '../claims/claim/ShareUpload/ShareUpload';
import {CompanyCamAuth} from '../integrations/company-cam/auth';
import {CompanyCam} from '../support/articles/companyCam';

// <PrivateRoute exact path="/test" component={Test}/>
const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/login" component={AuthWrapper}/>
        <Route exact path="/signup" component={AuthWrapper}/>
        <Route exact path="/forgot-password" component={AuthWrapper}/>
        <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
        <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
        <Route exact path="/verify-account/:token" component={AuthWrapper}/>
        <Route exact path="/reset-password/:token" component={AuthWrapper}/>
        <Route exact path="/shared-claim-attachment" component={SharedClaimAttachment}/>
        <Route exact path="/claim/upload/:id" component={ShareUpload}/>
        

        <PrivateRoute path="/claims/new" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Claim}/>
        <PrivateRoute path="/claims/import" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={ImportClaims}/>
        <PrivateRoute path="/claims/import/:id" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={ImportSummary}/>
        <PrivateRoute path="/claims/:id" rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={EditClaim}/>

        <Route path="/shared-claim/:shareToken" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
               component={SharedClaim}/>

        <PrivateRoute path="/" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={ClaimsTable}/>
        <PrivateRoute path="/claims" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={ClaimsTable}/>
        <PrivateRoute path="/custom-options/:subroute" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={CustomOptions}/>
        <PrivateRoute path="/calendar" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Calendar}/>
        <PrivateRoute path="/templates/upload" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={UploadTemplates}/>
        <PrivateRoute path="/templates" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Templates}/>
        <PrivateRoute path="/admins" exact rolesAllowed={['SUPERADMIN', 'ADMIN']} component={Admins}/>
        <PrivateRoute path="/adjusters" exact rolesAllowed={['SUPERADMIN', 'ADMIN']} component={Adjusters}/>
        <PrivateRoute path="/attorney-files" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={AttorneyFiles}/>
        <PrivateRoute path="/archive-files" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={ArchiveFiles}/>
        <PrivateRoute path="/archive-file/:id" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']}
                      component={ArchiveFileForm}/>
        <PrivateRoute path="/attorneys" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Attorneys}/>
        <PrivateRoute path="/vendors" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Vendors}/>
        <PrivateRoute path="/insurers" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Insurers}/>
        <PrivateRoute path="/status" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Status}/>
        <PrivateRoute path="/support" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Support}/>
        <PrivateRoute path="/support/articles/company-cam" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={CompanyCam}/>

        <PrivateRoute path="/profile" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Profile}/>
        <PrivateRoute path="/billing" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={NewBilling}/>
        <PrivateRoute path="/billing/payment-methods" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={NewPaymentMethods}/>
        <PrivateRoute path="/logout" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={Logout}/>
        <PrivateRoute path="/integrations/company-cam" exact rolesAllowed={['SUPERADMIN', 'ADMIN', 'ADJUSTER']} component={CompanyCamAuth}/>

        <Route component={Page404}/>
      </Switch>
    </Fragment>
  );
};

export default Routes;
