import { takeEvery, put, fork } from "redux-saga/effects";

import { AttorneyTypes, AttorneyActions } from "./index";
import * as Api from "../../Api";

function* getAttorneys() {
  try {
    const {
      data: { attorneys },
    } = yield Api.Attorneys.list();
    yield put(AttorneyActions.GetAttorney.success(attorneys));
  } catch (err) {
    const error = Api.getError(err);
    yield put(AttorneyActions.GetAttorney.error(error));
  }
}

function* watchGetAttorneysStart() {
  yield takeEvery(AttorneyTypes.GET_ATTORNEYS.START, getAttorneys);
}

const AttorneySagas = [fork(watchGetAttorneysStart)];

export default AttorneySagas;
