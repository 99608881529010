import React from "react";
import { Icon } from "../_common/Icons";

const options = [10, 20, 50, 100, 150, 200];

export function PaginationPanel({
  total,
  limitPerPage,
  setLimitPerPage,
  currentPage,
  setCurrentPage,
}) {
  const numberOfPages = Math.ceil(((total) ? total: 0) / limitPerPage);

  const goToNextPage = () => {
    if (currentPage === numberOfPages) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage === 1) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  const handleLimitChange = (e) => {
    setLimitPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handlePageInputChange = (e) => {
    const value = Number(e.target.value);

    if (value > numberOfPages) {
      return setCurrentPage(numberOfPages);
    }
    setCurrentPage(value);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <label>
          Show{" "}
          <select
            name="sample_5_length"
            aria-controls="sample_5"
            className="form-control input-sm input-xsmall input-inline"
            value={limitPerPage}
            onChange={handleLimitChange}
          >
            {options.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </label>
      </div>
      <div>
        Page{" "}
        <a
          href="#!"
          onClick={goToPreviousPage}
          style={{marginTop: "0px", position: "initial"}}
          className={`btn btn-sm default prev ${
            currentPage === 1 ? "disabled" : ""
          }`}
        >
          <Icon.AngleLeft />
        </a>
        <input
          type="number"
          className="pagination-panel-input form-control input-sm input-inline input-mini"
          maxlenght="5"
          style={{
            textAlign: "center !important",
          }}
          value={currentPage}
          onChange={handlePageInputChange}
        />
        <a
          href="#!"
          onClick={goToNextPage}
          style={{marginTop: "0px", position: "initial"}}
          className={`btn btn-sm default next ${
            currentPage === numberOfPages ? "disabled" : ""
          }`}
        >
          <Icon.AngleRight />
        </a>{" "}
        of <span className="pagination-panel-total">{numberOfPages}</span>
      </div>
    </div>
  );
}
