export const CLEAR_CLAIM = "CLEAR_CLAIM";
export const CLAIM_ROUTE = {
  SET_IS_NEW: "CLAIM_ROUTE/SET_IS_NEW",
  SET_IS_SHARED_VIEW: "CLAIM_ROUTE/SET_IS_SHARED_VIEW",
};

export const CREATE_OR_UPDATE_CLAIM = {
  PRE_CHECK: "CLAIMS/C_OR_U_PRE_CHECK",
  START: "CLAIMS/C_OR_U_START",
  IS_CREATING: "CLAIMS/C_OR_U_IS_CREATING",
  SET_IS_SAVE_AND_CLOSE: "CLAIMS/C_OR_U_SET_IS_SAVE_AND_CLOSE",
  SET_IS_FORM_SUBMITTED: "CLAIMS/C_OR_U_SET_IS_FORM_SUBMITTED",
  CANCEL: "CLAIMS/C_OR_U_CANCEL",
  SUCCESS: "CLAIMS/C_OR_U_SUCCESS",
  ERROR: "CLAIMS/C_OR_U_ERROR",
  CLEAR: "CLAIMS/CLEAR_CLAIM",
  CLAIM_ADJUSTERS_START: "CLAIMS/C_OR_U_CLAIM_ADJUSTERS_START",
  CLAIM_ADJUSTERS_SUCCESS: "CLAIMS/C_OR_U_CLAIM_ADJUSTERS_SUCCESS",
  CLAIM_ADJUSTERS_ERROR: "CLAIMS/C_OR_U_CLAIM_ADJUSTERS_ERROR",
  CLAIM_ADJUSTERS_CANCEL: "CLAIMS/C_OR_U_CLAIM_ADJUSTERS_CANCEL",

  DUPLICATE_CHECK_START: "CLAIMS/DUPLICATE_CHECK_START",
  DUPLICATE_CHECK_SUCCESS: "CLAIMS/DUPLICATE_CHECK_SUCCESS",
  DUPLICATE_CHECK_ERROR: "CLAIMS/DUPLICATE_CHECK_ERROR",
  DUPLICATE_SET_ALLOW: "CLAIMS/DUPLICATE_SET_ALLOW",
  CLEAR_DUPLICATES: "CLAIMS/CLEAR_DUPLICATES",
};

export const CLAIM_ADJUSTERS = {
  DELETE_START: "CLAIM_ADJUSTERS/DELETE_START",
  DELETE_SUCCESS: "CLAIM_ADJUSTERS/DELETE_SUCCESS",
  DELETE_ERROR: "CLAIM_ADJUSTERS/DELETE_ERROR",
  GET_START: "CLAIM_ADJUSTERS/GET_START",
  GET_SUCCESS: "CLAIM_ADJUSTERS/GET_SUCCESS",
  GET_ERROR: "CLAIM_ADJUSTERS/GET_ERROR",
};

export const GET_CLAIM = {
  START: "CLAIM/GET_CLAIM_START",
  SUCCESS: "CLAIM/GET_CLAIM_SUCCESS",
  ERROR: "CLAIM/GET_CLAIM_ERROR",
};

export const X_SHARE_TOKEN = "x-share-token";

export const GET_SHARED_CLAIM = {
  START: "CLAIM/GET_SHARED_CLAIM_START",
  SUCCESS: "CLAIM/GET_SHARED_CLAIM_SUCCESS",
  ERROR: "CLAIM/GET_SHARED_CLAIM_ERROR",
  CUSTOM_FIELDS_START: "CLAIM/GET_SHARED_CUSTOM_FIELDS_START",
  CUSTOM_FIELDS_SUCCESS: "CLAIM/GET_SHARED_CUSTOM_FIELDS_SUCCESS",
  CUSTOM_FIELDS_ERROR: "CLAIM/GET_SHARED_CUSTOM_FIELDS_ERROR",
};

export const SET_ON_CLAIM = {
  VALUE: "SET_ON_CLAIM/VALUE",
  CLIENT: "SET_ON_CLAIM/CLIENT",
  CLIENT1: "SET_ON_CLAIM/CLIENT1",
  CLIENT2: "SET_ON_CLAIM/CLIENT2",
  CONTACT: "SET_ON_CLAIM/CONTACT",
  PROPERTY: "SET_ON_CLAIM/PROPERTY",
  MAILING: "SET_ON_CLAIM/MAILING",
  CLAIM_ADJUSTER: "SET_ON_CLAIM/CLAIM_ADJUSTER",
  NEW_CLAIM_ADJUSTER: "SET_ON_CLAIM/NEW_CLAIM_ADJUSTER",
  DELETE_CLAIM_ADJUSTER: "SET_ON_CLAIM/DELETE_CLAIM_ADJUSTER",
  CLAIM_INFO: "SET_ON_CLAIM/CLAIM_INFO",
  VENDOR: "SET_ON_CLAIM/VENDOR_ID",
  ATTORNEY_ID: "SET_ON_CLAIM/ATTORNEY_ID",
  ATTORNEY: "SET_ON_CLAIM/ATTORNEY",
  ASSIGNED_ADJUSTERS: "SET_ON_CLAIM/ASSIGNED_ADJUSTERS",
  OTHER_ADJUSTERS_ALLOWED: "SET_ON_CLAIM/OTHER_ADJUSTERS_ALLOWED",
  CUSTOM_FIELDS: "SET_ON_CLAIM/CUSTOM_FIELDS",
};

export const ALLOW_DUPLICATE_STATUSES = {
  ALLOW: "allow",
  NOT_ALLOWED: "not-allowed",
  UNSPECIFIED: null,
};

export const CLIENT_TYPES = {
  residence: "residence",
  business: "business",
};

export const UPDATE_CC_PROJECT_ID = 'UPDATE_CC_PROJECT_ID';
