import React, {useEffect, useState} from 'react';
import {Pagebar} from '../layout/Pagebar';
import {Link} from 'react-router-dom';
import { AddPaymentMethodModal } from './addPaymentMethodModal';
import Modal from "react-modal";
import './newBilling.css';
import moment from 'moment';

import * as Api from '../../Api';

export function NewBilling() {
    const [isOpen, setIsOpen] = useState(false);
    const [modal, setModal] = useState('');
    const [customer, setCustomer]= useState(null);
    const [users, setUsers] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [plan, setPlan] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reason, setReason] = useState('');
    const [modalIsWaiting, setModalIsWaiting] = useState(false);

    const refreshData = async () => {
        /*const status = await Api.Billing.status();*/
        const usersResponse = await Api.Billing.getActiveUsers();
        setUsers(usersResponse.data);

        const customerResponse = await Api.Billing.getCustomer();
        const customer = customerResponse.data;
        if (customer.id) {
            setCustomer(customer);
            const subscription = customer.paymentMethods
                .map(x => x.subscriptions) // [[1,2,3],[4,5,6]]
                .flat()                    // [1,2,3,4,5,6]
                .find(s => ['Active','Pending','Past Due'].includes(s.status));
            setSubscription(subscription);
            let paymentMethod;
            if (subscription) {
                paymentMethod = customer.paymentMethods.find(x => x.subscriptions.find(s => s === subscription));
            } else {
                paymentMethod = customer.paymentMethods?.find(x => x.default);
            }
            setPaymentMethod(paymentMethod);
        } else {
            console.log('error getting customer');
        }
        
        setLoading(false);
    }

    useEffect(() => {
        refreshData();
        // return () => {};
    }, []);

    const getPlan = async() => {
        if (subscription) {
            const planResponse = await Api.Billing.getPlan(subscription.planId);
            const plan = planResponse.data;
            setPlan(plan);
        }
    }

    useEffect(() => {
        // console.log(subscription);
        getPlan();
        //eslint-disable-next-line
    }, [subscription]);


    const confirmCreateSubscription = async () => {
        setModal('confirmCreateSubscription');
        /*if (paymentMethod) {
            // TODO confirm payment method
            setModal('confirmCreateSubscription');
        } else {
            // add new card
            setIsOpen(true);
        }*/
    }

    const createSubscripiton = async () => {
        setModalIsWaiting(true);
        const addSubscriptionResponse = await Api.Billing.addSubscription({
            paymentMethodToken: paymentMethod.token,
            customerId: customer.id
        });
        if (addSubscriptionResponse.data.id) {
            setSubscription(addSubscriptionResponse.data);
            setModalIsWaiting(false);
            setModal('createSubscriptionSuccess');
        } else {
            // there was a problem
        }
    }

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    }

    const confirmCancelSubscription = async () => {
        setModal('confirmCancelSubscription');
    }
    
    const hideModalAndRefresh = () => {
        setModalIsWaiting(false);
        setModal('');
        setLoading(true);
        refreshData();
    }

    const hideModal = () => {
        setModalIsWaiting(false);
        setModal('')
    }

    const cancelSubscription = async () => {
        setModalIsWaiting(true);
        const response = await Api.Billing.cancelSubscription(subscription.id);
        if (response.data.success) {
            setModalIsWaiting(false);
            setModal('cancelSubscriptionSuccess');
            Api.Slack.sendMessage(`Canceled Subscription\nCustomer: ${customer.company}\nReason: ${reason}`);
        } else {
            // there was a problem
        }
    }

    const breadcrumb = [
        {
            title: 'Home',
            link: '/app/claims'
        },
        {
            title: 'Billing',
            link: ''
        }
    ];

    const changePaymentMethod = (pm) => {
        setPaymentMethod(pm);
    };

    const addPaymentMethod = () => {
        hideModal();
        setIsOpen(true);
    };

    const onPaymentMethodAdded = ({paymentMethod: pm, customer: cust}) => {
        if (cust) {
            // new Braintree customer added
            setCustomer(cust);
        } else {
            // existing Braintree customer
            setCustomer({...customer, paymentMethods: [...customer.paymentMethods, pm]});
        }
        setPaymentMethod(pm);
        setModal('confirmCreateSubscription');
    };

    const messages = {
        confirmCreateSubscription: {
            title: 'Purchase Subscription',
            body: (<>
                <p className='tw-mb-3'>
                    You will be billed $45/month for the first user plus $20/month for each additional user. 
                    You currently have {users.length} active user{(users.length >1) ? 's' : ''}, so your total will be:
                    ${45 + (users.length - 1) * 20}
                </p>
                <p className='tw-mb-3'>Bill My Credit Card</p>
                <div className="row billing">
                    {customer && customer.paymentMethods.map((pm, i) => 
                        <div className="col-sm-4" key={i}>
                            <div className={'cc' + ((pm === paymentMethod) ? ' selected' : '') + ' tw-cursor-pointer'} onClick={() => changePaymentMethod(pm)}>
                            <p><img className="tw-mx-auto" src={pm.imageUrl} alt={pm.cardType} /></p>
                            <p>{pm.cardType}</p>
                            <p>****{pm.last4}</p>
                            </div>
                        </div>
                    )}
                    <div className="col-sm-4">
                        <div className="cc tw-cursor-pointer" onClick={addPaymentMethod}>
                            <span style={{fontSize: 24, fontWeight: 'bold'}}>+</span><br />
                            Add New Card
                        </div>
                    </div>
                </div>
            </>),
            buttons: [
                {
                    text:'Cancel',
                    action: hideModal,
                    isPrimary: false,
                    isDisabled: false
                },
                {
                    text:'Purchase Subscription',
                    action: createSubscripiton,
                    isPrimary: true,
                    isDisabled: (!paymentMethod)
                }
            ]
        },
        createSubscriptionSuccess: {
            title: 'Success',
            body: 'Your Organized Adjusting Subscription has successfully been created.',
            buttons: [
                {
                    text:'OK',
                    action: hideModal,
                    isPrimary: true,
                    isDisabled: false
                }
            ]
        },
        confirmCancelSubscription: {
            title: 'Cancel Subscription',
            body: (<>
                <p className="tw-mb-3">Are you sure you want to cancel your subscription?</p>
                <p className="tw-mb-3"> Your account will be converted to a Free Test Drive plan and you will be limited to only 3 claim files.</p>
                <div className="form-group">
                    <label htmlFor="tell-us-why">Please take a moment to tell us why you are canceling.</label>
                    <textarea className="form-control" id="tell-us-why" name="tell-us-why" value={reason} onChange={handleReasonChange}></textarea>
                </div></>
            ),
            buttons: [
                {
                    text:'Keep Subscription',
                    action: hideModal.bind(this),
                    isPrimary: false,
                    isDisabled: false
                },
                {
                    text:'Cancel Subscription',
                    action: cancelSubscription,
                    isPrimary: true,
                    isDisabled: (reason === '')
                }
            ]
        },
        cancelSubscriptionSuccess: {
            title: 'Subscription Cancelled',
            body: 'Your Organized Adjusting Subscription has successfully been cancelled.',
            buttons: [
                {
                    text:'OK',
                    action: hideModalAndRefresh,
                    isPrimary: true,
                    isDisabled: false
                }
            ]
        }
    }

    const pricing = (
        <div className="tw-border-2 tw-bg-[#F7F7F7] tw-border-[#C3C5C9] tw-rounded-lg tw-p-3 tw-ml-auto tw-text-[#2D3643] tw-max-w-[620px]">
          <h3 className="tw-text-xl tw-text-center tw-font-bold tw-mb-3" >
              How Our Pricing Works
          </h3>
          <div className="tw-flex tw-flex-row">
              <div className="tw-basis-1/2 tw-text-center tw-border-r tw-border-[#2D3643]">
                  <svg className="tw-h-8 tw-mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                  <p className="tw-text-[#397F38] tw-text-xl tw-mt-2">$45/month</p>
                  <p className="tw-text-sm">for first user</p>
              </div>
              <div className="tw-basis-1/2 tw-text-center tw-border-l tw-border-[#2D3643]">
                  <svg className="tw-h-8 tw-mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
                  <p className="tw-text-[#397F38] tw-text-xl tw-mt-2">+ $20/month</p>
                  <p className="tw-text-sm">per additional user</p>
              </div>
          </div>
          <p className="tw-text-center tw-mt-3">
              <svg className="tw-h-4 tw-inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
              Unlimited Claim Files</p>
        </div>
    )
    /*const pricingPlaceholder = (
        <div style={{border: 'solid 1px', width: 500, height: 180}}></div>
    )*/

    return (
        <div>
            {<Pagebar breadcrumbs={breadcrumb}/>}
            <h2 className="tw-text-2xl tw-font-bold tw-mb-3">Billing</h2>
            <div className="card">
            {subscription && 
                <div className="card-body">
                    <div style={{alignSelf: 'start'}}>
                        <h3 className="tw-text-xl tw-font-bold" style={{marginTop: 8}}>Plan Details</h3>
                        <ul className="tw-list-disc tw-ml-3">
                            <li>{(plan) ? plan.name : ''}</li>
                            {(subscription.addOns.length > 0) && 
                                <>
                                {subscription.addOns.map((addOn, i) => <li key={i}>{addOn.name} (x {addOn.quantity} = ${+addOn.amount * +addOn.quantity})</li>)}
                                </>
                            }
                            <li>Next Billing Date: {subscription.nextBillingDate}</li>
                            <li>Next Billing Amount: ${subscription.nextBillAmount}</li>
                            <li>Status: {subscription.status}</li>
                        </ul>
                    </div>
                    <button className="btn btn-primary" onClick={confirmCancelSubscription}>Cancel Subscription</button>
                    
                </div>
            }
            {!subscription && !loading &&
                <div className="card-body">
                    <div className="tw-self-start">
                        <h3 className="tw-text-xl tw-font-bold tw-mb-3" style={{marginTop: 8}}>Plan Details</h3>
                        <ul className="tw-list-disc tw-ml-3">
                            <li>Free Test Drive - up to 3 claim files</li>
                        </ul>
                    </div>
                    <div className="tw-grow tw-text-right">
                        {pricing}
                        <button className="btn btn-primary" style={{marginTop: 16}} onClick={confirmCreateSubscription}>Subscribe Now!</button>
                    </div>
                </div>
            }
            </div>
            {(paymentMethod) &&
                <div className="card" style={{marginTop: 16}}>
                    <div className="card-body">
                        <div>
                            <h3 className="tw-text-xl tw-font-bold tw-mb-3" style={{marginTop: 8}}>Payment Method</h3>
                            <img className="tw-inline-block" src={paymentMethod.imageUrl} alt={paymentMethod.cardType} /> {paymentMethod.cardType} ending in ****{paymentMethod.last4}
                        </div>
                        
                        <Link className='btn btn-primary'
                            to={{
                            pathname: "/billing/payment-methods",
                            // state: {paymentMethods}
                            }}
                        >Manage Payment Methods</Link>
                    </div>
                </div>
            }
            <div className="card" style={{marginTop: 16}}>
                <div className="card-body">
                    <div style={{alignSelf: 'start'}}>
                        <h3 className="tw-text-xl tw-font-bold tw-mb-3" style={{marginTop: 8}}>User List</h3>
                        <ul className="tw-list-disc tw-ml-3">
                            {users.map((user, i) => <li key={i}>{user.email} ({user.type})</li>)}
                        </ul>
                    </div>
                </div>
            </div>
            {subscription && 
                <div className="card" style={{marginTop: 16}}>
                    <div className="card-body">
                        <div style={{alignSelf: 'start'}}>
                            <h3  className="tw-text-xl tw-font-bold tw-mb-3" style={{marginTop: 8}}>Recent Transactions</h3>
                            <ul className="tw-list-disc tw-ml-3">
                                {subscription.transactions.map((transaction, i) => <li key={i}>{moment(transaction.createdAt).format('MM-DD-YYYY')} - ${transaction.amount}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            }
            <AddPaymentMethodModal isOpen={isOpen} setIsOpen={setIsOpen} isEditing={false} paymentMethod={paymentMethod} customerId={(customer) ? customer.id: ''} onComplete={onPaymentMethodAdded}></AddPaymentMethodModal>
            <Modal
                isOpen={(modal !== '')}
                className="Modal__Bootstrap modal-dialog"
                ariaHideApp={false}
            >
                {(modal !== '') && <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title tw-text-2xl" style={{float: 'left'}}>{messages[modal].title}</h4>
                        <button type="button" className="close" disabled={modalIsWaiting} onClick={hideModal}
                            ref={(node) => node?.style.setProperty("background-image", "none", "important")}
                            style={{fontSize: 24, float: 'right', width: 'auto', textIndent: 0}}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {messages[modal].body}
                    </div>
                    <div className="modal-footer">
                        {messages[modal].buttons.map((button, i) => 
                            <button key={i} type="button" 
                                className={'btn btn-' + ((button.isPrimary) ? 'primary tw-bg-primary' : 'secondary tw-bg-gray-300')}
                                onClick={button.action} disabled={modalIsWaiting || button.isDisabled}>{button.text}</button>
                        )}
                    </div>
                </div>}
            </Modal>
            {(loading) && <div style={{position: 'fixed', display: 'flex', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0, 0, 0, 0.5)'}}>
                <div style={{margin: 'auto', color: '#FFF' }}><i className="fa fa-circle-o-notch fa-spin" /> Loading...</div>
            </div>}
        </div>
    )
}