import React from 'react';

import {DropzoneForExcel} from '../_common/DropzoneForExcel';

const NO_MARGINS = {marginLeft: '0px', marginRight: '0px'};

export function UploadTemplatesDropzone({allMappingTags, onFileSelected, onShowInstructions, children}) {
  function showInstructions(e) {
    e.preventDefault();
    onShowInstructions();
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <i className="fa fa-upload"/>Upload Your Document
            </div>
            <div className="actions">
              <button className="btn blue-primary add_new" onClick={showInstructions}>
                See Instructions
                <i className="ml-5 fa fa-question-circle"/>
              </button>
            </div>
          </div>
          <div className="portlet-body form">
            {children}
            <br/>
            <div className="row" style={NO_MARGINS}>
              <div className="col-sm-12">
                <DropzoneForExcel onFileDrop={onFileSelected}/>
              </div>
            </div>
            <div className="portlet light">
              <div className="portlet-title">
                <div className="caption">Available Tags to Map</div>
              </div>
              <div className="portlet-body">
                <div className="table-scrollable">
                  <table className="table table-striped table-bordered  table-checkable">
                    <thead>
                    <tr>
                      <th style={{width: '2%'}}>#</th>
                      <th style={{width: '25%'}}>Field</th>
                      <th>Tag</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allMappingTags?.map((a, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{a.value}</td>
                          <td>{'${' + a.value + '}'}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
