import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";

import { StatusActions } from "../../../store/statuses/index";
import { InsurerActions } from "../../../store/insurers";
import { ClaimActions, ClaimUtils, ClaimTypes } from "../../../store/claims";
import { CompanyActions } from "../../../store/company";
import { VendorActions } from "../../../store/vendors";
import { AttorneyActions } from "../../../store/attorneys";
import { CustomFieldActions } from "../../../store/custom-fields";
import { ProfileActions } from "../../../store/profile";
import { Notify } from "../../../services/Notify.service";

export const { CLIENT_TYPES } = ClaimTypes;

const claimRoutePaths = {
  shareView: "/shared-claim/:shareToken",
  new: "/claims/new",
  edit: "/claims/:id",
};

const AUTOSAVE_INTERVAL = 5000; // 5 seconds

export function useClaim() {
  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  const isSharedView = path === claimRoutePaths.shareView;

  useEffect(() => {
    if (isSharedView) {
      dispatch(ClaimActions.ClaimRoute.setIsSharedView(true));
    }
    return () => {
      dispatch(ClaimActions.ClaimRoute.setIsSharedView(false));
    };
  }, [path, dispatch, isSharedView]);

  useEffect(() => {
    if (path === claimRoutePaths.new) {
      dispatch(ClaimActions.ClaimRoute.setIsNew(true));
    }
    return () => {
      dispatch(ClaimActions.ClaimRoute.setIsNew(false));
    };
  }, [path, dispatch]);

  useEffect(() => {
    if (path === claimRoutePaths.edit) {
      dispatch(ClaimActions.ClaimRoute.setIsNew(false));
    }
    return () => {
      dispatch(ClaimActions.ClaimRoute.setIsNew(false));
    };
  }, [path, dispatch]);

  const { id, shareToken } = useParams();

  localStorage.setItem(ClaimTypes.X_SHARE_TOKEN, shareToken);

  const { data, lastSavedData } = useSelector((state) => state.claims.claim);
  const claim = useSelector((state) => state.claims.claim.data);
  const { isNew, isLoading } = useSelector((state) => state.claims.claim);
  const {
    list: claimAdjusters,
    lastSavedData: lastSavedClaimAdjustersData,
  } = useSelector((state) => state.claims.claim.claimAdjusters);

  const { client } = claim;
  const isAutoSaveClaimsOn = useSelector(
    (state) => state.profile.settings?.isAutoSaveClaimsOn
  );

  useEffect(() => {
    if (id) {
      dispatch(ClaimActions.GetClaim.start(id));
      dispatch(ClaimActions.ClaimAdjusters.getStart(id));
      document.title = "Edit Claim | Organized Adjusting";
    }

    return () => (document.title = "Organized Adjusting");
  }, [id, dispatch]);

  useEffect(() => {
    if (shareToken) {
      dispatch(ClaimActions.GetSharedClaim.start());
    }

    return () => (document.title = "Organized Adjusting");
  }, [shareToken, dispatch]);

  useEffect(() => {
    if (!isSharedView) {
      dispatch(ProfileActions.GetProfile.start());
      dispatch(InsurerActions.GetInsurers.start());
      dispatch(StatusActions.GetStatuses.start());
      dispatch(VendorActions.GetVendors.start());
      dispatch(AttorneyActions.GetAttorney.start());
      dispatch(CompanyActions.GetCompanyMembers.start());
    }
    if (isNew) {
      dispatch(
        CustomFieldActions.GetCustomFields.start({
          isSetCustomFieldsOnClaimState: true,
        })
      );
    }
  }, [dispatch, isNew, isSharedView]);

  const validate = useCallback(
    ({ showErrors }) => {
      if (!client || !client.type) {
        showErrors && Notify.error("Client Type must be selected.");
        return false;
      }

      // if business, must have business name
      const isBusiness = client.type === CLIENT_TYPES.business;
      const hasBusinessName = Boolean(client.businessName);
      if (isBusiness && !hasBusinessName) {
        showErrors && Notify.error("Business name is required");
        return false;
      }

      // if residence, must have first or last name of  client1
      const isResidence = client.type === CLIENT_TYPES.residence;
      const hasFirstName = client?.residence?.client1?.firstName;
      const hasLastName = client?.residence?.client1?.lastName;
      const hasName = hasFirstName && hasLastName;
      if (isResidence && !hasName) {
        showErrors && Notify.error(`Client's first and last name are required`);
        return false;
      }

      // otherwise it's valid
      return true;
    },
    [client]
  );

  const save = useCallback(() => {
    dispatch(ClaimActions.CreateOrUpdateClaim.preCheck());
  }, [dispatch]);

  const handleSubmit = () => {
    const isValid = validate({ showErrors: true });
    if (!isValid) return;

    dispatch(ClaimActions.CreateOrUpdateClaim.setIsFormSubmitted(true));

    save();
  };

  const handleSave = useCallback(() => {
    // exit if autosave is not enabled
    if (!isAutoSaveClaimsOn) return;

    // exit if form is not yet valid
    const isValid = validate({ showErrors: false });

    if (!isValid) return;

    save();
  }, [isAutoSaveClaimsOn, save, validate]);

  useEffect(() => {
    let interval;

    if (!isAutoSaveClaimsOn && !interval) return;

    interval = setInterval(() => {
      const { isChanged: isClaimAdjustersChanged } = ClaimUtils.detectChanges(
        claimAdjusters,
        lastSavedClaimAdjustersData
      );

      if (isClaimAdjustersChanged) {
        dispatch(ClaimActions.CreateOrUpdateClaim.claimAdjustersStart());
      }

      const { isChanged } = ClaimUtils.detectChanges(data, lastSavedData);

      if (isChanged) {
        console.debug("Change in Claim. Saving...");

        handleSave();
      } else {
        console.debug("No change in Claim");
      }
    }, AUTOSAVE_INTERVAL);

    if (!isAutoSaveClaimsOn && interval) {
      console.debug("Clearing Autosave Intervals");
      clearInterval(interval);
    }

    if (isAutoSaveClaimsOn) return () => clearInterval(interval);
  }, [
    claimAdjusters,
    data,
    dispatch,
    handleSave,
    isAutoSaveClaimsOn,
    lastSavedClaimAdjustersData,
    lastSavedData,
  ]);

  
  useEffect(() => {
    return () => {
      dispatch(ClaimActions.CreateOrUpdateClaim.setIsSaveAndClose(false));
      dispatch(ClaimActions.CreateOrUpdateClaim.setIsFormSubmitted(false));
    };
  }, [dispatch]);
  return {
    handleSubmit,
    isLoading,
  };
}
