import React, { useEffect, useState } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import * as Api from '../../Api';
import { Error } from '../_common/Error';
import Modal from "react-modal";

export function AddPaymentMethodModal({ isOpen, setIsOpen, isEditing, paymentMethod, customerId, onComplete }) {
    const [token, setToken] = useState('');
    const [tokenize, setTokenize] = useState();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [cardholderName, setCardHolderName] = useState('');
    // const [expirationDate, setExpirationDate] = useState('');
    const [error, setError] = useState('');


    const fetchToken = () => {
        Api.Billing.getToken()
            .then((data) => {
                setToken(data.data.clientToken);
            })
            .catch((err) => {
                const error = Api.getError(err);
                setError(error);
                console.log('error');
            })
            .finally(() => /*setIsLoading(false)*/ console.log('done loading'));
    };

    useEffect(() => {
        fetchToken();
    }, []);

    const addOrUpdateCard = async ({ nonce }) => {
        // paymentMethodNonce
        let paymentMethod;
        if (isEditing) {
            const payload = {
                token: paymentMethod.token,
                cardholderName,
                makeDefault: false,
                paymentMethodNonce: nonce
            };
            const updatePaymentMethodResponse = await Api.Billing.updatePaymentMethod(payload);
            if (updatePaymentMethodResponse.data.success) {
                paymentMethod = updatePaymentMethodResponse.data.paymentMethod;
                setIsLoadingButton(false);
                setIsOpen(false);
                onComplete({paymentMethod, customer: null});
            } else {
                console.log(updatePaymentMethodResponse.data);
                setError(updatePaymentMethodResponse.data.message);
                setIsLoadingButton(false);
            }
        } else {
            const payload = {
                customerId,
                cardholderName,
                paymentMethodNonce: nonce
            };
            const addPaymentMethodResponse = await Api.Billing.addPaymentMethod(payload);
            if (addPaymentMethodResponse.data.success) {
                let customer = null;
                if (addPaymentMethodResponse.data.customer) {
                    // new customer was created
                    customer = addPaymentMethodResponse.data.customer;
                    paymentMethod = customer.paymentMethods[0];
                } else {
                    paymentMethod = addPaymentMethodResponse.data.paymentMethod;
                }
                setIsLoadingButton(false);
                setIsOpen(false);
                onComplete({paymentMethod, customer});
            } else {
                console.log(addPaymentMethodResponse.data);
                setError(addPaymentMethodResponse.data.message);
                setIsLoadingButton(false);
            }
        }
    }

    const cancel = () => {
        setIsOpen(false);
    }

    const handleSubmit = async () => {
        setIsLoadingButton(true);
        try {
            const { nonce } = await tokenize();
            addOrUpdateCard({ nonce });
        } catch (err) {
            console.error(err);
            const { message } = err;
            setError(message);
            setIsLoadingButton(false);
        }
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                className="Modal__Bootstrap modal-dialog"
                onAfterOpen={() => {
                    if (isEditing) {
                        console.log(paymentMethod);
                        setCardHolderName(paymentMethod.cardholderName);
                        // setExpirationDate(paymentMethod.expirationDate);
                    } else {
                        setCardHolderName('');
                        // setExpirationDate('');
                    }
                }}
                onRequestClose={() => {

                }}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title tw-text-2xl" style={{float: 'left'}}>New Payment Method</h4>
                        <button type="button" className="close" onClick={cancel} 
                            ref={(node) => node?.style.setProperty("background-image", "none", "important")}
                            style={{fontSize: 24, float: 'right', width: 'auto', textIndent: 0}}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>

                    <Braintree
                        authorization={token}
                        getTokenRef={ref => setTokenize(() => ref)}
                        styles={{
                            input: {
                                'font-size': 'inherit'
                            },
                            ':focus': {
                                color: 'blue'
                            }
                        }}
                    >
                        <div className="modal-body">
                            <div id="error_divs" style={{ color: '#D13F3A' }} />
                            {error ? (
                                <div className="col-sm-12">
                                <Error message={error}/>
                                </div>
                            ) : (
                                ''
                            )}

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>
                                            Cardholder name <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={cardholderName}
                                            onChange={(e) => setCardHolderName(e.target.value)}
                                            placeholder="Cardholder name"
                                            className="form-control checkout-form"
                                            style={{
                                                borderRadius: '6px !important',
                                                paddingTop: '5px !important',
                                                paddingBottom: '5px !important',
                                                fontSize: '14px'
                                            }}
                                            id="card-holder-name"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>
                                            Card Number <span className="required">*</span>
                                        </label>
                                        <HostedField
                                            type="number"
                                            className="form-control"
                                            style={{
                                                borderRadius: '6px !important',
                                                paddingTop: '5px !important',
                                                paddingBottom: '5px !important',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            CVV <span className="required">*</span>
                                        </label>
                                        <HostedField
                                            placeholder="1234"
                                            type="cvv"
                                            className="form-control"
                                            style={{
                                                borderRadius: '6px !important',
                                                paddingTop: '5px !important',
                                                paddingBottom: '5px !important',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Expiration Date (MM / YYYY) <span className="required">*</span>
                                        </label>
                                        <HostedField
                                            placeholder="MM / YYYY"
                                            type="expirationDate"
                                            className="form-control"
                                            style={{
                                                borderRadius: '6px !important',
                                                paddingTop: '5px !important',
                                                paddingBottom: '5px !important',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                            <button onClick={cancel} className="btn btn-outline btn-secondary tw-bg-slate-300">
                                Cancel
                            </button>
                        
                            {isLoadingButton ? (
                                <button
                                    disabled
                                    className="btn btn-primary tw-bg-primary"
                                >
                                    <i className="fa fa-circle-o-notch fa-spin" /> Please
                                    Wait...
                                </button>
                            ) : (
                                <button onClick={handleSubmit} className="btn btn-primary tw-bg-primary">
                                    {(isEditing) ? 'Update' : 'Add'} Card
                                </button>
                            )}

                        </div>
                    </Braintree>
                </div>
            </Modal>
        </div>
    )
}

