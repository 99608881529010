import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {Error} from '../_common/Error';
import {PhoneNumberMask} from '../_common/MaskedInput';
import {Notify} from '../../services/Notify.service';
import {SubmitButton} from '../profile/SubmitButton';

export function AddNewAndEdit({openPanel, setOpenPanel, insurerDetails, reloadTable}) {
  const [insurerState, setInsurerState] = useState(insurerDetails || {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const {name, email, phone, extension, fax, zip, street, state, city} = insurerState;

  useEffect(() => {setInsurerState(insurerDetails);}, [insurerDetails, setInsurerState]);

  async function handleSubmit(event) {
    event.preventDefault();
    const insurerData = {
      ...insurerState,
      phoneExt: extension,
      streetAddress: street,
      zipcode: zip
    };
    setIsLoading(true);
    try {
      if (insurerState.id) {
        await Api.Insurers.edit({id: insurerState.id, insurer: insurerData});
      } else {
        await Api.Insurers.create({insurer: insurerData});
      }
      reloadTable();
      setOpenPanel(false);
      setError('');
      Notify.success('Updated!');
      setInsurerState({});
    } catch (err) {
      if (err.response
        && err.response.data
        && err.response.data.errors[0]
        && err.response.data.errors[0].msg) {
        setError(err.response.data.errors[0].msg);
        Notify.error(error.response.data.errors[0].msg);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose(e) {
    e.preventDefault();
    setOpenPanel(false);
    setError('');
    setInsurerState({});
  }

  function handleUpdate(field) {
    return ({target}) => {
      setInsurerState({...insurerState, [field]: target.value});
    };
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {insurerState.id ? 'Edit Insurer Details' : 'Add New Insurer'}
              </span>
            </div>
            <div className="tools">
              <a className="remove" href="#!" title="" data-original-title="" onClick={handleClose}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error}/> : ''}

            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Insurer Name</label>
                <input className="form-control" type="text" name="insurerName" placeholder="Insurer Name"
                       value={name} onChange={handleUpdate('name')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Email Address</label>
                <input className="form-control" type="text" name="insurerEmail" placeholder="Email Address"
                       value={email} onChange={handleUpdate('email')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Street</label>
                <input className="form-control" type="text" name="insurerStreet" placeholder="Street" value={street}
                       onChange={handleUpdate('street')}/>
              </div>
              <div className="form-group">
                <label className="control-label">City</label>
                <input type="text" className="form-control" name="insurerCity" placeholder="City" value={city}
                       onChange={handleUpdate('city')}/>
              </div>
              <div className="form-group">
                <label className="control-label">State</label>
                <input className="form-control" type="text" name="insurerState" placeholder="State" value={state}
                       onChange={handleUpdate('state')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Zip</label>
                <input className="form-control" type="text" name="insurerZip" placeholder="Zip Code" value={zip}
                       onChange={handleUpdate('zip')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Phone #</label>
                <PhoneNumberMask placeholder="Phone #" value={phone} handleChange={handleUpdate('phone')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Extension</label>
                <input className="form-control" type="text" name="insurerExtension" placeholder="Extension"
                       value={extension} onChange={handleUpdate('extension')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Fax #</label>
                <PhoneNumberMask placeholder="Fax #" value={fax} handleChange={handleUpdate('fax')}/>
              </div>

              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading}/>
                    <button className="btn default" style={{margin: 5}} type="button" name="cancel" value="cancel"
                            onClick={handleClose}>
                      <i className="fa fa-times"/> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
