import React, { useEffect, useState } from "react";
import { SlidingSidePanel } from "../../../_common/SlidingSidePanel";
import * as Api from "../../../../Api";
import { Notify } from "../../../../services/Notify.service";

export const ShareFileWithPolicyHolder = ({
  claimId,
  showSharePolicyHolderUI,
  setShowSharePolicyHolderUI,
  selectedClaimData,
  shareClaimToken,
  shareLink,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ccMe, setccMe] = useState(false);

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setccMe(true);
    }
    if (!event.target.checked) {
      setccMe(false);
    }
  };

  const testShareClaimLink = () => {
    window.open(shareLink, "_blank");
    return false;
  };

  const sendShareEmail = (event) => {
    setShowLoader(true);
    const requestData = {
      name,
      email,
      ccMe,
      message,
      shareClaimToken,
      claimId,
    };
    Api.Claims.Share.shareToPolicyholders({
      requestData,
    })
      .then(() => {
        Notify.success("Claim shared successfully.");
      })
      .catch((err) => {
        Notify.error(`Please wait. ${err.message}`);
      })
      .finally(() => {
        setShowLoader(false);
        setShowSharePolicyHolderUI(false);
      });
    event.preventDefault();
  };

  useEffect(() => {
    setEmail(selectedClaimData.clientEmail);
    setName(
      `${selectedClaimData.contactFirstName} ${selectedClaimData.contactLastName}`
    );
    setccMe(true);
  }, [selectedClaimData]);

  return (
    <>
      {showSharePolicyHolderUI && (
        <SlidingSidePanel type={"right"} isOpen={showSharePolicyHolderUI}>
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">
                  SHARE CLAIM WITH POLICYHOLDER
                </span>
              </div>
              <div className="tools">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSharePolicyHolderUI(false);
                  }}
                  href="#!"
                  className="remove"
                  data-original-title=""
                  title=""
                >
                  &nbsp;
                </a>
              </div>
            </div>
            <div className="portlet-body">
              <p style={{ textAlign: "justify" }}>
                Make your Claim visible to the Policyholder. We'll send them a
                link where they can view their claim and everything you've added
                into it like notes, attachments, emails on files, activity logs
                etc. They will not be able to make any changes.
                <br />
                <br />
              </p>
              <div className="inbox-body" style={{ marginBottom: "2rem" }}>
                <div className="inbox-content">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="control-label">PH Email:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Message:</label>
                        <textarea
                          className="form-control"
                          rows="5"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <br />
                  <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
                    <input
                      type="checkbox"
                      className="form-control"
                      value="true"
                      checked={ccMe}
                      onChange={handleCheckbox}
                    />
                    <span></span> Cc Me
                  </label>
                  <p
                    style={{
                      textDecoration: "none",
                      padding: "16px 19px",
                      backgroundColor: "rgb(242, 242, 242)",
                      border: "1px solid rgb(224, 224, 224)",
                      borderRadius: "6px",
                      color: "rgb(0, 0, 0)",
                      fontWeight: "bold",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "24px",
                    }}
                  >
                    {shareLink}
                    <br />
                    <span
                      className="btn btn-sm circle red"
                      style={{
                        WebkitBoxShadow:
                          "0px 5px 10px 2px rgba(244, 81, 108, 0.19) !important",
                        MozBoxShadow:
                          "0px 5px 10px 2px rgba(244, 81, 108, 0.19) !important",
                        boxShadow:
                          "0px 5px 10px 2px rgba(244, 81, 108, 0.19) !important",
                      }}
                      onClick={() => testShareClaimLink()}
                    >
                      Test
                      <i className="fa fa-share"></i>
                    </span>
                  </p>
                  <p
                    style={{
                      margin: "0rem!important",
                      width: "100%",
                      textAlign: "center",
                    }}
                    className="font-grey-mint"
                  >
                    Seperate links are sent to each email.
                  </p>
                  <div
                    className="row"
                    style={{
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <div className="col-sm-6">
                      <button
                        type="button"
                        onClick={sendShareEmail}
                        class="btn btn-primary mt-ladda-btn ladda-button btn-block btn-lg purple-soft next_share_all_vendor_btn_cls"
                        disabled={showLoader}
                      >
                        {showLoader ? "Sending..." : "Send Notification"}
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button
                        type="button"
                        class="btn btn-lg  btn-block"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSharePolicyHolderUI(false);
                        }}
                        disabled={showLoader}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SlidingSidePanel>
      )}
    </>
  );
};
