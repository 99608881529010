import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ClaimAdjuster } from "./ClaimAdjuster";
import { Button } from "../../../../_common/buttons/Button";
import { ClaimActions } from "../../../../../store/claims";

const Wrapper = ({ children, onClick, isDisabled }) => {
  const { isSharedView } = useSelector((state) => state.claims.claim);

  return (
    <div>
      {children}
      {!isSharedView && (
        <Button
          data-testid="add-another-adjuster"
          disabled={isDisabled}
          variant="light"
          onClick={onClick}
        >
          <i className="fa fa-plus"></i> Add Another Adjuster
        </Button>
      )}
    </div>
  );
};

export const ClaimAdjusters = () => {
  const dispatch = useDispatch();

  const { list: claimAdjusters } = useSelector(
    (state) => state.claims.claim.claimAdjusters
  );

  const handleAddClick = () => {
    dispatch(ClaimActions.SetOnClaim.newClaimAdjuster());
  };

  const handleAdjusterChange = (index, name, value) => {
    dispatch(
      ClaimActions.SetOnClaim.claimAdjuster({
        index,
        name,
        value,
      })
    );
  };

  const handleDelete = ({ id, index }) => {
    dispatch(ClaimActions.ClaimAdjusters.deleteStart({ id, index }));
  };

  return (
    <Wrapper onClick={handleAddClick}>
      {claimAdjusters?.map((adjuster, idx) => {
        return (
          <ClaimAdjuster
            key={idx === 0 ? `Desk Adjuster ${idx}` : `Field Adjuster ${idx}`}
            title={idx === 0 ? "Desk Adjuster" : `Field Adjuster ${idx}`}
            isShowDelete={idx !== 0}
            adjuster={adjuster}
            onAdjusterChange={(name, value) =>
              handleAdjusterChange(idx, name, value)
            }
            onDeleteClick={() => handleDelete({ id: adjuster.id, index: idx })}
            setAdjuster={alert}
            dataTestIdPrefix={idx + 1}
          />
        );
      })}
    </Wrapper>
  );
};
