import { takeEvery, put, fork, takeLatest } from "redux-saga/effects";

import { ProfileTypes, ProfileActions } from "./index";
import * as Api from "../../Api";
import { Notify } from "../../services/Notify.service";

function* getProfile() {
  try {
    const {
      data: { settings },
    } = yield Api.User.settings.get();
    yield put(ProfileActions.GetProfile.success(settings));
  } catch (err) {
    const error = Api.getError(err);
    yield put(ProfileActions.GetProfile.error(error));
  }
}

function* watchGetProfileStart() {
  yield takeEvery(ProfileTypes.GET_PROFILE.START, getProfile);
}

function* updateProfile({ payload }) {
  try {
    const {
      data: { settings },
    } = yield Api.User.settings.update({ data: payload });
    yield put(ProfileActions.UpdateProfile.success(settings));
    yield Notify.dark("Settings updated!");
  } catch (err) {
    const error = Api.getError(err);
    yield put(ProfileActions.UpdateProfile.error(error));
  }
}

function* watchUpdateProfileStart() {
  yield takeLatest(ProfileTypes.UPDATE_PROFILE.START, updateProfile);
}

const ProfileSagas = [
  fork(watchGetProfileStart),
  fork(watchUpdateProfileStart),
];

export default ProfileSagas;
