import { takeEvery, put, fork } from "redux-saga/effects";

import { CustomFieldTypes, CustomFieldActions } from "./index";
import * as Api from "../../Api";
import { ClaimActions, ClaimDataMapper } from "../claims";

function* getCustomFields({ payload }) {
  try {
    const { claimCustomFields, isSetCustomFieldsOnClaimState } = payload;
    const { data } = yield Api.Claims.CustomFields.get();
    yield put(CustomFieldActions.GetCustomFields.success(data.customFields));

    // payload will be provided as customFields for a claim
    // which are to be merged with all the custom fields
    if (!isSetCustomFieldsOnClaimState) return;

    if (claimCustomFields) {
      const mergedFields = ClaimDataMapper.CustomFieldMappers.mergeCustomFields(
        {
          allCustomFields: data.customFields,
          claimCustomFields,
        }
      );

      yield put(
        ClaimActions.SetOnClaim.customFields({
          customFields: mergedFields,
          isFetched: true,
        })
      );
    } else {
      yield put(
        ClaimActions.SetOnClaim.customFields({
          customFields: [...data.customFields],
          isFetched: true,
        })
      );
    }
  } catch (err) {
    const error = Api.getError(err);
    yield put(CustomFieldActions.GetCustomFields.error(error));
  }
}

function* watchGetCustomFieldsStart() {
  yield takeEvery(CustomFieldTypes.GET_CUSTOM_FIELDS.START, getCustomFields);
}

const CustomFieldSagas = [fork(watchGetCustomFieldsStart)];

export default CustomFieldSagas;
