import React, { useEffect, useState } from "react";
import * as Api from "../../Api";
import { AddAndEdit } from "../customFields/AddAndEdit";
import { DeleteSweetAlertConfirmation } from "../_common/Sweetalert";
import { Table } from "../customFields/Table";
import { Notify } from "../../services/Notify.service";

export function CustomFields() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [customFields, setCustomFields] = React.useState([]);
  const [error, setError] = React.useState("");

  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [deleteCustomField, setDeleteCustomField] = React.useState({});

  const [showAddNewCustomFields, setShowAddNewCustomFields] = useState(false);
  const [customFieldDetails, setCustomFieldDetails] = useState({});

  const fetchCustomFields = () => {
    setIsLoading(true);
    Api.CustomFields.list()
      .then((data) => {
        if (data.data && data.data.customFields) {
          setCustomFields(data.data.customFields);
        }
      })
      .catch((err) => {
        const error = Api.getError(err);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchCustomFields();
    const checkAddNew = window.location.hash === "#add-new";
    if (checkAddNew) {
      setCustomFieldDetails({});
      setShowAddNewCustomFields(true);
    }
  }, []);

  const confirmDelete = (selectedAdjuster) => {
    setShowDeleteConfirmation(true);
    setDeleteCustomField(selectedAdjuster);
  };

  const deleteCustomFields = (confirm) => {
    if (confirm) {
      return Api.CustomFields.delete({
        id: deleteCustomField.id,
      })
        .then(() => {
          fetchCustomFields();
          Notify.success("Deleted Successfully!");
        })
        .catch((err) => {
          Api.getAndShowError(err);
        })
        .finally(() => {
          setShowDeleteConfirmation(false);
        });
    }
    return setShowDeleteConfirmation(false);
  };

  return (
    <div>
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              CUSTOM FIELDS
            </span>
          </div>
          <div className="actions">
            <button
              onClick={() => {
                setShowAddNewCustomFields(true);
                setCustomFieldDetails({});
              }}
              className="btn blue-primary btn-outline pull-right"
              style={{
                paddingTop: 7,
                paddingBottom: 6,
              }}
            >
              <i className="fa fa-plus"></i> Add New Custom Field
            </button>
          </div>
        </div>
        <DeleteSweetAlertConfirmation
          show={showDeleteConfirmation}
          clickHandler={deleteCustomFields}
        />
        <AddAndEdit
          openPanel={showAddNewCustomFields}
          setOpenPanel={setShowAddNewCustomFields}
          customFieldDetails={customFieldDetails}
          reloadTable={fetchCustomFields}
        />
        <div className="portlet-body">
          <div className="table-scrollable  no-border">
            <Table
              customFields={customFields}
              isLoading={isLoading}
              error={error}
              setOpensidePanel={setShowAddNewCustomFields}
              setCustomFieldDetails={setCustomFieldDetails}
              confirmDelete={confirmDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
