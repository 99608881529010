import { takeLatest, put, fork, select } from "redux-saga/effects";

import {
  ClaimActions as Actions,
  ClaimTypes as Types,
  ClaimDataMapper,
} from "./index";
import * as Api from "../../Api";

function* getClaimAdjusters({ payload }) {
  try {
    const claimId = payload;

    const { data } = yield Api.Claims.Adjusters.getForClaim({ claimId });

    yield put(Actions.ClaimAdjusters.getSuccess(data));
  } catch (err) {
    console.error("Error in getClaimAdjusters", err.message, err);
    const error = Api.getError(err);
    yield put(Actions.ClaimAdjusters.getError(error));
  }
}

function* watchGetClaimAdjustersStart() {
  yield takeLatest(Types.CLAIM_ADJUSTERS.GET_START, getClaimAdjusters);
}

export function* createOrUpdateClaimAdjusters() {
  try {
    const { list: claimAdjusters } = yield select(
      (state) => state.claims.claim.claimAdjusters
    );
    const { id } = yield select((state) => state.claims.claim);

    const hasClaimAjusters =
      claimAdjusters &&
      claimAdjusters.filter((adj) => Object.values(adj).length).length;

    if (!hasClaimAjusters || !id) {
      yield put(
        Actions.CreateOrUpdateClaim.claimAdjustersCancel(
          "No id or claim adjusters found in createOrUpdateClaimAdjusters. Exiting..."
        )
      );
      return;
    }

    const formattedAdjusters = ClaimDataMapper.ClaimFieldMappers.ClaimAdjusters.mapForApi(
      claimAdjusters
    );

    yield Api.Claims.Adjusters.createOrUpdate({
      claimId: id,
      claimAdjusters: formattedAdjusters,
    });

    yield put(
      Actions.CreateOrUpdateClaim.claimAdjustersSuccess({
        lastSavedAt: new Date(),
      })
    );

    yield put(Actions.ClaimAdjusters.getStart(id));
    
  } catch (err) {
    console.error("Error in createOrUpdateClaimAdjuster", err.message, err);
    const error = Api.getError(err);
    yield put(Actions.CreateOrUpdateClaim.claimAdjustersError(error));
  }
}

function* watchCreateOrUpdateClaimAdjustersStart() {
  yield takeLatest(
    Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_START,
    createOrUpdateClaimAdjusters
  );
}

function* deleteClaimAdjuster({ payload }) {
  try {
    const { id, index } = payload;
    const { id: claimId } = yield select((state) => state.claims.claim);

    if (id) {
      yield Api.Claims.Adjusters.removeFromClaim({
        claimId,
        id,
      });
      yield put(
        Actions.ClaimAdjusters.deleteSuccess({
          lastSavedAt: new Date(),
          hasUnsavedChanges: false,
        })
      );
      yield put(Actions.ClaimAdjusters.getStart(claimId));
    }

    if (index) {
      yield put(Actions.SetOnClaim.deleteClaimAdjuster(index));
      yield put(Actions.ClaimAdjusters.deleteSuccess());
    }
  } catch (err) {
    console.error("Error in removeClaimAdjuster", err.message, err);
    const error = Api.getError(err);
    yield put(Actions.ClaimAdjusters.deleteError(error));
  }
}

function* watchDeleteClaimAdjusterStart() {
  yield takeLatest(Types.CLAIM_ADJUSTERS.DELETE_START, deleteClaimAdjuster);
}

const ClaimAdjustersSagas = [
  fork(watchCreateOrUpdateClaimAdjustersStart),
  fork(watchDeleteClaimAdjusterStart),
  fork(watchGetClaimAdjustersStart),
];

export default ClaimAdjustersSagas;
