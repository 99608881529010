import jsPDF from 'jspdf'
import 'jspdf-autotable'

const DownloadPDF = ({
    data,
    headers,
    filename
}) => {
    const doc = new jsPDF();
    
    doc.autoTable({
        columns: headers,
        body: data,
    });
    doc.save(filename);
    return "";
}

export default DownloadPDF;