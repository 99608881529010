import React from "react";
import { Dropdown } from "../_common/Dropdown";
import PrintClaim from "../claims/printClaims";
import ClaimsPdf from "../claims/claimsPdf";
import * as Api from "../../Api";

export function ToolBar({
  claims,
  setFullPageSpinner,
  searchParams,
  currentPage,
  limitPerPage,
  sortBy,
  orderBy
}) {
  
    const saveAsPdf = () => {
        return ClaimsPdf({
            data: claims,
        });
    }
  
  const exportToClaims = () => {
    setFullPageSpinner(true);
    const requestData = {
      filter: {
        adjustersId: searchParams.adjustersId,
        city: searchParams.cities,
        claimNumber: searchParams.claimNumber,
        fromDate: searchParams.createdAtFromDate,
        insurerCompanyName: searchParams.insurerCompanyName,
        onlyAttorneyFile: true,
        lastName: (searchParams.clientNameStartsWithLetter) ? searchParams.clientNameStartsWithLetter : searchParams.clientName, 
        lossFromDate: searchParams.dolFromDate,
        lossToDate: searchParams.dolToDate,
        status: ["attorney"],
        streetName: searchParams.streetName,
        toDate: searchParams.createdAtToDate,
        zip: searchParams.zip,
      },
      limit:{
        start: (currentPage === 1) ? 0: (currentPage * limitPerPage),
        total: limitPerPage
      },
      orderBy: {
        colomnName: sortBy,
        orderByFormat: orderBy
      }
    };

    Api.Claims.exports({
      requestData
    }).then((data) => {
      window.open(data.data.downloadUrl, "_blank")
    }).catch(() => {
    }).finally(() => {
      setFullPageSpinner(false);
    })
  }

  return (
    <div className="portlet-title">
      <div className="caption">
        <span className="caption-subject font-dark sbold uppercase">
            ATTORNEY FILES
        </span>
      </div>
      <div className="actions">
        <Dropdown title="Tools" variant="red">
          <Dropdown.ListItem onClick={() => { return false;}}><PrintClaim data={claims}/></Dropdown.ListItem>
          <Dropdown.ListItem onClick={() => saveAsPdf()}>Save as PDF</Dropdown.ListItem>
          <Dropdown.ListItem onClick={() => exportToClaims()}>Export to Excel</Dropdown.ListItem>
        </Dropdown>
      </div>
    </div>
  );
}
