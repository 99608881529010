// converts (345)-234-2342 into 3452342342
export function extractDigits(number) {
  if (!number) return number;

  const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  return number
    .split("")
    .filter((num) => digits.includes(num))
    .join("");
}

// converts 10,000.99 into 10000.99
export function removeCommaFromNumber(number) {
  if (!number) return number;

  return number
    .split("")
    .filter((n) => n !== ",")
    .join("");
}
