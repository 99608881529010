import { useState } from "react";

import * as Api from "../../../Api";

export function useResetPassword({
  token
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      setError("");
      if(password === ''){
        setError("Please Enter password.");
        return false;
      }
      if(confirmPassword === ''){
        setError("Please Enter Confirm password.");
        return false;
      }
      if(password !== confirmPassword){
        setError("Password and confirm password not matching.");
        return false;
      }
      setIsLoading(true);
      Api.Auth.resetpassword({
        data : {
          passwordResetToken: token,
          newPassword: password,
          confirmPassword
        }
      }).then(() => {
        setConfirmPassword("");
        setPassword("");
        setSuccessMessage("Password reset successfully. Please login with new password.");
      }).catch((err) => {
        
        setError(Api.getError(err));
      }).finally(() => {
        setIsLoading(false);
      })
    };

  return {
    isLoading,
    successMessage,
    error,
    handleSubmit,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword
  };
}
