import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import Uppy from '@uppy/core'
// import Tus from '@uppy/tus'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { /*DragDrop, StatusBar,*/ Dashboard } from '@uppy/react'
import * as Api from "../../../../Api";

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/dashboard/dist/style.css'

import './ShareUpload.css';
import SimpleLogo from '../../../../assets/images/oa-logo-simple.svg';

const uppy = new Uppy({
  meta: { type: 'meta-type' },
  /*restrictions: { maxNumberOfFiles: 16 },*/
  autoProceed: true,
});

/*async function getUploadRequest() {
  const request = await Api.UploadRequest.getByRequestId({request_id: id});
  return request;
}
const uploadRequest = getUploadRequest();*/
let path;
  
uppy.use(AwsS3Multipart, {
  id: 'AwsS3Multipart',
  limit: 5,
  async createMultipartUpload(file) {
    const response = await Api.Claims.Attachments.Files.multipart.executeCreateMultipartUpload({
      key: `${path}${file.name}`,
      contentType: file.type
    });
    return {
      uploadId: response.data.UploadId,
      key: response.data.Key
    };
  },
  async listParts(file, { uploadId, key }) {
    const response = await Api.Claims.Attachments.Files.multipart.executeListParts({
      key,
      uploadId,
      contentType: file.type
    });
    return response.data.Parts;
  },
  async signPart(file, partData) {
    partData.contentType = file.type;
    const response = await Api.Claims.Attachments.Files.multipart.executeSignPart(partData);
    return response.data;
  },
  async abortMultipartUpload(file, { uploadId, key }) {
    const response = await Api.Claims.Attachments.Files.multipart.executeAbortMultipartUpload({
      key,
      uploadId,
      contentType: file.type
    });
    return response.data;
  },
  async completeMultipartUpload(file, { uploadId, key, parts }) {
    const response = await Api.Claims.Attachments.Files.multipart.executeCompleteMultipartUpload({
      key,
      uploadId,
      parts,
      contentType: file.type
    });
    return response.data;
  }
});

/*uppy.use(StatusBar, {
  id: 'StatusBar',
  target: 'body',
  hideAfterFinish: true,
  showProgressDetails: false,
  hideUploadButton: false,
  hideRetryButton: false,
  hidePauseResumeButton: false,
  hideCancelButton: false,
  doneButtonHandler: null,
  locale: {},
});*/

export function ShareUpload() {
  let { id } = useParams();
  const [uploadRequest, setUploadRequest] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    async function getUploadRequest() {
      const request = await Api.UploadRequest.getByRequestId({request_id: id});
      setUploadRequest(request);
      path = `${request.company_id}/${request.claim_id}/attachments/uploads/${request.user_id}/`;
    }
    getUploadRequest();
  }, [id, setUploadRequest]);

  useEffect(() => {
    const handler = async (status, event) => {
      const data = {
        file_name: status.name,
        path: status.s3Multipart.key,
        claim_id: uploadRequest.claim_id,
        user_id: uploadRequest.user_id,
        user_display_name: uploadRequest.user.displayName,
        type: status.type,
        size: status.size
      };
      const response = await Api.Claims.Attachments.Files.createOrUpdate(data);
      console.log(response);
    };
    const startHandler = async (status, event) => {
      setIsUploading(true);
    };
    const endHandler = async (status, event) => {
      setIsUploading(false);
    }
    uppy.on('upload-success', handler);
    uppy.on('upload', startHandler);
    uppy.on('complete', endHandler);
    return () => {
      uppy.off('upload-success', handler);
      uppy.off('upload', startHandler);
      uppy.off('complete', endHandler);
    }
  }, [uploadRequest])

  return (
    <div className="tw-container tw-mx-auto tw-p-8">
      <div className="tw-w-12 tw-mb-8"><img src={SimpleLogo} alt="Organized Adjusting Logo Icon" /></div>
      {uploadRequest ? (
        <div>
          <p className="tw-w-[750px] tw-max-w-full tw-mx-auto tw-mb-4">{uploadRequest.user.displayName} has requested file(s) for claim: {uploadRequest.claim.fileName} </p>
          <Dashboard className="tw-drop-shadow"
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
          />
          {isUploading && (
            <div className="tw-w-[750px] tw-mx-auto tw-rounded tw-bg-amber-400 tw-p-4 tw-my-4 tw-text-center">Please stay on this page until your upload is complete.</div>
          )}
          <p className="tw-w-[750px] tw-max-w-full tw-mx-auto tw-mt-4">Your files will be uploaded securely to {uploadRequest.user.displayName}'s Organized Adjusting account. More about our <a href="https://organizedadjusting.com/privacy-policy.html">privacy policy</a>.</p>
        </div>
      ) : (
        <div>
          <p className="tw-w-[750px] tw-max-w-full">Upload request not found or expired.</p>
        </div>
      )}

    </div>
  )
}
/*
// more control, less feature rich
<DragDrop
        uppy={uppy}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Drop here or %{browse}',
            // Used as the label for the link that opens the system file selection dialog.
            browse: 'browse',
          },
        }}
      />
      <StatusBar
        // assuming `props.uppy` contains an Uppy instance:
        uppy={uppy}
        hideUploadButton
        hideAfterFinish={false}
        showProgressDetails
      />
*/