export const GET_CUSTOM_FIELDS = {
  START: "CUSTOM_FIELDS/GET_CUSTOM_FIELDS_START",
  SUCCESS: "CUSTOM_FIELDS/GET_CUSTOM_FIELDS_SUCCESS",
  ERROR: "CUSTOM_FIELDS/GET_CUSTOM_FIELDS_ERROR",
};

export const FIELD_TYPES = {
  textbox: "textbox",
  dropdown: "dropdown",
  checkbox: "checkbox",
  date: "date",
  time: "time",
  currency: "currency",
};
