import React, {useCallback, useEffect} from 'react';
import _ from 'lodash';
import * as Api from '../../Api';

const PAD_5_STYLE = {paddingLeft: '5px', paddingRight: '5px'};
const PAD_8_19 = {padding: '8px 19px 8px 19px'};

const INSTRUCTION_LABEL_STYLE = {
  textAlign: 'left',
  paddingBottom: '12px',
  backgroundColor: '#f2f2f2'
};

const LABEL_STYLE = {
  borderRadius: '4px!important',
  padding: '8px 19px 8px 19px'
};

const NEXT_BUTTON_STYLE = {
  padding: '12px 49px 12px 49px',
  borderRadius: '4px!important',
  marginRight: '10px'
};

const CANCEL_BUTTON_STYLE = {
  padding: '12px 29px 12px 29px',
  borderRadius: '4px!important'
};


function SelectOption({value}) {
  return (
    <option value={value.id}>
      {' '}
      {value.value}
    </option>
  );
}

function FieldRow({field, currentMappedData, onChange, allMappingTags}) {
  let selectValue = '';
  const targetMap = currentMappedData.find(({originalField}) => originalField === field);
  if (targetMap) {
    selectValue = targetMap.mappedwith;
  }

  function handleSelectChange({target}) {
    onChange(target.value, field);
  }

  return (
    <tr>
      <td>{field}</td>
      <td>
        <select className="form-control" value={selectValue} onChange={handleSelectChange}>
          <option value="">-- Select --</option>
          {
            _.orderBy(allMappingTags, 'value')
              .map((value, index) => (<SelectOption key={index} value={value}/>))
          }
        </select>
      </td>
    </tr>
  );
}

export function MappingTags(
  {
    uploadedFileId, allMappingTags, setCurrentMappedData, currentMappedData, setAllFieldsOfFile, allFieldsOfFile,
    onNext, onCancel
  }
) {

  function setAllPredefinedMapData(fileFields) {
    const inProgressMapping = allMappingTags
      .filter(({value}) => fileFields.includes(value))
      .map(({value, id, tableName}) => ({
        originalField: value,
        oa_field_name: value,
        mappedwith: id,
        tableName: tableName
      }));

    setCurrentMappedData(inProgressMapping);
  }

  //REDTAG:TJH - This needs to be reworked
  const fetchFieldsOfFile = useCallback(async function _fetchFieldsOfFile() {
    try {
      const {data} = await Api.Document.legacy.getTagsToMap({id: uploadedFileId});
      setAllFieldsOfFile(data.data);
      setAllPredefinedMapData(data.data);
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllFieldsOfFile, uploadedFileId]);

  function changeCurrentMapData(value, originalField) {
    const currentOne = allMappingTags.find((x) => x.id === value);
    const currentMapped = [...currentMappedData];

    const mapped = currentMapped.find((x) => x.originalField === originalField);
    if (mapped) {
      mapped.originalField = originalField;
      mapped.oa_field_name = currentOne.value;
      mapped.mappedwith = value;
      mapped.tableName = currentOne.tableName;
      setCurrentMappedData(currentMapped);
    } else {
      const currentObject = {
        originalField: originalField,
        oa_field_name: currentOne.value,
        mappedwith: value,
        tableName: currentOne.tableName
      };
      currentMapped.push(currentObject);
      setCurrentMappedData(currentMapped);
    }
  }

  useEffect(() => {
    fetchFieldsOfFile();
  }, [fetchFieldsOfFile]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <i className="fa fa-link"/>Merge Fields
            </div>
          </div>
          <div className="portlet-body form">
            <div>
              <label className="col-md-12 control-label" style={INSTRUCTION_LABEL_STYLE}>
                <h3 style={{lineHeight: '4.5rem'}}>
                  <span className="label label-sm label-danger">
                    Instructions
                  </span>
                  <br/>
                  Merge Placeholders we found in your document are shown on the left. The Claim form fields are shown on
                  the right. Merge Placeholders to the Fields.
                </h3>
              </label>
            </div>
            <div className="row" style={PAD_5_STYLE}>
              <div className="col-md-8">
                <div className="actions">
                  <div className="btn-group" style={PAD_8_19}>
                    <label className="btn dark btn-outline btn-circle btn-sm active" style={LABEL_STYLE}>
                      All ({allFieldsOfFile.length})
                    </label>
                    <label className="btn dark btn-outline btn-circle btn-sm" style={LABEL_STYLE}>
                      Mapped ({currentMappedData.length})
                    </label>
                    <label className="btn dark btn-outline btn-circle btn-sm" style={LABEL_STYLE}>
                      Unmapped (
                      {allFieldsOfFile.length - currentMappedData.length})
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4"/>
            </div>
            <div className="table-scrollable" style={PAD_5_STYLE}>
              <table className="table table-striped table-bordered table-advance table-hover">
                <thead>
                <tr>
                  <th>Merge Placeholders Found</th>
                  <th>Merge with Field</th>
                </tr>
                </thead>
                <tbody>
                {
                  allFieldsOfFile.length > 0 &&
                  allFieldsOfFile.map((field, index) => {
                    return (<FieldRow key={index} index={index} field={field} currentMappedData={currentMappedData}
                                      onChange={changeCurrentMapData} allMappingTags={allMappingTags}/>);
                  })
                }
                </tbody>
              </table>
            </div>
            <form className="form-horizontal">
              <div className="form-actions">
                <div className="col-md-offset-2 col-md-9">
                  <button type="button" className="btn btn-outline blue-primary" style={NEXT_BUTTON_STYLE}
                          onClick={onNext}>
                    Next
                  </button>
                  <button type="button" className="btn grey-salsa btn-outline" style={CANCEL_BUTTON_STYLE}
                          onClick={onCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
