import { VendorTypes as Types } from "./index";

const INITIAL_VENDOR_STATE = {
  isLoading: false,
  list: [],
  error: "",
};

export default function VendorsReducer(
  state = INITIAL_VENDOR_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.GET_VENDORS.START:
      return {
        ...state,
        isLoading: true,
      };

    case Types.GET_VENDORS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      };

    case Types.GET_VENDORS.ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    default:
      return state;
  }
}
