import React, { useState } from "react";

import { Button } from "./Button";
import { Icon } from "./Icons";

export const Dropdown = ({
  title,
  icon,
  variant,
  children,
  clientEdit,
  isNotOutline,
  marginLeft,
  marginRight
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isOutline = isNotOutline ? false : true;

  const handleClick = () => setIsOpen((prevState) => !prevState);
  const handleBlur = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  return (
    <div className={`btn-group ${isOpen ? "open" : ""}`}>
      <Button
        isOutline={isOutline}
        variant={variant}
        onClick={handleClick}
        onBlur={handleBlur}
        marginLeft={marginLeft}
        /*style={{
          borderBottomRightRadius: 4,
          borderTopRightRadius: 4,
        }}*/
      >
        {icon}
        <span className="hidden-xs"> {title} </span>
        <Icon.AngleDown />
      </Button>

      <ul
        className={marginRight ? "dropdown-menu":"dropdown-menu pull-right"}
        onClick={() => {
          if (isOpen) {
            setIsOpen(false);
          }
        }}
      >
        {children}
      </ul>
    </div>
  );
};

function DropdownListItem({ onClick, children, onlyLI }) {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };
  const handleMouseDown = (e) => {
    e.preventDefault(); // prevents dropdown blur from happening before item click
  }
  return (
    <li className="bold">
      {onlyLI ? (
        onlyLI
      ) : (
        <a className="bold" href="#!" onMouseDown={handleMouseDown} onClick={handleClick}>
          {children}
        </a>
      )}
    </li>
  );
}

function Divider() {
  return <li className="divider"> </li>;
}

Dropdown.ListItem = DropdownListItem;
Dropdown.Divider = Divider;
