import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
const validateFile = (file) => {
  console.log(`File Type is ${file.type}`);
  console.log(file);
  if (!file) {
    return;
  }

  if (file.type === "" || file.type === "application/vnd.ms-excel") {
    return true;
  }

  return file.type.split("/")[1] === "csv" ? true : false;
};

export const DropzoneForCsv = ({ onFileDrop }) => {

  "";
  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setUploadedImage(`${e.target.result}`);
    };
    reader.readAsDataURL(file);
  };

  const [uploadedImage, setUploadedImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const onDrop = (acceptedFiles) => {
    setErrorMsg("");
    const file = acceptedFiles[0];
    if (!validateFile(file)) {
      return setErrorMsg("Invalid file type");
    }
    setFileName(file.name);
    readFile(file);
    onFileDrop(file);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    //console.log(imageUrl);
    setTimeout(() => {
      setErrorMsg("");
    }, 3000);
  }, [errorMsg, setErrorMsg]);

  return (
    <>
      {uploadedImage ? (
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <img
              className="animated fadeIn"
              src={uploadedImage}
              alt=""
              style={{
                width: 200,
                borderRadius: 10,
                marginTop: 10,
              }}
            />
            <br />
            {fileName}
          </div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-danger"
              style={{
                paddingTop: 4,
                paddingBottom: 4,
                marginTop: 6,
              }}
              onClick={() => setUploadedImage("")}
            >
              Change
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            {...getRootProps()}
            className="kt-dropzone dropzone dz-clickable"
          >
            <input {...getInputProps()} accept="image" />
            <h3 className="sbold">Drop file here or click to upload</h3>
            <p>
              You can import up to 1000 records through a .csv file at a time.
            </p>
            <p className="kt-font-danger animated bounceIn">{errorMsg}</p>
          </div>
        </>
      )}
    </>
  );
};
