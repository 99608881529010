import { takeEvery, put, fork } from "redux-saga/effects";

import { VendorTypes, VendorActions } from "./index";
import * as Api from "../../Api";

function* getVendors() {
  try {
    const {
      data: { vendors },
    } = yield Api.Vendors.list();
    yield put(VendorActions.GetVendors.success(vendors));
  } catch (err) {
    const error = Api.getError(err);
    yield put(VendorActions.GetVendors.error(error));
  }
}

function* watchGetVendorsStart() {
  yield takeEvery(VendorTypes.GET_VENDORS.START, getVendors);
}

const VendorSagas = [fork(watchGetVendorsStart)];

export default VendorSagas;
