import { takeEvery, put, fork } from "redux-saga/effects";

import { InsurerTypes, InsurerActions, InsurersDataMapper } from "./index";
import * as Api from "../../Api";

function* getInsurers() {
  try {
    const { data: insurers } = yield Api.Insurers.list();
    const formattedInsurers = InsurersDataMapper.InsurersMapper.Insurers.format(
      insurers
    );
    yield put(InsurerActions.GetInsurers.success(formattedInsurers));
  } catch (err) {
    const error = Api.getError(err);
    yield put(InsurerActions.GetInsurers.error(error));
  }
}

function* watchGetInsurersStart() {
  yield takeEvery(InsurerTypes.GET_INSURERS.START, getInsurers);
}

const InsurerSagas = [fork(watchGetInsurersStart)];

export default InsurerSagas;
