export function buildNewDollarReceived({
  receivedAmount = "",
  feeAmount = "",
  checkDate = "",
} = {}) {
  return {
    receivedAmount,
    feeAmount,
    checkDate,
  };
}
export function buildFeeScheduleItem({
  userId = "",
  sharePercentage = "",
} = {}) {
  return {
    userId,
    sharePercentage,
  };
}

// class FeeSchedule {
//   constructor() {
//     this.userId = "";
//     this.sharePercentage = "";
//   }

//   setUserId(userId) {
//     this.userId = userId;
//     return this;
//   }

//   setSharePercentage(perc) {
//     this.sharePercentage = perc;
//     return this;
//   }

//   build() {
//     return this;
//   }
// }

// const feeSchedule = new FeeSchedule().setUserId("123").sharePercentage("20");
