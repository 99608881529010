import React from "react";
import { PopupModal } from "../_common/PopupModal";
import * as Api from "../../Api";

export function ArchiveClaims({
  openPanel,
  setOpenPanel,
  selectedArchiveClaims,
  setArchivedSuccessMessage,
}) {
  const archiveIt = (event) => {
    event.preventDefault();
    Api.Claims.markArchive({
      claimIds: selectedArchiveClaims,
    })
      .then(() => {
        setOpenPanel(false);
        setArchivedSuccessMessage("Archived Successfully!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <PopupModal isOpen={openPanel}>
        <div className="modal-dialog" style={{ margin: "0px" }}>
          <div className="modal-content">
            <div className="portlet box red">
              <div className="portlet-title">
                <div className="caption">
                  <i className="fa fa-warning"></i>ARCHIVE THIS FILE?
                </div>
              </div>
              <div className="portlet-body">
                <div
                  className="slimScrollDiv"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    width: "auto",
                    height: "200px",
                  }}
                >
                  <div
                    className="scroller"
                    style={{
                      height: "200px",
                      overflow: "hidden",
                      width: "auto",
                    }}
                    data-rail-visible="1"
                    data-rail-color="yellow"
                    data-handle-color="#a1b2bd"
                    data-initialized="1"
                  >
                    <div className="task-content">
                      <p>Archiving a file results in the below: </p>
                      <ul>
                        <li>Archiving a file makes it inactive.</li>
                        <li>
                          {" "}
                          You would only be able to access it from the Archives
                          section in Read-Only format.{" "}
                        </li>
                        <li>
                          {" "}
                          In the future if you decide to unarchive a file, it
                          then becomes active and will be included as a
                          chargeable file.
                        </li>
                        <li>
                          Any Attorney or Vendor you've shared this file with
                          will no longer be able to access this file.
                        </li>
                      </ul>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{ borderTop: "1px transparent", paddingTop: "0px" }}
            >
              <button type="button" onClick={archiveIt} className="btn red">
                Yes, archive it!
              </button>
              <button
                onClick={() => {
                  setOpenPanel(false);
                }}
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
