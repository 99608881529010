import React from "react";

import { Dropdown } from "../../_common/Dropdown";

export const NewClaimPageBar = () => {
  const manageCustomFields = () => {
    //REDTAG:TJH - replace with react-router navigation
    window.open("/custom-fields", "_blank");
  };

  const addCustomFields = () => {
    //REDTAG:TJH - replace with react-router navigation
    window.open("/custom-fields#add-new", "_blank");
  };

  const goToClaimsPage = () => {
    //REDTAG:TJH - replace with react-router navigation
    window.open("/claims");
  };
  return (
    <div className="page-bar">
      <ul className="page-breadcrumb">
        <li>
          <a href="#!" onClick={goToClaimsPage}>
            Home
          </a>
          <i className="fa fa-circle"/>
        </li>
        <li>
          <span>New Client File</span>
        </li>
      </ul>
      <div className="page-toolbar">
        <Dropdown title="Custom Fields" variant="blue">
          <Dropdown.ListItem onClick={() => manageCustomFields()}>
            Manage Custom Fields
          </Dropdown.ListItem>
          <Dropdown.ListItem onClick={() => addCustomFields()}>
            Add New Custom Field
          </Dropdown.ListItem>
        </Dropdown>
      </div>
    </div>
  );
};
