import React, { useState } from "react";
import { SlidingSidePanel } from "../../../_common/SlidingSidePanel";
import * as Api from "../../../../Api";
import { Notify } from "../../../../services/Notify.service";

export const ShareFileWithVendorAndAttroney = ({
  claimId,
  showSelectedUsersScreen,
  setShowSelectedUsersScreen,
  selectedVendors,
  selectedAttorney,
  shareLink,
  shareClaimToken,
  setSelectedVendor,
  setSelectedAttorney,
}) => {
  const [message, setMessage] = useState("");
  const [ccme, setCCme] = useState(false);
  const [showLoading, setshowLoading] = useState(false);

  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setCCme(true);
    }
    if (!event.target.checked) {
      setCCme(false);
    }
  };

  const changeVendorsAndAttorney = ({ type, field, value, index }) => {
    if (type === "vendor") {
      const current = [...selectedVendors];
      const updatedObj = Object.assign({}, current[index], { [field]: value });
      current[index] = updatedObj;
      setSelectedVendor(current);
    }
    if (type === "attorney") {
      const current = [...selectedAttorney];
      const updatedObj = Object.assign({}, current[index], { [field]: value });
      current[index] = updatedObj;
      setSelectedAttorney(current);
    }
  };

  const sendShareEmail = (event) => {
    setshowLoading(true);
    const requestData = {
      vendors: selectedVendors,
      attorneys: selectedAttorney,
      message,
      ccme,
      claimId,
      shareClaimToken,
    };
    Api.Claims.Share.shareToVendorAttorney({
      requestData,
    })
      .then(() => {
        Notify.success("Claim shared successfully.");
      })
      .catch((err) => {
        Notify.error(`Please wait. ${err.message}`);
      })
      .finally(() => {
        setShowSelectedUsersScreen(false);
        setshowLoading(false);
      });
    event.preventDefault();
  };
  const testShareClaimLink = () => {
    window.open(shareLink, "_blank");
    return false;
  };

  return (
    <>
      {showSelectedUsersScreen && (
        <SlidingSidePanel type={"right"} isOpen={showSelectedUsersScreen}>
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">
                  SHARE CLAIM
                </span>
              </div>
              <div className="tools">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSelectedUsersScreen(false);
                  }}
                  href="#!"
                  className="remove"
                  data-original-title=""
                  title=""
                >
                  &nbsp;
                </a>
              </div>
            </div>
            <div className="portlet-body">

              <p style={{ textAlign: "justify" }} class="font-grey-mint">
                Make your Claim visible to Attorneys/Vendors. We'll email them a
                link to view the Claim. They will be able to view the claim and
                everything you've added into it like notes, attachments, emails
                on files, activity logs etc. They will not be able to make any
                changes.
                <br />
                <br />
              </p>
              {selectedVendors.map((v, ind) => {
                return (
                  <div className="inbox-body" style={{ marginBottom: "2rem" }}>
                    <div className="inbox-content">
                      <div className="inbox-form-group mail-to">
                        <label className="control-label">Vendor:</label>
                        <div className="controls controls-to">
                          <input
                            value={v.name}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                              changeVendorsAndAttorney({
                                type: "vendor",
                                field: "name",
                                value: evt.target.value,
                                index: ind,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="inbox-form-group mail-to">
                        <label className="control-label">Email:</label>
                        <div className="controls controls-to">
                          <input
                            value={v.email}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                              changeVendorsAndAttorney({
                                type: "vendor",
                                field: "email",
                                value: evt.target.value,
                                index: ind,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="inbox-form-group mail-to">
                        <label className="control-label">2nd Email:</label>
                        <div className="controls controls-to">
                          <input
                            value={v.secondEmail}
                            type="text"
                            className="form-control"
                            onChange={(evt) => {
                              changeVendorsAndAttorney({
                                type: "vendor",
                                field: "secondEmail",
                                value: evt.target.value,
                                index: ind,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {selectedAttorney.map((a, ind) => {
                return (
                  <div class="inbox-body" style={{ marginBottom: "2rem" }}>
                    <div class="inbox-content">
                      <div class="inbox-form-group mail-to">
                        <label class="control-label">Attorney:</label>
                        <div class="controls controls-to">
                          <input
                            value={a.name}
                            type="text"
                            class="form-control"
                            onChange={(evt) => {
                              changeVendorsAndAttorney({
                                type: "attorney",
                                field: "name",
                                value: evt.target.value,
                                index: ind,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div class="inbox-form-group mail-to">
                        <label class="control-label">Email:</label>
                        <div class="controls controls-to">
                          <input
                            value={a.email}
                            type="text"
                            class="form-control"
                            onChange={(evt) => {
                              changeVendorsAndAttorney({
                                type: "attorney",
                                field: "email",
                                value: evt.target.value,
                                index: ind,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div class="inbox-form-group mail-to">
                        <label class="control-label">2nd Email:</label>
                        <div class="controls controls-to">
                          <input
                            value={a.secondEmail}
                            type="text"
                            class="form-control"
                            onChange={(evt) => {
                              changeVendorsAndAttorney({
                                type: "attorney",
                                field: "secondEmail",
                                value: evt.target.value,
                                index: ind,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label>Email/Message:</label>
                    <textarea
                      class="form-control"
                      rows="5"
                      value={message}
                      onChange={(evt) => setMessage(evt.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">
                <input
                  type="checkbox"
                  class="form-control"
                  checked={ccme}
                  onClick={handleCheckbox}
                />
                <span></span> Cc Me
              </label>
              <p
                style={{
                  textDecoration: "none",
                  padding: "16px 19px",
                  backgroundColor: "rgb(242, 242, 242)",
                  border: "1px solid rgb(224, 224, 224)",
                  borderRadius: "6px",
                  color: "rgb(0, 0, 0)",
                  fontWeight: "bold",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "24px",
                }}
              >
                {shareLink}
                <br />
                <span
                  className="btn btn-sm circle red"
                  style={{
                    WebkitBoxShadow:
                      "0px 5px 10px 2px rgba(244, 81, 108, 0.19) !important",
                    MozBoxShadow:
                      "0px 5px 10px 2px rgba(244, 81, 108, 0.19) !important",
                    boxShadow:
                      "0px 5px 10px 2px rgba(244, 81, 108, 0.19) !important",
                  }}
                  onClick={testShareClaimLink}
                >
                  Test
                  <i className="fa fa-share"></i>
                </span>
              </p>
              <p
                style={{
                  margin: "0rem!important",
                  width: "100%",
                  textAlign: "center",
                }}
                className="font-grey-mint"
              >
                Seperate links are sent to each email.
              </p>

              <div
                className="row"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div className="col-sm-6">
                  <button
                    type="button"
                    onClick={sendShareEmail}
                    class="btn btn-primary mt-ladda-btn ladda-button btn-block btn-lg purple-soft next_share_all_vendor_btn_cls"
                    disabled={showLoading}
                  >
                    {showLoading ? "Sending..." : "Send Notification"}
                  </button>
                </div>
                <div className="col-sm-6">
                  <button
                    type="button"
                    class="btn btn-lg  btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSelectedUsersScreen(false);
                    }}
                    disabled={showLoading}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SlidingSidePanel>
      )}
    </>
  );
};
