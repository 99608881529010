import React, { useState, useEffect } from "react";
import { SlidingSidePanel } from "../_common/SlidingSidePanel";
import * as Api from "../../Api";
import { Error } from "../_common/Error";
import { SubmitButton } from "../profile/SubmitButton";

export function AddNewAndEdit({
  openPanel,
  setOpenPanel,
  statusDetails,
  reloadTable,
}) {
  const [name, setName] = useState("");
  const [color, setColor] = useState("#000000");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setName(statusDetails.statusName ? statusDetails.statusName : "");
    setColor(statusDetails.color ? statusDetails.color : "#000000");
  }, [statusDetails.statusName, statusDetails.color]);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");

    if (name === "") {
      setError("Please enter status name.");
      return false;
    }
    const statusData = {
      statusName: name,
      color,
    };
    setIsLoading(true);
    if (statusDetails.id) {
      statusData.id = statusDetails.id;
        return Api.Statuses.edit({
          id: statusDetails.id,
          status: statusData,
        })
        .then((data) => {
          reloadTable();
          setOpenPanel(false);
          setError("");
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].msg
          ) {
            setIsLoading(false);
            setError(err.response.data.errors[0].msg);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return Api.Statuses.create({
      status: statusData,
    })
      .then((data) => {
        setOpenPanel(false);
        reloadTable();
        setError("");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].msg
        ) {
          setIsLoading(false);
          setError(error.response.data.errors[0].msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const previewStatusStyle = {
    color: "#fff",
    textAlign: "center",
    border: "none",
    backgroundColor: color,
  };
  return (
    <div>
      <SlidingSidePanel type={"right"} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {statusDetails.id ? "Edit Status Details" : "Add New Status"}
              </span>
            </div>
            <div className="tools">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPanel(false);
                  setError("");
                }}
                href="#!"
                className="remove"
                data-original-title=""
                title=""
              >
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error} /> : ""}

            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Status Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="statusName"
                  placeholder="Status Name"
                />
              </div>
              <div className="form-group">
                <label className="control-label">Color</label>
                <input
                  type="color"
                  className="form-control"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  name="statusColor"
                  placeholder="Status Color"
                />
              </div>
              <div className="form-group">
                <label className="control-label">Preview</label>
                <input
                  type="readonly"
                  className="form-control"
                  style={previewStatusStyle}
                  value={name}
                  name="statusPreview"
                  placeholder="Preview"
                />
              </div>
              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading} />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenPanel(false);
                        setError("");
                      }}
                      type="button"
                      className="btn default"
                      name="cancel"
                      value="cancel"
                      style={{ margin: 5 }}
                    >
                      <i className="fa fa-times"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
