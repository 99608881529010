import React from "react";

export const Icon = ({ iconName, onClick, classes = "", ...props }) => (
  <i onClick={onClick} className={`fa ${iconName} ${classes}`} {...props}></i>
);

export const AngleDown = (props) => (
  <Icon iconName="fa-angle-down" {...props} />
);
const AngleLeft = (props) => <Icon iconName="fa-angle-left" {...props} />;
const AngleRight = (props) => <Icon iconName="fa-angle-right" {...props} />;
const Upload = (props) => <Icon iconName="fa-upload" {...props} />;
const Plus = (props) => <Icon iconName="fa-plus" {...props} />;
const Calendar = (props) => <Icon iconName="fa-calendar" {...props} />;
const Pencil = (props) => <Icon iconName="fa-pencil" {...props} />;
const ListAlt = (props) => <Icon iconName="fa-list-alt" {...props} />;
const InfoCircle = (props) => <Icon iconName="fa-info-circle" {...props} />;
const MapPin = (props) => <Icon iconName="fa-map-pin" {...props} />;
const PieChart = (props) => <Icon iconName="fa-pie-chart" {...props} />;
const Money = (props) => <Icon iconName="fa-money" {...props} />;
const Phone = (props) => <Icon iconName="fa-phone" {...props} />;
const Search = (props) => <Icon iconName="fa-search" {...props} />;
const Times = (props) => <Icon iconName="fa-times" {...props} />;

Icon.AngleDown = AngleDown;
Icon.AngleLeft = AngleLeft;
Icon.AngleRight = AngleRight;
Icon.Upload = Upload;
Icon.Plus = Plus;
Icon.Calendar = Calendar;
Icon.Pencil = Pencil;
Icon.ListAlt = ListAlt;
Icon.InfoCircle = InfoCircle;
Icon.MapPin = MapPin;
Icon.PieChart = PieChart;
Icon.Money = Money;
Icon.Phone = Phone;
Icon.Search = Search;
Icon.Times = Times;
