import React, {useState} from 'react';
import * as Api from '../../../Api';

import useFetcher from '../../../hooks/useFetcher';

export function LossAddressFilter(
  {selectedCities, setSelectedCities, selectedZipcodes, setSelectedZipcodes, street = '', setStreet}
) {

  const {data: citiesData} = useFetcher(Api.Claims.getClientCities);
  const {data: zipCodesData} = useFetcher(Api.Claims.getClientZips);

  const {cities} = citiesData || {};
  const {zipCodes} = zipCodesData || {};

  return (
    <>
      <div className="panel-group accordion" id="accordion1">
        <Panel title="Search By City" options={cities} selectedOptions={selectedCities}
               setSelectedOptions={setSelectedCities}/>
        <Panel title="Search By Zipcode" options={zipCodes} selectedOptions={selectedZipcodes}
               setSelectedOptions={setSelectedZipcodes}/>
      </div>
      <input className="form-control form-filter input-sm margin-bottom-5"
             style={{marginTop: -15, textAlign: 'center'}} type="text" value={street}
             placeholder="Search By Street"
             onChange={(e) => setStreet(e.target.value)}/>
    </>
  );
}

const CONTAINER_STYLE = {
  background: 'transparent',
  border: '1px solid #c2cad8!important',
  borderRadius: '0px !important'
};

const ACCORDIAN_STYLE = {
  fontSize: 12,
  color: '#555',
  textDecoration: 'none',
  textAlign: 'center',
  padding: '7px 0'
};

function Panel({title, options = [], selectedOptions, setSelectedOptions}) {
  const [expand, setExpand] = useState(false);

  function handleClick() {
    setExpand((prev) => !prev);
  }

  function handleChange(e) {
    setSelectedOptions(Array.from(e.target.selectedOptions, (option) => option.value));
  }

  return (
    <div className="panel panel-default" style={CONTAINER_STYLE}>
      <div className="panel-heading" style={{background: 'transparent'}} onClick={handleClick}>
        <h4 className="panel-title">
          <a className="accordion-toggle" style={ACCORDIAN_STYLE} data-toggle="collapse" data-parent="#accordion1"
             href="#collapse_1" aria-expanded="true">
            {title}
          </a>
        </h4>
      </div>
      <div id="collapse_1" className={`panel-collapse collapse ${expand ? 'in' : ''}`} aria-expanded="true">
        <div className="panel-body">
          <select className="form-control form-filter input-sm" multiple="multiple" value={selectedOptions || []}
                  onChange={handleChange}>
            {options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
