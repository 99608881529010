import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import {ClientEditTopBar} from './ClientEditTopBar';
import {ClientFormTab} from './ClientFormTab';
import {ActivityTab} from './ClaimActivity/ActivityTab';
import {MapTab} from './claimMap/claimMap';
import {NotesTab} from './ClaimNotes/NotesTab';
import {AttachmentsTab} from './ClaimAttachments/AttachmentsTab';
import {FollowUpReminderTab} from './ClaimFollowupReminder/FollowUpReminderTab';
import '../../../assets/pages/css/client_edit.css';
import './EditClaim.css';


const claimRoutePaths = {
  shareView: '/shared-claim/:shareToken',
  new: '/claims/new',
  edit: '/claims/:id'
};

const Circle = () => (
  <i
    className="fa fa-circle"
    style={{marginLeft: '5px!important', marginRight: '5px !important'}}
  />
);

const EditClaimPageBar = ({
                            isSharedView,
                            clientName,
                            setFollowUpReminder,
                            id
                          }) => {
  if (isSharedView) {
    return null;
  }

  return (
    <div className="page-bar">
      <ul className="page-breadcrumb" style={{marginTop: '5px'}}>
        <li>
          <a href="/app/claims">Home{'   '}</a>
          <Circle/>
          {'   '}
        </li>
        <li style={{marginLeft: 5}}>
          <a href="#!">
            <span data-testid="file-name">
              {'   '}Client File{'   '}
              {clientName && `(${clientName})`}
            </span>
          </a>
        </li>
      </ul>
      <div className="page-toolbar">
        <ClientEditTopBar
          setFollowUpReminder={setFollowUpReminder}
          claimId={id}
        />
      </div>
    </div>
  );
};

function getClientName(client) {
  const {type, businessName, residence} = client ?? {};

  if (type === 'business') {
    return businessName;
  }

  if (type === 'residence') {
    const {client1} = residence ?? {};

    const {firstName, lastName} = client1 ?? {};

    return [firstName, lastName].filter((item) => item).join(' ');
  }
}

export const EditClaim = (params) => {
  const id = params.match ? params.match.params.id : 0;
  const currentActiveTab = window.location.hash
    ? window.location.hash
    : '#claim-form';

  const {path} = useRouteMatch();
  const isSharedView = path === claimRoutePaths.shareView;

  const [addFollowupReminder, setAddFollowupReminder] = useState(false);
  const [clientFormTabView, setClientFormTabView] = useState(false);
  const [attachmentTabView, setAttachmentTabView] = useState(false);
  const [notesTabView, setNotesTabView] = useState(false);
  const [remindersTabView, setRemindersTabView] = useState(false);
  const [activityLogTabView, setActivityLogTabView] = useState(false);
  const [mapTabView, setMapTabView] = useState(false);

  const {client} = useSelector((state) => state.claims.claim?.data);

  const clientName = getClientName(client);

  const resetAllTab = () => {
    setClientFormTabView(false);
    setAttachmentTabView(false);
    setNotesTabView(false);
    setRemindersTabView(false);
    setActivityLogTabView(false);
    setMapTabView(false);
  };

  const setFollowUpReminder = () => {
    resetAllTab();
    window.location.href = '#follow-up-reminder';
    setRemindersTabView(true);
    setAddFollowupReminder(true);
  };

  const tabRoute = useMemo(() => {
    return [
      {
        path: '#claim-form',
        controller: setClientFormTabView
      },
      {
        path: '#attachments',
        controller: setAttachmentTabView
      },
      {
        path: '#notes',
        controller: setNotesTabView
      },
      {
        path: '#follow-up-reminder',
        controller: setRemindersTabView
      },
      {
        path: '#activity',
        controller: setActivityLogTabView
      },
      {
        path: '#map',
        controller: setMapTabView
      }
    ];
  }, [
    setClientFormTabView, setAttachmentTabView, setNotesTabView, setRemindersTabView, setActivityLogTabView,
    setMapTabView
  ]);

  const activateCurrentTab = useCallback(() => {
    const controllerFunction = tabRoute.find((r) => {
      if (r.path === currentActiveTab) {
        return r.controller;
      }
      return '';
    });

    if (controllerFunction) {
      resetAllTab();
      controllerFunction['controller'](true);
      // controllerFunction(true);
    }
    if (!controllerFunction) {
      setClientFormTabView(true);
    }
  }, [currentActiveTab, tabRoute]);

  useEffect(() => {
    activateCurrentTab();
  }, [activateCurrentTab]);

  return (
    <div>
      <EditClaimPageBar
        id={id}
        setFollowUpReminder={setFollowUpReminder}
        clientName={clientName}
        isSharedView={isSharedView}
      />
      <br/>
      <div
        className="portlet light bordered"
        style={{marginTop: isSharedView ? 30 : ''}}
      >
        <div className="tw-font-bold tw-text-xl tw-mb-4">Client File Tabs</div>
        <div className="">
          <div className="tw-flex tw-border-b -tw-mb-px tw-space-x-2 tw-text-base">
            <a className={`tw-py-3 tw-px-2 tw-font-bold tw-no-underline hover:tw-no-underline focus:tw-no-underline focus:tw-text-blue ${clientFormTabView ? 'tw-border-b-2 tw-border-blue tw-text-blue hover:tw-border-blue hover:tw-text-blue' : 'tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-600 hover:tw-border-b-2'}`}
              href="#claim-form"
              onClick={() => {
                resetAllTab();
                setClientFormTabView(true);
              }}
            >
              Client Form
            </a>

            <a className={`tw-py-3 tw-px-2 tw-font-bold tw-no-underline hover:tw-no-underline focus:tw-no-underline focus:tw-text-blue ${attachmentTabView ? 'tw-border-b-2 tw-border-blue tw-text-blue hover:tw-border-blue hover:tw-text-blue' : 'tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-600 hover:tw-border-b-2'}`}
              href="#attachments"
              onClick={() => {
                resetAllTab();
                setAttachmentTabView(true);
              }}
            >
              Attachments
            </a>
          
            <a className={`tw-py-3 tw-px-2 tw-font-bold tw-no-underline hover:tw-no-underline focus:tw-no-underline focus:tw-text-blue ${notesTabView ? 'tw-border-b-2 tw-border-blue tw-text-blue hover:tw-border-blue hover:tw-text-blue' : 'tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-600 hover:tw-border-b-2'}`}
              href="#notes"
              onClick={() => {
                resetAllTab();
                setNotesTabView(true);
              }}
            >
              Notes
            </a>
          
            <a className={`tw-py-3 tw-px-2 tw-font-bold tw-no-underline hover:tw-no-underline focus:tw-no-underline focus:tw-text-blue  ${remindersTabView ? 'tw-border-b-2 tw-border-blue tw-text-blue hover:tw-border-blue hover:tw-text-blue' : 'tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-600 hover:tw-border-b-2'}`}
              href="#follow-up-reminder"
              onClick={() => {
                resetAllTab();
                setRemindersTabView(true);
              }}
            >
              Follow Up Reminders
            </a>

            <a className={`tw-py-3 tw-px-2 tw-font-bold tw-no-underline hover:tw-no-underline focus:tw-no-underline focus:tw-text-blue ${activityLogTabView ? 'tw-border-b-2 tw-border-blue tw-text-blue hover:tw-border-blue hover:tw-text-blue' : 'tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-600 hover:tw-border-b-2'}`}
              href="#activity"
              onClick={() => {
                resetAllTab();
                setActivityLogTabView(true);
              }}
            >
              Activity Log
            </a>

            <a className={`tw-py-3 tw-px-2 tw-font-bold tw-no-underline hover:tw-no-underline focus:tw-no-underline focus:tw-text-blue ${mapTabView ? 'tw-border-b-2 tw-border-blue tw-text-blue hover:tw-border-blue hover:tw-text-blue' : 'tw-text-gray-500 hover:tw-text-gray-600 hover:tw-border-gray-600 hover:tw-border-b-2'}`}
              href="#map"
              onClick={() => {
                resetAllTab();
                setMapTabView(true);
              }}
            >
              Map
            </a>

          </div>
          <div className="tab-content client-edit-mid-tab-content">
            <div className={clientFormTabView ? 'tab-pane active' : 'tab-pane'}>
              {clientFormTabView && <ClientFormTab id={id}/>}
            </div>
            <div className={attachmentTabView ? 'tab-pane active' : 'tab-pane'}>
              {attachmentTabView && <AttachmentsTab claimId={id}/>}
            </div>
            <div className={notesTabView ? 'tab-pane active' : 'tab-pane'}>
              {notesTabView && <NotesTab claimId={id}/>}
            </div>
            <div className={remindersTabView ? 'tab-pane active' : 'tab-pane'}>
              {remindersTabView && (
                <FollowUpReminderTab
                  claimId={id}
                  addFollowupReminder={addFollowupReminder}
                  setAddFollowupReminder={setAddFollowupReminder}
                />
              )}
            </div>
            <div
              className={activityLogTabView ? 'tab-pane active' : 'tab-pane'}
            >
              {activityLogTabView && <ActivityTab claimId={id}/>}
            </div>
            <div className={mapTabView ? 'tab-pane active' : 'tab-pane'}>
              {mapTabView && <MapTab claimId={id}/>}
            </div>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
};
