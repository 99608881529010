import React, {useState} from 'react';

import * as Api from '../../Api';
import {Notify} from '../../services/Notify.service';
import {FullPageSpinner} from '../_common/Spinner';

const NO_MARGINS = {marginLeft: '0px', marginRight: '0px'};

const NEXT_BUTTON_STYLE = {
  padding: '12px 49px',
  borderRadius: '4px !important',
  marginRight: '10px'
};

const CANCEL_BUTTON_STYLE = {
  padding: '12px 29px',
  borderRadius: '4px !important'
};

export function UploadTemplatesNext({uploadedFile, onSeeInstructions, onCancel, onUploaded, children}) {
  const [isLoading, setIsLoading] = useState(false);

  async function goToMapping() {
    setIsLoading(true);
    try {
      const {data} = await Api.Document.legacy
        .uploadFile({file: uploadedFile});
      if (typeof data.files.file_id !== 'undefined') {
        onUploaded(data.files.file_id);
        return true;
      }
      Notify.error('Error in uploading file.');
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  function seeInstructions(e) {
    e.preventDefault();
    onSeeInstructions();
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        {isLoading && <FullPageSpinner/>}
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <i className="fa fa-upload"/>Upload Your Document
            </div>
            <div className="actions">
              <button className="btn btn-outline blue-primary add_new" onClick={seeInstructions}>
                See Instructions
                <i className="fa fa-question-circle"/>
              </button>
            </div>
          </div>
          <div className="portlet-body form">
            {children}
            <br/>
            <div className="row" style={NO_MARGINS}>
              <div className="col-sm-12">
                <div style={{textAlign: 'center', paddingTop: '22px'}}>
                  <i className="fa fa-check-circle" style={{color: '#4caf50', paddingRight: '10px'}}/>
                  <b>Your file has been uploaded!</b>
                  <br/>
                  {uploadedFile.name}
                </div>
              </div>
            </div>
            <div className="form-actions">
              <div className="col-md-offset-2 col-md-9">
                <button type="button" className="btn btn-outline blue-primary" style={NEXT_BUTTON_STYLE}
                        onClick={goToMapping}>
                  Next
                </button>
                <button type="button" className="btn grey-salsa btn-outline" style={CANCEL_BUTTON_STYLE}
                        onClick={onCancel}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
