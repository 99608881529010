import { AuthTypes } from "./index";

export const GetAuthUser = {
  start: () => ({
    type: AuthTypes.GET_AUTH_USER.START,
  }),
  success: (user) => ({
    type: AuthTypes.GET_AUTH_USER.SUCCESS,
    payload: user,
  }),
  error: (err) => ({
    type: AuthTypes.GET_AUTH_USER.ERROR,
    payload: err,
  }),
  clear: () => ({
    type: AuthTypes.GET_AUTH_USER.CLEAR,
  }),
};
