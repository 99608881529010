import React, {useCallback, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';

import {FullPageSpinner} from '../../../_common/Spinner';
import * as Api from '../../../../Api';
import {SharedClaim} from '../../../../api/sharedClaim';

const claimRoutePaths = {
  shareView: '/shared-claim/:shareToken',
  new: '/claims/new',
  edit: '/claims/:id'
};

export const ActivityTab = ({claimId}) => {
  const {path} = useRouteMatch();
  const isSharedView = path === claimRoutePaths.shareView;

  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadActivity = useCallback(() => {
    Api.Claims.Activity.get({
        claimId
      })
      .then((data) => {
        setActivity(data.data.activity);
      })
      .catch((err) => {
        Api.getError(err);
        console.log(err);
      }).finally(() => {
      setIsLoading(false);
    });
  }, [claimId]);

  const loadSharedClaimActivity = useCallback(() => {
    SharedClaim.getActivity()
      .then((data) => {
        setActivity(data.data.activity);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (isSharedView) {
      loadSharedClaimActivity();
    }
    if (!isSharedView) {
      loadActivity();
    }
  }, [isSharedView, loadActivity, loadSharedClaimActivity]);

  return (
    <div>
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption">Activity Log</div>
        </div>
        <div className="portlet-body form">
          {isLoading && <FullPageSpinner/>}
          {!isLoading && (
            <div>
              {activity.length === 0 && (
                <div style={{padding: '20px'}}>
                  No recent activities found.
                </div>
              )}
              {activity.length !== 0 && (
                <div style={{padding: '20px'}}>
                  <ul className="feeds">
                    {activity.map((v) => {
                      return (
                        <li key={v.id}>
                          <div className="col1">
                            <div className="cont">
                              <div className="cont-col1">
                                {v.status.trim() === 'removed' ? (
                                  <div className="label label-sm label-danger">
                                    <i className="fa fa-trash"/>
                                  </div>
                                ) : (
                                  <div className="label label-sm bg-green-jungle">
                                    <i className="fa fa-check"/>
                                  </div>
                                )}
                              </div>
                              <div className="cont-col2">
                                <div className="desc font-dark">
                                  <strong>{v.displayFieldName}</strong>
                                  {v.status === '' && (
                                    <span>{` changed to `}</span>
                                  )}
                                  {v.status !== '' && (
                                    <span>{` ${v.status} `}</span>
                                  )}
                                  {(v.status === 'marked' ||
                                    v.status === 'unmarked' ||
                                    v.status === 'pol required' ||
                                    v.status === 'pol not required') && (
                                    <span/>
                                  )}
                                  <strong dangerouslySetInnerHTML={{__html: v.fieldValue || '&lt;empty&gt;'}}/>
                                  {' '}By{' '}
                                  <strong>{v.userName}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col2 ">
                            <div className="date">
                              {' '}
                              {v.activityFormatedDate}{' '}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
