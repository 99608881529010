import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

import '../../assets/css/global/plugins/bootstrap/css/bootstrap.min.css';
import '../../assets/css/global/plugins/bootstrap-daterangepicker/css/daterangepicker.min.css';
import '../../assets/css/global/plugins/bootstrap-daterangepicker/css/custom.css';
import './DatePickerWithOption.css';

const BASE_PICKER_SETTINGS = {
  autoApply: false,
  showWeekNumbers: true,
  linkedCalendars: false,
  locale: {
    cancelLabel: 'Clear'
  },
  showDropdowns: true,
  startDate: moment().toDate(),
  endDate: moment().add(60, 'days').toDate()
};

export function DatePickerForReminder({fromDate, toDate, onDatesChange}) {
  const today = moment().toDate();
  const ranges = {
    'Next 60 Days': [today, moment().add(60, 'days').toDate()],
    'Last 30 Days': [moment().subtract(29, 'days').toDate(), today]
  };

  function handleApply(event, picker) {
    const {startDate, endDate} = picker;
    fromDate = moment(startDate);
    toDate = moment(endDate);
    onDatesChange(fromDate, toDate);
  }

  function handleClear() {
    onDatesChange(null, null);
  }

  return (
    <div>
      <DateRangePicker initialSettings={{...BASE_PICKER_SETTINGS, ranges}} onApply={handleApply} onCancel={handleClear}>
        <div className="input-group">
          <input type="text" readOnly={true} className="form-control" value={toDate ? `${fromDate.format('MMM DD YYYY')} - ${toDate.format('MMM DD YYYY')}` : 'Select Date Range'} />
          <span className="input-group-btn">
            <button className="btn date-range-toggle blue-primary" type="button">
              <i className="fa fa-calendar white"/>
            </button>
          </span>
        </div>
      </DateRangePicker>
    </div>
  );
}
