import {
  getClientName,
  getAdjusterNames,
  formatAddress,
} from "./ClaimsTable.utils";

export function mapClaims(claims = []) {
  const hasClaims = claims && claims.length;

  if (!hasClaims) {
    return [];
  }
  return claims.map((claim) => {
    const {
      id,
      fileNumber,
      createdAt,
      clientType,
      client1FirstName,
      client1LastName,
      client2FirstName,
      client2LastName,
      clientBusinessName,
      clientPhone,
      claimNumber,
      adjusterId: adjusters,
      dateOfLoss,
      propertyStreetAddress: street,
      propertyCity: city,
      propertyState: state,
      propertyCountry: country,
      propertyZipcode: zip,
      insAdjuster1Name,
      status,
      adjusterClaimPerc,
      contractFee,
      insuranceCompany: insuranceCompanyId,
      insuranceCompanyName,
      statusColor,
      acolor
    } = claim;

    const name = getClientName({
      clientType,
      firstName: client1FirstName,
      lastName: client1LastName,
      firstName2: client2FirstName,
      lastName2: client2LastName,
      businessName: clientBusinessName,
    });

    const adjusterNames = getAdjusterNames(adjusters);

    const propertyAddress = formatAddress({
      street,
      city,
      state,
      country,
      zip,
    });

    return {
      id,
      fileNumber,
      createdAt,
      name,
      clientPhone,
      adjusterNames,
      dateOfLoss,
      propertyAddress,
      claimNumber,
      insAdjuster1Name,
      status,
      adjusterClaimPerc,
      contractFee,
      adjusters,
      lossCity: city,
      lossZip: zip,
      insurer: {
        id: insuranceCompanyId,
        name: insuranceCompanyName,
      },
      statusColor,
      acolor
    };
  });
}
