import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const UPLOADED_IMAGE_STYLE = {
  width: 200,
  borderRadius: 10,
  marginTop: 10
};

const CHANGE_IMAGE_STYLE = {
  paddingTop: 4,
  paddingBottom: 4,
  marginTop: 6
};

const TEXT_ALIGN_CENTER = {textAlign: 'center'};

function validateFile(file) {
  if (!file || !file.type) {
    return;
  }
  const ext = file.name.split('.')
    .pop();
  return ['docx', 'doc', 'rtf', 'RTF'].includes(ext);
}

export function DropzoneForExcel({onFileDrop}) {
  const [uploadedImage, setUploadedImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        setUploadedImage(`${e.target.result}`);
        if (reader.readyState === 2) {
          resolve();
        }
      };

      reader.onerror = reject;
      reader.onabort = reject;
      reader.readAsDataURL(file);
    });
  }

  async function onDrop(acceptedFiles) {
    setErrorMsg('');
    const file = acceptedFiles[0];
    if (!validateFile(file)) {
      return setErrorMsg('Invalid file type');
    }
    setFileName(file.name);
    await readFile(file);
    onFileDrop(file);
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg('');
    }, 3000);
  }, [errorMsg, setErrorMsg]);

  let result;
  if (uploadedImage) {
    result = (
      <div className="row">
        <div className="col-12" style={TEXT_ALIGN_CENTER}>
          <img className="animated fadeIn" src={uploadedImage} alt="" style={UPLOADED_IMAGE_STYLE}/>
          <br/>
          {fileName}
        </div>
        <div className="col-12" style={TEXT_ALIGN_CENTER}>
          <button type="button" className="btn btn-danger" style={CHANGE_IMAGE_STYLE}
                  onClick={() => setUploadedImage('')}>
            Change
          </button>
        </div>
      </div>
    );
  } else {
    result = (
      <>
        <div {...getRootProps()} className="kt-dropzone dropzone dz-clickable">
          <input {...getInputProps()} accept="image"/>
          <h3 className="sbold">Drop file here or click to upload</h3>
          <p className="kt-font-danger animated bounceIn">{errorMsg}</p>
        </div>
      </>
    );
  }
  return result;
}
