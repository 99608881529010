import React from "react";
import { Pagebar } from "../layout/Pagebar";
import { VendorDatatable } from "./tables";
import { NewAndEditVendors } from "./newAndEditVendors";
import { DeleteSweetAlertConfirmation } from "../_common/Sweetalert";
import { Dropdown } from "../_common/Dropdown";
import DownloadCSV from "../_common/DownloadCSV";
import DownloadPDF from "../_common/DownloadPDF";
import DownloadImage from "../_common/DownloadImage";
import * as Api from "../../Api";

export function Vendors() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [vendors, setVendors] = React.useState([]);
  const [error, setError] = React.useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [deleteSelectedVendor, setDeleteSelectedVendor] = React.useState({});
  const downloadCSVHeaders = [
    { label: "Vendor Name", key: "name" },
    { label: "Email Address", key: "email" },
    { label: "Street", key: "streetAddress" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zipcode", key: "zipcode" },
    { label: "Phone #", key: "phone" },
    { label: "Extension", key: "phoneExt" },
    { label: "Fax #", key: "fax" },
  ];
  const downloadPDFHeaders = [
    { header: "Vendor Name", dataKey: "name" },
    { header: "Email Address", dataKey: "email" },
    { header: "Street", dataKey: "streetAddress" },
    { header: "City", dataKey: "city" },
    { header: "State", dataKey: "state" },
    { header: "Zipcode", dataKey: "zipcode" },
    { header: "Phone #", dataKey: "phone" },
    { header: "Extension", dataKey: "phoneExt" },
    { header: "Fax #", dataKey: "fax" },
  ];
  const fetchVendor = () => {
    setIsLoading(true);
    Api.Vendors.list()
      .then((data) => {
        if (data.data && data.data.vendors) {
          setVendors(data.data.vendors);
        }
      })
      .catch((err) => {
        const error = Api.getError(err);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };
  React.useEffect(() => {
    fetchVendor();
  }, []);

  const [opensidePanel, setOpensidePanel] = React.useState(false);
  const [vendorDetails, setVendorDetails] = React.useState({});
  const breadcrumb = [
    {
      key: 1,
      title: "Home",
      link: "/app/claims",
    },
    {
      key: 2,
      title: "Vendors",
      link: "",
    },
  ];

  const confirmDelete = (selectedVendors) => {
    setShowDeleteConfirmation(true);
    setDeleteSelectedVendor(selectedVendors);
  };
  const deleteVendor = (confirm) => {
    if (confirm) {
      Api.Vendors.delete({
        id: deleteSelectedVendor.id,
      })
        .then(() => {
          fetchVendor();
        })
        .catch(() => {
          // catch error is
        })
        .finally(() => {
          setDeleteSelectedVendor({});
          setShowDeleteConfirmation(false);
        });
    }
    if (!confirm) {
      setShowDeleteConfirmation(false);
    }
  };

  const saveAsPdf = () => {
    return DownloadPDF({
      data: vendors,
      headers: downloadPDFHeaders,
      filename: "vendors.pdf",
    });
  };

  return (
    <div>
      {<Pagebar breadcrumbs={breadcrumb} />}
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              Vendors
            </span>
          </div>
          <div className="actions"></div>
        </div>
        <DeleteSweetAlertConfirmation
          show={showDeleteConfirmation}
          clickHandler={deleteVendor}
        />
        <NewAndEditVendors
          openPanel={opensidePanel}
          setOpenPanel={setOpensidePanel}
          vendorDetails={vendorDetails}
          reloadTable={fetchVendor}
        />
        <div className="portlet-body">
          <div className="table-toolbar">
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group">
                  <button
                    className="btn btn-outline blue-primary add_new"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpensidePanel(true);
                      setVendorDetails({});
                    }}
                  >
                    Add New <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="btn-group pull-right">
                  <Dropdown title="Tools" variant="red">
                    <Dropdown.ListItem
                      onClick={() => {
                        return false;
                      }}
                    >
                      <DownloadImage
                        Data={vendors}
                        headers={downloadPDFHeaders}
                        TableName="Vendors"
                      />
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={saveAsPdf}>
                      Save as PDF
                    </Dropdown.ListItem>
                    <Dropdown.ListItem
                      onlyLI={
                        <DownloadCSV
                          data={vendors}
                          filename="vendors.csv"
                          headers={downloadCSVHeaders}
                          target="_blank"
                        />
                      }
                      onClick={() => {
                        return false;
                      }}
                    ></Dropdown.ListItem>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="table-scrollable">
            <VendorDatatable
              vendors={vendors}
              isLoading={isLoading}
              error={error}
              setOpensidePanel={setOpensidePanel}
              setVendorDetails={setVendorDetails}
              confirmDelete={confirmDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
