import { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";

import * as Api from "../../../../Api";
import { SharedClaim } from "../../../../api/sharedClaim";

const claimRoutePaths = {
    shareView: "/shared-claim/:shareToken",
    new: "/claims/new",
    edit: "/claims/:id",
};

export function useClaimNotesData({
    claimId,
    setIsLoading,
    setOpenPanel,
    setShowDeleteConfirmation
}) {   

    const { path } = useRouteMatch();
    const isSharedView = path === claimRoutePaths.shareView;

    const [notes, setNotes] = useState([]);
    const [noteDetails, setNoteDetails] = useState({});
    const [deleteNoteId, setDeleteNoteId] = useState(0);

    const getShareClaimNote = useCallback(() => {
        SharedClaim.getNotes().then((data) =>{
            setNotes(data.data);
        }).catch((err) => {
            console.error("Getting issue in loading all Notes.");
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    },[setIsLoading]);

    const getAllNotes = useCallback(() => {
        Api.Claims.Notes.getForClaims({
            id: claimId
        }).then((data) =>{
            setNotes(data.data);
        }).catch((err) => {
            Api.getError(err);
            console.error("Getting issue in loading all Notes.");
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [claimId, setIsLoading]);

    const reloadNotes = () => {
        getAllNotes();
    }

    const EditNote = ({
        note,
    }) => {
        setNoteDetails(note);
        setOpenPanel(true);
    }

    const DeleteNote = (id) => {
        setDeleteNoteId(id);
        setShowDeleteConfirmation(true);
    }

    const deleteConfirm = (check) => {
        setShowDeleteConfirmation(false);
        if(check){
            setIsLoading(true);
            Api.Claims.Notes.delete({
                noteId: deleteNoteId,
                claimId
            }).then((data) =>{
                reloadNotes();
            }).catch((err) => {
                Api.getError(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const addNewNote = () => {
        setNoteDetails({});
        setOpenPanel(true);
    }

    const getInitials = (firstName, lastName) => {
        if (!firstName && !lastName) {
          return "";
        }
        if (firstName && lastName) {
          return firstName[0].toUpperCase() + lastName[0].toUpperCase();
        }
        if (firstName) {
          return firstName[0].toUpperCase();
        }
        if (lastName) {
          return lastName[0].toUpperCase();
        }
    };

    useEffect(() => {
        if(isSharedView){
            getShareClaimNote();
        }
        if(!isSharedView){
            getAllNotes();
        }
    }, [getAllNotes, getShareClaimNote, isSharedView]);

    return {
        isSharedView,
        notes,
        EditNote,
        DeleteNote,
        getInitials,
        noteDetails,
        addNewNote,
        reloadNotes,
        deleteConfirm
    }
}