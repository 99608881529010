import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Portlet } from "../../../_common/portlets/Portlet";
import { ClientType } from "../claim-form/ClientType";
import { ClientContact } from "../claim-form/ClientContact";
import { PropertyAddress } from "../claim-form/PropertyAddress";
import { MailingAddress } from "../claim-form/MailingAddress";
import { Form } from "../../../_common/form/Form";
import { ClaimAdjusters } from "../claim-form/adjusters/ClaimAdjusters";
import { ClaimInfo } from "../claim-form/claim-fields/ClaimInfo";
import "../claim-form/ClaimForm.css";
import { CustomFields } from "../claim-form/custom-fields/CustomFields";


class ComponentToPrint extends React.Component {
  render() {
    return (
        <div style={{width: "100%"}}>
            <Portlet isLoading={false} isBox classColor="light mt-10">
                <Form.SectionHeading>Client Info</Form.SectionHeading>
                <ClientType onSubmit={() => {
                    return false;
                }} />
                <ClientContact />
                <PropertyAddress />
                <MailingAddress />

                <Form.SectionHeading>Claim Info</Form.SectionHeading>
                <ClaimAdjusters />

                <Form.SectionHeading>&nbsp;&nbsp;</Form.SectionHeading>
                <ClaimInfo />

                <CustomFields />
            </Portlet>
        </div>
    );
  }
}
 
const ClaimPrint = ({
    claimId
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
 
  return (
    <div>
        <div style={{display: "none",width: "100%", position:"fixed",left: "0px"}}>
            <ComponentToPrint ref={componentRef} claimId={claimId}/>
        </div>
        <button style={{ 
                        background: "none",
                        border: "none",
                        width: "100%",
                        textAlign: "left"
                    }} onClick={handlePrint}>Print</button>
    </div>
  );
};

export default ClaimPrint;