import React from "react";

import { DatePickerWithPresets } from "../../_common/date-pickers/DatePickerWithPresets";
import { LossAddressFilter } from "./LossAddressFilter";
import { Icon } from "../../_common/Icons";

export function FilterHead({
  adjusters = [],
  lossCities = [],
  lossZipcodes = [],
  insurers = [],
  statusData = [],
  onSearchSubmit,
  fileNumber,
  createdAtFromDate,
  createdAtToDate,
  dolFromDate,
  dolToDate,
  clientName,
  claimNumber,
  assignedAdjusters,
  cities,
  zipcodes,
  street,
  selectedInsurers,
  status,
  setFileNumber,
  setCreatedAtFromDate,
  setCreatedAtToDate,
  setDolFromDate,
  setDolToDate,
  setClientName,
  setClaimNumber,
  setAssignedAdjusters,
  setCities,
  setZipcodes,
  setStreet,
  setSelectedInsurers,
  setStatus,
  onTableFilterResetClick,
  createdAtDateRange,
  setCreatedAtDateRange,
  dateOfLossRange,
  setDateOfLossRange,
}) {
  const handleSearchSubmit = () => {
    onSearchSubmit();
  };

  return (
    <tr role="row" className="filter">
      <td rowSpan="1" colSpan="1"></td>
      <td rowSpan="1" colSpan="1">
        <input
          type="text"
          className="form-control form-filter input-sm"
          value={fileNumber}
          name="fileNumber"
          onChange={(e) => setFileNumber(e.target.value)}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <DatePickerWithPresets
          fromDate={createdAtFromDate}
          setFromDate={setCreatedAtFromDate}
          toDate={createdAtToDate}
          setToDate={setCreatedAtToDate}
          range={createdAtDateRange}
          setRange={setCreatedAtDateRange}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <input
          type="text"
          className="form-control form-filter input-sm margin-bottom-5"
          placeholder="Search By Clients"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <input
          type="text"
          className="form-control form-filter input-sm"
          placeholder="Search By Claim #"
          value={claimNumber}
          onChange={(e) => setClaimNumber(e.target.value)}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <List
          data={adjusters}
          value={assignedAdjusters}
          onChange={setAssignedAdjusters}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <DatePickerWithPresets
          fromDate={dolFromDate}
          setFromDate={setDolFromDate}
          toDate={dolToDate}
          setToDate={setDolToDate}
          range={dateOfLossRange}
          setRange={setDateOfLossRange}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <LossAddressFilter
          cities={lossCities}
          zipcodes={lossZipcodes}
          selectedCities={cities}
          setSelectedCities={setCities}
          selectedZipcodes={zipcodes}
          setSelectedZipcodes={setZipcodes}
          street={street}
          setStreet={setStreet}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <List
          data={insurers}
          value={selectedInsurers}
          onChange={setSelectedInsurers}
        />
      </td>
      <td rowSpan="1" colSpan="1">
        <StatusList data={statusData} value={status} onChange={setStatus} />
      </td>
      <td rowSpan="1" colSpan="1">
        <div className="margin-bottom-5">
          <button
            onClick={handleSearchSubmit}
            className="btn btn-sm blue-primary btn-outline filter-submit margin-bottom"
          >
            <Icon.Search /> Search
          </button>
        </div>
        <button
          onClick={onTableFilterResetClick}
          className="btn btn-sm red btn-outline filter-cancel"
        >
          <Icon.Times /> Reset
        </button>
      </td>
    </tr>
  );
}

function StatusList({ data, value, onChange }) {
  const hasData = data && data.length;

  if (!hasData) {
    return null;
  }

  const handleChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    onChange(selectedValues);
  };

  return (
    <select
      multiple="multiple"
      className="form-control form-filter input-sm"
      value={value}
      onChange={handleChange}
    >
      <option value="">Select...</option>
      {data.map((item, index) => (
        <option key={index} value={item.statusName}>
          {item.statusName}
        </option>
      ))}
    </select>
  );
}

function List({ data, value, onChange }) {
  const hasData = data && data.length;

  if (!hasData) {
    return null;
  }

  const handleChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    onChange(selectedValues);
  };

  return (
    <select
      multiple="multiple"
      className="form-control form-filter input-sm"
      value={value}
      onChange={handleChange}
    >
      <option value="">Select...</option>
      {data.map((item, index) => (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );
}
