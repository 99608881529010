import { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";

import * as Api from "../../../../Api";
import {SharedClaim} from "../../../../api/sharedClaim";

const claimRoutePaths = {
    shareView: "/shared-claim/:shareToken",
    new: "/claims/new",
    edit: "/claims/:id",
};

export function useClaimFollowupReminderData({
    claimId,
    setIsLoading,
    setShowDeleteConfirmation,
    setOpenPanel,
    addFollowupReminder,
    setAddFollowupReminder
}) {
    const { path } = useRouteMatch();
    const isSharedView = path === claimRoutePaths.shareView;

    const [reminderDetails, setReminderDetails] = useState({});
    const [reminders, setReminders] = useState([]);
    const [deleteReminderId, setDeleteReminderId] = useState(0);

    const editReminder = ({
        reminder
    }) => {
        setReminderDetails(reminder);
        setOpenPanel(true);
    }

    const addNewReminder = useCallback(() => {
        setReminderDetails({});
        setOpenPanel(true);
    },[setOpenPanel])

    const deleteReminder = ({
        reminder
    }) => {
        setDeleteReminderId(reminder.id);
        setShowDeleteConfirmation(true);
    }

    const deleteConfirm = (check) => {
        setShowDeleteConfirmation(false);
        if(check){
            setIsLoading(true);
            Api.Claims.Reminders.delete({
                reminderId: deleteReminderId,
                claimId
            }).then((data) =>{
                reloadReminders();
            }).catch((err) => {
                console.error("Getting issue in Deleting Reminders.");
                console.error(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const getSharedClaimReminders = useCallback(() => {
        SharedClaim.getReminders()
        .then((data) =>{
            setReminders(data.data);
        }).catch((err) => {
            console.error("Getting issue in loading all Reminders.");
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    },[setIsLoading]);

    const getAllReminders = useCallback(() => {
        Api.Claims.Reminders.get({
            claimId
        }).then((data) =>{
            setReminders(data.data);
        }).catch((err) => {
            console.error("Getting issue in loading all Reminders.");
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [claimId, setIsLoading]);

    const reloadReminders = () => {
        getAllReminders();
    }

    useEffect(() => {
        if(isSharedView){
            getSharedClaimReminders();
        }
        if(!isSharedView){
            getAllReminders();
        }

        if(addFollowupReminder){
            addNewReminder();
            setAddFollowupReminder(false);
        }
    }, [addFollowupReminder, addNewReminder, getAllReminders, getSharedClaimReminders, isSharedView, setAddFollowupReminder]);

    return {
        isSharedView,
        reminders,
        editReminder,
        deleteReminder,
        deleteConfirm,
        reloadReminders,
        reminderDetails,
        addNewReminder
    }

}
