import React from "react";
import { PopupModal } from "../_common/PopupModal";

export function SubscriptionModal({ openPanel, setOpenPanel, userType }) {

  const pricing = (
    <div className="tw-border-2 tw-bg-[#F7F7F7] tw-border-[#C3C5C9] tw-rounded-lg tw-p-3 tw-m-3 tw-text-[#2D3643]">
      <h2 className="tw-text-xl tw-text-center tw-font-bold tw-mb-3" >
          How Our Pricing Works
      </h2>
      <div className="tw-flex tw-flex-row">
          <div className="tw-basis-1/2 tw-text-center tw-border-r tw-border-[#2D3643]">
              <svg className="tw-h-8 tw-mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
              <p className="tw-text-[#397F38] tw-text-xl tw-mt-2">$45/month</p>
              <p className="tw-text-sm">for first user</p>
          </div>
          <div className="tw-basis-1/2 tw-text-center tw-border-l tw-border-[#2D3643]">
              <svg className="tw-h-8 tw-mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>
              <p className="tw-text-[#397F38] tw-text-xl tw-mt-2">+ $20/month</p>
              <p className="tw-text-sm">per additional user</p>
          </div>
      </div>
      <p className="tw-text-center tw-mt-3">
          <svg className="tw-h-4 tw-inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
          Unlimited Claim Files</p>
    </div>
  )

  return (
    <div>
      <PopupModal isOpen={openPanel}>
        <div
          className="modal-content"
          style={{ background: "#eff1f4", borderRadius: "10px!important" }}
        >
          <div className="modal-body">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "22px",
                fontFamily: "'Lato',sans-serif",
              }}
            >
              Claim Limit Reached
            </p>
            <p
              style={{
                fontFamily: "Nunito",
                maxWidth: 500,
                textAlign: "center",
              }}
            >
              {userType === "Adjuster" ? (
                <>
                  You cannot add any more claims without a subscription. For
                  unlimited claim files, please request your account`s
                  Superadmin to subscribe now.{" "}
                </>
              ) : (
                <>
                  You cannot add any more claims without a subscription. For
                  unlimited claim files, subscribe now.
                </>
              )}
            </p>
            <div className="row">
              <div className="col-xs-12 text-center">
                {pricing}
              </div>
            </div>
          </div>
          <div
            className="modal-footer text-center"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              borderTop: "none",
              paddingBottom: 60,
            }}
          >
            {userType === "Adjuster" ? (
              <button
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setOpenPanel(false);
                  return false;
                }}
                class="btn btn-warning btn-lg margin-top-10 text-dark"
                style={{ width: "300px" }}
              >
                Close
              </button>
            ) : (
              <>
                <a
                  href="/app/billing"
                  className="btn btn-outline blue-primary"
                  style={{
                    width: "300px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Start Subscription
                </a>
                <a
                  href="!#"
                  onClick={(event) => {
                    event.preventDefault();
                    setOpenPanel(false);
                    return false;
                  }}
                  className="btn btn-link"
                >
                  Not Now
                </a>
              </>
            )}
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
