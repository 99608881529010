import React, { useEffect } from "react";

import { useVerifyAccount } from "./useVerifyAccount.hooks";
import { Error } from "../../_common/Error";
import { SuccessMessage } from "../../_common/SuccessMessage";
import { Spinner } from "../../_common/Spinner";

export function VerifyAccount({ match }) {
  const {
    isLoading,
    successMessage,
    error,
    handleVerification,
  } = useVerifyAccount();

  const { token } = match.params;

  useEffect(() => {
    handleVerification(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  function handleLoginClick() {
    window.location.href = "/app/login";
  }
  return (
    <div>
      <h2>Verify Account</h2>
      <div style={{ marginTop: 100 }}>
        {isLoading && <Spinner isLoading={isLoading} />}
        {error && <Error message={error} />}
        {successMessage && (
          <>
            <SuccessMessage message={successMessage} />
            <div className="" style={{ marginTop: "1em" }}>
              <button
                onClick={handleLoginClick}
                className="btn dark btn-block btn-lg"
                type="submit"
              >
                Go to Login
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
