import React, {useEffect, useState} from 'react';

import jpgPng from '../../../../assets/images/client-edit/jpg.png';

import {FullPageSpinner} from '../../../_common/Spinner';
import {AttachmentTabTopSection} from './AttachmentTabTopSection';
import {useAttachments} from './AttachmentHooks';

export function EstimatesTab({claimId, folderId, folderName, selectedFiles, setSelectedFiles, isSharedView}) {
  const [allFilesId, setAllFilesId] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const {
    folderFiles, uploadFiles, uploadOnAws, changeStatusOfFile, deleteFiles, downloadFile, reloadFolderFiles, moveFiles
  } = useAttachments({
    claimId, folderId, selectedFiles, setSelectedFiles, setIsloading, isSharedView
  });

  function selectAll(evt) {
    if (evt.target.checked) {
      setSelectedFiles(folderFiles.map((f) => f.id));
    }
    if (!evt.target.checked) {
      setSelectedFiles([]);
    }
  }

  function handleCheckbox(id) {
    return evt => {
      const allSelected = [...selectedFiles];
      if (evt.target.checked) {
        allSelected.push(id);
        setSelectedFiles(allSelected);
      }
      if (!evt.target.checked) {
        const index = allSelected.indexOf(id);
        if (index > -1) {
          allSelected.splice(index, 1);
        }
        setSelectedFiles(allSelected);
      }
    };
  }

  function openFile(url) {
    window.open(url, '_blank');
  }

  useEffect(() => {
    setAllFilesId(folderFiles.map(f => f.id));
  }, [folderFiles]);

  const allFilesChecked = (allFilesId.length > 0 && allFilesId.sort().join(',') === selectedFiles.sort().join(','));
  return (
    <div>
      {isLoading && <FullPageSpinner/>}
      {
        !isSharedView && (
          <AttachmentTabTopSection folderId={folderId} claimId={claimId} selectedFiles={selectedFiles}
                                   isLoading={isLoading} uploadFiles={uploadFiles} uploadOnAws={uploadOnAws}
                                   changeStatusOfFile={changeStatusOfFile} deleteFiles={deleteFiles}
                                   downloadFile={downloadFile} setIsloading={setIsloading}
                                   reloadFolderFiles={reloadFolderFiles} moveFiles={moveFiles} folderName={folderName}/>
        )
      }
      <table className="table table-striped table-bordered table-hover table-checkable order-column dataTable">
        <thead>
        <tr>
          <th>
            <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
              <input className="form-control" style={{height: '18px'}} type="checkbox" checked={allFilesChecked}
                     onChange={selectAll}/>
              <span/>
            </label>
          </th>
          <th>Name</th>
          <th>Uploaded By</th>
          <th>Uploaded On</th>
        </tr>
        </thead>
        {
          (folderFiles.length === 0)
          && (<tbody>
          <tr>
            <td colSpan={4}>
              <div>
                All Your {folderName}'s Files will show here. Drop file above to add a new file.
              </div>
            </td>
          </tr>
          </tbody>)
        }
        {
          (folderFiles.length > 0)
          && (<tbody>
          {
            folderFiles.map((v) => {
              const fileChecked = (selectedFiles.indexOf(v.id) > -1);
              return (
                <tr key={v.id}>
                  <td>
                    <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
                      <input className="form-control" style={{height: '18px'}} type="checkbox" checked={fileChecked}
                             value={v.id} onChange={handleCheckbox(v.id)}/>
                      <span/>
                    </label>
                    <label/>
                  </td>
                  <td>
                    <img src={jpgPng} alt="Name" style={{verticalAlign: 'middle'}}/>
                    <a style={{color: '#61615c', textDecoration: 'none'}} href="#!" title={v.originalName}
                       onClick={() => openFile(v.signedUrl)}>
                      {v.originalName}
                    </a>
                  </td>
                  <td>{v.username}</td>
                  <td>{v.formatedDate}</td>
                </tr>
              );
            })
          }
          </tbody>)
        }
      </table>
    </div>
  );
}
