import {useCallback, useEffect, useState} from 'react';

import * as Api from '../../../../Api';
import config from '../../../../config';

export function useShareClaimHook({claimId, isSharedView}) {
  const {appUrl} = config;
  const [shareClaimToken, setShareClaimToken] = useState('');
  const [shareLink, setShareLink] = useState('');

  const getCliamShareToken = useCallback(() => {
    Api.Claims.Share.getToken({claimId})
      .then((data) => {
        setShareClaimToken(data.data.shareClaimToken);
        setShareLink(`${appUrl}/shared-claim/${data.data.shareClaimToken}`);
      }).catch((err) => {
      console.log(err);
    });
  }, [appUrl, claimId]);

  useEffect(() => {
    if (!isSharedView) {
      getCliamShareToken();
    }

  }, [getCliamShareToken, isSharedView]);

  return {shareLink, shareClaimToken};
}
