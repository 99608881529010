import { ClaimTypes } from "./index";

export const INITIAL_DUPLICATE_CLIENTS_STATE = {
  isLoading: false,
  list: [],
  allowDuplicateStatus: ClaimTypes.ALLOW_DUPLICATE_STATUSES.UNSPECIFIED,
  hasFetched: false,
  checkedForParams: {
    clientType: "",
    businessName: "",
    client1FirstName: "",
    client1LastName: "",
  },
};

export const INITIAL_CLAIM_STATE = {
  client: {
    type: ClaimTypes.CLIENT_TYPES.residence,
    businessName: "",
    residence: {
      client1: {
        title: "",
        firstName: "",
        lastName: "",
      },
      client2: {
        title: "",
        firstName: "",
        lastName: "",
      },
    },
    contact: {
      primary: {
        email: "",
        phone: "",
        phoneExt: "",
        workPhone: "",
        workPhoneExt: "",
        mobile: "",
        other: "",
      },
      secondary: {
        email: "",
        phone: "",
        phoneExt: "",
      },
    },
    property: {
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      lat: "",
      lng: "",
      unitNumber: "",
    },
    isMailingSameAsProperty: true,
  },
  customFields: [],
};

const INITIAL_CLAIM_ADJUSTERS = {
  list: [{}],
  unsaved: [],
  isLoading: false,
  isDeleting: false,
  lastSavedData: [],
  error: {
    code: "",
    message: "",
    description: "",
  },
};

export const INITIAL_STATE = {
  claims: {
    data: [],
    isLoading: false,
  },
  claim: {
    isNew: false,
    isSharedView: false,
    shareToken: null,
    id: "",
    isSaveAndClose: false,
    isFormSubmitted: false,
    data: INITIAL_CLAIM_STATE,
    isLoading: false,
    isSaving: false,
    isCreating: false,
    hasUnsavedChanges: false,
    claimAdjusters: INITIAL_CLAIM_ADJUSTERS,
    duplicateClients: INITIAL_DUPLICATE_CLIENTS_STATE,
    lastSavedAt: "",
    lastSavedData: { ...INITIAL_CLAIM_STATE },
  },
};
