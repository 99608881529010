import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Error} from '../_common/Error';
import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {PhoneNumberMask} from '../_common/MaskedInput';

import {SubmitButton} from '../profile/SubmitButton';

function validateEmail(email) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const INITIAL_ADJUSTER_STATE = {name:'', email:'', status:'', phone:'', phoneExt:'', fax:'', license:''};

export function AddNewAndEdit({openPanel, setOpenPanel, adjusterDetails, reloadTable}) {
  const [adjusterState, setAdjusterState] = useState(adjusterDetails || INITIAL_ADJUSTER_STATE)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {setAdjusterState(adjusterDetails || INITIAL_ADJUSTER_STATE)}, [setAdjusterState, adjusterDetails]);

  const {
    name = '',
    email = '',
    status = '',
    phone = '',
    phoneExt = '',
    fax = '',
    license = ''
  } = adjusterState;

  async function handleSubmit(event) {
    event.preventDefault();
    if (!name) {
      setError('Name is required');
      return false;
    }
    if (!email) {
      setError('Email is required');
      return false;
    }
    if (!validateEmail(email)) {
      setError('Email Should be valid.');
      return false;
    }
    if (status === '') {
      setError('Status is required');
      return false;
    }
    const adjusterData = {name, email, phone, phoneExt, fax, license, status: status.toLowerCase()};
    setIsLoading(true);
    try {
      if (adjusterDetails.id) {
        await Api.Adjusters.edit({id: adjusterDetails.id, adjuster: adjusterData});
      } else {
        await Api.Adjusters.create({adjuster: adjusterData});
      }
      reloadTable();
      setOpenPanel(false);
      setError('');
    } catch (err) {
      if (err.response
        && err.response.data
        && err.response.data.errors[0]
        && err.response.data.errors[0].msg) {
        setIsLoading(false);
        setError(err.response.data.errors[0].msg);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleUpdate(field) {
    return ({target}) =>
      setAdjusterState({...adjusterState, [field]: target.value});
  }

  function closePanel(e) {
    e.preventDefault();
    setOpenPanel(false);
    setError('');
  }

  const caption = adjusterDetails.id ? 'Edit Adjuster Details' : 'Add New Adjuster';
  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-sdark uppercase">
                {caption}
              </span>
            </div>
            <div className="tools">
              <a href="#!" className="remove" data-original-title="" title="" onClick={closePanel}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error}/> : ''}

            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Adjuster Name</label>
                <input type="text" className="form-control" value={name} name="adjusterName" placeholder="Adjuster Name"
                       onChange={handleUpdate('name')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Email Address</label>
                <input type="text" className="form-control" value={email} name="adjusterEmail"
                       placeholder="Email Address" onChange={handleUpdate('email')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Phone #</label>
                <PhoneNumberMask placeholderText="Phone #" value={phone} handleChange={handleUpdate('phone')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Extension</label>
                <input type="text" className="form-control" value={phoneExt} name="adjusterExtension"
                       placeholder="Extension" onChange={handleUpdate('phoneExt')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Fax #</label>
                <PhoneNumberMask placeholderText="Fax #" value={fax} handleChange={handleUpdate('fax')}/>
              </div>
              <div className="form-group">
                <label className="control-label"> License Number #</label>
                <input type="text" className="form-control" value={license} name="adjusterFax"
                       placeholder=" License Number #" onChange={handleUpdate('license')}/>
              </div>
              <div className="form-group">
                <label className="control-label">Status</label>
                <select className="form-control" value={status} data-testid="adjuster-status"
                        onChange={handleUpdate('status')}>
                  <option value="">--SELECT--</option>
                  <option value="Allow Login">Allow Login</option>
                  <option value="Don't Allow Login">Don't Allow Login</option>
                </select>
              </div>
              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">
                    <SubmitButton isLoading={isLoading}/>

                    <button style={{margin: 5}} type="button" className="btn default" name="cancel" value="cancel"
                            onClick={closePanel}>
                      <i className="fa fa-times"/> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
