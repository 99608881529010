import React from "react";

import "./Status.css";

export function Status({ name, statusColor }) {
  if(statusColor){
    return <span className="label label-sm" style={{backgroundColor : statusColor}}>{name}</span>;
  }
  return <span className={`label label-sm label-${name.toLowerCase()}`}>{name}</span>;
}
