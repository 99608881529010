import React from "react";
import { useSelector } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { Row, Col } from "../../../../_common/layout/Layout";
import { CurrencyMask } from "../../../../_common/MaskedInput";

export const FeeScheduleItem = ({
  feeSchedule,
  isShowAdd,
  isShowDelete,
  onChange,
  adjusters,
  onAddClick,
  onDeleteClick,
  index,
}) => {
  const { userId, sharePercentage } = feeSchedule || {};
  const { isSharedView } = useSelector((state) => state.claims.claim);

  return (
    <Row style={{ marginBottom: 0 }}>
      <Col md={6}>
        <Form.Group style={{ marginBottom: 0 }}>
          <Form.Label>Adjuster/Admin Fee: Choose One</Form.Label>
          <Form.Select
            value={userId}
            onChange={(e) =>
              onChange({
                name: "userId",
                value: e.target.value,
              })
            }
            disabled={isSharedView}
            data-testid={`adjuster-admin-fee-${index + 1}`}
          >
            <option value="">--Select--</option>
            {adjusters?.map((a) => (
              <option key={a.id} value={a.id}>
                {a.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={5}>
        <Form.Group>
          <Form.Label>Fee Share %</Form.Label>
          <Form.InputGroup>
            <CurrencyMask
              className="form-control"
              placeholder="0"
              value={sharePercentage}
              handleChange={(e) =>
                onChange({
                  name: "sharePercentage",
                  value: e.target.value,
                })
              }
              disabled={isSharedView}
              data-testid={`fee-share-perc-${index + 1}`}
            />
            <Form.InputGroupAddon>%</Form.InputGroupAddon>
          </Form.InputGroup>
        </Form.Group>
      </Col>
      <Col md={1}>
        <Form.Label>&nbsp;&nbsp;&nbsp;</Form.Label>
        {!isSharedView && isShowAdd && (
          <button
            type="button"
            className="btn btn-success"
            style={{ marginLeft: "-15px" }}
            onClick={onAddClick}
            disabled={isSharedView}
            data-testid="add-adjuster-admin-fee-row"
          >
            <i className="fa fa-plus"></i>
          </button>
        )}
        {!isSharedView && isShowDelete && (
          <button
            type="button"
            className="btn btn-danger"
            style={{ marginLeft: "-15px" }}
            onClick={onDeleteClick}
            disabled={isSharedView}
            data-testid={`delete-adjuster-admin-fee-row-${index + 1}`}
          >
            <i className="fa fa-minus"></i>
          </button>
        )}
      </Col>
    </Row>
  );
};
