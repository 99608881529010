import React, {useEffect, useState} from 'react';
import {MultiSelect} from 'react-multi-select-component';

import {Form} from './form/Form';

export default function MultiSelectDropDown(
  {handleChange, label, options, optionsLabelField, optionsValueField, selectedFields = [], disabled, dataTestId}
) {
  const [selected, setSelected] = useState([]);

  const hasSelectedFields = selectedFields?.filter((item) => item).length;
  useEffect(() => {
    if (hasSelectedFields === 0) {
      return;
    }
    if (hasSelectedFields > 0) {
      const newFormaatedSelectedField = selectedFields.filter((x) => {
        return x !== undefined;
      });
      const formattedData = newFormaatedSelectedField?.map((item) => ({
        ...item,
        label: optionsLabelField ? item[optionsLabelField] : '',
        value: optionsValueField ? item[optionsValueField] : ''
      }));

      setSelected(formattedData);
    }
  }, [selectedFields, optionsLabelField, optionsValueField, hasSelectedFields]);

  const newOptions = options?.map((o, v) => {
    const newO = o;
    newO.label = o[optionsLabelField];
    newO.value = o[optionsValueField];
    return newO;
  });

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      {disabled ? (
        <Form.Select value={selected} disabled={true}>
          {newOptions?.map((option) => (
            <option key={option.id}>{`${option.firstName} ${option.lastName}`}</option>
          ))}
        </Form.Select>
      ) : (
        <MultiSelect
          options={newOptions}
          value={selected}
          onChange={(data) => {
            setSelected(data);
            handleChange(data);
          }}
          labelledBy={dataTestId}
          className={`multiSelectDropdown ${disabled ? 'multi-drop-down-disabled' : ''}`}
          disabled={disabled}
        />
      )}
    </Form.Group>
  );
}
