import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SelectTitle } from "./SelectTitle";
import { Form } from "../../../_common/form/Form";
import { Button } from "../../../_common/buttons/Button";
import { CLIENT_TYPES as Types } from "../claim.hooks";
import { ClaimActions, ClaimInitialState } from "../../../../store/claims";
import { DuplicateClientAlert } from "./DuplicateClientAlert";

const detectClient2 = (client2) => {
  const { title, firstName, lastName } = client2 || {};
  const hasAny = Boolean(title || firstName || lastName);
  return hasAny;
};

const AddSecondClient = ({ setIsShowClient2, isSharedView }) => {
  if (isSharedView) return null;
  return (
    <Form.Group>
      <div className="row">
        <div className="col-md-12">
          <Button variant="light" onClick={() => setIsShowClient2(true)}>
            <i className="fa fa-plus"></i> Add Second Client
          </Button>
        </div>
      </div>
    </Form.Group>
  );
};

export const ClientType = ({ onSubmit }) => {
  const client = useSelector((state) => state.claims.claim?.data?.client);
  const { isSharedView } = useSelector((state) => state.claims.claim);

  const { client2 } = client?.residence;
  const hasClient2 = detectClient2(client2);

  const [isShowClient2, setIsShowClient2] = useState(hasClient2);

  useEffect(() => {
    if (hasClient2) {
      setIsShowClient2(true);
    }
  }, [hasClient2]);

  const { SetOnClaim } = ClaimActions;
  const { type, businessName, residence } = client;

  const dispatch = useDispatch();
  const setClient1 = (data) => dispatch(ClaimActions.SetOnClaim.client1(data));
  const setClient2 = (data) => dispatch(ClaimActions.SetOnClaim.client2(data));
  const setClient = (data) => dispatch(SetOnClaim.client(data));

  const handleClient2Delete = () => {
    Object.entries(ClaimInitialState.INITIAL_CLAIM_STATE.client.residence.client2).forEach(
      (entry) =>
        setClient2({
          name: entry[0],
          value: entry[1],
        })
    );

    setIsShowClient2(false);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label>Choose Type</Form.Label>
        <Form.Select
          value={type}
          onChange={(e) => {
            const { value } = e.target;
            setClient({
              name: "type",
              value,
            });
          }}
          disabled={isSharedView}
          data-testid="client-type"
        >
          <option value="" disabled>
            Choose Type
          </option>
          <option value={Types.residence}>Residence</option>
          <option value={Types.business}>Business</option>
        </Form.Select>
      </Form.Group>

      {type === Types.business && (
        <Form.Group>
          <Form.Label>Business Name</Form.Label>
          <Form.Input
            type="text"
            value={businessName}
            onChange={(e) =>
              setClient({
                name: "businessName",
                value: e.target.value,
              })
            }
            data-testid="business-name"
            placeholder="Business Name"
            disabled={isSharedView}
          />
        </Form.Group>
      )}

      {type === Types.residence && (
        <>
          <Form.Group>
            <div className="row">
              <div className="col-md-2">
                <SelectTitle
                  value={residence?.client1?.title}
                  handleChange={(e) =>
                    setClient1({
                      name: "title",
                      value: e.target.value,
                    })
                  }
                  disabled={isSharedView}
                  data-testid="client-title"
                />
              </div>
              <div className="col-md-5">
                <Form.Label>First Name</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="First Name"
                  value={residence?.client1?.firstName}
                  onChange={(e) =>
                    setClient1({
                      name: "firstName",
                      value: e.target.value,
                    })
                  }
                  disabled={isSharedView}
                  data-testid="client-first-name"
                />
              </div>

              <div className="col-md-5">
                <Form.Label>Last Name</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Last Name"
                  value={residence?.client1?.lastName}
                  onChange={(e) =>
                    setClient1({
                      name: "lastName",
                      value: e.target.value,
                    })
                  }
                  disabled={isSharedView}
                  data-testid="client-last-name"
                />
              </div>
            </div>
          </Form.Group>

          {!isShowClient2 && (
            <AddSecondClient setIsShowClient2={setIsShowClient2} isSharedView={isSharedView} />
          )}

          {isShowClient2 && (
            <Form.Group>
              <div className="row">
                <div className="col-md-2">
                  <SelectTitle
                    value={residence?.client2?.title}
                    handleChange={(e) =>
                      setClient2({
                        name: "title",
                        value: e.target.value,
                      })
                    }
                    disabled={isSharedView}
                    dataTestId="select-title-2"
                  />
                </div>

                <div className="col-md-5">
                  <Form.Label>First Name</Form.Label>
                  <Form.Input
                    type="text"
                    placeholder="First Name"
                    value={residence?.client2?.firstName}
                    onChange={(e) =>
                      setClient2({
                        name: "firstName",
                        value: e.target.value,
                      })
                    }
                    disabled={isSharedView}
                    data-testid="client-first-name-2"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Input
                    type="text"
                    placeholder="Last Name"
                    value={residence?.client2?.lastName}
                    onChange={(e) =>
                      setClient2({
                        name: "lastName",
                        value: e.target.value,
                      })
                    }
                    disabled={isSharedView}
                    data-testid="client-last-name-2"
                  />
                </div>

                <div className="col-md-1" style={{ textAlign: "right" }}>
                  <Button
                    onClick={handleClient2Delete}
                    variant="danger"
                    style={{ marginTop: "25px" }}
                    disabled={isSharedView}
                    data-testid="delete-client-2"
                  >
                    <i className="fa fa-close"></i>
                  </Button>
                </div>
              </div>
            </Form.Group>
          )}
        </>
      )}

      <DuplicateClientAlert onSubmit={onSubmit} />
    </div>
  );
};
