import { useState, useEffect } from 'react';
import { company } from './companyCamApi';

const useCompanyCamAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const checkLogin = async () => {
      try {
        const companyResponse = await company.get();
        if (isMounted) {
          if (companyResponse.data?.id) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        }
      } catch (e) {
        if (isMounted) {
          console.log(e);
          setIsLoggedIn(false);
        }
      }
    };

    checkLogin();

    return () => {
      isMounted = false;
    };
  }, []);

  return isLoggedIn;
};

export default useCompanyCamAuth;