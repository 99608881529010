import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';

import * as Api from '../../Api';

import BillingContext from './BillingContext';
import useClaimTableQueryParams from './useClaimTableQueryParams';

import {Dropdown} from '../_common/Dropdown';
import {Icon} from '../_common/Icons';
import {Button} from '../_common/Button';
import PrintClaim from './printClaims';
import ClaimsPdf from './claimsPdf';
import {SubscriptionModal} from '../_common/showSubscriptionModal';

export function ToolBar({setOpenArchiveClaims, selectedArchiveClaims, setArchivedError, claims, setFullPageSpinner}) {

  const history = useHistory();
  const {billingStatus, userType, loading, error} = useContext(BillingContext);
  const [openPanel, setOpenPanel] = useState(false);
  const [query] = useClaimTableQueryParams();

  const disableClaims = loading || !!error;
  const {sortBy, orderBy, ...searchParams} = query;

  function newClaim() {
    if (loading || error || !billingStatus) {
      return;
    }
    const {allowToAddNewClaim} = billingStatus;

    if (allowToAddNewClaim) {
      history.push('/claims/new');
    } else {
      setOpenPanel(true);
    }
  }

  function ImportClaims() {
    if (loading || error || !billingStatus) {
      return;
    }

    const {allowToAddNewClaim} = billingStatus;
    if (allowToAddNewClaim) {
      history.push('/claims/import');
    } else {
      setOpenPanel(true);
    }
  }

  function handleArchiveClick() {
    if (selectedArchiveClaims.length > 0) {
      setOpenArchiveClaims(true);
    }
    if (selectedArchiveClaims.length === 0) {
      setArchivedError('Please first select file to make archive. ');
    }
  }

  function saveAsPdf() {
    return ClaimsPdf({data: claims});
  }

  async function exportAllMyClaimFiles() {
    try {
      setFullPageSpinner(true);
      const data = await Api.Claims.exports({requestData: {}});
      window.location.href = data.data.downloadUrl;
    } catch (err) {
      /* intentionally empty */
    } finally {
      setFullPageSpinner(false);
    }
  }

  async function exportToClaims() {
    setFullPageSpinner(true);
    const requestData = {
      filter: {
        ...searchParams,
        adjustersId: (searchParams.assignedAdjusters || []).join(','),
        city: searchParams.cities,
        claimNumber: searchParams.claimNumber,
        fromDate: searchParams.createdAtFromDate,
        insurerCompanyName: searchParams.insurers,
        isArchive: searchParams.isIncludeArchives ? 'yes' : '',
        isAttorney: searchParams.isIncludeAttorneyFiles ? 'yes' : '',
        lastName: searchParams.clientNameStartsWithLetter
          ? searchParams.clientNameStartsWithLetter
          : searchParams.clientName,
        lossFromDate: searchParams.dolFromDate,
        lossToDate: searchParams.dolToDate,
        status: searchParams.status,
        streetName: searchParams.street,
        toDate: searchParams.createdAtToDate,
        zip: searchParams.zipcodes || []
      },
      orderBy: {
        colomnName: sortBy,
        orderByFormat: orderBy
      }
    };

    try {
      const {data} = await Api.Claims.exports({requestData});
      window.location.href = data.downloadUrl;
    } catch (err) {
      /* intentionally empty */
    } finally {
      setFullPageSpinner(false);
    }
  }


  return (
    <div className="portlet-title">
      <div className="caption">
        <span className="caption-subject font-dark sbold uppercase">
          Claims
        </span>
      </div>
      <div className="actions">
        <Button classes="margin-right-10" variant="blue-primary" disabled={disableClaims} isOutline
                onClick={newClaim}>
          <Icon.Plus/> Add New Claim
        </Button>
        <Button classes="margin-right-10" variant="blue-primary" disabled={disableClaims} isOutline
                onClick={ImportClaims}>
          <Icon.Upload/> Import Claims via Excel
        </Button>

        <Dropdown title="Tools" variant="red">
          <Dropdown.ListItem onClick={() => false}>
            <PrintClaim data={claims}/>
          </Dropdown.ListItem>
          <Dropdown.ListItem onClick={saveAsPdf}>
            Save as PDF
          </Dropdown.ListItem>
          <Dropdown.ListItem onClick={exportAllMyClaimFiles}>
            Export All My Client Files to Excel
          </Dropdown.ListItem>
          <Dropdown.ListItem onClick={exportToClaims}>
            Export to Excel
          </Dropdown.ListItem>
          <Dropdown.ListItem onClick={handleArchiveClick}>
            Archive
          </Dropdown.ListItem>
        </Dropdown>
        <SubscriptionModal openPanel={openPanel} userType={userType} setOpenPanel={setOpenPanel}/>
      </div>
    </div>
  );
}
