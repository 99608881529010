import React, { useEffect } from "react";
import styled from "styled-components";

import { DatePicker } from "./DatePicker";
import { Colors } from "../../../colors";
import { ranges, getChosenDates } from "./DatePicker.utils";

const Cancel = styled.div`
  font-size: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${Colors.primary};
  }
`;

export function DatePickerWithPresets({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  range,
  setRange,
}) {
  useEffect(() => {
    if (range && range !== ranges.custom) {
      const dates = getChosenDates({ range }) || {};
      setFromDate(dates.fromDate);
      setToDate(dates.toDate);
    }
  }, [range, setFromDate, setToDate]);

  const showPickers = range === ranges.custom;

  const setRangeForDropdown  = (value) => {
    if(value === ranges.custom){
      setFromDate("");
      setToDate("");
    }
    setRange(value);
  }

  if (!showPickers) {
    return (
      <select
        className="form-control form-filter input-sm"
        name="range"
        onChange={(e) => setRangeForDropdown(e.target.value)}
        value={range}
      >
        <option value="">Select...</option>
        <option value={ranges.today}>Today</option>
        <option value={ranges.yesterday}>Yesterday</option>
        <option value={ranges.last7Days}>Last 7 Days</option>
        <option value={ranges.last30Days}>Last 30 Days</option>
        <option value={ranges.thisMonth}>This Month</option>
        <option value={ranges.lastMonth}>Last Month</option>
        <option value={ranges.custom}>Custom Range</option>
      </select>
    );
  }

  return (
    <>
      <DatePicker
        date={fromDate}
        onChange={(date) => setFromDate(date)}
        placeholder="From"
      />
      <DatePicker
        date={toDate}
        onChange={(date) => setToDate(date)}
        placeholder="To"
      />
      <Cancel onClick={() => setRange("")}>Cancel</Cancel>
    </>
  );
}
