import React from 'react';
import { useSelector } from 'react-redux';
import ccLogo from '../../../assets/images/company-cam-logo.png';
import config from '../../../config';

export function CompanyCamLogin() {
    const { REACT_APP_COMPANY_CAM_CLIENT_ID } = process.env;
    const { appUrl } = config;

    const { id: claimId } = useSelector((state) => state.claims.claim);

    const companyCamLogin = () => {
        const url = 'https://app.companycam.com/oauth/authorize';
        const recirectUri = `${appUrl}/app/integrations/company-cam${(claimId) ? '?claim=' + claimId : ''}`;
        const encodedRedirectUri = encodeURIComponent(recirectUri);
        const responseType = 'code';
        const scope = encodeURIComponent('read write destroy');
        window.location.href = `${url}?client_id=${REACT_APP_COMPANY_CAM_CLIENT_ID}&redirect_uri=${encodedRedirectUri}&response_type=${responseType}&scope=${scope}`;
    };

    return (
        <button className="tw-transition tw-bg-[#0967d2] tw-rounded-[4px] tw-text-white tw-px-[10px] tw-py-[4px] tw-text-[13px] tw-mx-[2px] tw-flex tw-content-center tw-relative tw-mr-4 
                hover:tw-bg-[#085cbd]
                after:tw-content-['NEW!'] after:tw-bg-orange-500 after:tw-text-[8px] after:tw-font-bold after:tw-absolute after:-tw-top-1 after:-tw-right-4 after:tw-rounded-full after:tw-py-px after:tw-px-1.5 after:tw-shadow-md"
                onClick={companyCamLogin}><img src={ccLogo} className='tw-w-5 tw-mr-1' alt="CompanyCam Logo" /><span>CompanyCam Login</span></button>
    );
}