import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { ClaimActions } from "../../../../../store/claims";

export const AgentFields = () => {
  const dispatch = useDispatch();

  const { agentName, agentAddress } =
    useSelector((state) => state.claims.claim.data?.claimInfo) || {};

  const { isSharedView } = useSelector((state) => state.claims.claim);

  const onClaimInfoChange = (data) => dispatch(ClaimActions.SetOnClaim.claimInfo(data));

  return (
    <>
      <Form.Group>
        <Form.Label>Agent name</Form.Label>
        <Form.Input
          type="text"
          placeholder="Agent name"
          value={agentName ? agentName : ""}
          onChange={(e) =>
            onClaimInfoChange({
              name: "agentName",
              value: e.target.value,
            })
          }
          disabled={isSharedView}
          data-testid="agent-name"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Agent Address</Form.Label>
        <Form.Input
          type="text"
          placeholder="Agent Address"
          value={agentAddress ? agentAddress : ""}
          onChange={(e) =>
            onClaimInfoChange({
              name: "agentAddress",
              value: e.target.value,
            })
          }
          disabled={isSharedView}
          data-testid="agent-address"
        />
      </Form.Group>
    </>
  );
};
