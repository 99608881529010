import { AttorneyTypes as Types } from "./index";

export const GetAttorney = {
  start: () => ({ type: Types.GET_ATTORNEYS.START }),
  success: (attorneys) => ({
    type: Types.GET_ATTORNEYS.SUCCESS,
    payload: attorneys,
  }),
  error: (err) => ({
    type: Types.GET_ATTORNEYS.ERROR, 
    payload: err 
  }),
};
