import React from "react";

const classes = {
  primary: "btn-info",
  light: "btn-light",
  warning: "warning",
  dark: "dark",
  danger: "red",
  success: "green-meadow",
  default: "default",
};

const sizes = {
  lg: "btn-lg",
  sm: "btn-sm",
};

export const Button = ({
  children,
  variant,
  isOutline,
  size,
  isLink,
  isBlock,
  isLoading,
  ...props
}) => {
  const className = `btn ${classes[variant]} ${
    isOutline ? "btn-outline" : ""
  } ${isLink ? "btn-link" : ""} ${size ? sizes[size] : ""} ${
    isBlock ? "btn-block" : ""
  }`;
  return (
    <button type="button" className={className} {...props}>
      {isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : children}
    </button>
  );
};
