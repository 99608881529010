// API => app
// insurer_published => public
// insurer => customized

export const Insurers = {
  format(insurers) {
    return insurers.map((insurer) => ({
      ...insurer,
      type: insurer.tableName === "insurer_published" ? "public" : "customized",
    }));
  },
  mapForAPI(insurers) {
    return insurers.map((insurer) => ({
      ...insurer,
      type: insurer.type === "public" ? "insurer_published" : "insurer",
    }));
  },
};
