import React from "react";
import { PopupModal } from "../../../_common/PopupModal";
import * as Api from "../../../../Api";
import { Notify } from "../../../../services/Notify.service";

export const ArchiveClaim = ({
  claimId,
  setShowArchiveClaimConfirmation,
  showArchiveClaimConfirmation,
}) => {
  const archivedClaim = () => (evt) => {
    Api.Claims.markArchive({
      claimIds: [claimId],
    })
      .then(() => {
        Notify.success("Archived successfully.");
      })
      .catch(() => {
        Notify.error("Not able to process archive claim request.");
      })
      .finally(() => {
        setTimeout(() => {
          window.location.href = "/app/claims/";
          setShowArchiveClaimConfirmation(false);
        }, 1000);
      });
    evt.stopPropagation();
  };

  if (!showArchiveClaimConfirmation) {
    return null;
  }

  return (
    <PopupModal isOpen={showArchiveClaimConfirmation}>
      <div className="modal-content">
        <div className="portlet box red">
          <div className="portlet-title">
            <div className="caption">
              <i className="fa fa-warning"></i>ARCHIVE THIS FILE?
            </div>
          </div>
          <div className="portlet-body">
            <div
              className="slimScrollDiv"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "auto",
                height: "200px",
              }}
            >
              <div
                className="scroller"
                style={{
                  height: "200px",
                  overflow: "hidden",
                  width: "auto",
                }}
              >
                <div className="task-content">
                  <p>Archiving a file results in the below: </p>
                  <ul>
                    <li>Archiving a file makes it inactive.</li>
                    <li>
                      {" "}
                      You would only be able to access it from the Archives
                      section in Read-Only format.{" "}
                    </li>
                    <li>
                      {" "}
                      In the future if you decide to unarchive a file, it then
                      becomes active and will be included as a chargeable file.
                    </li>
                    <li>
                      Any Attorney or Vendor you've shared this file with will
                      no longer be able to access this file.
                    </li>
                  </ul>
                  <p></p>
                </div>
              </div>
              <div
                className="slimScrollBar"
                style={{
                  background: "rgb(161, 178, 189)",
                  width: "7px",
                  position: "absolute",
                  top: "0px",
                  opacity: "0.4",
                  display: "none",
                  borderRadius: "7px",
                  zIndex: "99",
                  right: "1px",
                  height: "200px",
                }}
              ></div>
              <div
                className="slimScrollRail"
                style={{
                  width: "7px",
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  borderRadius: "7px",
                  background: "yellow",
                  opacity: "0.2",
                  zIndex: "90",
                  right: "1px",
                  display: "none",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            borderTop: "1px transparent",
            paddingTop: "0px",
          }}
        >
          <button type="button" className="btn red" onClick={archivedClaim()}>
            Yes, archive it!
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => setShowArchiveClaimConfirmation(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </PopupModal>
  );
};
