import React, {useState} from 'react';
import {useAsyncDebounce, useGlobalFilter, useSortBy, useTable} from 'react-table';

import * as Api from '../../Api';
import {WarningMessage} from '../_common/Sweetalert';
import {Error} from '../_common/Error';
import {FullPageSpinner} from '../_common/Spinner';

function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {setGlobalFilter(value || undefined);}, 200);

  return (
    <span>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        type="search"
        className="form-control input-sm input-small input-inline"
      />
    </span>
  );
}

function InsurersDatatable({insurers, isLoading, error, setOpensidePanel, setInsurerDetails, confirmDelete}) {
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const [isAllowDelete, setIsAllowDelete] = useState(false);
  const [linkedClaim, setLinkedClaim] = useState(0);

  async function checkLinked(insurerData) {
    try {
      const data = await Api.Insurers.isLinkedWithClaim({insurerId: insurerData.id});
      if (data.data.canDelete) {
        confirmDelete(insurerData);
      }

      if (!data.data.canDelete) {
        setIsAllowDelete(true);
        setLinkedClaim(data.data.totalAssignedClaims);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function showEditWarning() {
    setIsAllowEdit(true);
  }

  function allowEdit() {
    setIsAllowEdit(false);
    setOpensidePanel(true);
  }

  const columns = [
    {Header: 'Insurers Name', accessor: 'name', sortType: 'basic'},
    {Header: 'Email Address', accessor: 'email', sortType: 'basic'},
    {Header: 'Street', accessor: 'street', sortType: 'basic'},
    {Header: 'City', accessor: 'city', sortType: 'basic'},
    {Header: 'State', accessor: 'state', sortType: 'basic'},
    {Header: 'Zipcode', accessor: 'zip', sortType: 'basic'},
    {Header: 'Phone #', accessor: 'phone', sortType: 'basic'},
    {Header: 'Extension', accessor: 'extension', sortType: 'basic'},
    {Header: 'Fax #', accessor: 'fax', sortType: 'basic'},
    {
      Header: 'Action',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({row}) => (
        <div>
          <a className="edit" style={{marginRight: '8px', color: '#1bbc9b'}} href="#!"
             onClick={(e) => {
               e.preventDefault();
               showEditWarning(row.original);
               setInsurerDetails(row.original);
             }}>
            <i className="fa fa-pencil"/>
          </a>
          <a className="delete" style={{color: '#e7505a'}} href="#!"
             onClick={(e) => {
               e.preventDefault();
               checkLinked(row.original);
             }}>
            <i className="fa fa-trash-o"/>
          </a>
        </div>
      )
    }
  ];

  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (error) {
    return <Error message={error}/>;
  }

  if (!(insurers && insurers.length)) {
    return (
      <div>
        <h6 style={{textAlign: 'center', fontWeight: 'bold'}}>
          All your Insurers will show here. Use the 'Add New +' button to add an Insurer.
        </h6>
      </div>
    );
  }

  return (
    <div>
      <WarningMessage heading="Are you sure you want to edit this?"
                      message="This will also change the Insurer information shown on the Claims if you have any Claims linked to this Insurer."
                      show={isAllowEdit} cancelHandler={() => setIsAllowEdit(false)} clickHandler={allowEdit}/>

      <WarningMessage type="warning" heading=""
                      message={`You cannot delete this Insurer. This Insurer is linked to ${linkedClaim} Claim. To delete this Insurer, please go to those Claim and unselect this Insurer on those Claim and then come back here to delete it.`}
                      show={isAllowDelete} clickHandler={() => {setIsAllowDelete(false);}}/>

      <InsurersTable insurersData={insurers} columns={columns}/>
    </div>
  );
}

function InsurersTable({insurersData, columns, updateMyData}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({
      columns,
      data: insurersData,
      updateMyData,
      initialState: {sortBy: [{id: 'name', desc: false}]}
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className="dataTables_wrapper no-footer">
      <div className="dataTables_filter">
        <label>
          Search:
          {
            <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter}
                          setGlobalFilter={setGlobalFilter}/>
          }
        </label>
      </div>
      <table
        id="Insurers-Data-Table"
        className="table table-striped table-hover table-bordered dataTable no-footer"
        {...getTableProps()}>
        <thead>
        {
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map((column) => {
                  let columnClass = '';
                  if (!column.disableSortBy) {
                    columnClass = 'sorting';
                    if (column.isSorted) {
                      columnClass = ' sorting_asc';
                      if (column.isSortedDesc) {
                        columnClass = 'sorting_desc';
                      }
                    }
                  }
                  return (
                    <th className={columnClass}{...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  );
                })
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {
                  row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })
                }
              </tr>
            );
          })
        }
        </tbody>
      </table>
    </div>
  );
}

export {InsurersDatatable};
