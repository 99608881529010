import React from "react";

import { Error } from "../../_common/Error";
import { useLogin } from "./useLogin.hooks";

export const Login = ({ goToForgotPassword }) => {
  const {
    isLoggingIn,
    username,
    password,
    error,
    activeAccountError,
    handleChange,
    handleLogin,
    resendVerificationEmail,
    successMessage
  } = useLogin();

  return (
    <>
      <form className="login-form" onSubmit={handleLogin}>
        {error && <Error message={error} />}
        {activeAccountError && 
          <div className="alert alert-danger">
            <strong>Error!</strong> Your account has not been activated. Please check your Email for verification link to activate your account. 
            <br/><button type='button' className='btn red' onClick={resendVerificationEmail} style={{marginTop: "0.5em", marginRight: "1em"}}>Resend Activation Email</button>
          </div> 
        }
        {
          successMessage && 
          <div class="alert alert-success">
            We've sent you an email with the link to activate your account. Please check email <strong>{username}.</strong>
          </div>
        }
        <div className="row">
          <div className="col-xs-12">
            <input
              className="form-control form-control-solid placeholder-no-fix form-group login-input"
              type="email"
              autoComplete="off"
              placeholder="Email"
              name="username"
              required
              value={username}
              onChange={handleChange}
              data-testid="username"
            />
          </div>
          <div className="col-xs-12">
            <input
              className="form-control form-control-solid placeholder-no-fix form-group  login-input"
              type="password"
              autoComplete="off"
              placeholder="Password"
              name="password"
              required
              aria-required="true"
              aria-invalid="false"
              value={password}
              onChange={handleChange}
              data-testid="password"
            />
          </div>
        </div>
        <div className="" style={{ marginTop: "2em" }}>
          <button
            className="btn dark btn-block btn-lg"
            type="submit"
            disabled={isLoggingIn}
            data-testid="login-btn"
          >
            {isLoggingIn ? "Logging In..." : "Sign In"}
          </button>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center" style={{ maginTop: "2em" }}>
            <div className="forgot-password" style={{ marginTop: "2em" }}>
              <button
                onClick={goToForgotPassword}
                className="forget-password"
                type="button"
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
