import React, { useState, useEffect, useCallback } from "react";
import * as Api from "../../../Api";
import { Notify } from "../../../services/Notify.service";
import { FullPageSpinner } from "../../_common/Spinner";
import { Pagebar } from "../../layout/Pagebar";

export function ImportSummary(params) {
  const token = params.match ? params.match.params.id : 0;
  const breadcrumb = [
    {
      title: "Home",
      link: "/app/claims",
    },
    {
      title: " Import Claims ",
      link: "",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [addedTab, setAddedTab] = useState(true);
  const [updateTab, setUpdateTab] = useState(false);
  const [skipedTab, setSkipedTab] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [addedData, setAddedData] = useState([]);
  const [updatedData, setupdatedData] = useState([]);
  const [skipedData, setskipedData] = useState([]);
  const resetTab = () => {
    setUpdateTab(false);
    setAddedTab(false);
    setSkipedTab(false);
  };

  const getImportSummary = useCallback(() => {
    setIsLoading(true);
    Api.Claims.Import.summary({
      token,
    })
      .then((data) => {
        setFileName(data.data.importSummary.fileName);
        setTotalRecords(data.data.importSummary.total);
        setAddedData(data.data.importSummary.added);
        setupdatedData(data.data.importSummary.updated);
        setskipedData(data.data.importSummary.skipped);
      })
      .catch((err) => {
        Notify.error("Error in saving file. Please wait!");
      })
      .finally(() => setIsLoading(false));
  }, [token]);

  useEffect(() => {
    getImportSummary();
  }, [getImportSummary]);

  return (
    <div>
      {isLoading && <FullPageSpinner />}
      {<Pagebar breadcrumbs={breadcrumb} />}
      <h1 className="page-title">Import Summary</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption">
                <i className="fa fa-upload"></i>Import Summary
              </div>
            </div>
            <div className="portlet-body form">
              <div>
                <label
                  className="col-md-12 control-label"
                  style={{
                    textAlign: "left",
                    paddingBottom: "12px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <h2>Import Summary of {fileName}</h2>
                </label>
              </div>
              <div
                className="row"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                <div style={{ paddingLeft: "25px" }}>
                  <p
                    style={{
                      fontSize: "20px",
                      lineHeight: "3",
                      color: "#615f5f",
                    }}
                  >
                    Total Records in the file
                    <span style={{ color: "#000000", paddingLeft: "5px" }}>
                      {totalRecords}
                    </span>
                  </p>
                  <hr style={{ marginTop: "0px" }} />
                  <p></p>
                  {!skipedTab && (
                    <p style={{ fontSize: "20px", color: "rgb(97, 95, 95)" }}>
                      We found some data not matching the field property
                      requirements. So we added the data for such cells in the{" "}
                      <strong>Notes</strong> section. You can go to this section
                      by opening any Claim File and going to the{" "}
                      <strong>Notes</strong> tab.
                    </p>
                  )}
                </div>
                <div className="col-md-8">
                  <div className="actions">
                    <div
                      className="btn-group btn-group-devided"
                      style={{ padding: "8px 19px 8px 19px" }}
                    >
                      <label
                        onClick={() => {
                          resetTab();
                          setAddedTab(true);
                        }}
                        className={
                          addedTab
                            ? "btn dark btn-outline btn-circle btn-sm  active"
                            : "btn dark btn-outline btn-circle btn-sm "
                        }
                        style={{
                          borderRadius: "4px!important",
                          padding: "8px 19px 8px 19px",
                        }}
                      >
                        {addedData.length} Added Records
                      </label>
                      <label
                        onClick={() => {
                          resetTab();
                          setUpdateTab(true);
                        }}
                        className={
                          updateTab
                            ? "btn dark btn-outline btn-circle btn-sm  active"
                            : "btn dark btn-outline btn-circle btn-sm "
                        }
                        style={{
                          borderRadius: "4px!important",
                          padding: "8px 19px 8px 19px",
                        }}
                      >
                        {updatedData.length} Updated Records
                      </label>
                      <label
                        onClick={() => {
                          resetTab();
                          setSkipedTab(true);
                        }}
                        className={
                          skipedTab
                            ? "btn dark btn-outline btn-circle btn-sm  active"
                            : "btn dark btn-outline btn-circle btn-sm "
                        }
                        style={{
                          borderRadius: "4px!important",
                          padding: "8px 19px 8px 19px",
                        }}
                      >
                        {skipedData.length} Skipped Records
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
              {addedTab && (
                <div>
                  <div
                    className="table-scrollable"
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <table className="table table-striped table-bordered table-advance table-hover">
                      <thead>
                        <tr>
                          <th>Row Number</th>
                          <th>Client Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      {addedData.length > 0 && (
                        <tbody>
                          {addedData.map((a, i) => {
                            return (
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  {a.rowNumber}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {a.name}
                                </td>
                                <td>{a.email}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                      {addedData.length === 0 && (
                        <tbody>
                          <tr>
                            <td colSpan="3">No added record found</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
              {updateTab && (
                <div>
                  <div
                    className="table-scrollable"
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <table className="table table-striped table-bordered table-advance table-hover">
                      <thead>
                        <tr>
                          <th>Row Number</th>
                          <th>Client Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      {updatedData.length > 0 && (
                        <tbody>
                          {updatedData.map((a, i) => {
                            return (
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  {a.rowNumber}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {a.name}
                                </td>
                                <td>{a.email}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                      {updatedData.length === 0 && (
                        <tbody>
                          <tr>
                            <td colSpan="3">No update record found</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
              {skipedTab && (
                <div>
                  <div style={{ paddingLeft: "15px" }}>
                    <p style={{ color: "#F44336" }}>
                      Check the root-cause for error below, Please correct and
                      import them again
                    </p>
                  </div>
                  <div
                    className="table-scrollable"
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    <table className="table table-striped table-bordered table-advance table-hover">
                      <thead>
                        <tr>
                          <th>Row Number</th>
                          <th>Client Name</th>
                          <th>Email</th>
                          <th>Error </th>
                        </tr>
                      </thead>
                      {skipedData.length > 0 && (
                        <tbody>
                          {skipedData.map((e, i) => {
                            return (
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  {e.rowNumber}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {e.name}
                                </td>
                                <td>{e.email}</td>
                                <td>{e.message}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                      {skipedData.length === 0 && (
                        <tbody>
                          <tr>
                            <td colSpan="3">No Error record found</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
              <form className="form-horizontal" action="#">
                <div className="form-actions"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
