import React from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../_common/Icons";
import { Status } from "./Status";

export function TableRow({
  claim,
  selectedArchiveClaims,
  setSelectedArchiveClaims,
}) {
  let history = useHistory();
  if (!claim) {
    return null;
  }

  const hadleCheckboxChange = (event, value) => {
    let check = event.target.checked;
    let archivedClaimsArray = [...selectedArchiveClaims];
    if (check) {
      archivedClaimsArray.push(value);
      setSelectedArchiveClaims(archivedClaimsArray);
    } else {
      let index = archivedClaimsArray.indexOf(value);
      if (index > -1) {
        archivedClaimsArray.splice(index, 1);
        setSelectedArchiveClaims(archivedClaimsArray);
      }
    }
  };

  const redirectOnClientEdit = (id) => {
    history.push(`/claims/${id}`);
  };
  const {
    id,
    fileNumber,
    createdAt,
    name,
    clientPhone,
    claimNumber,
    dateOfLoss,
    adjusterNames,
    insAdjuster1Name,
    status,
    adjusterClaimPerc,
    propertyAddress,
    contractFee,
    insurer,
    statusColor,
    acolor,
  } = claim;

  return (
    <tr
      role="row"
      className="odd"
      style={{ cursor: "pointer", backgroundColor: acolor }}
      onClick={() => redirectOnClientEdit(id)}
    >
      <td>
        <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
          <input
            type="checkbox"
            className={`checkboxes ${selectedArchiveClaims.indexOf(id)}`}
            checked={selectedArchiveClaims.indexOf(id) > -1 ? true : false}
            value={id}
            onChange={(event) => hadleCheckboxChange(event, id)}
          />
          <span></span>
        </label>
      </td>
      <td className="RedirectWithoutPreview sorting_1">{fileNumber}</td>
      <td className=" RedirectWithoutPreview">{createdAt}</td>
      <td className=" RedirectWithoutPreview">
        {name}
        {clientPhone && (
          <span>
            <br /> <Icon.Phone classes="font-grey-cascade" /> {clientPhone}{" "}
          </span>
        )}
        {claimNumber && (
          <span>
            <br />
            <Icon.InfoCircle classes="font-grey-cascade" /> {claimNumber}
          </span>
        )}
      </td>
      <td className=" RedirectWithoutPreview">
        <span
          id="avatar_div_inner_1"
          style={{ float: "left", paddingTop: "2%" }}
        >
          <span style={{ fontWeight: "bold" }}>
            {adjusterNames}
            <br />
          </span>
        </span>
      </td>
      <td className=" RedirectWithoutPreview">{dateOfLoss}</td>
      <td className=" RedirectWithoutPreview">
        {propertyAddress && (
          <span>
            <Icon.MapPin classes="font-grey-cascade" /> {propertyAddress}{" "}
          </span>
        )}
      </td>
      <td className=" RedirectWithoutPreview">
        <span style={{ fontWeight: "bold" }}>{insurer.name}</span>
        <br />
        {insAdjuster1Name && (
          <span>
            <Icon.Phone classes="font-grey-cascade" /> {insAdjuster1Name}{" "}
          </span>
        )}
        {adjusterClaimPerc && (
          <span>
            <br />{" "}
            <Icon.PieChart classes="font-grey-cascade" aria-hidden="true" />{" "}
            Adjuster Claim %: {adjusterClaimPerc}
          </span>
        )}
        {contractFee && (
          <span>
            <br /> <Icon.Money classes="font-grey-cascade" aria-hidden="true" />{" "}
            Fee Schedule: {contractFee}
          </span>
        )}
      </td>
      <td className=" RedirectWithoutPreview">
        <Status name={status} statusColor={statusColor} />
      </td>
      <td>
        <a
          href="#!"
          onClick={() => redirectOnClientEdit(id)}
          className="btn btn-sm btn-outline grey-salsa"
        >
          <i className="fa fa-search"></i> View
        </a>
      </td>
    </tr>
  );
}
