import React from "react";

import { Header } from "../layout/Header";
import "./Error.css";

export const Error = ({
  code = "500",
  message = "Oops! Something went wrong.",
  description = "We are fixing it! Please come back in a while.",
}) => {
  return (
    <div class="error-container">
      <Header />
      <div class="row">
        <div class="col-md-12 page-500">
          <div class=" number font-red"> {code} </div>
          <div class=" details">
            <h3>{message}</h3>
            <p>
              {" "}
              {description}
              <br />{" "}
            </p>
            <p>
              <a href="/" class="btn red btn-outline">
                {" "}
                Return home{" "}
              </a>
              <br />{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
