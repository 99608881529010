import {
  useQueryParams,
  StringParam,
  NumberParam,
  BooleanParam,
  encodeDelimitedArray,
  decodeDelimitedArray,
  encodeDelimitedNumericArray,
  decodeDelimitedNumericArray,
  withDefault
} from 'use-query-params';

const CommaArrayParam = {
  encode(value) {
    return value && encodeDelimitedArray(value);
  },

  decode(value) {
    return value && decodeDelimitedArray(value);
  }
};

const NumericCommaArrayParam = {
  encode(value) {
    return value && encodeDelimitedNumericArray(value);
  },

  decode(value) {
    return value && decodeDelimitedNumericArray(value);
  }
};

const PARAM_CONFIG_MAP = {
  fileNumber: NumberParam,
  clientName: StringParam,
  claimNumber: StringParam,
  assignedAdjusters: CommaArrayParam,
  cities: CommaArrayParam,
  zipcodes: CommaArrayParam,
  street: StringParam,
  insurers: NumericCommaArrayParam,
  status: CommaArrayParam,
  createdAtFromDate: StringParam,
  createdAtToDate: StringParam,
  dolFromDate: StringParam,
  dolToDate: StringParam,
  clientNameStartsWithLetter: StringParam,
  isIncludeArchives: withDefault(BooleanParam, false),
  sortBy: withDefault(StringParam, 'fileNumber'),
  orderBy: withDefault(StringParam, 'desc'),
  limitPerPage: withDefault(NumberParam, 20),
  currentPage: withDefault(NumberParam, 1)
};

export default function useClaimTableQueryParams() {

  return useQueryParams(PARAM_CONFIG_MAP);
}