import React from "react";
import { Notify } from "../../../services/Notify.service";

export function OAMappingFields({ 
    mappedData,
    setMappedData,
    oaFields,
    fileField,
}){
    const mapAFieldsWithOa = (value) => {

        const result = oaFields.filter(obj => {
            return obj.id.toString() === value.toString()
        });
        const isMappedToOther = mappedData.filter(obj => obj.id === value);
        
        if(isMappedToOther.length > 0){
            Notify.error(`The OA field ${isMappedToOther[0].name} is already mapped to another field of your file.`);
            return false;
        }
        
        const mappedDataArray = [...mappedData];
        const index = mappedDataArray.findIndex(obj => obj.mappedWith === fileField);
        if (index > -1 && value === "") {
            mappedDataArray.splice(index, 1);
            setMappedData(mappedDataArray);
        }
        
        if(index === -1 && value !== ""){
            const currentValue = result[0];
            currentValue.mappedWith = fileField;
            mappedDataArray.push(currentValue);
            setMappedData(mappedDataArray);
        }
    };
    
    
    return (
  
        <select  className="form-control" onChange={(e) => {
            mapAFieldsWithOa(e.target.value);
        }}>
            <option value=""> -- Select Field Type -- </option>
            {oaFields.map((o,i) => {
                return (
                    <option key={`oaOptionFields-${i}`} value={o.id}> {o.name}</option>
                )
            })}
        </select>
    );
}