import React from "react";

import { Row, Col } from "../../../../_common/layout/Layout";
import { ClaimFieldsLeft } from "./ClaimFieldsLeft";
import { ClaimFieldsRight } from "./ClaimFieldsRight";

export const ClaimInfo = () => {
  return (
    <Row>
      <Col md={6}>
        <ClaimFieldsLeft />
      </Col>
      <Col md={6}>
        <ClaimFieldsRight />
      </Col>
    </Row>
  );
};
