import { useState } from "react";

import * as Api from "../../../Api";

export function useVerifyAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState("");

  const handleVerification = async (token) => {
    setError("");

    if (!token) {
      setError("Verification Token missing!");
      return;
    }

    try {
      setIsLoading(true);

      const {
        data: { msg: message },
      } = await Api.Auth.verifyAccount({ token });

      setSuccessMessage(message);
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    successMessage,
    error,
    handleVerification,
  };
}
