import React, {useEffect} from 'react'

import { GoogleMap,Marker } from '@react-google-maps/api';
 
const containerStyle = {
  width: '100%',
  height: '100%'
};
 
const GoogleMapContainer = ({
  lat,
  lng,
}) => {
  const center = {
    lat,
    lng
  };
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null)
  
  const options = {
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_CENTER 
    },
    zoom: 20,
    mapTypeId: 'hybrid'
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const onLoad = React.useCallback((map, maps) => {
    const bounds = new window.google.maps.LatLngBounds();
    if(typeof map !== "undefined"){
      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds);
    }
  }, []);
 
  const onUnmount = React.useCallback((map) => {
    setMap(null)
  }, []);
  
  useEffect(() => {
    onLoad();
  }, [lat, lng, onLoad]);

  return (
    <GoogleMap
        center = {center}
        mapContainerStyle={containerStyle}
        options = {options}
        mapTypeId={'hybrid'}
        onLoad={({ map, maps }) => onLoad(map, maps) }
        onUnmount={onUnmount}
      >
        <Marker position={center}/>
        <></>
      </GoogleMap>
  )
}

export default GoogleMapContainer;