import React, {useState, useEffect} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import _ from 'lodash';

import {LoadingButton} from '../../../_common/Button';
import {SlidingSidePanel} from '../../../_common/SlidingSidePanel';
import {Error} from '../../../_common/Error';
import * as Api from '../../../../Api';

import './NoteEditor.css';

const {REACT_APP_TINYMCE_API_KEY} = process.env;

export function AddAndEditNote({claimId, openPanel, setOpenPanel, NoteDetails, reloadNotes}) {
  const [error, setError] = useState('');
  const [note, setNote] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');

  useEffect(() => {
    setNote(NoteDetails.note ? NoteDetails.note : '');
  }, [NoteDetails]);

  async function saveNote() {
    try {
      setButtonLoading(true);
      if (NoteDetails.id) {
        await Api.Claims.Notes.edit({noteId: NoteDetails.id, requestData: {note}, claimId});
        setOpenPanel(false);
        reloadNotes();
      } else {
        await Api.Claims.Notes.add({requestData: {note}, claimId});
        setOpenPanel(false);
        reloadNotes();
      }
    } catch (err) {
      const error = _.get(err, 'response.data.errors[0].msg', err.message);
      setError(error);
    } finally {
      setButtonLoading(false);
    }
  }

  function handleRemove(e) {
    e.preventDefault();
    setOpenPanel(false);
    setError('');
  }

  function handleCancel(e) {
    e.preventDefault();
    setOpenPanel(false);
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-yellow-casablanca uppercase">
                {NoteDetails.id ? 'Edit Notes' : 'Add New Note'}
              </span>
            </div>
            <div className="tools">
              <a className="remove" href="#!" title="" data-original-title="" onClick={handleRemove}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error}/> : ''}
            <div className="form-group">
              <Editor apiKey={REACT_APP_TINYMCE_API_KEY} initialValue={note}
                      init={{
                        height: 500,
                        menubar: false,
                        contextmenu: false,
                        plugins: [
                          'advlist autolink lists image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],

                        toolbar:
                        // eslint-disable-next-line no-multi-str
                          'undo redo |  formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat'
                      }}
                      onEditorChange={setNote}/>
            </div>
            <div className="form-actions">
              <div className="row">
                <div className="col-md-offset-3 col-md-9">
                  {
                    buttonLoading
                      ? (<LoadingButton/>)
                      : (
                        <button className="btn btn-outline blue-primary" style={{margin: 5}} type="submit"
                                onClick={saveNote}>
                          Submit
                        </button>
                      )
                  }
                  <button className="btn default" style={{margin: 5}} type="button" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
