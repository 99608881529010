import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { Row } from "../../../../_common/layout/Layout";
import { ClaimActions, ClaimUtils } from "../../../../../store/claims";
import { CoverageItem } from "./CoverageItem";

export const CoverageFields = () => {
  const dispatch = useDispatch();

  const coverages =
    useSelector((state) => state.claims.claim.data?.claimInfo?.coverages) || {};

  const setCoverage = (data) =>
    dispatch(
      ClaimActions.SetOnClaim.claimInfo({
        name: "coverages",
        value: {
          ...coverages,
          ...data,
        },
      })
    );

  return (
    <Form.Group style={{ marginBottom: "0px" }}>
      <Row>
        <CoverageItem
          name="A"
          label="Coverage A"
          value={coverages?.A}
          onChange={setCoverage}
        />
        <CoverageItem
          name="B"
          label="Coverage B"
          value={coverages?.B}
          onChange={setCoverage}
        />
      </Row>
      <Row>
        <CoverageItem
          name="C"
          label="Coverage C"
          value={coverages?.C}
          onChange={setCoverage}
        />
        <CoverageItem
          name="D"
          label="Coverage D"
          value={coverages?.D}
          onChange={setCoverage}
        />
      </Row>

      <Row>
        <CoverageItem
          name="total"
          label="Total"
          value={ClaimUtils.getCoverageTotal(coverages)}
          onChange={() => {}}
          md={12}
        />
      </Row>
    </Form.Group>
  );
};
