import React from "react";
import moment from "moment";

import { Form } from "../../../../_common/form/Form";
import {
  CurrencyMask,
  DatePickerMask,
  TimePickerMask,
} from "../../../../_common/MaskedInput";
import { FIELD_TYPES } from "../../../../../store/custom-fields/customFields.constants";
import { formatDate, formatTime } from "../../../../../utils/dateTime";

const Textbox = ({ label, value, onChange, disabled }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Input value={value} onChange={onChange} disabled={disabled} />
    </Form.Group>
  );
};

const Datebox = ({ label, value, onChange, disabled }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <DatePickerMask
        selectedValue={value}
        handleChange={(date) =>
          onChange({ target: { value: formatDate(date, 'MM/DD/YYYY') } })
        }
        placeholderText={"Format as MM/DD/YYYY"}
        disabled={disabled}
      />
    </Form.Group>
  );
};

const Timebox = ({ label, value, onChange, disabled }) => {
  const time = value ? moment(value, "h:m a").toISOString() : "";
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <TimePickerMask
        selectedValue={time}
        handleChange={(date) => {
          onChange({ target: { value: formatTime(date) } });
        }}
        disabled={disabled}
      />
    </Form.Group>
  );
};

const Currencybox = ({ label, value, onChange, disabled }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.InputGroup>
        <Form.InputGroupAddon>$</Form.InputGroupAddon>
        <CurrencyMask
          value={value}
          handleChange={onChange}
          disabled={disabled}
        />
      </Form.InputGroup>
    </Form.Group>
  );
};

const Dropdown = ({ label, value, onChange, options, disabled }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Select value={value} onChange={onChange} disabled={disabled}>
        <option value="">--Select--</option>
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.optionText}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

const Checkbox = ({ label, onChange, options, disabled }) => {
  const hasOptions = options && options.length;
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label> <br />
      {hasOptions ? (
        options.map((option) => (
            <div key={option.id}>
              <Form.Check
                key={option.id}
                checked={option.isSelected}
                onChange={(e) => onChange(e, option.id)}
                label={option.optionText}
                disabled={disabled}
              />
            </div>
        ))
      ) : (
        <small>
          <i>No options configured</i>
        </small>
      )}
    </Form.Group>
  );
};

const KeysToComponentMap = {
  [FIELD_TYPES.textbox]: Textbox,
  [FIELD_TYPES.dropdown]: Dropdown,
  [FIELD_TYPES.checkbox]: Checkbox,
  [FIELD_TYPES.date]: Datebox,
  [FIELD_TYPES.time]: Timebox,
  [FIELD_TYPES.currency]: Currencybox,
};

export const FieldRenderer = ({ type, props }) => {
  const Component = KeysToComponentMap[FIELD_TYPES[type]];

  if (!Component) {
    return <div>This type of component has not been implemented</div>;
  }

  return (
    <>
      <Component {...props} />
    </>
  );
};
