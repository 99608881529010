import React, {useState} from 'react';

import * as Api from '../../Api';

import {SlidingSidePanel} from '../_common/SlidingSidePanel';
import {Notify} from '../../services/Notify.service';
import {Error} from '../_common/Error';
import {SubmitButton} from '../profile/SubmitButton';

export function ManageLabel(
  {
    openManageLabelPanel, setOpenManageLabelPanel, selectedClaimId, labels, reloadClaims, reloadLabels,
    setShowDeleteConfirmation, setSelectedLabel
  }
) {
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [name, setName] = useState('');
  const [labelId, setLabelId] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [error, setError] = useState('');

  function addToClaim({label}) {
    setName(label.name);
    setLabelId(label.id);
    setBgColor(label.bgColor);
  }

  function resetClaim() {
    setName('');
    setLabelId('');
    setBgColor('');
    setIsChanged(false);
    setIsLoading(false);
  }

  async function addToClaims({label}) {
    try {
      await Api.Claims.Label.addToClaim({claimId: selectedClaimId, labelData: label});
      setIsChanged(true);
    } catch (err) {
      /* intentionally empty */
    }
  }

  function onCancel() {
    if (isChanged) {
      reloadClaims();
    }
    resetClaim();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setError('');
    if (name === '') {
      setError('Please enter label name.');
      return false;
    }
    try {
      setIsLoading(true);
      const labelData = {id: labelId, name, bgColor};
      let action;
      if (labelId) {
        action = 'Updated';
        await Api.Claims.Label.update({claimId: labelId, labelData});
      } else {
        action = 'Added';
        await Api.Claims.Label.create({labelData});
      }
      reloadLabels();
      await addToClaims({label: labelData});
      Notify.success(`${action} successfully!`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setOpenManageLabelPanel(false);
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    setOpenManageLabelPanel(false);
    setError('');
    onCancel();
  }

  return (
    <div>
      <SlidingSidePanel type={'right'} isOpen={openManageLabelPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-yellow-casablanca uppercase">
                  CREATE & MANAGE LABELS
              </span>
            </div>
            <div className="tools">
              <a className="remove" href="#!" title="" data-original-title="" onClick={handleCancel}>
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {(error) ? <Error message={error}/> : ''}
            <form onSubmit={handleSubmit} method="post">
              <div className="form-group">
                <label className="control-label">Label Name</label>
                <input type="text" className="form-control" value={name} placeholder="Label Name"
                       onChange={e => setName(e.target.value)}/>
              </div>
              <div className="form-group">
                <label className="control-label">Choose Color</label>
                <input type="color" className="form-control" value={bgColor} placeholder="Background Color"
                       onChange={e => setBgColor(e.target.value)}/>
              </div>
              <br/>
              <br/>
              <div className="row">
                {
                  labels.map((l) => (
                    <div className="col-sm-3" style={{marginTop: '10px'}} key={l.id}>
                                        <span style={{
                                          backgroundColor: l.bgColor,
                                          padding: '2%',
                                          color: 'black',
                                          borderRadius: '5px',
                                          margin: '10px'
                                        }}>
                                            <span style={{cursor: 'pointer'}} onClick={() => {
                                              addToClaim({label: l});
                                            }}>{l.name}</span>
                                            <span onClick={() => {
                                              setSelectedLabel(l);
                                              setShowDeleteConfirmation(true);
                                              setOpenManageLabelPanel(false);
                                            }} style={{marginLeft: '5%', cursor: 'pointer'}}>X</span>
                                        </span>
                    </div>
                  ))
                }
              </div>
              <br/>
              <br/>
              <div className="form-actions">
                <div className="row">
                  <div className="col-md-offset-3 col-md-9">

                    <SubmitButton isLoading={isLoading}/>
                    <button className="btn default" style={{margin: 5}} type="button" name="cancel"
                            value="cancel" onClick={handleCancel}>
                      <i className="fa fa-times"/> Cancel
                    </button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
