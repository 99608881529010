import React from 'react';
import {Multiselect} from 'multiselect-react-dropdown';

import './searchableInput.css';

const STYLES = {
  searchBox: {
    fontSize: 12,
    padding: 2,
    fontWeight: 300,
    borderRadius: 4
  },
  option: {
    fontSize: 12,
    padding: 5,
    textTransform: 'capitalize'
  }
};

export function SearchableInputDropDown({data, value, onChange}) {

  function onChangeSelectedValue(data) {
    onChange(data.map(({id}) => id));
  }

  const selectedValues = data?.filter(d => value?.includes(d.id));

  return (
    <Multiselect style={STYLES} closeIcon="cancel" options={data} selectedValues={selectedValues}
                 displayValue="name" onSelect={onChangeSelectedValue} onRemove={onChangeSelectedValue}/>
  );
}
