import React from "react";
import { PopupModal } from "../../_common/PopupModal";

export function ImportScheduled({ 
    openPanel,
    setOpenPanel,
    token
}){

    const finishImport = () => {
        setOpenPanel(false);
        window.location.href = `/app/claims/import/${token}`;
    }

    return (
        <div>
            <PopupModal isOpen={openPanel}>
                <div className="modal-dialog modal-lg" style={{padding: "0px", margin: "0px"}}>
                    <div className="modal-content">
                        <div className="modal-header" style={{textAlign:"left", padding:"10px 7px 14px 0", backgroundColor: "#32c5d3"}}>
                            <label className="col-md-12 modal-title" style={{textAlign:"center", padding:"12 7 6 8", color:"#ffffff", lineHeight:"2"}}> 
                                <h1><strong>Claim Import Scheduled!</strong></h1>
                                <h3 style={{lineHeight:"2"}}>
                                    It will take a few minutes to complete this import. You will be notified by email when the import is complete.
                                </h3>
                            </label>
                        </div>
                        <div className="modal-footer">
                            <div className="row">                                           
                                <div className="col-md-5"></div>
                                <div className="col-md-1">
                                    <button onClick={() => {
                                        finishImport();
                                    }} type="button" className="btn green" style={{padding: "12px 49px 12px 49px", borderRadius:"4px", marginLeft:"auto", marginRight:"auto", align:"center", fontWeight:"800", fontSize:"24px"}}>
                                        <i className="fa fa-check" style={{paddingRight:"5px"}}></i>
                                    </button>
                                </div>
                                <div className="col-md-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupModal>
        </div>
    )
}