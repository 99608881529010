import React from "react";

export function CustomFieldsOptions({ autopullOptions, setAutopullOptions }) {
    const addNewOption = i => {
        const current = [...autopullOptions];
        current.push({optionText: ""});
        setAutopullOptions(current);
    };

    const removeCurrentOption  =  i => {
        const current = [...autopullOptions];
        current.splice(i, 1);
        setAutopullOptions(current);
    };

    const setOptionName = (i, val) => {
        const current = [...autopullOptions];
        current[i].optionText = val;
        setAutopullOptions(current);
    }

    return (
        <div>
            {
                autopullOptions.map((o,i) => {
                    return (
                        <div className="form-group">
                            <label className="control-label">Option {i+1}</label>
                            <div className="row">
                                <div className="col-md-10 col-sm-10 col-xs-10 ">
                                    <input type="text" className="form-control" placeholder="Option" value={o.optionText} onChange={(e) => setOptionName(i, e.target.value)}/>
                                </div>
                                <div className="col-md-2 col-sm-2 col-xs-2">
                                    {((autopullOptions.length - 1) !== i) && 
                                        <button type="button" className="btn btn-danger" onClick={() => removeCurrentOption(i)}>
                                            <i className="fa fa-close"></i>
                                        </button>
                                    }
                                    {((autopullOptions.length - 1) === i) && 
                                        <button type="button" className="btn btn-success" onClick={() => addNewOption(i)}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}