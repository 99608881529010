import { useState } from "react";

import * as Api from "../../../Api";

export function useForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!email) {
      setError("Email is required");
      return;
    }

    requestNewPassword();
  };

  async function requestNewPassword() {
    try {
      setIsLoading(true);

      await Api.Auth.requestPWReset({ email });

      setEmail("");
      setSuccessMessage(
        `We have sent an email to ${email} with the link to reset your Password. Please check your Inbox.`
      );
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    error,
    email,
    setEmail,
    handleSubmit,
    successMessage,
  };
}
