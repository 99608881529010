import React from "react";

import { useForgotPassword } from "./useForgotPassword";
import { Error } from "../../_common/Error";
import { SuccessMessage } from "../../_common/SuccessMessage";

const ForgotPassword = () => {
  const {
    isLoading,
    email,
    setEmail,
    error,
    handleSubmit,
    successMessage,
  } = useForgotPassword();

  return (
    <form
      style={{ marginTop: 80 }}
      className="forget-form"
      onSubmit={handleSubmit}
    >
      {error && <Error message={error} />}
      {successMessage && <SuccessMessage message={successMessage} />}

      <p> Enter your e-mail address below to reset your password. </p>
      <div className="form-group">
        <input
          className="form-control form-control-solid placeholder-no-fix form-group login-input"
          type="email"
          autoComplete="off"
          placeholder="Email"
          name="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ border: "none" }}
          data-testid="forgot-password-email"
        />
      </div>
      <div className="" style={{ marginTop: "2em" }}>
        <button
          className="btn dark btn-block btn-lg"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Request Password Reset"}
        </button>
      </div>
    </form>
  );
};

export { ForgotPassword };
