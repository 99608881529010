import React from 'react';
import _ from 'lodash';
import LocationAutocomplete from '../../../_common/LocationAutocomplete';
import {Form} from '../../../_common/form/Form';
import {Col, Row} from '../../../_common/layout/Layout';

function stringifyAddress(address = {}) {
  const addr = {...address};
  delete addr.lat;
  delete addr.lng;
  delete addr.unitNumber;
  return _.compact(Object.values(addr)).join(', ');
}

export function AutoAddressFields(
  {address, setAddress, setAddressField, isShowCountry, label, disabled, dataTestIdPrefix = ''}
) {
  const addressString = stringifyAddress(address);

  function setAddrFieldName(name) {
    return ({target}) => {
      setAddressField({name, value: target.value});
    };
  }

  return (
    <>
      <Row>
        <Col md={9}>
          <LocationAutocomplete dataTestIdPrefix={dataTestIdPrefix} label={label} value={addressString}
                                placeholder="Enter a location" disabled={disabled} handleChange={setAddress}/>
        </Col>
        <Col md={3}>
          <Form.Label>&nbsp;&nbsp;&nbsp;</Form.Label>
          <Form.Input data-testid={`${dataTestIdPrefix}-suite`} type="text" value={address?.unitNumber}
                      placeholder="Suite/Apt #" disabled={disabled}
                      onChange={setAddrFieldName('unitNumber')}/>
        </Col>
      </Row>

      <div className="form-group">
        <div className="well">
          <Form.Group>
            <Row>
              <Col md={2}>Street address</Col>
              <Col md={7}>
                <Form.Input data-testid={`${dataTestIdPrefix}-street`} type="text" value={address?.streetAddress1}
                            disabled={disabled} onChange={setAddrFieldName('streetAddress1')}/>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col md={2}>City</Col>
              <Col md={7}>
                <Form.Input data-testid={`${dataTestIdPrefix}-city`} type="text" value={address?.city}
                            disabled={disabled} onChange={setAddrFieldName('city')}/>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col md={2}>State</Col>
              <Col md={2}>
                <Form.Input data-testid={`${dataTestIdPrefix}-state`} type="text" value={address?.state}
                            disabled={disabled} onChange={setAddrFieldName('state')}/>
              </Col>
              <Col md={1}>&nbsp;</Col>
              <Col md={2}>Zip</Col>
              <Col md={2}>
                <Form.Input data-testid={`${dataTestIdPrefix}-zip`} type="text" value={address?.zipcode}
                            disabled={disabled} onChange={setAddrFieldName('zipcode')}/>
              </Col>
            </Row>
          </Form.Group>
          {isShowCountry && (
            <Form.Group>
              <Row>
                <Col md={2}>Country</Col>
                <Col md={7}>
                  <Form.Input data-testid={`${dataTestIdPrefix}-country`} type="text" value={address?.country}
                              disabled={disabled} onChange={setAddrFieldName('country')}/>
                </Col>
              </Row>
            </Form.Group>
          )}
        </div>
      </div>
    </>
  );
}
