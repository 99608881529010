import React, {useCallback, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Geocode from 'react-geocode';

import notShowMap from '../../../../assets/images/client-edit/Map_default_message_with_bg.png';

import * as Api from '../../../../Api';
import {SharedClaim} from '../../../../api/sharedClaim';
import GoogleMapContainer from '../../../_common/GoogleMap';
import {FullPageSpinner} from '../../../_common/Spinner';
import {formatAddress} from '../../ClaimsTable.utils';

const {REACT_APP_GOOGLE_MAPS_API_KEY} = process.env;

const claimRoutePaths = {
  shareView: '/shared-claim/:shareToken',
  new: '/claims/new',
  edit: '/claims/:id'
};

Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_API_KEY);

export function MapTab({claimId}) {
  const {path} = useRouteMatch();
  const isSharedView = path === claimRoutePaths.shareView;
  const [isLoading, setIsloading] = useState(true);
  const [isShowMap, setIsShowMap] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const loadCordinates = useCallback(async () => {

    function formatAddressAndGetLatLong(claimData) {
      if (claimData.data.propertyStreetAddress !== ''
        && claimData.data.propertyCity !== ''
        && claimData.data.propertyState !== '') {
        const address = formatAddress({
          street: claimData.data.propertyStreetAddress,
          city: claimData.data.propertyCity,
          state: claimData.data.propertyState,
          country: (claimData.data.propertyCountry) ? claimData.data.propertyCountry : 'US',
          zip: claimData.data.propertyZip
        });
        Geocode.fromAddress(address)
          .then(response => {
            const {lat, lng} = response.results[0].geometry.location;
            setLat(lat);
            setLng(lng);
            setIsShowMap(true);
            return setIsloading(false);
          }, error => {
            return setIsloading(false);
          });
      }
      return setIsloading(false);
    }

    try {
      if (isSharedView) {
        const claimData = await SharedClaim.getMapCoordinates();
        if (claimData.data.latitude !== '' && claimData.data.longitude !== '') {
          setLat(Number.parseFloat(claimData.data.latitude));
          setLng(Number.parseFloat(claimData.data.longitude));
          setTimeout(() => {
            setIsShowMap(true);
            return setIsloading(false);
          }, 1000);
        }
        if (claimData.data.latitude === '' && claimData.data.longitude === '') {
          return formatAddressAndGetLatLong(claimData);
        }
        setIsloading(false);
      }
      if (!isSharedView) {
        const claimData = await Api.Claims.getMapCoordinates({id: claimId});
        if (claimData.data.latitude !== '' && claimData.data.longitude !== '') {
          setLat(Number.parseFloat(claimData.data.latitude));
          setLng(Number.parseFloat(claimData.data.longitude));
          setIsShowMap(true);
          return setIsloading(false);
        }
        if (claimData.data.latitude === '' && claimData.data.longitude === '') {
          return formatAddressAndGetLatLong(claimData);
        }
        setIsloading(false);
      }
    } catch (err) {
      console.error(err);
      Api.getError(err);
      setIsloading(false);
    }
  }, [claimId, isSharedView]);

  useEffect(() => {loadCordinates();}, [loadCordinates]);

  return (
    <div>
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption">
            <i className="icon-map"/>Map
          </div>
        </div>
        <div className="portlet-body form">
          <div className="form-body" style={{paddingLeft: '20px', paddingRight: '20px'}}>
            {(isLoading) ? <FullPageSpinner/> :
              <div id="map1"
                   style={{
                     width: '100%', height: '800px', textAlign: 'center', margin: '0px auto', position: 'relative',
                     overflow: 'hidden'
                   }}>
                {isShowMap && <GoogleMapContainer lat={lat} lng={lng}/>}
                {!isShowMap && (
                  <img src={notShowMap} alt="Nothing to show here!" height="400"/>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
