import React from "react";
import { Pagebar } from "../../layout/Pagebar";
import ccLinkScreenShot from '../../../assets/images/cc-link-screen-shot.png';
export function CompanyCam() {

    const breadcrumb = [
      {
        title: "Home",
        link: "/app/claims",
      },
      {
        title: "Support",
        link: "/app/support",
      },
    ];
  
    return (
      <div>
        {<Pagebar breadcrumbs={breadcrumb} />}

        <div
          style={{
            background: "white",
            padding: "1rem 3rem",
            paddingBottom: "5rem",
            borderRadius: "4px!important",
            
          }}
          className="well"
        >

          <h1 className="tw-font-bold tw-text-xl tw-mb-4"> 
            <i className="fa fa-support tw-text-gray-500 tw-mr-2.5" ></i>
            CompanyCam Integration
          </h1>

          <p className="tw-mb-12">We're pleased to introduce Organized Adjusting's integration with CompanyCam. 
          You are now able to link OA to your CompanyCam (CC) projects and view your photos and documents in OA.</p>

          <h2 className="tw-font-bold tw-text-lg tw-mb-2">Initial Step – Always Create The Connection:</h2>

          <p className="tw-mb-6">For your first connection, (or if you change windows, logged out, cleared cache, etc.) you will need to make the connection by opening any claim in Organized Adjusting and selecting the CompanyCam Login button (below the address section). If the button says Link CompanyCam Project you are connected.</p>

          <h2 className="tw-font-bold tw-text-lg tw-mb-2">New File</h2>

          <ol className="tw-ml-8 tw-list-decimal tw-mb-6">
            <li className="tw-mb-2"><p className="tw-font-bold">Login to CompanyCam (CC) & Organized Adjusting (OA)</p></li>
            <li className="tw-mb-2"><p className="tw-font-bold">Start file in OA</p></li>
            <li className="tw-mb-2"><p className="tw-font-bold">Complete Name & Address (required for CC)</p></li>
            <li className="tw-mb-2">
              <p className="tw-font-bold">
                Click CompanyCam Button in OA <span className="tw-font-normal">(Below Address on Client Page)</span> &ndash; 
                if connected button should say Link New CompanyCam Project if connected or revert to Initial Step <span className="tw-font-normal">(Below Address on Client Pages)</span>.
              </p>
              <p className="tw-font-bold">Choose New or Existing Option.</p>
              <p className="tw-font-bold">If It&rsquo;s A New File, It Is Now Created in CompanyCam</p>
            </li>
          </ol>

          <h2 className="tw-font-bold tw-text-lg tw-mb-2">Existing File</h2>

          <ol className="tw-ml-8 tw-list-decimal tw-mb-6">
            <li className="tw-mb-2"><p className="tw-font-bold">Login to CompanyCam (CC) & Organized Adjusting (OA) make sure the softwares are connected or revert to Initial Step</p></li>
            <li className="tw-mb-2"><p className="tw-font-bold">Open your client file in OA</p></li>
            <li className="tw-mb-2">
              <p className="tw-font-bold">Click Link CompanyCam Button and Select &ldquo;Link Existing CompanyCam Project&rdquo; The below menu will appear:</p>
              <div className="tw-shadow-lg tw-p-2 tw-rounded-xl tw-w-fit tw-my-2"><img className=" tw-w-[490px]" src={ccLinkScreenShot} alt="Screen capture of Link Existing CompanyCam Project pop-up dialog." /></div>
            </li>
          </ol>

        </div>
      </div>
    );
  }