export const BRAND_NAME = "Organized Adjusting";

const {REACT_APP_ENV, REACT_APP_API_BASE_URL, REACT_APP_URL, REACT_APP_LEGACY_API_URL} = process.env;
const config = {
  env: REACT_APP_ENV,
  apiBaseUrl: REACT_APP_API_BASE_URL,
  appUrl: REACT_APP_URL,
  frontEndLegacyApiURL: REACT_APP_LEGACY_API_URL,
};

export default config;
