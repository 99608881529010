import { useState, useEffect } from "react";
import * as Api from "../../Api";

export function useDocuments() { 
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getFileName = (fileName) => {
        if(fileName){
            const newFileName = fileName.split("/");
            const newFileName2 = newFileName[newFileName.length - 1];
            const newFileName3 = newFileName2.split(".");
            return newFileName3[0];
        }   
        
        return "";
    };

    const reloadDocuments = () => {
        getDocuments();
    };

    const deleteTemplateDocument = (selectedData) => {
        Api.Document.deleteDoc({
            id: selectedData.id
        }).then(() => {
            getDocuments();
        }).catch(() => {

        })
    }

    const getDocuments = () => {
        setIsLoading(true)
        Api.Document.get().then((data) => {
            setDocuments(data.data.documents);
        }).catch(() => {

        }).finally(() => {
            setIsLoading(false);
        })
    };

    useEffect(() => {
        getDocuments();
    }, []);

    return {
        documents,
        getFileName,
        reloadDocuments,
        deleteTemplateDocument,
        setIsLoading,
        isLoading
    }
}