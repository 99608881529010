import { CompanyTypes as Types } from "./index";

export const GetCompanyMembers = {
  start: () => ({ type: Types.GET_COMPANY_MEMBERS.START }),
  success: (members) => ({
    type: Types.GET_COMPANY_MEMBERS.SUCCESS,
    payload: members,
  }),
  error: (err) => ({ type: Types.GET_COMPANY_MEMBERS.ERROR, payload: err }),
};
