import React from "react";
import { useSelector } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { Row, Col } from "../../../../_common/layout/Layout";
import { CurrencyMask, DatePickerMask } from "../../../../_common/MaskedInput";

export const ReceivedItem = ({
  received,
  isShowAdd,
  isShowDelete,
  onChange,
  onAddClick,
  onDeleteClick,
  index,
}) => {
  const { receivedAmount, feeAmount, checkDate } = received || {};
  const { isSharedView } = useSelector((state) => state.claims.claim);

  return (
    <Row style={{ marginBottom: 0 }}>
      <Col md={4}>
        <Form.Group style={{ marginBottom: 0 }}>
          <Form.Label>New $ Received</Form.Label>
          <Form.InputGroup>
            <Form.InputGroupAddon>$</Form.InputGroupAddon>
            <CurrencyMask
              className="form-control"
              placeholder="0"
              value={receivedAmount}
              handleChange={(e) =>
                onChange({
                  name: "receivedAmount",
                  value: e.target.value,
                })
              }
              disabled={isSharedView}
              data-testid={`new-dollar-received-${index + 1}`}
            />
          </Form.InputGroup>
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>Fee Amount</Form.Label>
          <Form.InputGroup>
            <Form.InputGroupAddon>$</Form.InputGroupAddon>
            <CurrencyMask
              className="form-control"
              placeholder="0"
              value={feeAmount}
              handleChange={(e) =>
                onChange({
                  name: "feeAmount",
                  value: e.target.value,
                })
              }
              disabled={isSharedView}
              data-testid={`fee-amount-${index + 1}`}
            />
          </Form.InputGroup>
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Label>Check Date</Form.Label>
        <DatePickerMask
          placeholderText={`Format as MM/DD/YYYY (${index + 1})`}
          selectedValue={checkDate}
          handleChange={(date) =>
            onChange({
              name: "checkDate",
              value: date,
            })
          }
          disabled={isSharedView}
        />
      </Col>
      <Col md={1} style={{ paddingLeft: "0px", marginLeft: "-6px" }}>
        <Form.Label>&nbsp;&nbsp;&nbsp;</Form.Label>
        {!isSharedView && isShowAdd && (
          <button
            onClick={onAddClick}
            type="button"
            className="btn btn-success"
            disabled={isSharedView}
            data-testid="add-new-received-row"
          >
            <i className="fa fa-plus"></i>
          </button>
        )}
        {!isSharedView && isShowDelete && (
          <button
            onClick={onDeleteClick}
            className="btn btn-danger"
            disabled={isSharedView}
            data-testid={`delete-new-received-row-${index + 1}`}
          >
            <i className="fa fa-minus"></i>
          </button>
        )}
      </Col>
    </Row>
  );
};
