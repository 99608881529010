import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Icon } from "../_common/Icons";

class ComponentToPrint extends React.Component {
  render() {
    return (
        <div style={{padding: "15px"}}>
            <div>
                <h2 style={{fontWeight: "bold"}}>{this.props.TableName}</h2>
            </div>
            <table style={{
                border: "1px solid #666",
                width: "98%"
            }}>
                <thead>
                    <tr>
                        {this.props.headers.map((column, ind) => (
                            <th key={ind} style={{
                                "border": "1px solid #e7ecf1"
                            }}>
                                {column.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.props.Data.map((d, i) => (
                        <tr key={i}>
                            {this.props.headers.map((column, ind) => (
                                <td key={ind} style={{
                                "border": "1px solid #e7ecf1"
                                }}>
                                    {(column.dataKey === "clientName")? 
                                        <span>
                                            {d["name"]}
                                            {d["clientPhone"] && <span><br /> <Icon.Phone classes="font-grey-cascade" /> {d["clientPhone"]} </span>}
                                            {d["claimNumber"] && <span><br/><Icon.InfoCircle classes="font-grey-cascade" /> {d["claimNumber"]}</span>}
                                        </span> 
                                        : (
                                            ( column.dataKey === "adjusterNames") ? 
                                                <span style={{ float: "left", paddingTop: "2%" }}>
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {d["adjusterNames"]}
                                                        <br />
                                                    </span>
                                                </span> 
                                            :
                                            (
                                                (column.dataKey === "propertyAddress") ?
                                                    <span>{d["propertyAddress"] && <span><Icon.MapPin classes="font-grey-cascade" />{d["propertyAddress"]}</span>}</span>
                                                : 
                                                    (
                                                        (column.dataKey === "insurerName") ? 
                                                            <span>
                                                                <span style={{ fontWeight: "bold" }}>{d["insurer"]["name"]}</span>
                                                                <br />
                                                                { d["insAdjuster1Name"] && <span><Icon.Phone classes="font-grey-cascade" /> {d["insAdjuster1Name"]} </span>}
                                                                {d["adjusterClaimPerc"] && <span><br /> <Icon.PieChart classes="font-grey-cascade" aria-hidden="true" /> Adjuster Claim %: {d["adjusterClaimPerc"]}</span>}
                                                                {d["contractFee"] && <span><br /> <Icon.Money classes="font-grey-cascade" aria-hidden="true" /> Fee Schedule: {d["contractFee"]}</span>}
                                                            </span> 
                                                        : 
                                                        d[column.dataKey]
                                                    )
                                                )
                                        )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
  }
}

const PrintClaims = ({
    data
}) => {
    const headers = [
        { header: 'File #', dataKey: 'fileNumber' },
        { header: 'Date', dataKey: 'createdAt' },
        { header: 'Client', dataKey: 'clientName' },
        { header: 'Assigned Adjuster', dataKey: 'adjusterNames' },
        { header: 'DOL', dataKey: 'dateOfLoss' },
        { header: 'Loss Address', dataKey: 'propertyAddress' },
        { header: 'Insurer', dataKey: 'insurerName' },
        { header: 'Status', dataKey: 'status' },
    ];
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <div style={{display: "none"}}>
                <ComponentToPrint ref={componentRef} Data={data} headers={headers}/>
            </div>
            <button style={{ 
                            background: "none",
                            border: "none",
                            width: "100%",
                            textAlign: "left"
                        }} onClick={handlePrint}>Print</button>
        </div>
    );
}

export default PrintClaims;