import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ClaimActions as Actions, ClaimUtils} from '../../../../store/claims';

import * as DateTimeUtils from '../../../../utils/dateTime';

import {Button} from '../../../_common/buttons/Button';

import './ClaimActions.css';

export function ClaimActions({onSubmit}) {
  const {
    isSaving,
    isLoading,
    lastSavedAt,
    data,
    lastSavedData,
    isSharedView
  } = useSelector((state) => state.claims.claim);
  const isCheckingDuplicates = useSelector((state) => state.claims.claim.duplicateClients.isLoading);
  const [timeAgo, setTimeAgo] = useState(DateTimeUtils.formatFromNow(lastSavedAt));
  const {isChanged} = ClaimUtils.detectChanges(data, lastSavedData);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeAgo(DateTimeUtils.formatFromNow(lastSavedAt));
  }, [lastSavedAt, setTimeAgo]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(DateTimeUtils.formatFromNow(lastSavedAt));
    }, 1000);

    return () => clearInterval(interval);
  }, [lastSavedAt, setTimeAgo]);

  function handleSaveAndCloseClick() {
    dispatch(Actions.CreateOrUpdateClaim.claimAdjustersStart());
    dispatch(Actions.CreateOrUpdateClaim.setIsSaveAndClose(true));
    onSubmit();
  }

  if (isSharedView) {
    return null;
  }

  return (
    <div className="row margin-bottom-20 claim__actions">
      {isCheckingDuplicates && (
        <span className="claim__actions--saving">
          Checking for Duplicates{' '}
          <i className="fa fa-circle-o-notch fa-spin"/>...
        </span>
      )}
      {isSaving && (
        <span className="claim__actions--saving">
          Saving <i className="fa fa-circle-o-notch fa-spin"/>...
        </span>
      )}
      {isLoading && (
        <span className="claim__actions--loading">
          Loading <i className="fa fa-circle-o-notch fa-spin"/>...
        </span>
      )}
      {!isSaving && isChanged && (
        <span className="claim__actions--last-saved">Unsaved Changes</span>
      )}
      {!isSaving && !isChanged && lastSavedAt && (
        <span className="claim__actions--last-saved">
          Last saved {timeAgo}{' '}
        </span>
      )}
      <Button className="btn blue-primary bold pull-right claim__action" type="submit" disabled={isSaving || isLoading}
              data-testid="save-claim-btn">
        Save
      </Button>
      <Button className="btn blue-primary bold pull-right " type="button" disabled={isSaving || isLoading}
              data-testid="save-and-close-claim-btn" onClick={handleSaveAndCloseClick}>
        Save &amp; Close
      </Button>
    </div>
  );
}
