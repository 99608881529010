import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export function AlertPopup({
  show,
  children,
  title,
  warning,
  showCancel,
  confirmBtnText,
  confirmBtnBsStyle,
  onConfirm,
  onCancel,
  focusCancelBtn,
  confirmBtnCssClass,
  cancelBtnText,
  cancelBtnBsStyle,
  cancelBtnCssClass,
  showCloseButton = true,
}) {
  return (
    <SweetAlert
      title={title || ""}
      show={show}
      warning={warning}
      showCancel={showCancel}
      confirmBtnText={confirmBtnText}
      confirmBtnBsStyle={confirmBtnBsStyle}
      confirmBtnCssClass={confirmBtnCssClass ?? "btn green-meadow"}
      onConfirm={onConfirm}
      onCancel={onCancel}
      focusCancelBtn={focusCancelBtn}
      cancelBtnText={cancelBtnText}
      cancelBtnBsStyle={cancelBtnBsStyle}
      cancelBtnCssClass={cancelBtnCssClass ?? "btn btn-danger"}
      showCloseButton={showCloseButton}
    >
      {children}
    </SweetAlert>
  );
}
