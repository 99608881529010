import React from "react";
import { useRouteMatch } from "react-router-dom";

import { Dropdown } from "../../_common/Dropdown";
import { ClientEditActionButton } from "./ClientEditActionButton/ClientEditActionButton";
import { ClaimGenrateDocument } from "./ClaimGenrateDocument/ClaimGenrateDocument";

const claimRoutePaths = {
  shareView: "/shared-claim/:shareToken",
  new: "/claims/new",
  edit: "/claims/:id",
};

export const ClientEditTopBar = ({ setFollowUpReminder, claimId }) => {
  const { path } = useRouteMatch();
  const isSharedView = path === claimRoutePaths.shareView;

  const manageCustomFields = () => {
    //REDTAG:TJH - replace with react-router navigation
    window.open("/app/custom-options/custom-fields", "_blank");
  };

  const addCustomFields = () => {
    //REDTAG:TJH - replace with react-router navigation
    window.open("/app/custom-options/custom-fields#add-new", "_blank");
  };

  const back = () => {
    //REDTAG:TJH - replace with react-router navigation
    window.location.href = `/app/claims`;
  };

  return (
    <>
      <button type="button" className="btn red btn-outline bold" onClick={back}>
        <i className="fa fa-angle-left"></i>&nbsp;&nbsp; Back
      </button>
      <button
        type="button"
        className="btn blue-primary btn-outline ml-5 mr-5 bold"
        onClick={setFollowUpReminder}
      >
        Set Follow Up Reminder
      </button>

      <Dropdown
        title="Custom Fields"
        clientEdit={true}
        isNotOutline={false}
        variant="blue-primary bold"
      >
        <Dropdown.ListItem onClick={() => manageCustomFields()}>
          Manage Custom Fields
        </Dropdown.ListItem>
        <Dropdown.ListItem onClick={() => addCustomFields()}>
          Add New Custom Field
        </Dropdown.ListItem>
      </Dropdown>

      <ClientEditActionButton claimId={claimId} isSharedView={isSharedView} />

      <ClaimGenrateDocument claimId={claimId} isSharedView={isSharedView} />
    </>
  );
};
