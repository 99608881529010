import React from "react";
import { Link } from "react-router-dom";

export function Page404({ location }) {
  return (
    <div className="container">
      <div className="well mt-30 br-4">
        <h2>
          No match found for <code>{location.pathname}</code>
        </h2>
        <Link to="/" className="btn btn-lg btn-outline blue-primary br-4">
          Go home
        </Link>
      </div>
    </div>
  );
}
