import React from "react";
import Modal from "react-modal";

export function PopupModal({ isOpen, children }) { 
    const customStyles = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0px!important",
            border: "none"
        }
    };
    return (
        <div>
            <Modal
                isOpen={isOpen}
                onAfterOpen={() => {

                }}
                onRequestClose={() => {

                }}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp = {false}
            >
   
                {children}
            </Modal>
        </div>
    );
}
