import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { Form } from "../../../../_common/form/Form";
import { FeeScheduleItem } from "./FeeScheduleItem";
import { ClaimActions, ClaimBuilders } from "../../../../../store/claims";

function getAllAdjusters(members = []) {
  const { adjusters, admins, superadmin } = members;
  const allAdjusters = [
    ...(adjusters ? [...adjusters] : []),
    ...(admins ? [...admins] : []),
    ...(superadmin ? [...superadmin] : []),
  ];

  const sortedAdjusters = _.orderBy(
    allAdjusters,
    [(adjuster) => adjuster.name.toLowerCase()],
    ["asc"]
  );
  return sortedAdjusters;
}

export const FeeSchedule = () => {
  const dispatch = useDispatch();

  const feeSchedule = useSelector((state) => {
    return state.claims.claim.data?.claimInfo?.feeSchedule;
  }) || [{}];

  const members = useSelector((state) => state.company.members?.list) || [];

  const adjusters = getAllAdjusters(members);

  const onClaimInfoChange = (data) =>
    dispatch(
      ClaimActions.SetOnClaim.claimInfo({
        name: "feeSchedule",
        value: data,
      })
    );

  const handleChange = ({ index, name, value }) => {
    const updatedSchedule = feeSchedule.map((schedule, idx) =>
      idx === index
        ? {
            ...schedule,
            [name]: value,
          }
        : schedule
    );

    onClaimInfoChange(updatedSchedule);
  };

  const handleAddClick = () => {
    const newItem = ClaimBuilders.buildFeeScheduleItem();
    onClaimInfoChange([...feeSchedule, newItem]);
  };

  const handleDeleteClick = (idx) => {
    const updatedSchedule = feeSchedule.filter((feeSch, index) => index !== idx);

    onClaimInfoChange(updatedSchedule);
  };

  return (
    <Form.Group style={{ marginBottom: 0 }}>
      {feeSchedule?.map((feeSch, idx) => (
        <FeeScheduleItem
          key={idx}
          feeSchedule={feeSch}
          adjusters={adjusters}
          isShowAdd={idx === 0}
          isShowDelete={idx !== 0}
          onChange={(data) => handleChange({ index: idx, ...data })}
          onAddClick={handleAddClick}
          onDeleteClick={() => handleDeleteClick(idx)}
          index={idx}
        />
      ))}
    </Form.Group>
  );
};
