import { ClaimDataMapper } from "./index";
import { formatDate } from "../../utils/dateTime";
import { removeCommaFromNumber } from "../../utils/numbers";

export const detectChanges = (localData, lastSavedData) => {
  const isSame = JSON.stringify(localData) === JSON.stringify(lastSavedData);

  return isSame ? { isChanged: false } : { isChanged: true };
};

export const getCoverageTotal = (coverages) => {
  const _coverages = { ...coverages };
  delete _coverages.total;
  
  const total = Object.values(_coverages)
    ?.map((c) => removeCommaFromNumber(c))
    ?.map((c) => parseFloat(c))
    .reduce((prev, curr) => {
      if(isNaN(prev)){
        return curr;
      }
      if(isNaN(curr)){
        return prev;
      }
      return prev + curr;
    }, 0);
    
  return total;
};

export const mapCreateOrUpdateClaimData = ({ claim, customFields }) => {
  const {
    client,
    claimInfo = {},
    vendor,
    attorney,
    assignedAdjusters,
    otherAdjustersAccess,
  } = claim;
  return {
    // Client
    clientType: ClaimDataMapper.ClaimFieldMappers.ClientType.mapForApi(
      client.type
    ),
    clientBusinessName: client.businessName,

    // Client 1
    client1Title: client.residence?.client1?.title,
    client1FirstName: client.residence?.client1?.firstName,
    client1LastName: client.residence?.client1?.lastName,

    // Client 2
    client2Title: client.residence?.client2?.title,
    client2FirstName: client.residence?.client2?.firstName,
    client2LastName: client.residence?.client2?.lastName,

    // Contact
    clientEmail: client.contact?.primary?.email,
    clientPhone: client.contact?.primary?.phone,
    clientPhoneExt: client.contact?.primary?.phoneExt,
    clientWorkNumber: client.contact?.primary?.workPhone,
    clientWorkNumberExt: client.contact?.primary?.workPhoneExt,
    clientMobileNumber: client.contact?.primary?.mobile,
    clientOtherNumber: client.contact?.primary?.other,
    secondaryClientEmail: client.contact?.secondary?.email,
    secondaryClientPhone: client.contact?.secondary?.phone,
    secondaryClientPhoneExt: client.contact?.secondary?.phoneExt,

    // Property address
    propertyStreetAddress: client.property?.streetAddress1,
    propertyCity: client.property?.city,
    propertyState: client.property?.state,
    propertyZipcode: client.property?.zipcode,
    propertyCountry: client.property?.country,
    propertyLat: client.property?.lat,
    propertyLng: client.property?.lng,
    propertySuiteOrAptNum: client.property?.unitNumber,

    // Mailing address
    mailingStreetAddress: client.mailing?.streetAddress1,
    mailingCity: client.mailing?.city,
    mailingState: client.mailing?.state,
    mailingZipcode: client.mailing?.zipcode,
    mailingSuiteOrAptNum: client.mailing?.unitNumber,

    // Claim Info
    claimNumber: claimInfo.claimNumber,
    policyNumber: claimInfo.policyNumber,
    dateOfLoss: formatDate(claimInfo.dateOfLoss),
    causesOfLoss: claimInfo.causeOfLoss,
    dateCalledIn: formatDate(claimInfo.dateCalledIn),
    deductible: claimInfo.deductible,
    settlementDate: formatDate(claimInfo.settlementDate),
    status: claimInfo.status,
    policyStartDate: formatDate(claimInfo.policyStartDate, "YYYY-MM-DD"),
    policyEndDate: formatDate(claimInfo.policyEndDate, "YYYY-MM-DD"),
    mortgageName: claimInfo.mortgageName,
    mortgageAddress: claimInfo.mortgageAddress,
    adjusterClaimPerc: claimInfo.adjusterClaimPercentage,
    coverageA: claimInfo.coverages?.A,
    coverageB: claimInfo.coverages?.B,
    coverageC: claimInfo.coverages?.C,
    coverageD: claimInfo.coverages?.D,
    coverageTotal: getCoverageTotal(claimInfo.coverages),
    contractSigned: formatDate(claimInfo.contractSigned),
    previouslyPaid: claimInfo.previouslyPaid,
    newDollarReceived: ClaimDataMapper.ClaimFieldMappers.NewDollarsReceived.mapForApi(
      claimInfo.newDollarReceived
    ),
    adjusterShare: ClaimDataMapper.ClaimFieldMappers.FeeSchedule.mapForApi(
      claimInfo.feeSchedule
    ),
    contractFee: claimInfo.contractFee,
    upcomingAppointmentDate: formatDate(
      claimInfo.upcomingAppointmentDate,
      "MM/DD/YYYY"
    ),
    upcomingAppointmentTime: claimInfo.upcomingAppointmentTime,
    agentName: claimInfo.agentName,
    agentAddress: claimInfo.agentAddress,

    // vendor
    vendorId: vendor?.id,
    vendorFees: vendor?.fees,

    // attorney
    attorney: attorney?.id,

    // Assigned Adjusters
    adjusterAssignedId: ClaimDataMapper.ClaimFieldMappers.AssignedAdjusters.mapForApi(
      assignedAdjusters
    ),

    // Other Adjuster Access
    otherAdjustersAssignedAccess: ClaimDataMapper.ClaimFieldMappers.OtherAdjusterAccess.mapForApi(
      otherAdjustersAccess
    ),

    // Custom Fields
    customFields: ClaimDataMapper.CustomFieldMappers.mapForApi(customFields),
  };
};

function detectIfSameAddress(property, mailing) {
  return (
    property.streetAddress1 === mailing.streetAddress1 &&
    property.city === mailing.city &&
    property.state === mailing.state &&
    property.zipcode === mailing.zipcode &&
    property.unitNumber === mailing.unitNumber
  );
}

export const formatClaimFromApi = (data) => {
  console.log('formatClaimFromApi');
  const property = {
    streetAddress1: data.propertyStreetAddress,
    streetAddress2: "",
    city: data.propertyCity,
    state: data.propertyState,
    zipcode: data.propertyZipcode,
    country: data.propertyCountry,
    lat: data.propertyLat,
    lng: data.propertyLng,
    unitNumber: data.propertySuiteOrAptNum,
  };

  const mailing = {
    streetAddress1: data.mailingStreetAddress,
    streetAddress2: "",
    city: data.mailingCity,
    state: data.mailingState,
    zipcode: data.mailingZip,
    country: "",
    lat: "",
    lng: "",
    unitNumber: data.mailingSuiteOrAptNum,
  };

  const formattedClaim = {
    client: {
      id: data.id,
      type: ClaimDataMapper.ClaimFieldMappers.ClientType.format(
        data.clientType
      ),
      businessName: data.clientBusinessName,
      residence: {
        client1: {
          title: data.client1Title,
          firstName: data.client1FirstName,
          lastName: data.client1LastName,
        },
        client2: {
          title: data.client2Title,
          firstName: data.client2FirstName,
          lastName: data.client2LastName,
        },
      },
      property,
      mailing,
      isMailingSameAsProperty: detectIfSameAddress(property, mailing),
      contact: {
        primary: {
          email: data.clientEmail,
          phone: data.clientPhone,
          phoneExt: data.clientPhoneExt,
          workPhone: data.clientWorkNumber,
          workPhoneExt: data.clientWorkNumberExt,
          mobile: data.clientMobileNumber,
          other: data.clientOtherNumber,
        },
        secondary: {
          email: data.secondaryClientEmail,
          phone: data.secondaryClientPhone,
          phoneExt: data.secondaryClientPhoneExt,
        },
      },
    },
    claimInfo: {
      claimNumber: data.claimNumber,
      policyNumber: data.policyNumber,
      dateOfLoss: data.dateOfLoss,
      causeOfLoss: data.causesOfLoss,
      dateCalledIn: data.dateCalledIn,
      deductible: data.deductible,
      settlementDate: data.settlementDate,
      status: data.status,
      policyStartDate: data.policyStartDate,
      policyEndDate: data.policyEndDate,
      mortgageName: data.mortgageName,
      mortgageAddress: data.mortgageAddress,
      adjusterClaimPercentage: data.adjusterClaimPerc,
      coverages: {
        A: data.coverageA || "",
        B: data.coverageB || "",
        C: data.coverageC || "",
        D: data.coverageD || "",
        total: data.coverageTotal || "",
      },
      contractSigned: data.contractSigned,
      previouslyPaid: data.previouslyPaid,
      newDollarReceived: ClaimDataMapper.ClaimFieldMappers.NewDollarsReceived.format(
        data.newDollarReceived
      ),
      feeSchedule: ClaimDataMapper.ClaimFieldMappers.FeeSchedule.format(
        data.adjusterShare
      ),
      contractFee: data.contractFee,
      upcomingAppointmentDate: data.upcomingAppointmentDate,
      upcomingAppointmentTime: data.upcomingAppointmentTime,
      agentName: data.agentName,
      agentAddress: data.agentAddress,
    },
    vendor: {
      id: data.vendorId,
      fees: data.vendorFees,
      name: data.vendorName,
    },
    attorney: {
      id: data.attorney,
      name: data.attorneyName,
    },
    assignedAdjusters: data.adjusterId,
    otherAdjustersAccess: data.otherAdjusterAccess,
    _raw: data,
  };

  return formattedClaim;
};
