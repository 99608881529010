import React from "react";
import PropTypes from "prop-types";

export function SuccessMessage({ message }) {
  return (
    <div className="alert alert-success">
      <strong>{message}</strong>
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};
