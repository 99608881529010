import React from 'react';

import loaderImage from '../../assets/images/loader.svg';

function SpinnerImage() {
  return <img style={{width: 50}} src={loaderImage} alt="loading spinner"/>;
}

const styles = {
  spinnerDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  spinnerTable: {
    width: '100%'
  }
};

const NUMBER_OF_COLUMNS = '10';

export function TableSpinner() {
  return (
    <tr>
      <td style={styles.spinnerTable} colSpan={NUMBER_OF_COLUMNS}>
        <SpinnerImage/>
      </td>
    </tr>
  );
}

export function FullPageSpinner() {
  return (
    <>
      <div
        className="blockUI blockOverlay"
        style={{
          zIndex: '1000',
          border: 'none',
          margin: '0px',
          padding: '0px',
          width: '100%',
          height: '100%',
          top: '0px',
          left: '0px',
          backgroundColor: 'rgb(85, 85, 85)',
          opacity: '0.1',
          cursor: 'wait',
          position: 'fixed'
        }}
      />
      <div
        className="blockUI blockMsg blockPage"
        style={{
          zIndex: '1011',
          position: 'fixed',
          padding: '0px',
          margin: '0px',
          width: '30%',
          top: '40%',
          left: '35%',
          textAlign: 'center',
          color: 'rgb(0, 0, 0)',
          border: '0px',
          cursor: 'wait'
        }}
      >
        <div
          className="loading-message"
          style={{
            background: 'black',
            opacity: 0.7,
            borderRadius: 4,
            color: 'white'
          }}
        >
          <i
            className="fa fa-circle-o-notch fa-spin"
            style={{color: 'white'}}
          />
          <span style={{color: 'white'}}>&nbsp;&nbsp;LOADING...</span>
        </div>
      </div>
    </>
  );
}

export const Spinner = FullPageSpinner;
