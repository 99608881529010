import { ProfileTypes as Types } from "./index";

export const GetProfile = {
  start: () => ({ type: Types.GET_PROFILE.START }),
  success: (profile) => ({
    type: Types.GET_PROFILE.SUCCESS,
    payload: profile,
  }),
  error: (err) => ({
    type: Types.GET_PROFILE.ERROR,
    payload: err,
  }),
};

export const UpdateProfile = {
  start: (payload) => ({ type: Types.UPDATE_PROFILE.START, payload }),
  success: (profile) => ({
    type: Types.UPDATE_PROFILE.SUCCESS,
    payload: profile,
  }),
  error: (err) => ({
    type: Types.UPDATE_PROFILE.ERROR,
    payload: err,
  }),
};
