import {useCallback, useEffect, useState} from 'react';

import * as Api from '../../Api';
import useClaimTableQueryParams from './useClaimTableQueryParams';
import useLazyFetcher from '../../hooks/useLazyFetcher';

import {mapClaims} from './ClaimsTable.mapper';

function mapQueryToRequestData(query) {
  const {
    assignedAdjusters, cities, fileNumber, claimNumber, createdAtFromDate, insurers, isIncludeArchives,
    clientNameStartsWithLetter, clientName, dolFromDate, dolToDate, status, street, createdAtToDate, zipcodes,
    currentPage, limitPerPage, sortBy, orderBy
  } = query;
  return {
    filter: {
      adjustersId: (assignedAdjusters && assignedAdjusters.length > 0)
        ? assignedAdjusters.join()
        : '',
      city: cities,
      fileNumber,
      claimNumber,
      fromDate: createdAtFromDate,
      insurerCompanyName: insurers,
      isArchive: isIncludeArchives ? 'yes' : '',
      isAttorney: '',
      clientNameStartCharacter: clientNameStartsWithLetter,
      lastName: clientName,
      lossFromDate: dolFromDate,
      lossToDate: dolToDate,
      status,
      streetName: street,
      toDate: createdAtToDate,
      zip: zipcodes
    },
    limit: {start: ((currentPage - 1) * limitPerPage), total: limitPerPage},
    orderBy: {colomnName: sortBy, orderByFormat: orderBy}
  };
}

export function useClaims() {
  const [query] = useClaimTableQueryParams();
  const [fetchClientList, {data: clientData, loading: clientsLoading, error: clientsError}]
    = useLazyFetcher(Api.Claims.getClaims);
  const [fetchClientCount, {data: countData, loading: countLoading, error: countError}]
    = useLazyFetcher(Api.Claims.getClaimsTotal);
  const [error, setError] = useState('');

  useEffect(() => {
    if(clientsError || countError){
      setError(Api.getError(clientsError || countError));
    } else {
      setError(null)
    }
  }, [clientsError, countError, setError]);

  const getClaims = useCallback(() => {
    fetchClientList({requestData:mapQueryToRequestData(query)});
    fetchClientCount({requestData:mapQueryToRequestData(query)});
  }, [query, fetchClientList, fetchClientCount]);

  const {claims} = clientData || {};
  const {total = 0} = countData || {};

  useEffect(() => {
    getClaims();
  }, [getClaims]);

  const formattedClaims = mapClaims(claims);

  return {
    isLoading: clientsLoading || countLoading,
    claims: formattedClaims,
    error,
    total,
    reloadClaims: getClaims
  };
}
