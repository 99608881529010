import React from "react";

export const Check = ({ checked, label, onChange, disabled, ...remainingProps }) => {
  return (
    <label className="mt-checkbox mt-checkbox-outline">
      {label}
      <input
        type="checkbox"
        checked={checked || false}
        onChange={onChange}
        disabled={disabled}
        {...remainingProps}
      />
      <span></span>
    </label>
  );
};
