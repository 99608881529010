import React from 'react';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

function PhoneNumberMask({placeholderText, value, handleChange, inputClass, customStyle, ...remainingProps}) {
  return (
    <MaskedInput
      className={inputClass ? inputClass : 'form-control'}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder={placeholderText}
      value={value}
      guide={false}
      onChange={handleChange}
      style={customStyle}
      type="tel"
      {...remainingProps}
    />
  );
}

function DatePickerMask({inputClass, selectedValue, handleChange, placeholderText, disabled, ...remainingProps}) {
  let selectedVal = '';
  if (selectedValue && selectedValue !== '0000-00-00') {
    const mSelected = Moment(selectedValue);
    if(mSelected.isValid()){
      selectedVal = mSelected.toDate();
    }
  }

  return (
    <DatePicker
      placeholderText={placeholderText}
      selected={selectedVal}
      onChange={handleChange}
      className={inputClass ? inputClass : 'form-control'}
      disabled={disabled}
      {...remainingProps}
    />
  );
}

function TimePickerMask({inputClass, selectedValue, handleChange, placeholderText, disabled, remainingProps}) {
  return (
    <DatePicker
      placeholderText={placeholderText}
      selected={selectedValue ? new Date(selectedValue) : ''}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="h:mm aa"
      onChange={handleChange}
      className={inputClass ? inputClass : 'form-control'}
      disabled={disabled}
      {...remainingProps}
    />
  );
}

function CurrencyMask({placeholderText, value, handleChange, inputClass, customStyle, disabled, ...remainingProps}) {
  const numberMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowLeadingZeroes: true
  });

  return (
    <MaskedInput
      className={inputClass ? inputClass : 'form-control'}
      mask={numberMask}
      placeholder={placeholderText}
      value={value || ''}
      guide={false}
      onChange={handleChange}
      style={customStyle}
      disabled={disabled}
      {...remainingProps}
    />
  );
}

function DateTimePickerMask(
  {inputClass, selectedValue, handleChange, placeholderText, inputFormat, outputFormat, ...remainingProps}
) {
  const selectedDateFormat = inputFormat || 'MMM DD,YYYY HH:MM A';
  const outputDateFormat = outputFormat || 'MM/DD/YYYY hh:MM A';
  return (
    <DatePicker
      showTimeSelect
      placeholderText={placeholderText}
      selected={
        Moment(selectedValue, selectedDateFormat).isValid()
          ? new Date(Moment(selectedValue).format('LLL'))
          : ''
      }
      onChange={(date) => {
        const newDate = Moment(date).format(outputDateFormat);
        handleChange(newDate);
      }}
      dateFormat="MMM d, yyyy h:mm aa"
      minDate={new Date()}
      className={inputClass ? inputClass : 'form-control'}
      {...remainingProps}
    />
  );
}

export {PhoneNumberMask, DatePickerMask, TimePickerMask, CurrencyMask, DateTimePickerMask};
