import { useState } from "react";

import { formatDateToMmDdYyyy } from "../_common/date-pickers/DatePicker.utils";
export function useSearch({
  setSearchParams,
  INITIAL_SEARCH_PARAMS,
  resetTableFilters,
  resetLetterFilters,
}) {
  const [fileNumber, setFileNumber] = useState(
    INITIAL_SEARCH_PARAMS.fileNumber
  );
  const [createdAtFromDate, setCreatedAtFromDate] = useState();
  const [createdAtToDate, setCreatedAtToDate] = useState();
  const [dolFromDate, setDolFromDate] = useState();
  const [dolToDate, setDolToDate] = useState();
  const [clientName, setClientName] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [assignedAdjusters, setAssignedAdjusters] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [street, setStreet] = useState("");
  const [selectedInsurers, setSelectedInsurers] = useState([]);
  const [status, setStatus] = useState([]);
  const [clientNameStartsWithLetter, setClientNameStartsWithLetter] = useState(
    ""
  );
  const [isIncludeAttorneyFiles, setIsIncludeAttorneyFiles] = useState(false);
  const [isIncludeArchives, setIsIncludeArchives] = useState(false);

  const searchParams = {
    fileNumber,
    clientName,
    claimNumber,
    assignedAdjusters,
    cities,
    zipcodes,
    street,
    insurers: selectedInsurers,
    status,
    createdAtFromDate: formatDateToMmDdYyyy(createdAtFromDate),
    createdAtToDate: formatDateToMmDdYyyy(createdAtToDate),
    dolFromDate: formatDateToMmDdYyyy(dolFromDate),
    dolToDate: formatDateToMmDdYyyy(dolToDate),
    clientNameStartsWithLetter,
    isIncludeAttorneyFiles,
    isIncludeArchives,
  };

  const onSearchSubmit = () => setSearchParams(searchParams);

  const onTableFilterResetClick = () => {
    setFileNumber(INITIAL_SEARCH_PARAMS.fileNumber);
    resetTableFilters();
  };

  const onLetterFilterResetClick = () => {
    setClientNameStartsWithLetter("");
    setIsIncludeAttorneyFiles(false);
    setIsIncludeArchives(false);

    resetLetterFilters();
  };

  return {
    onSearchSubmit,
    fileNumber,
    createdAtFromDate,
    createdAtToDate,
    dolFromDate,
    dolToDate,
    clientName,
    claimNumber,
    assignedAdjusters,
    cities,
    zipcodes,
    street,
    selectedInsurers,
    status,
    setFileNumber,
    setCreatedAtFromDate,
    setCreatedAtToDate,
    setDolFromDate,
    setDolToDate,
    setClientName,
    setClaimNumber,
    setAssignedAdjusters,
    setCities,
    setZipcodes,
    setStreet,
    setSelectedInsurers,
    setStatus,
    clientNameStartsWithLetter,
    setClientNameStartsWithLetter,

    isIncludeAttorneyFiles,
    isIncludeArchives,
    setIsIncludeAttorneyFiles,
    setIsIncludeArchives,
    onTableFilterResetClick,
    onLetterFilterResetClick,
  };
}
