import { takeLatest, put, fork } from "redux-saga/effects";

import {
  AuthTypes as Types,
  AuthActions as Actions,
  AuthUtils as Utils,
} from "./index";
import * as Api from "../../Api";

function* getAuthUser() {
  try {
    const { data: user } = yield Api.Auth.getAuthUser();

    const formattedUser = Utils.mapAuthUser(user);

    yield put(Actions.GetAuthUser.success(formattedUser));
  } catch (err) {
    const error = Api.getError(err);
    window.open(`/app/login`, "_self");

    yield put(Actions.GetAuthUser.error(error));
  }
}

function* watchGetAuthUserStart() {
  yield takeLatest(Types.GET_AUTH_USER.START, getAuthUser);
}

const AuthSagas = [fork(watchGetAuthUserStart)];

export default AuthSagas;
