import React from 'react';
import {PopupModal} from '../_common/PopupModal';

const CONFIRM_BUTTON_STYLE = {
  padding: '12px 49px 12px 49px',
  borderRadius: '4px',
  marginRight: '5px'
};

const CANCEL_BUTTON_STYLE = {
  padding: '12px 29px 12px 29px',
  borderRadius: '4px',
  marginRight: '5px'
};

function UnmappedClause({unmappedCount}) {
  let [noun, verb] = ['column', 'is'];
  if (!unmappedCount || unmappedCount > 1) {
    ([noun, verb] = ['columns', 'are']);
  }

  return (
    <>
      <b>{unmappedCount} {noun}</b> {verb} not mapped to any field columns.
    </>
  );
}

export function MappingConfirmationModal({openPanel, unmappedCount, onConfirm, onCancel}) {
  return (
    <div>
      <PopupModal isOpen={openPanel}>
        <div className="modal-dialog" style={{margin: '0px'}}>
          <div className="modal-content">
            <div className="modal-body" style={{textAlign: 'center'}}>
              <p style={{fontSize: '18px'}}>
                <UnmappedClause unmappedCount={unmappedCount}/>
              </p>
              <p>Are you sure want to continue without mapping them?</p>
              <button type="button" className="btn btn-outline blue-primary" style={CONFIRM_BUTTON_STYLE}
                      onClick={onConfirm}>
                Yes, continue
              </button>
              <button type="button" className="btn grey-salsa btn-outline" style={CANCEL_BUTTON_STYLE}
                      onClick={onCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
