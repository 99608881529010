import * as Types from "./insurer.constants";

export const GetInsurers = {
  start: () => ({ type: Types.GET_INSURERS.START }),
  success: (insurers) => ({
    type: Types.GET_INSURERS.SUCCESS,
    payload: insurers,
  }),
  error: (err) => ({ type: Types.GET_INSURERS.ERROR, payload: err }),
  clear: () => ({ type: Types.GET_INSURERS.clear }),
};
