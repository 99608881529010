import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { project } from './companyCamApi';
import * as Api from "../../../Api";
import { updateCcProjectId } from '../../../store/claims/claims.actions';
import ccLogo from '../../../assets/images/company-cam-logo.png';
import { CompanyCamAlert } from './companyCamAlert';

export function CompanyCamCreate() {

    const { 
        id: claimId, 
        data: { _raw: {ccProjectId} = {} } = {},
        data: { client: {property} = {} } = {},
        data: { client: {residence} = {} } = {}
    } = useSelector((state) => state.claims.claim);

    const dispatch = useDispatch();

    // const [ccProjectId, setCcProjectId] = useState(originalCcProjectId);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showLinkChoices, setShowLinkChoices] = useState(false);
    const [showLinkExisting, setShowLinkExisting] = useState(false);
    const [linkProjectId, setLinkProjectId] = useState('');
    const toggleShowLinkChoices = () => setShowLinkChoices(!showLinkChoices);

    const handleChangeLinkProjectId = (event) => {
        setLinkProjectId(event.target.value);
    }

    // const dispatch = useDispatch();
    const hideAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    }
    
    const newCompanyCamProject = (event) => {
        event.preventDefault();
        setShowLinkChoices(false);
        create();
    }
    const existingCompanyCamProject = (event) => {
        event.preventDefault();
        setShowLinkChoices(false);
        setShowLinkExisting(true);
    }
    const create = async () => {
        // TODO make sure fields exist
        let name = `${residence.client1.lastName}, ${residence.client1.firstName}`;
        if (residence.client2.lastName || residence.client2.firstName) {
            name = `${name} & ${residence.client2.lastName}, ${residence.client2.firstName}`
        }
        const projectData = {
            name,
            address: {
                street_address_1: property.streetAddress1,
                street_address_2: property.streetAddress2,
                city: property.city,
                state: property.state,
                postal_code: property.zipcode,
                country: 'US'
            }
        };
        
        // create
        const response = await project.create(projectData);
        const newCcProjectId = response.data.id;
        const updateClaimResponse = await Api.Claims.setCcProjectId(claimId, {ccProjectId: newCcProjectId});
        if (updateClaimResponse.data.ccProjectId === newCcProjectId) {
            // success
            dispatch(updateCcProjectId(newCcProjectId));
            // setCcProjectId(newCcProjectId);
            setAlertMessage('CompanyCam project created successfully.');
            setShowAlert(true);
        }
        
    };
    const linkExisting = async () => {
        setShowLinkExisting(false);
        try {
            const response = await project.get(linkProjectId);
            if (response.data?.id) {
                const {id, name} = response.data;
                const updateClaimResponse = await Api.Claims.setCcProjectId(claimId, {ccProjectId: id});
                if (updateClaimResponse.data.ccProjectId === id) {
                    // success
                    dispatch(updateCcProjectId(id));
                    // setCcProjectId(id);
                    setAlertMessage(`You've successfully linked this claim to the CompanyCam project: ${name}.`);
                    setShowAlert(true);
                }
            } 
        } catch (e) {
            console.log(e);
        }
    }

    if (ccProjectId && !showAlert) return null;

    return (
        <>
            <div className="tw-relative">
                <button className={`tw-transition tw-bg-[#0967d2] tw-rounded-[4px] tw-text-white tw-px-[10px] tw-py-[4px] tw-text-[13px] tw-mx-[2px] tw-flex tw-content-center tw-relative tw-mr-4 ${(!claimId) ? 'tw-opacity-50' : 'tw-opacity-100'}
                hover:tw-bg-[#085cbd]
                after:tw-content-['NEW!'] after:tw-bg-orange-500 after:tw-text-[8px] after:tw-font-bold after:tw-absolute after:-tw-top-1 after:-tw-right-4 after:tw-rounded-full after:tw-py-px after:tw-px-1.5 after:tw-shadow-md`}
                onClick={toggleShowLinkChoices} type="button" disabled={!claimId}><img src={ccLogo} className='tw-w-5 tw-mr-1' alt="CompanyCam Logo" /><span>Link CompanyCam Project <i className="fa fa-angle-down"/></span></button>

                {showLinkChoices && (
                <div className="tw-z-[5] tw-origin-top-left tw-absolute tw-mt-2 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <div className="tw-py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button onClick={newCompanyCamProject}
                        className="tw-bg-transparent tw-block tw-text-[13px] tw-px-[10px] tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-blue focus:tw-text-blue"
                        role="menuitem"
                        >
                        Create New CompanyCam Project
                        </button>
                        <button onClick={existingCompanyCamProject}
                        className="tw-bg-transparent tw-block tw-text-[13px] tw-px-[10px] tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-blue focus:tw-text-blue"
                        role="menuitem"
                        >
                        Link Existing CompanyCam Project
                        </button>
                    </div>
                </div>
                )}
            </div>

            {showLinkExisting && (
                <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-10">
                    <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg tw-relative tw-min-w-[600px]">
                        <button
                            type="button"
                            className="tw-absolute tw-bg-transparent tw-top-2 tw-right-2 tw-text-gray-500 tw-font-bold tw-text-[30px] tw-leading-[30px] hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
                            onClick={() => {setShowLinkExisting(false);setShowLinkChoices(false);}}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Link Existing Company Cam Project</h2>
                        <ol className="tw-list-decimal tw-ml-4 tw-text-base tw-mb-4">
                            <li>Go to <a href="https://app.companycam.com" className="tw-text-blue tw-underline" target="_blank" rel="noreferrer">CompanyCam</a> and navigate to your project</li>
                            <li>
                            <p>Copy the number at the end of the URL after the word projects and a slash. Example:</p>
                            <span className="tw-bg-gray-300 tw-p-4 tw-leading-[48px] tw-font-bold">https://app.companycam.com/projects/<span className="tw-bg-yellow-500">12345678</span></span>
                            </li>
                            <li>Paste the Project Number below and click Link Project</li>
                        </ol>

                        <label htmlFor="projectId" className="tw-block tw-text-base tw-font-bold">
                            Company Cam Project Number
                            <input className="tw-block tw-px-[12px] tw-py-[6px] tw-border tw-border-gray-300 tw-rounded-md tw-w-full tw-mb-4" type="text" name="projectId" id="projectId" placeholder="12345678" value={linkProjectId} onChange={handleChangeLinkProjectId} />
                        </label>

                        <button type="button" onClick={linkExisting} className="tw-px-[12px] tw-py-[6px] tw-bg-blue tw-rounded-md tw-text-base tw-text-white">Link Project</button>

                    </div>
                </div>
            )}
            <CompanyCamAlert isOpen={showAlert} onClose={hideAlert} title="Success" body={alertMessage} action={hideAlert} actionText="OK" cancelButton={false} />
        </>
    );
}