import React, { useState, useEffect } from "react";
import { SlidingSidePanel } from "../../../_common/SlidingSidePanel";
import * as Api from "../../../../Api";
import { Notify } from "../../../../services/Notify.service";
import { ShareFileWithVendorAndAttroney } from "./ShareFileWithVendorAndAttorney";

export const SelectAttorney = ({
  claimId,
  showShareAttorneyUI,
  setShowShareAttorneyUI,
  shareClaimToken,
  shareLink,
  isSharedView,
}) => {
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectedAttorney, setSelectedAttorney] = useState([]);
  const [allAttorney, setAllAttorney] = useState([]);
  const [showSelectedUsersScreen, setShowSelectedUsersScreen] = useState(false);


  const goToNextLevel = () => {
    if (selectedVendor.length === 0 && selectedAttorney.length === 0) {
      Notify.error("Please select Attorney/Vendor List. ");
      return false;
    }
    setShowShareAttorneyUI(false);
    setShowSelectedUsersScreen(true);
  };

  const fetchAllAttorneys = () => {
    Api.Claims.Attorneys.get()
      .then((data) => {
        setAllAttorney(data.data.attorneys);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectAttorneyForSharingClaim = (attorney) => {
    const currentSelected = [...selectedAttorney];
    const index = selectedAttorney.find((x) => x.id === attorney.id);
    if (!index) {
      currentSelected.push(attorney);
      setSelectedAttorney(currentSelected);
    }
    if (index) {
      const newSelected = currentSelected.filter((x) => x.id !== attorney.id);
      setSelectedAttorney(newSelected);
    }
  };

  useEffect(() => {
    if (!isSharedView) {
      fetchAllAttorneys();
    }
  }, [isSharedView]);

  return (
    <>
      {showShareAttorneyUI && (
        <SlidingSidePanel type={"right"} isOpen={showShareAttorneyUI}>
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">
                  Share File With Attorneys
                </span>
              </div>
              <div className="tools">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setShowShareAttorneyUI(false);
                  }}
                  href="#!"
                  className="remove"
                  data-original-title=""
                  title=""
                >
                  &nbsp;
                </a>
              </div>
            </div>
            <div className="portlet-body">
              <div className="tabbable-custom nav-justified">
                <div
                  className="tab-content client-edit-mid-tab-content"
                  style={{ border: "1px transparent" }}
                >
                  <div
                    className="tab-pane active" style={{ minHeight: "90vh" }}>
                    <div className="alert alert-info">
                      <strong>Step 1 - Choose who to Share with. </strong>You
                      can select one or more Attorneys to share the file with.
                    </div>
                    <div className="mt-element-list">
                      <div
                        className="mt-list-container list-default"
                        style={{ borderColor: "#ffffff" }}
                      >
                        <div className="mt-list-title uppercase">
                          Choose Attorneys
                        </div>
                        {allAttorney.length > 0 && (
                          <ul className="ven_share">
                            {allAttorney.map((a) => {
                              return (
                                <li
                                  className="mt-list-item"
                                  key={a.id}
                                  onClick={(event) => {
                                    selectAttorneyForSharingClaim(a);
                                  }}
                                >
                                  <div
                                    className={
                                      selectedAttorney.find(
                                        (x) => x.id === a.id
                                      )
                                        ? `list-icon-container done`
                                        : `list-icon-container`
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a href="#!">
                                      <i className="fa fa-check-circle"></i>
                                    </a>
                                  </div>
                                  <div className="list-item-content">
                                    <h3 className="uppercase bold">
                                      <a href="#!">{a.name}</a>
                                    </h3>
                                    <p className="email_pra">
                                      {a.email ? a.email : <br />}
                                    </p>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  position: "sticky",
                  bottom: 0,
                  background: "#e1e5eb",
                  paddingTop: 20,
                  paddingBottom: 20,
                  opacity: 0.8,
                }}
              >
                <div className="col-sm-6">
                  <button
                    type="button"
                    onClick={() => goToNextLevel()}
                    className="btn btn-primary mt-ladda-btn ladda-button btn-block btn-lg purple-soft next_share_all_vendor_btn_cls"
                  >
                    NEXT
                  </button>
                </div>
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-lg white btn-block"
                    onClick={() => setShowShareAttorneyUI(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SlidingSidePanel>
      )}
      <ShareFileWithVendorAndAttroney
        claimId={claimId}
        showSelectedUsersScreen={showSelectedUsersScreen}
        setShowSelectedUsersScreen={setShowSelectedUsersScreen}
        selectedVendors={selectedVendor}
        selectedAttorney={selectedAttorney}
        shareClaimToken={shareClaimToken}
        shareLink={shareLink}
        setSelectedVendor={setSelectedVendor}
        setSelectedAttorney={setSelectedAttorney}
      />
    </>
  );
};
