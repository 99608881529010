import { VendorTypes as Types } from "./index";

export const GetVendors = {
  start: () => ({ type: Types.GET_VENDORS.START }),
  success: (vendors) => ({
    type: Types.GET_VENDORS.SUCCESS,
    payload: vendors,
  }),
  error: (err) => ({ type: Types.GET_VENDORS.ERROR, payload: err }),
};
