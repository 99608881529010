export const GET_PROFILE = {
  START: "PROFILE/GET_PROFILE_START",
  SUCCESS: "PROFILE/GET_PROFILE_SUCCESS",
  ERROR: "PROFILE/GET_PROFILE_ERROR",
};

export const UPDATE_PROFILE = {
  START: "PROFILE/UPDATE_PROFILE_START",
  SUCCESS: "PROFILE/UPDATE_PROFILE_SUCCESS",
  ERROR: "PROFILE/UPDATE_PROFILE_ERROR",
};
