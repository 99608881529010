import React from 'react';
import {useHistory} from 'react-router-dom';

import {Label} from './Label';
import {Status} from './Status';
import {Icon} from '../_common/Icons';

export function TableRow(
  {
    setOpenNotePanel, claim, setSelectedClaimId, labelsData, reloadClaims, setOpenManageLabelPanel,
    selectedArchiveClaims, setSelectedArchiveClaims, setArchivedError, setFullPageSpinner, setShowClaimPreview,
    getSelectedClaimData
  }
) {

  const history = useHistory();
  if (!claim) {
    return null;
  }

  function handleCheckboxChange(value) {
    return event => {
      let check = event.target.checked;
      let archivedClaimsArray = [...selectedArchiveClaims];
      if (check) {
        archivedClaimsArray.push(value);
        setSelectedArchiveClaims(archivedClaimsArray);
        setArchivedError('');
      } else {
        let index = archivedClaimsArray.indexOf(value);
        if (index > -1) {
          archivedClaimsArray.splice(index, 1);
          setSelectedArchiveClaims(archivedClaimsArray);
        }
      }
    };
  }

  function redirectOnClientEdit(id) {
    return () => {
      history.push(`/claims/${id}`);
    };
  }

  const {
    id,
    fileNumber,
    createdAt,
    name,
    clientPhone,
    claimNumber,
    dateOfLoss,
    adjusterNames,
    insAdjuster1Name,
    status,
    adjusterClaimPerc,
    propertyAddress,
    contractFee,
    insurer,
    statusColor,
    acolor
  } = claim;

  function handleNotesClick(e) {
    e.preventDefault();
    setOpenNotePanel(true);
    setSelectedClaimId(id);
  }

  function handlePreview(e) {
    e.preventDefault();
    setShowClaimPreview(true);
    getSelectedClaimData(id);
    setSelectedClaimId(id);
  }

  return (
    <tr className="odd" style={{cursor: 'pointer', backgroundColor: acolor}} role="row"
        data-testid={`claim-${id}`} data-claimid={id}>
      <td>
        <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
          <input className={`checkboxes ${selectedArchiveClaims.indexOf(id)}`}
                 type="checkbox" checked={selectedArchiveClaims.indexOf(id) > -1} value={id}
                 onChange={handleCheckboxChange(id)}/>
          <span/>
        </label>
      </td>
      <td className="RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        {fileNumber}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        {createdAt}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        {name}
        {clientPhone && (
          <span>
            <br/> {clientPhone}{' '}
          </span>
        )}
        {claimNumber && (
          <span>
            <br/> {claimNumber}
          </span>
        )}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        <span id="avatar_div_inner_1" style={{float: 'left', paddingTop: '2%'}}>
          <span style={{fontWeight: 'bold'}}>
            {adjusterNames}
            <br/>
          </span>
        </span>
        {contractFee && (
          <span>
            <br/> Fee Schedule: {contractFee}
          </span>
        )}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        {dateOfLoss}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        {propertyAddress && <span>{propertyAddress} </span>}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        <span style={{fontWeight: 'bold'}}>{insurer.name}</span>
        <br/>
        {insAdjuster1Name && <span>{insAdjuster1Name} </span>}
        {adjusterClaimPerc && (
          <span>
            <br/> Adjuster Claim %: {adjusterClaimPerc}
          </span>
        )}
      </td>
      <td className=" RedirectWithoutPreview" onClick={redirectOnClientEdit(id)}>
        <Status name={status} statusColor={statusColor}/>
      </td>
      <td>
        <div className="actions">
          <div className="btn-group">
            <a className="icon-btn table_file_list"
               style={{minWidth: 35, border: 'none', background: 'transparent'}}
               href="!#"
               onClick={handleNotesClick}>
              <Icon.Pencil/>
              <div> Notes</div>
              {' '}
            </a>
            <a className="icon-btn table_file_list"
               style={{minWidth: 35, border: 'none', background: 'transparent'}}
               href="#!" onClick={handlePreview}>
              <Icon.ListAlt/>
              <div> Preview</div>
            </a>

            <Label
              labelsData={labelsData}
              setSelectedClaimId={setSelectedClaimId}
              selectedClaimId={id}
              reloadClaims={reloadClaims}
              setOpenManageLabelPanel={setOpenManageLabelPanel}
              setFullPageSpinner={setFullPageSpinner}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
