import React, { useState, useEffect, useCallback } from "react";
import { Dropdown } from "../../../_common/Dropdown";
import * as Api from "../../../../Api";
import { FullPageSpinner } from "../../../_common/Spinner";
import { AskForFieldsForDocuments } from "./AskForFieldsForDocument";
import { DocumentViewModal } from "./DocumentViewModal";

export const ClaimGenrateDocument = ({ claimId, isSharedView }) => {
  const [documentList, setDocumentList] = useState([]);
  const [showLoading, setshowLoading] = useState(false);
  const [openDocumentViewModal, setOpenDocumentViewModal] = useState(false);
  const [openAskForFieldsModal, setOpenAskForFieldsModal] = useState(false);
  const [askFieldsArray, setAskFieldsArray] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const [fileSrcUrl, setFileSrcUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [userFilledData, setUserFilledData] = useState([]);
  const fetchDocumentList = useCallback(() => {
    setshowLoading(true);
    Api.Document.list()
      .then((data) => {
        setDocumentList(data.data.documents);
      })
      .catch((err) => {
        Api.getAndShowError(err);
      })
      .finally(() => {
        setshowLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!isSharedView) {
      fetchDocumentList();
    }
  }, [fetchDocumentList, isSharedView]);

  const openDocument = (document) => {
    setshowLoading(true);
    Api.Document.legacy
      .getDocumentUrl({
        documentId: document.id,
        claimId,
      })
      .then((data) => {
        if (data.data.isAsk) {
          console.log("Show right panel to ask the Data to enter into Doc");
          setAskFieldsArray(data.data.askArray);
          setOpenAskForFieldsModal(true);
        }
        if (!data.data.isAsk) {
          setFileSrcUrl(data.data.fileName);
          setOpenDocumentViewModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setshowLoading(false);
        setDocumentId(document.id);
        if (document.name) {
          setFileName(document.name);
        }
        if (!document.name) {
          setFileName(
            document.fileName.replace(/^.*[\\/]/, "").replace(/\.[^/.]+$/, "")
          );
        }
      });
  };

  return (
    <>
      {showLoading && <FullPageSpinner />}
      <DocumentViewModal
        openDocumentViewModal={openDocumentViewModal}
        setOpenDocumentViewModal={setOpenDocumentViewModal}
        fileSrcUrl={fileSrcUrl}
        setshowLoading={setshowLoading}
        fileName={fileName}
        documentId={documentId}
        claimId={claimId}
        userFilledData={userFilledData}
      />

      <AskForFieldsForDocuments
        openAskForFieldsModal={openAskForFieldsModal}
        setOpenAskForFieldsModal={setOpenAskForFieldsModal}
        askFieldsArray={askFieldsArray}
        setAskFieldsArray={setAskFieldsArray}
        documentId={documentId}
        claimId={claimId}
        setOpenDocumentViewModal={setOpenDocumentViewModal}
        setFileSrcUrl={setFileSrcUrl}
        setshowLoading={setshowLoading}
        setUserFilledData={setUserFilledData}
      />

      <Dropdown
        title="Generate Document"
        clientEdit={true}
        isNotOutline={false}
        variant="blue-primary bold"
        marginRight={true}
      >
        {documentList.map((d) => {
          return (
            <Dropdown.ListItem onClick={() => openDocument(d)} key={d.id}>
              {d.name
                ? d.name
                : d.fileName.replace(/^.*[\\/]/, "").replace(/\.[^/.]+$/, "")}
            </Dropdown.ListItem>
          );
        })}
      </Dropdown>
    </>
  );
};
