import React from "react";
import RDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import { Icon } from "../Icons";

export function DatePicker({ date, onChange, placeholder }) {
  return (
    <div className="input-group date date-picker">
      <RDatePicker
        className="form-control form-filter input-sm"
        selected={date}
        onChange={onChange}
        placeholderText={placeholder}
        popperClassName="react-datepicker-popper"
      />
      <span
        className="input-group-btn"
        style={{ pointerEvents: "none", top: -2 }}
      >
        <button className="btn btn-sm default" type="button">
          <Icon.Calendar />
        </button>
      </span>
    </div>
  );
}
