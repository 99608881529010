import React, { useState, useEffect } from "react";
import { LoadingButton } from "../../../_common/Button";
import { SlidingSidePanel } from "../../../_common/SlidingSidePanel";
import { DateTimePickerMask } from "../../../_common/MaskedInput";
import { Error } from "../../../_common/Error";
import * as Api from "../../../../Api";
import Moment from "moment";
import moment from "moment";

export function AddAndEditReminder({
  claimId,
  openPanel,
  setOpenPanel,
  reminderDetails,
  reloadReminders,
}) {
  const error = "";
  const [description, setDescription] = useState("");
  const [buttonLoading, setButtonLoading] = useState("");
  const [reminderSpecificDate, setReminderSpecificDate] = useState("");

  const saveReminder = () => {
    const reminderData = {
      specificDate: reminderSpecificDate
        ? Moment(new Date(reminderSpecificDate)).utc().format("MM-DD-YYYY HH:mm")
        : "",
      repeatAfter: "",
      repeatStartFrom: "",
      description,
      outlookReminderId: "",
    };
    setButtonLoading(true);
    if (reminderDetails.id) {
      Api.Claims.Reminders.edit({
        reminderId: reminderDetails.id,
        reminderData,
        claimId,
      })
        .then(() => {
          setOpenPanel(false);
          reloadReminders();
        })
        .finally(() => {
          setButtonLoading(false);
          setDescription("");
          setReminderSpecificDate("");
        });
    }
    if (!reminderDetails.id) {
      Api.Claims.Reminders.add({
        reminderData,
        claimId,
      })
        .then(() => {
          setOpenPanel(false);
          reloadReminders();
        })
        .finally(() => {
          setButtonLoading(false);
          setDescription("");
          setReminderSpecificDate("");
        });
    }
  };

  useEffect(() => {
    setDescription(
      reminderDetails.description ? reminderDetails.description : ""
    );
    setReminderSpecificDate(
      reminderDetails.specificDate ? moment.utc(reminderDetails.specificDate).local().format("MMM, DD YYYY hh:mm A") : ""
    );
  }, [reminderDetails]);

  return (
    <div>
      <SlidingSidePanel type={"right"} isOpen={openPanel}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-yellow-casablanca uppercase">
                {reminderDetails.id
                  ? "Edit Reminder"
                  : "Set Follow Up Reminder"}
              </span>
            </div>
            <div className="tools">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPanel(false);
                }}
                href="#!"
                className="remove"
                data-original-title=""
                title=""
              >
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            {error ? <Error message={error} /> : ""}
            <div className="form-group">
              <label className="control-label">Remind me to</label>
              <textarea
                className="form-control"
                value={description}
                rows="5"
                placeholder="Enter Text"
                onChange={(evt) => setDescription(evt.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label className="control-label">On</label>
              <DateTimePickerMask
                selectedValue={reminderSpecificDate}
                inputFormat={"LLL"}
                outputFormat={"LLL"}
                handleChange={(date) => {
                  setReminderSpecificDate(date);
                }}
              ></DateTimePickerMask>
            </div>
            <div className="form-actions">
              <div className="row">
                <div className="col-md-offset-3 col-md-9">
                  {buttonLoading ? (
                    <LoadingButton />
                  ) : (
                    <button
                      type="submit"
                      onClick={() => saveReminder()}
                      className="btn btn-outline blue-primary"
                      style={{ margin: 5 }}
                    >
                      {reminderDetails.id ? "Update" : "Submit"}
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn default"
                    style={{ margin: 5 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenPanel(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlidingSidePanel>
    </div>
  );
}
