import React, {useState} from "react";
import { SlidingSidePanel } from "../../../_common/SlidingSidePanel";
import { Error } from "../../../_common/Error";
import { LoadingButton } from "../../../_common/Button";
import * as Api from "../../../../Api";

function SubmitButton(){
    return (
        <button type="submit" className="btn green" name="submit" value="submit">
            <i className="fa fa-check"></i> Save
        </button>
    );
}

export const RenameFolder = ({
    openPanel, 
    setOpenPanel,
    allFoldersName,
    setAllFolderName
}) => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();
        Api.Claims.Attachments.folder.save({
            allFoldersName
        }).then(() => {
            setIsLoading(false);
            setOpenPanel(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleChangeEvent = (idx) => evt => {
        const newFolderName = allFoldersName.map((obj) => { 
            const newObj = obj;
            if(obj.defaultFolderId !== idx){
                return newObj;
            }
            newObj.folderName = evt.target.value;
            return newObj;
        });
        setAllFolderName(newFolderName);
    }

    return (
        <div>
            <SlidingSidePanel type={"right"} isOpen={openPanel}>
                <div className="portlet light">
                    <div className="portlet-title">
                        <div className="caption">
                            <span className="caption-subject bold font-yellow-casablanca uppercase">
                                Rename Folders
                            </span>
                        </div>
                        <div className="tools">
                            <a 
                                onClick={(e) => { 
                                    e.preventDefault();
                                    setOpenPanel(false);
                                    setError("");
                                }} 
                                href="#!" 
                                className="remove" 
                                data-original-title="" 
                                title=""
                            >&nbsp;</a>
                        </div>
                    </div>
                    <div className="portlet-body">
                        {(error)? <Error message={error}/>: ''}
                        <form onSubmit={handleSubmit} method="post">
                            {
                                allFoldersName.map((f,i) => {
                                    return (
                                        <div className="form-group" key={f.defaultFolderId}>
                                            <input  type="text" className="form-control" value={f.folderName} onChange={handleChangeEvent(f.defaultFolderId)}/>
                                        </div>
                                    );
                                })
                            }
                            <div className="form-actions">
                                <div className="row">
                                    <div className="col-md-offset-3 col-md-9">
                                        {(!isLoading)?<SubmitButton />:<LoadingButton />}
                                        <button onClick={(e) => { 
                                                e.preventDefault();
                                                setOpenPanel(false);
                                                setError("");
                                            }}  
                                            type="button" 
                                            className="btn default" 
                                            name="cancel" 
                                            value="cancel" 
                                            style={{ margin: 5 }}
                                        >
                                            <i className="fa fa-times"></i> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </SlidingSidePanel>
        </div>
    );
}