import compact from 'lodash.compact';
import uniqBy from 'lodash.uniqby';

function formatName(firstName, lastName) {
  const name = [lastName, firstName];

  return compact(name).join(', ');
}

export function getClientName({clientType, firstName, lastName, firstName2, lastName2, businessName}) {
  if (clientType === 'business') {
    return businessName;
  }

  if (clientType !== '') {
    if (firstName2 || lastName2) {
      return formatName(firstName, lastName) +
        ` & ` +
        formatName(firstName2, lastName2);
    }
    return formatName(firstName, lastName);
  }
  return '';
}

export function getAdjusterNames(adjusters) {
  const adjusterNames = adjusters.map((adjuster) => adjuster.name);
  return compact(adjusterNames).join(', ');
}

export function formatAddress({street, city, state, country, zip}) {
  const address = [street, city, state, country];
  const addressAsString = compact(address).join(', ');
  return zip ? `${addressAsString} ${zip}` : addressAsString;
}

export function getAdjusters(claims = []) {
  const adjusters = claims.map((claim) => claim.adjusters).flat();
  const hasAdjusters = adjusters && adjusters.length;
  if (!hasAdjusters) {
    return [];
  }
  const definedAdjusters = compact(adjusters);
  return uniqBy(definedAdjusters, 'id');
}

export function getInsurers(claims = []) {
  return claims.map((claim) => claim.insurer);
}
