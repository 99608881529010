import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EditClaim } from "./EditClaim";
import { Error } from "../../errors/Error";
import { Header } from "../../layout/Header";

export const SharedClaim = () => {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.claims.claim);
  console.log('shared claim');

  useEffect(() => {
    document.title = "Shared Claim | Organized Adjusting";
    return () => {
      document.title = "Organized Adjusting";
    };
  }, [dispatch]);

  if (error) {
    return (
      <Error
        code={error.code}
        message={error.message}
        description={error.description}
      />
    );
  }

  return (
    <>
      <Header />
      <EditClaim />
    </>
  );
};
