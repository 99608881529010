import React, {useEffect, useState} from 'react';
import SlidingPanel from 'react-sliding-side-panel';

export function SlidingSidePanel({isOpen, children, ...props}) {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    function handleResize() {
      const {scrollWidth, offsetWidth} = document.body;
      const html = document.documentElement;

      setWindowWidth(Math.max(scrollWidth, offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth));
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [setWindowWidth]);

  function getPanelWidth() {
    if (windowWidth > 1500) {
      return 30;
    }
    if (windowWidth > 900) {
      return 40;
    }

    if (windowWidth < 600) {
      return 90;
    }
  }

  return (
    <div>
      <SlidingPanel type={'right'} isOpen={isOpen} size={getPanelWidth()}{...props}>
        {children}
      </SlidingPanel>
    </div>
  );
}
