import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
 
class ComponentToPrint extends React.Component {
  render() {
    return (
        <div style={{padding: "15px"}}>
            <div>
                <h2 style={{fontWeight: "bold"}}>{this.props.TableName}</h2>
            </div>
            <table style={{
                border: "1px solid #666",
                width: "98%"
            }}>
                <thead>
                    <tr>
                        {this.props.headers.map((column, ind) => (
                            <th key={ind} style={{
                                "border": "1px solid #e7ecf1"
                            }}>
                                {column.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.props.Data.map((d, i) => (
                        <tr key={i}>
                            {this.props.headers.map((column, ind) => (
                                <td key={ind} style={{
                                "border": "1px solid #e7ecf1"
                                }}>
                                    {d[column.dataKey]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
  }
}
 
const DownloadImage = ({
    Data,
    headers,
    TableName
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
 
  return (
    <div>
        <div style={{display: "none"}}>
            <ComponentToPrint ref={componentRef} Data={Data} headers={headers} TableName={TableName}/>
        </div>
        <button style={{ 
                        background: "none",
                        border: "none",
                        width: "100%",
                        textAlign: "left"
                    }} onClick={handlePrint}>Print</button>
    </div>
  );
};

export default DownloadImage;