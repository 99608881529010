import React from 'react';
import mergeFieldPlaceholder from '../../assets/images/importDocument/merge-field-placeholder.svg';
import upload from '../../assets/images/importDocument/upload.svg';
import mergeField from '../../assets/images/importDocument/merge-field.svg';
import complete from '../../assets/images/importDocument/complete.svg';
import printPreview from '../../assets/images/importDocument/print-preview.svg';

const CLOSE_BUTTON_STYLE = {
  padding: '1rem 1.5rem',
  boxShadow: '0 5px 10px 2px #f4433652',
  textTransform: 'uppercase',
  backgroundColor: '#F44336',
  borderColor: '#F44336',
  borderRadius: '0.3rem!important',
  color: 'white',
  marginTop: '-1rem'
};

const MERGE_PLACEHOLDERS = '${}';

const MERGE_ILLUSTRATION_STYLE = {
  width: '40%!',
  textAlign: 'center',
  marginLeft: '1.3rem'
};

const UPLOAD_ILLUSTRATION_STYLE = {
  width: '24%',
  textAlign: 'center',
  marginTop: '-1rem',
  marginBottom: '0.8rem'
};

const MAPPING_ILLUSTRATION_STYLE = {
  width: '26%',
  textAlign: 'center',
  marginBottom: '1rem'
};

const COMPLETE_ILLUSTRATION_STYLE = {
  width: '9%',
  textAlign: 'center',
  marginLeft: '1rem'
};

const PRINT_PREVIEW_ILLUSTRATION_STYLE = {
  width: '29%',
  textAlign: 'center',
  marginTop: '1rem'
};

export function InstructionView({onClose}) {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="note note-info">
          <h4 className="block">
            <span style={{color: '#95A5A6'}}>
              Instructions for Merge Fields
            </span>
            <button onClick={onClose} className="btn pull-right" style={CLOSE_BUTTON_STYLE}>
              <i className="fa fa-close"/>
            </button>
            <br/>
            <br/>
            <span style={{fontSize: '14px', color: '#333'}}>
              <ol style={{lineHeight: '1.8'}}>
                <li>
                  Add Merge Placeholders to Your File using <code>{MERGE_PLACEHOLDERS}</code>
                </li>
                <img src={mergeFieldPlaceholder} alt="merge Field Placeholder" style={MERGE_ILLUSTRATION_STYLE}/>
                <li>Upload that file here. </li>
                <img src={upload} alt="upload" style={UPLOAD_ILLUSTRATION_STYLE}/>
                <li>Merge Fields with the Fields you want them to get the datafrom.</li>
                <img src={mergeField} alt="Merge Field" style={MAPPING_ILLUSTRATION_STYLE}/>
                <li>
                  Review &amp; submit. You'll be shown a Preview of yourDocument in PDF format showing placeholders.
                  {' '}
                </li>
                <img src={complete} alt="complete" style={COMPLETE_ILLUSTRATION_STYLE}/>
                <li style={{marginTop: '1.5rem'}}>
                  Now let's test it by generating the document for a real Claim.Go to a Claim File and click on
                  "Generate Documents". The document you just imported should be listed there. Click on itand your doc
                  should be generated it with real data replacing your Merge Field Placeholders!
                </li>
                <img src={printPreview} alt="Print Preview" style={PRINT_PREVIEW_ILLUSTRATION_STYLE}/>
                <li>
                  {' '}
                  You can print or download the PDF or save it to the Documents folder of that Claim.
                </li>
              </ol>
            </span>
          </h4>
          <a className="btn btn-lg  btn-outline blue-primary mr-5" href={process.env.REACT_APP_SAMPLE_TEMPLATE_URL}>
            Download a Sample File
            <i className="ml05 fa fa-download"/>
          </a>
          <button onClick={onClose} className="btn btn-lg btn-outline blue-primary">
            Upload Template
            <i className="ml-5 fa fa-arrow-right"/>
          </button>
        </div>
      </div>
    </div>
  );
}
