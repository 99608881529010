import * as Types from "./claims.constants";

export const clearClaim = () => ({
  type: Types.CLEAR_CLAIM
});

export const ClaimRoute = {
  setIsNew: (bool) => ({
    type: Types.CLAIM_ROUTE.SET_IS_NEW,
    payload: bool,
  }),
  setIsSharedView: (bool) => ({
    type: Types.CLAIM_ROUTE.SET_IS_SHARED_VIEW,
    payload: bool,
  }),
};

export const CreateOrUpdateClaim = {
  preCheck: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.PRE_CHECK,
  }),
  setIsSaveAndClose: (bool) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.SET_IS_SAVE_AND_CLOSE,
    payload: bool,
  }),
  setIsFormSubmitted: (bool) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.SET_IS_FORM_SUBMITTED,
    payload: bool,
  }),
  start: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.START,
  }),
  isCreating: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.IS_CREATING,
  }),
  success: (id) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.SUCCESS,
    payload: id,
  }),
  error: (err) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.ERROR,
    payload: err,
  }),
  cancel: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CANCEL,
  }),
  clear: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CLEAR,
  }),
  duplicateCheckStart: (payload) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_CHECK_START,
    payload,
  }),
  duplicateCheckSuccess: (payload) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_CHECK_SUCCESS,
    payload,
  }),
  duplicateCheckError: (err) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_CHECK_ERROR,
    payload: err,
  }),
  duplicateSetAllow: (bool) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_SET_ALLOW,
    payload: bool,
  }),
  clearDuplicates: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CLEAR_DUPLICATES,
  }),
  claimAdjustersStart: () => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_START,
  }),
  claimAdjustersSuccess: (payload) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_SUCCESS,
    payload,
  }),
  claimAdjustersError: (err) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_ERROR,
    payload: err,
  }),
  claimAdjustersCancel: (message) => ({
    type: Types.CREATE_OR_UPDATE_CLAIM.CLAIM_ADJUSTERS_CANCEL,
    payload: message,
  }),
};

export const GetClaim = {
  start: (id) => ({
    type: Types.GET_CLAIM.START,
    payload: id,
  }),
  success: (claim) => ({
    type: Types.GET_CLAIM.SUCCESS,
    payload: claim,
  }),
  error: () => ({
    type: Types.GET_CLAIM.ERROR,
  }),
};

export const GetSharedClaim = {
  start: () => ({
    type: Types.GET_SHARED_CLAIM.START,
  }),
  success: (claim) => ({
    type: Types.GET_SHARED_CLAIM.SUCCESS,
    payload: claim,
  }),
  error: (err) => ({
    type: Types.GET_SHARED_CLAIM.ERROR,
    payload: err,
  }),
  customFieldsStart: () => ({
    type: Types.GET_SHARED_CLAIM.CUSTOM_FIELDS_START,
  }),
  customFieldsSuccess: (customFields) => ({
    type: Types.GET_SHARED_CLAIM.CUSTOM_FIELDS_SUCCESS,
    payload: customFields,
  }),
  customFieldsError: (err) => ({
    type: Types.GET_SHARED_CLAIM.CUSTOM_FIELDS_ERROR,
    payload: err,
  }),
};

export const setClaim = (payload) => ({
  type: Types.SET_ON_CLAIM.TYPE,
  payload,
});

export const SetOnClaim = {
  value: (payload) => ({ type: Types.SET_ON_CLAIM.VALUE, payload }),
  client: (payload) => ({ type: Types.SET_ON_CLAIM.CLIENT, payload }),
  client1: (payload) => ({ type: Types.SET_ON_CLAIM.CLIENT1, payload }),
  client2: (payload) => ({ type: Types.SET_ON_CLAIM.CLIENT2, payload }),
  contact: (payload) => ({ type: Types.SET_ON_CLAIM.CONTACT, payload }),
  property: (payload) => ({
    type: Types.SET_ON_CLAIM.PROPERTY,
    payload,
  }),
  mailing: (payload) => ({ type: Types.SET_ON_CLAIM.MAILING, payload }),
  claimInfo: (payload) => ({ type: Types.SET_ON_CLAIM.CLAIM_INFO, payload }),
  claimAdjuster: (payload) => ({
    type: Types.SET_ON_CLAIM.CLAIM_ADJUSTER,
    payload,
  }),
  newClaimAdjuster: () => ({
    type: Types.SET_ON_CLAIM.NEW_CLAIM_ADJUSTER,
  }),
  deleteClaimAdjuster: (payload) => ({
    type: Types.SET_ON_CLAIM.DELETE_CLAIM_ADJUSTER,
    payload,
  }),
  vendor: (payload) => ({ type: Types.SET_ON_CLAIM.VENDOR, payload }),
  attorney: (payload) => ({ type: Types.SET_ON_CLAIM.ATTORNEY, payload }),
  assignedAdjusters: (payload) => ({
    type: Types.SET_ON_CLAIM.ASSIGNED_ADJUSTERS,
    payload,
  }),
  otherAdjustersAllowed: (payload) => ({
    type: Types.SET_ON_CLAIM.OTHER_ADJUSTERS_ALLOWED,
    payload,
  }),
  customFields: (payload) => ({
    type: Types.SET_ON_CLAIM.CUSTOM_FIELDS,
    payload,
  }),
  customField: (payload) => ({
    type: Types.SET_ON_CLAIM.CUSTOM_FIELD,
    payload,
  }),
};

export const ClaimAdjusters = {
  getStart: (id) => ({
    type: Types.CLAIM_ADJUSTERS.GET_START,
    payload: id,
  }),
  getSuccess: (payload) => ({
    type: Types.CLAIM_ADJUSTERS.GET_SUCCESS,
    payload,
  }),
  getError: (err) => ({
    type: Types.CLAIM_ADJUSTERS.GET_ERROR,
    payload: err,
  }),
  deleteStart: (id) => ({
    type: Types.CLAIM_ADJUSTERS.DELETE_START,
    payload: id,
  }),
  deleteSuccess: (payload) => ({
    type: Types.CLAIM_ADJUSTERS.DELETE_SUCCESS,
    payload,
  }),
  deleteError: (err) => ({
    type: Types.CLAIM_ADJUSTERS.ADJUSTERS_ERROR,
    payload: err,
  }),
};

export const updateCcProjectId = (ccProjectId) => ({
  type: Types.UPDATE_CC_PROJECT_ID,
  payload: ccProjectId
});
