import React, { useState } from "react";
import * as Api from "../../../Api";
import { Notify } from "../../../services/Notify.service";
import { FullPageSpinner } from "../../_common/Spinner";
import { ImportScheduled } from "./ImportScheduled";
import ReviewImportImage from "../../../assets/images/import-claim/mapping-confirmation.svg";

export function ReviewImport({
  reviewImport,
  setReviewImport,
  setShowMapView,
  mappedData,
  uplodedFileId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [showImportScheduleModal, setShowImportScheduleModal] = useState(false);
  const cancel = () => {
    window.location.reload();
  };

  const finish = () => {
    setIsLoading(true);
    Api.Claims.Import.mapData({
      id: uplodedFileId,
      mappedData,
    })
      .then((data) => {
        setToken(data.data.importLinkToken);
      })
      .catch(() => {
        Notify.error(
          "Unable to get Data From your Uploded File. Please Upload again."
        );
      })
      .finally(() => {
        setIsLoading(false);
        setShowImportScheduleModal(true);
      });
  };

  const goPrevious = () => {
    setReviewImport(false);
    setShowMapView(true);
  };

  return (
    <div>
      {isLoading && <FullPageSpinner />}
      {reviewImport && (
        <div>
          <ImportScheduled
            token={token}
            openPanel={showImportScheduleModal}
            setOpenPanel={setShowImportScheduleModal}
          />
          <div className="portlet-body form">
            <div>
              <label
                className="col-md-12 control-label"
                style={{
                  textAlign: "left",
                  paddingBottom: "12px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <h3 style={{ lineHeight: "4.5rem" }}>
                  <span className="label label-sm label-danger">
                    Instructions
                  </span>
                  <br />
                </h3>
                <h3>
                  Confirm the Import?
                  <br />
                  <br />
                  Use
                  <span className="bold">Previous</span>
                  button to make any changes. Use
                  <span className="bold">Finish</span> to complete import.
                </h3>
              </label>
            </div>
            <div className="m-grid m-grid-responsive-md m-grid-demo">
              <div className="m-grid-row">
                <div
                  className="col-md-11"
                  style={{ padding: "30px", border: "none", margin: "2rem" }}
                >
                  <div className="m-grid m-grid-full-height">
                    <div
                      className="m-grid-col m-grid-col-middle m-grid-col-center "
                      style={{
                        margin: "20px",
                        border: "none",
                        background: "none",
                      }}
                    >
                      <img
                        alt="Review Import"
                        src={ReviewImportImage}
                        style={{ textAlign: "center" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form className="form-horizontal" action="#">
              <div className="form-actions">
                <div className="col-md-offset-2 col-md-9">
                  <button
                    onClick={() => goPrevious()}
                    type="button"
                    className="btn grey-salsa btn-outline"
                    style={{
                      padding: "12px 29px 12px 29px",
                      borderRadius: "4px",
                      marginRight: "5px",
                    }}
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ paddingRight: "5px" }}
                    ></i>
                    Previous
                  </button>
                  <button
                    onClick={() => finish()}
                    type="button"
                    className="btn btn-outline blue-primary"
                    style={{
                      padding: "12px 49px 12px 49px",
                      borderRadius: "4px",
                      marginRight: "5px",
                    }}
                  >
                    <i
                      className="fa fa-check"
                      style={{ paddingRight: "5px" }}
                    ></i>
                    Finish
                  </button>
                  <button
                    onClick={() => cancel()}
                    type="button"
                    className="btn grey-salsa btn-outline"
                    style={{
                      padding: "12px 29px 12px 29px",
                      borderRadius: "4px",
                      marginRight: "5px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
