import React, {useCallback, useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Pagebar} from '../layout/Pagebar';
import {AdminDatatable} from './tables';
import {AddNewAndEdit} from './addNewAndEdit';
import {DeleteSweetAlertConfirmation} from '../_common/Sweetalert';
import {Dropdown} from '../_common/Dropdown';
import DownloadCSV from '../_common/DownloadCSV';
import DownloadPDF from '../_common/DownloadPDF';
import DownloadImage from '../_common/DownloadImage';
import Modal from "react-modal";

const DOWNLOAD_CSV_HEADERS = [
  {label: 'Admin Name', key: 'name'},
  {label: 'Email Address', key: 'email'},
  {label: 'Phone #', key: 'phone'},
  {label: 'Extension', key: 'phoneExt'},
  {label: 'Fax #', key: 'fax'},
  {label: 'Status', key: 'status'}
];

const DOWNLOAD_PDF_HEADERS = [
  {header: 'Admin Name', dataKey: 'name'},
  {header: 'Email Address', dataKey: 'email'},
  {header: 'Phone #', dataKey: 'phone'},
  {header: 'Extension', dataKey: 'phoneExt'},
  {header: 'Fax #', dataKey: 'fax'},
  {label: 'Status', key: 'status'}
];

const BREADCRUMB = [
  {title: 'Home', link: '/app/claims'},
  {title: 'Admins', link: ''}
];

export function Admins() {
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteSelectedAdmin, setDeleteSelectedAdmin] = useState({});
  const [opensidePanel, setOpensidePanel] = useState(false);
  const [adminDetails, setAdminDetails] = useState({});
  const [modal, setModal] = useState('');
  const [modalIsWaiting, setModalIsWaiting] = useState(false);

  const fetchAdmin = useCallback(async function _fetchAdmin() {
    try {
      setIsLoading(true);
      const data = await Api.Admins.list();
      if (data.data && data.data.admins) {
        setAdmins(data.data.admins);
      }
    } catch (err) {
      const error = Api.getError(err);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setAdmins, setError]);

  useEffect(() => {fetchAdmin();}, [fetchAdmin]);

  function confirmDelete(selectedAdmin) {
    setShowDeleteConfirmation(true);
    setDeleteSelectedAdmin(selectedAdmin);
  }

  function saveAsPdf() {
    return DownloadPDF({
      data: admins,
      headers: DOWNLOAD_PDF_HEADERS,
      filename: 'admins.pdf'
    });
  }

  async function deleteAdmin(confirm) {
    if (confirm) {
      try {
        await Api.Admins.delete({id: deleteSelectedAdmin.id});
        fetchAdmin();
      } catch (err) {
        //intentionally empty
      } finally {
        setDeleteSelectedAdmin({});
        setShowDeleteConfirmation(false);
        updateSubscription();
      }
    } else {
      setShowDeleteConfirmation(false);
    }
  }

  async function didUpdateOrAdd() {
    await updateSubscription();
    await fetchAdmin();
  }

  async function updateSubscription() {
    setIsLoading(true);
    const subscriptionResponse = await Api.Billing.updateSubscription(null);
    if (subscriptionResponse.data.message !== 'no change') {
      console.log(subscriptionResponse.data);
      setModal('subscriptionChanged');
    }
  }

  function handleOpenSidePanel(e) {
    e.preventDefault();
    setOpensidePanel(true);
    setAdminDetails({});
  }

  const hideModal = () => {
    setModalIsWaiting(false);
    setModal('')
  }

  const messages = {
    subscriptionChanged: {
      title: 'Subscription Changed',
      body: (<>
          <p>
              Your subscription has changed and will be prorated accordingly. Please see the <a href="/billing">billing page</a> for more details.
          </p>
      </>),
      buttons: [
          {
              text:'OK',
              action: hideModal,
              isPrimary: true,
          }
      ]
    }
  }

  return (
    <div>
      <Pagebar breadcrumbs={BREADCRUMB}/>
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              Admins
            </span>
          </div>
          <div className="actions"/>
        </div>
        <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={deleteAdmin}/>
        <AddNewAndEdit openPanel={opensidePanel} setOpenPanel={setOpensidePanel} adminDetails={adminDetails}
                       reloadTable={didUpdateOrAdd}/>
        <div className="portlet-body">
          <div className="table-toolbar">
            <div className="row">
              <div className="col-md-6">
                <div className="btn-group">
                  <button className="btn blue-primary btn-outline add_new" onClick={handleOpenSidePanel}>
                    Add New <i className="fa fa-plus"/>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="btn-group pull-right">
                  <Dropdown title="Tools" variant="red">
                    <Dropdown.ListItem onClick={() => false}>
                      <DownloadImage Data={admins} headers={DOWNLOAD_PDF_HEADERS} TableName="Admins"/>
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={saveAsPdf}>
                      Save as PDF
                    </Dropdown.ListItem>
                    <Dropdown.ListItem onClick={() => false}
                                       onlyLI={
                                         <DownloadCSV data={admins} filename="admins.csv" headers={DOWNLOAD_CSV_HEADERS}
                                                      target="_blank"/>
                                       }/>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="table-scrollable">
            <AdminDatatable admins={admins} isLoading={isLoading} error={error} setOpensidePanel={setOpensidePanel}
                            setAdminDetails={setAdminDetails} confirmDelete={confirmDelete}/>
          </div>
        </div>
      </div>
      <Modal
        isOpen={(modal !== '')}
        className="Modal__Bootstrap modal-dialog"
        ariaHideApp={false}
    >
        {(modal !== '') && <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title tw-text-2xl" style={{float: 'left'}}>{messages[modal].title}</h4>
                <button type="button" className="close" disabled={modalIsWaiting} onClick={hideModal}
                    ref={(node) => node?.style.setProperty("background-image", "none", "important")}
                    style={{fontSize: 24, float: 'right', width: 'auto', textIndent: 0}}>
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                </button>
            </div>
            <div className="modal-body">
                {messages[modal].body}
            </div>
            <div className="modal-footer">
                {messages[modal].buttons.map((button, i) => 
                    <button key={i} type="button" 
                        className={'btn btn-' + ((button.isPrimary) ? 'primary tw-bg-primary' : 'secondary tw-bg-gray-300')}
                        onClick={button.action} disabled={modalIsWaiting || button.isDisabled}>{button.text}</button>
                )}
            </div>
        </div>}
    </Modal>
    </div>
  );
}
