import React,{useState} from "react";
import "../../assets/css/global/css/plugins.min.css";
import "../../assets/css/global/plugins/datatables/datatables.min.css";

import { ToolBar } from "./ToolBar";
import { LetterToolbar } from "./LetterToolbar";
import { PaginationPanel } from "./PaginationPanel";
import { useClaims } from "./ClaimsTable.hooks";
import { ClaimsTableHeader } from "./ClaimsTableHeader";
import { FilterHead } from "./filters/FilterHead";
import { ListRenderer } from "../_common/ListRenderer";
import { TableRow } from "./TableRow";
import { FullPageSpinner } from "../_common/Spinner";

export function AttorneyFiles() { 
    const [selectedArchiveClaims, setSelectedArchiveClaims] = useState([]);
    const [fullPageSpinner, setFullPageSpinner] = useState(false);
    const {
        isLoading,
        error,
        claims,
        sortBy,
        orderBy,
        adjusters,
        lossCities,
        lossZipcodes,
        status,
        insurers,
        limitPerPage,
        currentPage,
        allSearchParams,
        searchParams,
        setLimitPerPage,
        total,
        createdAtDateRange,
        setCreatedAtDateRange,
        setSortBy,
        setOrderBy,
        setCurrentPage,
        dateOfLossRange,
        setDateOfLossRange,
    } = useClaims();

    const checkAllCheckbox = (isCheck) => {
        if(isCheck){
          const newArray = claims.map((c) => {
            return c.id;
          });
          setSelectedArchiveClaims(newArray);
        }
        if(!isCheck){
          setSelectedArchiveClaims([]);
        }
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="portlet light portlet-fit portlet-datatable bordered">
                    <ToolBar 
                        claims={claims} 
                        setFullPageSpinner = {setFullPageSpinner} 
                        searchParams = {allSearchParams} 
                        currentPage={currentPage} 
                        limitPerPage={limitPerPage} 
                        sortBy={sortBy} 
                        orderBy= {orderBy}
                    />
                    <div className="portlet-body">
                        <LetterToolbar
                            clientNameStartsWithLetter={
                                searchParams.clientNameStartsWithLetter
                            }
                            setClientNameStartsWithLetter={
                                searchParams.setClientNameStartsWithLetter
                            }
                            onSearchSubmit={searchParams.onSearchSubmit}
                            onResetClick={searchParams.onLetterFilterResetClick}
                        />
                        {fullPageSpinner && <FullPageSpinner/>}
                        <div className="table-container">
                            <div className="table-scrollable">
                                <div className="dataTables_wrapper dataTables_extended_wrapper no-footer">
                                    <PaginationPanel
                                        limitPerPage={limitPerPage}
                                        setLimitPerPage={setLimitPerPage}
                                        total={total}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                    <table
                                        className="table table-striped table-bordered  table-checkable dataTable no-footer"
                                        role="grid"
                                    >
                                        <thead>
                                            <ClaimsTableHeader
                                                sortBy={sortBy}
                                                setSortBy={setSortBy}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                                checkAllCheckbox = {checkAllCheckbox}
                                            />
                                            <FilterHead
                                                adjusters={adjusters}
                                                lossCities={lossCities}
                                                lossZipcodes={lossZipcodes}
                                                insurers={insurers}
                                                statusData={status}
                                                createdAtDateRange={createdAtDateRange}
                                                setCreatedAtDateRange={setCreatedAtDateRange}
                                                dateOfLossRange={dateOfLossRange}
                                                setDateOfLossRange={setDateOfLossRange}
                                                {...searchParams}
                                            />
                                        </thead>
                                        <tbody>
                                            <ListRenderer
                                                isLoading={isLoading}
                                                data={claims}
                                                error={error}
                                                emptyStateMessage="No claims found."
                                                isTable = {true}
                                                tableColomns = {10}
                                            >
                                                {claims.map((claim, index) => (
                                                    <TableRow
                                                        key={index}
                                                        claim={claim}
                                                        selectedArchiveClaims = {selectedArchiveClaims}
                                                        setSelectedArchiveClaims = {setSelectedArchiveClaims}
                                                    />
                                                ))}
                                            </ListRenderer>
                                        </tbody>
                                    </table>
                                    <PaginationPanel
                                        limitPerPage={limitPerPage}
                                        setLimitPerPage={setLimitPerPage}
                                        total={total}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}