import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ClaimActions} from '../../../../store/claims';
import {Form} from '../../../_common/form/Form';
import {Row} from '../../../_common/layout/Row';
import {Col} from '../../../_common/layout/Col';
import {AutoAddressFields} from './AutoAddressFields';

export function MailingAddress() {
  const {isSharedView} = useSelector((state) => state.claims.claim);
  const dispatch = useDispatch();
  const {mailing, isMailingSameAsProperty, property} = useSelector((state) => state.claims.claim.data.client);

  function setMailingAddress(data) {
    dispatch(ClaimActions.SetOnClaim.mailing({type: undefined, ...data}));
  }

  function setMailingAddressField(data) {
    dispatch(ClaimActions.SetOnClaim.mailing({type: 'field', ...data}));
  }

  function setIsSame() {
    dispatch(
      ClaimActions.SetOnClaim.client({name: 'isMailingSameAsProperty', value: !isMailingSameAsProperty})
    );

    setMailingAddress({...property});
  }

  return (
    <>
      <Row>
        <Col md={6}>Mailing Address</Col>
        <Col md={6}>
          <Form.Check data-testid="same-as-property" label="Same as Property Address" checked={isMailingSameAsProperty}
                      disabled={isSharedView} onChange={() => setIsSame((prevState) => !prevState)}/>
        </Col>
      </Row>

      {
        !isMailingSameAsProperty && (
          <AutoAddressFields dataTestIdPrefix="mailing" address={mailing} label="Enter Mailing Address"
                             isShowCountry={false} disabled={isSharedView} setAddress={setMailingAddress}
                             setAddressField={setMailingAddressField}/>
        )
      }
    </>
  );
}
