import React from "react";

import { TermsWrapper } from "./TermsWrapper";
export const TermsAndConditions = () => {
  // const appName = process.env.REACT_APP_NAME;
  const appDomain = process.env.REACT_APP_DOMAIN;
  const brandCompanyName = process.env.REACT_APP_BRAND_COMPANY_NAME;
  const brandAddress = process.env.REACT_APP_BRAND_ADDRESS;
  return (
    <TermsWrapper title="Terms & Conditions" lastUpdated="May 22, 2018">
      <section className="content">
        <div className="container">
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the{" "}
          <a href={appDomain}>{appDomain}</a> website (the "Service") operated
          by {brandCompanyName} ("us", "we", or "our").
          <br />
          <br />
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
          <br />
          <br />
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </div>
      </section>
      <section className="content bg-grey">
        <div className="container">
          <h2 className="title">TERMINATION</h2>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
          <br />
          <br />
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </div>
      </section>

      <section className="content">
        <div className="container">
          <h2 className="title">Links To Other Web Sites</h2>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled {brandCompanyName} has no control
          over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You
          further acknowledge and agree that {brandCompanyName} shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such web sites or services.
          <br />
          <br />
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </div>
      </section>

      <section className="content bg-grey">
        <div className="container">
          <h2 className="title">GOVERNING LAW</h2>
          These Terms shall be governed and construed in accordance with the
          laws of United States, without regard to its conflict of law
          provisions.
          <br />
          <br />
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </div>
      </section>

      <section className="content">
        <div className="container">
          <h2 className="title">CHANGES</h2>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
          <br />
          <br />
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </div>
      </section>

      <section className="content bg-grey">
        <div className="container">
          <h2 className="title">CONTACT US</h2>
          If you have any questions about these Terms, please contact us. We are
          located at {brandAddress}.
        </div>
      </section>
    </TermsWrapper>
  );
};
