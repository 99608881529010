import React, {useEffect, useState} from 'react';

import {PhotoGallary} from '../../../_common/photoGallary';
import {FullPageSpinner} from '../../../_common/Spinner';
import {AttachmentTabTopSection} from './AttachmentTabTopSection';
import {useAttachments} from './AttachmentHooks';

export function PhotosTab({claimId, folderId, folderName, selectedFiles, setSelectedFiles, isSharedView}) {
  const [allFilesId, setAllFilesId] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const {
    folderFiles, uploadFiles, uploadOnAws, changeStatusOfFile, deleteFiles, downloadFile, reloadFolderFiles, moveFiles
  } = useAttachments({
    claimId, folderId, selectedFiles, setSelectedFiles, setIsloading, isSharedView
  });

  function selectAll(evt) {
    if (evt.target.checked) {
      setSelectedFiles(folderFiles.map(f => f.id));
    }
    if (!evt.target.checked) {
      setSelectedFiles([]);
    }
  }

  function handleCheckbox(id) {
    return evt => {
      const allSelected = [...selectedFiles];
      if (evt.target.checked) {
        allSelected.push(id);
        setSelectedFiles(allSelected);
      }
      if (!evt.target.checked) {
        const index = allSelected.indexOf(id);
        if (index > -1) {
          allSelected.splice(index, 1);
        }
        setSelectedFiles(allSelected);
      }
    };
  }

  useEffect(() => {
    setAllFilesId(folderFiles.map((f) => f.id));
  }, [folderFiles]);

  const allFilesChecked = (allFilesId.length > 0 && allFilesId.sort().join(',') === selectedFiles.sort().join(','));
  return (
    <div>
      {isLoading && <FullPageSpinner/>}
      {
        !isSharedView
        && (<AttachmentTabTopSection folderId={folderId} claimId={claimId} selectedFiles={selectedFiles}
                                     isLoading={isLoading} uploadFiles={uploadFiles} uploadOnAws={uploadOnAws}
                                     changeStatusOfFile={changeStatusOfFile} deleteFiles={deleteFiles}
                                     downloadFile={downloadFile} setIsloading={setIsloading}
                                     reloadFolderFiles={reloadFolderFiles} moveFiles={moveFiles} folderName={folderName}/>)
      }
      <div className="row">
        <div className="col-sm-12">
          <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
            <input className="form-control" style={{height: '18px'}} type="checkbox" checked={allFilesChecked}
                   onChange={selectAll}/>
            <span/>
          </label>
          <span> Select All</span>
        </div>
      </div>
      {
        (folderFiles.length === 0) &&
        <div className="row">
          <div className="col-sm-12">
            <div style={{textAlign: 'center'}}>
              All Your {folderName} will show here. Drop file above to add a new file.
            </div>
          </div>
        </div>
      }
      {
        (folderFiles.length !== 0) &&
        <div className="row">
          <div className="col-sm-12">
            <PhotoGallary picturs={folderFiles} selectedFiles={selectedFiles} showCheckBox={true}
                          checkBoxEvent={handleCheckbox}/>
          </div>
        </div>
      }
    </div>
  );
}
