import { ThemeTypes as Types } from "./index";

const INITIAL_THEME_STATE = {
  sidebar: {
    isCollapsed: false,
  },
};

export default function ThemeReducer(
  state = INITIAL_THEME_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.SIDEBAR.IS_COLLAPSED:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          isCollapsed: !state.sidebar.isCollapsed,
        },
      };

    default:
      return state;
  }
}
