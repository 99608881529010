import React from 'react';

import useClaimTableQueryParams from './useClaimTableQueryParams';

const COLUMNS = [
  {id: 'fileNumber', name: 'File #', width: '4%'},
  {id: 'createdByDate', name: 'Date', width: '10%'},
  {id: 'clientName', name: 'Client', width: '15%'},
  {id: 'assignedAdjusterName', name: 'Adjuster Assigned', width: '10%'},
  {id: 'dateOfLoss', name: 'DOL', width: '10%'},
  {id: 'lossCity', name: 'Loss Address', width: '15%'},
  {id: 'insurer', name: 'Insurer', width: '15%'},
  {id: 'status', name: 'Status', width: '7%'}
];

export function ClaimsTableHeader({checkAllCheckbox}) {
  const [query, setQuery] = useClaimTableQueryParams();

  const {sortBy, orderBy} = query;

  function handleColumnSort(newSortBy) {
    return () => {
      let newOrderBy = 'asc';
      if (sortBy === newSortBy) {
        newOrderBy = 'asc' === orderBy ? 'desc' : 'asc';
      }
      setQuery({...query, sortBy: newSortBy, orderBy: newOrderBy});
    };
  }

  return (
    <tr role="row" className="heading">
      <th style={{width: '1%'}} rowSpan="1" colSpan="1">
        <label className="mt-checkbox mt-checkbox-single mt-checkbox-outline">
          <input className="group-checkable" type="checkbox" data-set="#sample_2 .checkboxes"
                 onChange={(e) => checkAllCheckbox(e.target.checked)}/>
          <span/>
        </label>
      </th>
      {
        COLUMNS.map((column) => {
          let columnClassName = 'sorting';
          if (sortBy === column.id) {
            columnClassName += `_${orderBy}`;
          }
          return (
            <th className={columnClassName} style={{width: column.width}} key={column.id} rowSpan="1" colSpan="1"
                onClick={handleColumnSort(column.id)}>
              {column.name}
            </th>
          );
        })
      }

      <th style={{width: '10%'}} className="sorting" rowSpan="1" colSpan="1">
        Actions
      </th>
    </tr>
  );
}
