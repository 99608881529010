import {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import * as Api from '../../Api';
import {mapClaims} from '../claims/ClaimsTable.mapper';
import {useSearch} from './ClaimsTableSearch.hooks';

const DEFAULT_SORT_BY = 'fileNumber';
const DEFAULT_ORDER_BY = 'desc';
const DEFAULT_LIMIT_PER_PAGE = 20;
const DEFAULT_CURRENT_PAGE = 1;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DEFAULT_TABLE_FILTERS = {
  fileNumber: '',
  clientName: '',
  claimNumber: '',
  assignedAdjusters: [],
  cities: [],
  zipcodes: [],
  street: '',
  insurers: [],
  status: '',
  createdAtFromDate: '',
  createdAtToDate: '',
  dolFromDate: '',
  dolToDate: ''
};

const DEFAULT_LETTER_TOOLBAR_FILTERS = {
  clientNameStartsWithLetter: ''
};

function setQueryParams({query, history, ...params}) {
  Object.keys(params)
    .forEach((key) => {
      query.set(key, params[key]);
    });

  history.push({search: query.toString()});
}

export function useClaims() {

  const query = useQuery();
  const history = useHistory();

  const INITIAL_SEARCH_PARAMS = {
    fileNumber: query.get('fileNumber') || '',
    clientNameStartsWithLetter: query.get('clientNameStartsWithLetter') || ''
  };
  const [claims, setClaims] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(
    query.get('createdBy') || DEFAULT_SORT_BY
  );
  const [orderBy, setOrderBy] = useState(
    query.get('orderBy') || DEFAULT_ORDER_BY
  );
  const [limitPerPage, setLimitPerPage] = useState(
    query.get('limitPerPage') || DEFAULT_LIMIT_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(
    Number(query.get('currentPage')) || DEFAULT_CURRENT_PAGE
  );
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useState(INITIAL_SEARCH_PARAMS);
  const [createdAtDateRange, setCreatedAtDateRange] = useState('');
  const [dateOfLossRange, setDateOfLossRange] = useState('');

  const [lossCities, setLossCities] = useState([]);
  const [lossZipcodes, setLossZipcodes] = useState([]);

  const [insurers, setInsurers] = useState([]);

  const [status, setStatus] = useState([]);

  const formattedClaims = mapClaims(claims);

  const [adjusters, setAdjusters] = useState([]);

  async function fetchAdjusters() {
    try {
      const {data} = await Api.Claims.Adjusters.get();
      const {adjusters, admins, superadmin} = data.members;
      const allAdjusters = [
        ...(adjusters ? [...adjusters] : []),
        ...(admins ? [...admins] : []),
        ...(superadmin ? [...superadmin] : [])
      ];
      setAdjusters(allAdjusters);

    } catch (err) {
      console.log('Error is getting all cities for all Claims.');
      console.log(err);
    }
  }

  async function fetchInsurers() {
    try {
      const {data} = await Api.Claims.Insurers.get();
      setInsurers(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchStatus() {
    try {
      const {data} = await Api.Claims.Status.get();
      const archiveData = data.filter((obj) => obj.statusName === 'Archive');
      setStatus(archiveData);
    } catch (err) {
      console.log(err);
      console.log('Error in fetching status.');

    }
  }

  async function fetchCitiesAndZip() {
    try {
      const {data} = await Api.Claims.getCitiesAndZip();
      setLossCities(data.data.cities);
      setLossZipcodes(data.data.zips);
    } catch (err) {
      console.log('Error is getting all cities for all Claims.');
      console.log(err);
    }
  }

  function resetTableFilters() {
    setQueryParams({
      query,
      history,
      sortBy,
      orderBy,
      limitPerPage,
      currentPage,
      ...searchParams
    });
    setSearchParams(DEFAULT_TABLE_FILTERS);
    setCreatedAtDateRange('');
    setDateOfLossRange('');
  }

  function resetLetterFilters() {
    setSearchParams({
      ...searchParams,
      ...DEFAULT_LETTER_TOOLBAR_FILTERS
    });

    setQueryParams({
      query,
      history,
      ...DEFAULT_LETTER_TOOLBAR_FILTERS
    });
  }

  const localSearchParams = useSearch({
    setSearchParams,
    INITIAL_SEARCH_PARAMS,
    resetTableFilters,
    resetLetterFilters
  });

  const getRequestData = useCallback(() => {
    return {
      filter: {
        adjustersId: (searchParams.assignedAdjusters && searchParams.assignedAdjusters.length > 0)
          ? searchParams.assignedAdjusters.join()
          : '',
        city: searchParams.cities,
        fileNumber: searchParams.fileNumber,
        claimNumber: searchParams.claimNumber,
        fromDate: searchParams.createdAtFromDate,
        insurerCompanyName: searchParams.insurers,
        onlyArchiveFile: true,
        clientNameStartCharacter: searchParams.clientNameStartsWithLetter,
        lastName: searchParams.clientName,
        lossFromDate: searchParams.dolFromDate,
        lossToDate: searchParams.dolToDate,
        status: ['archive'],
        streetName: searchParams.street,
        toDate: searchParams.createdAtToDate,
        zip: searchParams.zipcodes
      },
      limit: {start: ((currentPage - 1) * limitPerPage), total: limitPerPage},
      orderBy: {
        colomnName: sortBy,
        orderByFormat: orderBy
      }
    };
  }, [searchParams, currentPage, limitPerPage, sortBy, orderBy]);

  const getClaims = useCallback(async () => {
    try {
      setIsLoading(true);
      const {data} = await Api.Claims.getClaims({requestData: getRequestData()});
      setClaims(data.claims);
    } catch (err) {
      setError(Api.getError(err));
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, getRequestData, setClaims, setError]);

  const getTotalClaims = useCallback(async () => {
    try {
      // setIsLoading(true);
      const {data} = await Api.Claims.getClaimsTotal({requestData: getRequestData()});
      setTotal(data.total);
    } catch (err) {
      setError(Api.getError(err));
    } finally {
      // setIsLoading(false);
    }
  }, [getRequestData, setTotal, setError]);

  useEffect(() => {
    setQueryParams({
      query,
      history,
      sortBy,
      orderBy,
      limitPerPage,
      currentPage,
      ...searchParams
    });
    getClaims();

    //REDTAG:TJH - hook should be rewritten. query and history are misused
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClaims, searchParams, sortBy, orderBy, limitPerPage, currentPage]);

  useEffect(() => {
    getTotalClaims();
  }, [getTotalClaims, searchParams]);

  useEffect(() => {
    fetchCitiesAndZip();
    fetchAdjusters();
    fetchInsurers();
    fetchStatus();
  }, []);

  return {
    isLoading,
    error,
    claims: formattedClaims,
    sortBy,
    orderBy,
    adjusters,
    lossCities,
    lossZipcodes,
    status,
    insurers,
    limitPerPage,
    currentPage,
    allSearchParams: searchParams,
    setSearchParams,
    searchParams: localSearchParams,
    setLimitPerPage,
    total,
    createdAtDateRange,
    setCreatedAtDateRange,
    setSortBy,
    setOrderBy,
    setCurrentPage,
    dateOfLossRange,
    setDateOfLossRange
  };
}
