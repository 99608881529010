import React from "react";
import classNames from "classnames";
import { FullPageSpinner } from "../Spinner";

export const Portlet = ({
  children,
  title,
  isForm,
  classColor,
  isBox,
  isLoading,
}) => {
  return (
    <div className={classNames("portlet", classColor, { box: isBox }, "mt-20")}>
      {title && (
        <div className="portlet-title">
          <div className="caption">{title}</div>
        </div>
      )}
      <div
        className={classNames("portlet-body", { form: isForm })}
        style={{ height: isLoading ? 500 : "auto" }}
      >
        {isLoading ? <FullPageSpinner /> : children}
      </div>
    </div>
  );
};
