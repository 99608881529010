import React from "react";

export function SignupSuccess({ goToLoginPage, clearIsDone }) {
  const handleOk = () => {
    clearIsDone();
    goToLoginPage();
  };
  return (
    <>
      <div
        style={{
          background: "#26c281",
          padding: 0.5,
          marginBottom: 10,
          marginTop: 30,
        }}
      >
        <p style={{ color: "white", textAlign: "center", fontSize: 20 }}>
          Thank you for signing up!
        </p>

        <p style={{ color: "white", textAlign: "center" }}>
          We've sent you an email with the link to activate your account.
        </p>
      </div>

     
      <div>
        <button
          className="btn dark btn-block btn-lg margin-bottom-10"
          type="button"
          onClick={handleOk}
        >
          Ok
        </button>
      </div>
    </>
  );
}
