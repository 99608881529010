import React, { useState } from "react";
import { Pagebar } from "../layout/Pagebar";
import { SendMail } from "./sendMail";

export function Support() {
  const [openSendMail, setOpenSendMail] = useState(false);

  const breadcrumb = [
    {
      title: "Home",
      link: "/app/claims",
    },
    {
      title: "Support",
      link: "",
    },
  ];

  return (
    <div>
      {<Pagebar breadcrumbs={breadcrumb} />}
      <SendMail openPanel={openSendMail} setOpenPanel={setOpenSendMail} />
      <div
        style={{
          background: "white",
          padding: "1rem 3rem",
          paddingBottom: "5rem",
          borderRadius: "4px!important",
          
        }}
        className="well"
      >
        <h1 className="tw-font-bold tw-text-xl tw-mb-4"> 
          <i className="fa fa-support tw-text-gray-500 tw-mr-2.5" ></i>
          Support
        </h1>
        <div className="tw-pb-8 tw-border-b">
          <p className="tw-mb-2">
            Need Help? Have a suggestion? Submit a request to support for a quick response!
          </p>
          <button
            className="btn btn-outline blue-primary"
            onClick={() => {
              setOpenSendMail(true);
            }}
            
          >
            Submit a Support Request
          </button>
        </div>

        <h2 className="tw-font-bold tw-text-lg tw-my-4">Support Articles</h2>
        <ul className="tw-list-disc tw-ml-4">
          <li className="tw-mb-2"><span className="tw-text-orange-500">New!</span> <a href="/support/articles/company-cam" className="tw-text-blue tw-underline visited:tw-text-blue hover:tw-text-blue-800">CompanyCam Integration - How to Use</a></li>
          <li className="tw-mb-2">
            <a href="/docs/calendar_reporting.pdf" target="_blank" rel="noopener noreferrer" className="tw-text-blue tw-underline visited:tw-text-blue hover:tw-text-blue-800">Calendar Reporting</a>
          </li>
          <li className="tw-mb-2">
            <a href="/docs/inbound_email.pdf" target="_blank" rel="noopener noreferrer" className="tw-text-blue tw-underline visited:tw-text-blue hover:tw-text-blue-800">Sending Entire File or Attachments</a>
          </li>
          <li className="tw-mb-2">
            <a href="/docs/reporting_clientsinfo.pdf" target="_blank" rel="noopener noreferrer" className="tw-text-blue tw-underline visited:tw-text-blue hover:tw-text-blue-800">Reporting in Organized Adjusting</a>
          </li>
          <li className="tw-mb-2">
            <a href="/docs/viewing_links.pdf" target="_blank" rel="noopener noreferrer" className="tw-text-blue tw-underline visited:tw-text-blue hover:tw-text-blue-800">Viewing Shared Links</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
