import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldRenderer } from "./FieldRenderer";
import { Row, Col } from "../../../../_common/layout/Layout";
import { CustomFieldTypes } from "../../../../../store/custom-fields";
import { ClaimActions } from "../../../../../store/claims";
import { Spinner } from "../../../../_common/Spinner";
import { Form } from "../../../../_common/form/Form";

const { FIELD_TYPES } = CustomFieldTypes;

export const CustomFields = () => {
  const dispatch = useDispatch();

  const { isSharedView } = useSelector((state) => state.claims.claim);

  const customFields = useSelector(
    (state) => state.claims.claim.data.customFields
  );

  const isLoading = useSelector((state) => state.customFields.isLoading);

  const onClaimInfoChange = (data) =>
    dispatch(
      ClaimActions.SetOnClaim.customFields({
        customFields: data,
      })
    );

  const handleChange = ({ type, id, name, value, optionId }) => {
    const updatedCustomFields = customFields.map((field) => {
      if (String(field.id) !== String(id)) return field;

      // textfield
      const isPrimitiveTextField = [
        FIELD_TYPES.textbox,
        FIELD_TYPES.currency,
        FIELD_TYPES.date,
        FIELD_TYPES.time,
      ].includes(type);

      if (isPrimitiveTextField) {
        return {
          ...field,
          fieldValues: value,
        };
      }

      // dropdown
      if (type === FIELD_TYPES.dropdown) {
        return {
          ...field,
          fieldValues: value,
        };
      }

      // checkbox
      if (type === FIELD_TYPES.checkbox) {
        const updatedOption = field.options.map((option) =>
          option.id === optionId
            ? {
                ...option,
                isSelected: !option.isSelected,
              }
            : option
        );

        const updatedFieldValues = updatedOption
          .filter((opt) => opt.isSelected)
          .map((opt) => opt.id)
          .join(",");

        return {
          ...field,
          options: updatedOption,
          fieldValues: updatedFieldValues,
        };
      }

      return undefined;
    });

    onClaimInfoChange(updatedCustomFields);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const hasCustomFields = customFields?.length;

  if (!hasCustomFields) return null;

  return (
    <>
      <Form.SectionHeading>Custom Fields</Form.SectionHeading>

      <Row>
        {customFields?.map((field, idx) => (
          <Col md={6} key={idx}>
            <FieldRenderer
              type={FIELD_TYPES[field.fieldType]}
              key={field.id}
              props={{
                label: field.displayName,
                value: field.fieldValues,
                options: field.options,
                name: field.name,
                disabled: isSharedView,
                onChange: (e, optionId) =>
                  handleChange({
                    type: field.fieldType,
                    name: field.name,
                    value: e.target.value,
                    id: field.id,
                    optionId,
                  }),
              }}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
