import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Notify} from '../../services/Notify.service';
import {Pagebar} from '../layout/Pagebar';
import {UploadTemplatesDropzone} from './uploadTemplateDropzone';
import {UploadTemplatesNext} from './uploadTemplateNext';
import {InstructionView} from './instructionView';
import {MappingTags} from './MappingTags';
import {MappingConfirmationModal} from './MappingConfirmationModal';
import {PreFinalStep} from './PreFinalStep';
import {PreviewModal} from './PreviewModal';

const BREADCRUMB = [
  {title: 'Home', link: '/app/claims'},
  {title: 'Import Document Template', link: ''}
];

const NO_MARGINS = {marginLeft: '0px', marginRight: '0px'};

const INSTRUCTIONS_DIV_STYLE = {
  textAlign: 'left',
  paddingBottom: '12px',
  backgroundColor: '#f2f2f2',
  paddingTop: '10px'
};

const INSTRUCTIONS_P_STYLE = {
  lineHeight: '3.5rem',
  fontSize: '21px',
  fontWeight: 'normal',
  fontFamily: 'Lato'
};

const TEMPLATE_FORMATTING_INSTRUCTIONS =
  `BEFORE YOU UPLOAD - Add $ and { } to the words you want the system to identify for merge fields. For example email 
  will be \${email}. Do this to all fields you want the system to merge before adding your documents to the system.`;

function TemplateFormattingInstructions() {
  return (
    <div className="row" style={NO_MARGINS}>
      <div className="col-md-12 control-label" style={INSTRUCTIONS_DIV_STYLE}>
                <span className="label label-sm label-danger">
                  Instructions
                </span>
        <p style={INSTRUCTIONS_P_STYLE}>
          {TEMPLATE_FORMATTING_INSTRUCTIONS}
        </p>
      </div>
    </div>
  );
}

export function UploadTemplates() {
  const [dropzoneView, setDropzoneView] = useState(true);
  const [instructionView, setInstructionView] = useState(false);
  const [uploadTemplateNextShow, setUploadTemplateNextShow] = useState(false);
  const [mappingTagsUi, setMappingUi] = useState(false);
  const [showMappingConfirmation, setShowMappingConfirmation] = useState(false);
  const [showPreFinalStep, setShowPreFinalStep] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const [uploadedFile, setUploadedFile] = useState('');
  const [uploadedFileId, setUploadedFileId] = useState('0');
  const [allMappingTags, setAllMappingTags] = useState([]);
  const [allFieldsOfFile, setAllFieldsOfFile] = useState([]);
  const [currentMappedData, setCurrentMappedData] = useState([]);
  const [finalDocId, setFinalDocId] = useState(0);

  const unmappedCount = allFieldsOfFile.length - currentMappedData.length;

  function setExcelFile(file) {
    setUploadedFile(file);
    setDropzoneView(false);
    setUploadTemplateNextShow(true);
  }

  function handleShowInstructions() {
    setInstructionView(true);
    setDropzoneView(false);
  }

  function handleCloseInstructions() {
    setInstructionView(false);
    setDropzoneView(true);
  }

  function handleCancelUpload() {
    setDropzoneView(true);
    setUploadTemplateNextShow(false);
  }

  function handleUploaded(file_id) {
    setUploadedFileId(file_id);
    setUploadTemplateNextShow(false);
    setMappingUi(true);
  }

  function handleNext() {
    if (unmappedCount === 0) {
      setMappingUi(false);
      setShowPreFinalStep(true);
    } else if (unmappedCount > 0) {
      setShowMappingConfirmation(true);
    }
  }

  function handleCancelMapping() {
    setUploadTemplateNextShow(true);
    setMappingUi(false);
  }

  function handleConfirm() {
    setMappingUi(false);
    setShowMappingConfirmation(false);
    setShowPreFinalStep(true);
  }

  function handleCancelConfirm() {
    setShowMappingConfirmation(false);
  }

  function handleShowMappingUI() {
    setShowPreFinalStep(false);
    setMappingUi(true);
  }

  function handleShowPreview({data}) {
    setFinalDocId(data.data_original);
    setShowPreviewModal(true);
    Notify.success('Import Complete!');
  }

  function navigateToTemplates() {
    setShowPreviewModal(false);
    window.location.href = '/app/templates';
  }

  useEffect(() => {
    async function fetchMappingTags() {
      try {
        const {data} = await Api.Document.legacy.getMappingTags();
        setAllMappingTags(data);
      } catch (err) {
        console.log(err);
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    fetchMappingTags();
  }, [setAllMappingTags]);

  return (
    <div>
      <Pagebar breadcrumbs={BREADCRUMB}/>
      {dropzoneView && (
        <UploadTemplatesDropzone allMappingTags={allMappingTags} onFileSelected={setExcelFile}
                                 onShowInstructions={handleShowInstructions}>
          <TemplateFormattingInstructions/>
        </UploadTemplatesDropzone>
      )}
      {instructionView && (
        <InstructionView onClose={handleCloseInstructions}/>
      )}
      {uploadTemplateNextShow && (
        <UploadTemplatesNext uploadedFile={uploadedFile} onSeeInstructions={handleShowInstructions}
                             onCancel={handleCancelUpload} onUploaded={handleUploaded}>
          <TemplateFormattingInstructions/>
        </UploadTemplatesNext>
      )}
      {mappingTagsUi && (
        <MappingTags uploadedFileId={uploadedFileId} allMappingTags={allMappingTags}
                     setCurrentMappedData={setCurrentMappedData} currentMappedData={currentMappedData}
                     allFieldsOfFile={allFieldsOfFile} setAllFieldsOfFile={setAllFieldsOfFile}
                     onNext={handleNext} onCancel={handleCancelMapping}/>
      )}
      {showMappingConfirmation && (
        <MappingConfirmationModal openPanel={showMappingConfirmation} onConfirm={handleConfirm}
                                  unmappedCount={unmappedCount}
                                  onCancel={handleCancelConfirm}/>
      )}
      {showPreFinalStep && (
        <PreFinalStep currentMappedData={currentMappedData} uploadedFileId={uploadedFileId}
                      onPrevious={handleShowMappingUI} onFinish={handleShowPreview}/>
      )}
      {showPreviewModal && (
        <PreviewModal finalDocId={finalDocId} onClose={navigateToTemplates}/>
      )}
    </div>
  );
}
