import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { AlertPopup } from "../../../_common/AlertPopup";
import { ClaimActions, ClaimTypes } from "../../../../store/claims";

export const DuplicateClientAlert = () => {
  const dispatch = useDispatch();
  const { list: duplicateClients, allowDuplicateStatus } = useSelector(
    (state) => state.claims.claim.duplicateClients
  );

  const hasDuplicateClients = duplicateClients?.length;

  const show = hasDuplicateClients;

  const clearDuplicates = () => {
    dispatch(ClaimActions.CreateOrUpdateClaim.clearDuplicates());
  };

  const handleClose = () => {
    dispatch(
      ClaimActions.CreateOrUpdateClaim.duplicateSetAllow(
        ClaimTypes.ALLOW_DUPLICATE_STATUSES.NOT_ALLOWED
      )
    );
  };

  const handleConfirm = () => {
    dispatch(
      ClaimActions.CreateOrUpdateClaim.duplicateSetAllow(
        ClaimTypes.ALLOW_DUPLICATE_STATUSES.ALLOW
      )
    );
    clearDuplicates();

    dispatch(ClaimActions.CreateOrUpdateClaim.preCheck());
  };

  if (!hasDuplicateClients) {
    return null;
  }

  const hasSelectedToNotAllow =
    allowDuplicateStatus === ClaimTypes.ALLOW_DUPLICATE_STATUSES.NOT_ALLOWED;

  if (hasSelectedToNotAllow) {
    return null;
  }

  return (
    <AlertPopup
      show={show}
      warning
      title="Duplicate Client Alert"
      onConfirm={handleConfirm}
      confirmBtnText="Create New, Anyways"
      cancelBtnText="Cancel"
      onCancel={handleClose}
      focusCancelBtn
      showCancel
    >
      <p className="lead text-muted ">
        A Client File already exists for {duplicateClients[0].name}. Would you
        like to continue creating a new file with this name?
      </p>
      <table className="table table-striped table-bordered table-advance table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>File</th>
            <th>Created On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {duplicateClients.map((client, index) => (
            <tr key={client.id}>
              <td>{index + 1}</td>
              <td>{client.clientName}</td>

              <td>{client.createdOn}</td>
              <td>
                <button
                  type="button"
                  onClick={() =>
                    //REDTAG:TJH - replace with react-router navigation
                    window.open(`/app/claims/${client.id}`)
                  }
                  className="btn dark btn-sm btn-outline sbold uppercase"
                >
                  <i className="fa fa-external-link"></i> View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AlertPopup>
  );
};
