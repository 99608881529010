import React, { useEffect,useState } from "react";
import {useSelector} from 'react-redux';
import { FullPageSpinner } from "../../../_common/Spinner";
import { project } from "../../../integrations/company-cam/companyCamApi";
import { CompanyCamLinks } from "../../../integrations/company-cam/companyCamLinks";
import { CompanyCamLogin } from "../../../integrations/company-cam/companyCamLogin";
import { CompanyCamCreate } from "../../../integrations/company-cam/companyCamCreate";
import useCompanyCamAuth from "../../../integrations/company-cam/useCompanyCamAuth";
import moment from 'moment';
import { useClaim } from "../claim.hooks";

export const CompanyCamTab = (/*{
    ccProjectId
}*/) => {

    const isLoggedIn = useCompanyCamAuth();

    // eslint-disable-next-line no-empty-pattern
    const {  } = useClaim();
    const claim = useSelector((state) => state.claims.claim);
    const ccProjectId = claim?.data?._raw?.ccProjectId;
    
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState('photos');
    const [photos, setPhotos] = useState([]);
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
        const getPhotosAndDocuments = async () => {
            setIsLoading(true);
            try {
                const [photosResult, documentsResult] = await Promise.all([
                    project.photos.get(ccProjectId),
                    project.documents.get(ccProjectId),
                ]);
        
                if (photosResult.data.length) {
                    setPhotos(photosResult.data);
                } else {
                    setPhotos([]);
                }
        
                if (documentsResult.data.length) {
                    setDocuments(documentsResult.data);
                    console.log(documentsResult.data);
                } else {
                    setDocuments([]);
                }
            } catch (error) {
                // console.error('Error fetching data:', error);
                setPhotos([]);
                setDocuments([]);
            }
            setIsLoading(false);
        }
        if (ccProjectId) {
            getPhotosAndDocuments();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ccProjectId]);
    return (
        <div className="tw-mx-2.5 tw-border tw-border-gray-300 tw-rounded-md tw-p-4">
            {isLoading && <FullPageSpinner />}
            {(!isLoggedIn || !ccProjectId) && (
               <div className="tw-text-base tw-mb-4">
                    If you&rsquo;re a CompanyCam user, you can now link projects to your claim files.{' '}
                    <a
                        href="/support/articles/company-cam"
                        className="tw-text-blue hover:tw-text-blue hover:tw-underline"
                    >Click here to learn more</a>.
               </div>
            )}
            {!isLoggedIn && <CompanyCamLogin />}
            {isLoggedIn && <CompanyCamCreate />}
            {isLoggedIn && <CompanyCamLinks />}

            {(isLoggedIn && ccProjectId) && (
                <>
                <div className="tw-flex tw-items-center tw-justify-center tw-p-4">
                    <div className="tw-flex tw-border tw-rounded-md">
                        <button
                        className={`tw-px-4 tw-py-2 tw-rounded-l-md ${
                            selected === 'photos' ? 'tw-bg-company-cam-blue tw-text-white' : 'tw-bg-white tw-text-gray-800'
                        }`}
                        onClick={() => setSelected('photos')}
                        >
                        Photos
                        </button>
                        <button
                        className={`tw-px-4 tw-py-2 tw-rounded-r-md ${
                            selected === 'documents' ? 'tw-bg-company-cam-blue tw-text-white' : 'tw-bg-white tw-text-gray-800'
                        }`}
                        onClick={() => setSelected('documents')}
                        >
                        Documents
                        </button>
                    </div>
                </div>
                {(selected === 'photos') && (
                    photos?.length ? (
                        <div className="tw-grid tw-grid-cols-3 tw-gap-2">
                            {photos.map((photo, index) => (
                                <div className="tw-flex tw-flex-col" key={index}>
                                
                                    <img className="tw-aspect-square tw-w-full tw-object-cover" src={photo.uris.find(x => x.type === 'thumbnail').uri} alt={photo.description || moment(photo.captured_at).format('YYYY-MM-DD')}/>
                                    <p>{photo.description?.plain_text_content || ''}</p>
                                    <p>{moment(photo.created_at * 1000).format('YYYY-MM-DD')}</p>

                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>No photos found</div>
                    )
                )}
                {(selected === 'documents') && (
                    documents?.length ? (
                        <table className="tw-table-auto tw-border-collapse tw-w-full">
                            <thead>
                                <tr>
                                    <th className="tw-border-b tw-p-4">Name</th>
                                    <th className="tw-border-b tw-p-4">Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                            {documents.map((document, index) => (
                                <tr className="" key={index}>
                                    <td className="tw-border-b tw-p-4"><a className="tw-text-blue hover:tw-text-company-cam-blue focus:tw-text-company-cam-blue" href={document.url} target="_blank" rel="noreferrer">{document.name || ''}</a></td>
                                    <td className="tw-border-b tw-p-4">{moment(document.created_at * 1000).format('MMM D, YYYY')}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>No documents found</div>
                    )
                )}
                </>
            )}
            
        </div>
    );
}