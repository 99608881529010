import React, {useEffect, useState} from 'react';

import * as Api from '../../Api';
import {Error} from '../_common/Error';
import {FullPageSpinner} from '../_common/Spinner';
import {SuccessMessage} from '../_common/Sweetalert';
import {PhoneNumberMask} from '../_common/MaskedInput';
import {SubmitButton} from './SubmitButton';

const INITIAL_PROFILE_STATE = {users: {name: '', email: '', phone: '', phoneExt: '', fax: ''}};

function PersonalInfo({isLoading, error, profile}) {
  const [profileState, setProfileState] = useState(profile || INITIAL_PROFILE_STATE);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const {users} = profileState;
  const {name, email, phone, phoneExt, fax} = users || {};

  useEffect(() => setProfileState(profile || INITIAL_PROFILE_STATE), [profile, setProfileState]);

  async function handleSubmit(event) {
    event.preventDefault();
    const {phone: phoneNumber, ...theRest} = users;
    setbuttonLoading(true);
    try {
      await Api.User.updateProfile({user: {...theRest, phoneNumber}});
      window.location.reload();
    } catch (error) {
      if (error.response
        && error.response.data
        && error.response.data.errors[0]
        && error.response.data.errors[0].msg) {
        setbuttonLoading(false);
      }
    } finally {
      setbuttonLoading(false);
    }
  }

  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (error) {
    return <Error message={error}/>;
  }

  function handleUpdate(field) {
    const {users, ...remainingProfile} = profileState;
    return ({target}) => setProfileState({...remainingProfile, users: {...users, [field]: target.value}});
  }

  return (
    <div>
      <SuccessMessage message={successMessage} show={showSuccessMessage} clickHandler={setShowSuccessMessage}
                      setSuccessMessage={setSuccessMessage}/>
      <form onSubmit={handleSubmit} className="horizontal-form" method="post">
        <div className="form-body">
          <div className="form-group">
            <label className="control-label"> Name</label>
            <input id="name" className="form-control" type="text" name="name" placeholder="Name" value={name}
                   onChange={handleUpdate('name')}/>
          </div>
          <div className="form-group">
            <label className="control-label">Email</label>
            <input id="email" className="form-control" type="email" name="email" placeholder="Email" value={email}
                   disabled="disabled"/>
          </div>
          <div className="form-group">
            <label className="control-label" style={{width: '100%'}}>
              Phone #
            </label>
            <PhoneNumberMask id="phone" inputClass="form-control col-md-5" style={{width: '80%'}}
                             placeholder="Phone" value={phone} handleChange={handleUpdate('phone')}/>
            <input id="extension" className="form-control col-md-1" style={{width: '20%'}} type="text" name="extension"
                   placeholder="Ext" value={phoneExt} onChange={handleUpdate('phoneExt')}/>
          </div>
          <div className="form-group">
            <label className="control-label">Fax</label>
            <PhoneNumberMask id="fax" inputClass="form-control" placeholderText="Fax" value={fax}
                             handleChange={handleUpdate('fax')}/>
          </div>
        </div>
        <div className="form-actions right">
          <SubmitButton isLoading={buttonLoading}/>
        </div>
      </form>
    </div>
  );
}

export {PersonalInfo};
