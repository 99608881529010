import React from "react";

import { useResetPassword } from "./useResetPassword.hook";
import { Error } from "../../_common/Error";
import { SuccessMessage } from "../../_common/SuccessMessage";

export function ResetPassword({ match }) {
  const { token } = match.params;

  const {
    isLoading,
    successMessage,
    error,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    handleSubmit,
  } = useResetPassword({
    token,
  });

  return (
    <form style={{ marginTop: 80 }} className="forget-form" onSubmit={handleSubmit} method="post">
      <br></br>
      <br></br>
      <br></br>
      {error && <Error message={error} />}
      {successMessage && <SuccessMessage message={successMessage} />}
      <h3>Reset New Password</h3>
      <br />
      <div className="form-group">
        <input
          className="form-control form-control-solid placeholder-no-fix form-group login-input"
          type="password"
          autoComplete="off"
          placeholder="Password"
          name="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          data-testid="password-1"
        />
      </div>
      <div className="form-group">
        <input
          className="form-control form-control-solid placeholder-no-fix form-group login-input"
          type="password"
          autoComplete="off"
          placeholder="Confirm Password"
          name="cpassword"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          data-testid="password-2"
        />
      </div>
      <div className="" style={{ marginTop: "2em" }}>
        <button className="btn dark btn-block btn-lg" type="submit" disabled={isLoading}>
          {isLoading ? "Loading..." : "Reset Password"}
        </button>
      </div>
    </form>
  );
}
