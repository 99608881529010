import React, {useState} from 'react';

import {Pagebar} from '../../layout/Pagebar';
import {DropzoneForCsv} from '../../_common/DropzoneForCsv';
import {SaveFileAfterConfirm} from './SaveFileAfterConfirm';
import {MapImportedFile} from './MapImportedFile';
import {ReviewImport} from './ReviewImport';

const BREADCRUMB = [
  {title: 'Home', link: '/app/claims'},
  {title: ' Import Claims ', link: ''}
];

const LABEL_STYLE = {textAlign: 'left', paddingBottom: '12px', backgroundColor: '#f2f2f2'};

export function ImportClaims() {
  const [uploadedFileId, setUploadedFileId] = useState(0);
  const [uploadFile, setUploadFile] = useState(true);
  const [askConfirmUpload, setAskConfirmUpload] = useState(false);
  const [uploadedFile, setUploadedFile] = useState('');
  const [showMapView, setShowMapView] = useState(false);
  const [reviewImport, setReviewImport] = useState();
  const [allMappedData, setAllMappedData] = useState([]);

  function setCsvFile(file) {
    setUploadedFile(file);
    setAskConfirmUpload(true);
    setUploadFile(false);
  }

  return (
    <div>
      {<Pagebar breadcrumbs={BREADCRUMB}/>}
      <h1 className="page-title">
        Import Claims<small>&nbsp;&nbsp;&nbsp;&nbsp;via CSV</small>
      </h1>
      <div className="row">
        <div className="col-sm-12">
          <div className="portlet light">
            <div className="portlet-title">
              {reviewImport && (
                <div className="caption">
                  <i className="fa fa-check"/>Review Import
                </div>
              )}
              {!reviewImport && (
                <div className="caption">
                  <i className="fa fa-upload"/>Import Claims
                </div>
              )}
            </div>
            <div className="portlet-body form">
              {(uploadFile || askConfirmUpload) && (
                <div>
                  <label className="col-sm-12 control-label" style={LABEL_STYLE}>
                    <h2>Upload CSV File</h2>
                  </label>
                </div>
              )}
              {showMapView && (
                <div>
                  <label className="col-sm-12 control-label" style={LABEL_STYLE}>
                    <h2>
                      Map Import Fields to Claim Form - {uploadedFile.name}
                    </h2>
                  </label>
                </div>
              )}
              <div className="form-body" style={{minHeight: '500px'}}>
                {uploadFile && (
                  <div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <DropzoneForCsv onFileDrop={setCsvFile}/>
                  </div>
                )}
                <SaveFileAfterConfirm setUplodedFileId={setUploadedFileId} askConfirmUpload={askConfirmUpload}
                                      uplodedFile={uploadedFile} setShowMapView={setShowMapView}
                                      setaskConfirmUpload={setAskConfirmUpload}/>
                <ReviewImport mappedData={allMappedData} setMappedData={setAllMappedData} uplodedFileId={uploadedFileId}
                              reviewImport={reviewImport} setReviewImport={setReviewImport}
                              setShowMapView={setShowMapView}/>
                <MapImportedFile mappedData={allMappedData} setMappedData={setAllMappedData} uploadFile={uploadFile}
                                 uplodedFileId={uploadedFileId} showMapView={showMapView}
                                 setShowMapView={setShowMapView} setReviewImport={setReviewImport}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
