import React, { useState } from "react";
import { Error } from "../_common/Error";
import { Spinner } from "../_common/Spinner";
import { SuccessMessage } from "../_common/Sweetalert";
import * as Api from "../../Api";
import { SubmitButton } from "./SubmitButton";
function ChangePassword({ isLoading, error }) {
  const [current, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const userDetails = {
      currentPassword: current,
      newPassword,
      confirmPassword,
    };

    if (newPassword !== confirmPassword) {
      setPasswordError(
        "New password and confirm password are not same. Please enter it same."
      );
    } else {
      setPasswordError("");
    }
    setbuttonLoading(true);
    return Api.User.updateProfilePassword({
      user: userDetails,
    })
    .then((data) => {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");

      setShowSuccessMessage(true);
      setSuccessMessage("Updated!");
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors[0] &&
        error.response.data.errors[0].msg
      ) {
        setPasswordError(error.response.data.errors[0].msg);
        setbuttonLoading(false);
      }
    })
    .finally(() => {
      setbuttonLoading(false);
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Error message={error} />;
  }

  return (
    <div>
      <SuccessMessage
        show={showSuccessMessage}
        clickHandler={setShowSuccessMessage}
        message={successMessage}
      />
      <form onSubmit={handleSubmit} className="horizontal-form" method="post">
        <div className="row">
          <div className="col-md-12">
            {passwordError ? (
              <div className="alert alert-danger">{passwordError}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <div className="col-md-12">
              <label>Current Password</label>
              <input
                type="password"
                className="form-control"
                value={current}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current Password"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="form-group">
            <div className="col-md-12">
              <label>New Password</label>
              <input
                type="password"
                value={newPassword}
                className="form-control"
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="form-group">
            <div className="col-md-12">
              <label>Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="form-actions right">
          <SubmitButton isLoading={buttonLoading} />
        </div>
      </form>
    </div>
  );
}

export { ChangePassword };
