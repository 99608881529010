import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import { Row, Col } from "../../../../_common/layout/Layout";
import { CurrencyMask } from "../../../../_common/MaskedInput";
import { ClaimActions } from "../../../../../store/claims";

export const VendorFields = () => {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.vendors.list);
  const { vendor } = useSelector((state) => state.claims.claim.data);
  const { isSharedView } = useSelector((state) => state.claims.claim);

  const onVendorChange = (data) => dispatch(ClaimActions.SetOnClaim.vendor(data));

  return (
    <Form.Group>
      <Row>
        <Col md={6}>
          <Form.Label>Vendor</Form.Label>
          <div className="input-group">
            {isSharedView ? (
              <Form.Input value={vendor?.name} disabled={isSharedView} data-testid="vendor" />
            ) : (
              <Form.Select
                value={vendor?.id || ''}
                onChange={(e) => onVendorChange({ name: "id", value: e.target.value })}
                disabled={isSharedView}
                data-testid="vendor"
              >
                <option value="">Select Vendor </option>
                {vendors?.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </div>
        </Col>
        <Col md={6}>
          <Form.Label>Vendor Fees</Form.Label>
          <div className="input-group">
            <div className="input-group-addon">$</div>
            <CurrencyMask
              className="form-control"
              placeholder="0"
              value={vendor?.fees}
              handleChange={(e) => {
                onVendorChange({
                  name: "fees",
                  value: e.target.value,
                });
              }}
              disabled={isSharedView}
              data-testid="vendor-fees"
            />
          </div>
        </Col>
      </Row>
    </Form.Group>
  );
};
