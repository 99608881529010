import { StatusTypes as Types } from "./index";

const INITIAL_STATUSES_STATE = {
  isLoading: false,
  list: [],
  error: "",
};

export default function StatusReducer(state = INITIAL_STATUSES_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_STATUSES.START:
      return {
        ...state,
        isLoading: true,
      };

    case Types.GET_STATUSES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      };

    case Types.GET_STATUSES.ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case Types.GET_STATUSES.CLEAR:
      return INITIAL_STATUSES_STATE;

    default:
      return state;
  }
}
