import React from "react";
import Logo from "../../assets/images/oa-logo.png";

import "./TermsWrapper.css";

export const TermsWrapper = ({ title, lastUpdated, children }) => {
  const date = new Date();
  const year = date.getFullYear();
  const brandCompanyName = process.env.REACT_APP_BRAND_COMPANY_NAME;
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  return (
    <div className="terms-wrapper">
      <header>
        <a href="/">
          <img src={Logo} alt="Organized Adjusting Logo" />
        </a>
        <div>
          <a href="/" className="btn blue-primary-solid">
            HOME
          </a>
          <a href="/app/signup" className="btn blue-primary-solid">
            SIGN UP NOW
          </a>
        </div>
      </header>
      <main>
        <section className="content title">
          <div className="container">
            <h2>
              {title} <br />
            </h2>
            <h3>Last Updated: {lastUpdated}</h3>
          </div>
        </section>

        {children}

        <section className="content">
          <div className="container">
            <h2 className="title">CONTACT US</h2>
            If you have any questions about these Terms, please contact us.
            <br />
            <div className="cta-container">
              <a
                href={`mailto:${supportEmail}`}
                className="btn blue-primary-solid"
              >
                CONTACT US
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <em>
          © Copyright {year} {brandCompanyName} - All Rights Reserved
        </em>
      </footer>
    </div>
  );
};
