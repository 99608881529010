import React from "react";
import { Pagebar } from "../layout/Pagebar";
import { CustomFields } from "./CustomFields";
import { Statuses } from "./Statuses";
import { Link } from "react-router-dom";

export const CustomOptions = ({ match }) => {
  const {
    params: { subroute },
  } = match;

  const isStatusSubroute = subroute === "statuses";
  const breadcrumb = [
    {
      title: "Home",
      link: "/app/claims",
    },
    {
      title: "Custom Options",
      link: "/app/custom-options/claim-fields",
    },
    {
      title: isStatusSubroute ? "Statuses" : "Custom Fields",
      link: isStatusSubroute
        ? "/app/custom-options/statuses"
        : "/app/custom-options/custom-fields",
    },
  ];
  return (
    <div>
      <Pagebar breadcrumbs={breadcrumb} />
      <div className="portlet light">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              Custom Options
            </span>
          </div>
        </div>
        <div className="portlet-body">
          <ul className="nav nav-tabs client-edit-mid-tab client-edit-tabs">
            <li className={isStatusSubroute ? "" : "active"}>
              <Link to="/custom-options/custom-fields">Custom Fields</Link>
            </li>
            <li className={isStatusSubroute ? "active" : ""}>
              <Link to="/custom-options/statuses">Status</Link>
            </li>
          </ul>
          <br />
          {isStatusSubroute ? <Statuses /> : <CustomFields />}
        </div>
      </div>
    </div>
  );
};
