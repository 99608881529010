import React from 'react';

function Breadcrumb({title, link, isLast}) {
  return (
    <li>
      <a className="mr-5 ml-5" href={link}>
        {title}
      </a>
      {!isLast && <i className="fa fa-circle"/>}
    </li>
  );
}

function Pagebar({breadcrumbs}) {
  if (breadcrumbs) {
    return (
      <div>
        <div className="page-bar margin-bottom-20">
          <ul className="page-breadcrumb">
            {breadcrumbs.map((b, idx) => (
              <Breadcrumb key={idx} title={b.title} link={b.link} isLast={breadcrumbs.length === idx + 1}/>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  return '';
}

export {Pagebar};
