import React from "react";
import { StatusDatatable } from "../status/tables";
import { AddNewAndEdit } from "../status/addNewAndEdit";
import { DeleteSweetAlertConfirmation } from "../_common/Sweetalert";
import { Dropdown } from "../_common/Dropdown";
import DownloadCSV from "../_common/DownloadCSV";
import DownloadPDF from "../_common/DownloadPDF";
import DownloadImage from "../_common/DownloadImage";
import * as Api from "../../Api";

export function Statuses() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [statuses, setStatuses] = React.useState([]);
  const [error, setError] = React.useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [deleteSelectedStatus, setDeleteSelectedStatus] = React.useState({});
  const [opensidePanel, setOpensidePanel] = React.useState(false);
  const [statusDetails, setStatusDetails] = React.useState({});
  const downloadCSVHeaders = [
    { label: "Status Name", key: "statusName" },
    { label: "Color", key: "color" },
  ];
  const downloadPDFHeaders = [
    { header: "Status Name", dataKey: "statusName" },
    { header: "Color", dataKey: "color" },
  ];

  const fetchStatus = () => {
    setIsLoading(true);
    Api.Statuses.list()
      .then((data) => {
        if (data.data && data.data) {
          const newStatus = data.data.filter((row) => {
            return row.isEditable ? true : false;
          });
          setStatuses(newStatus);
        }
      })
      .catch((err) => {
        const error = Api.getError(err);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };
  React.useEffect(() => {
    fetchStatus();
  }, []);

  const confirmDelete = (selectedStatus) => {
    setShowDeleteConfirmation(true);
    setDeleteSelectedStatus(selectedStatus);
  };
  const saveAsPdf = () => {
    return DownloadPDF({
      data: statuses,
      headers: downloadPDFHeaders,
      filename: "status.pdf",
    });
  };

  const deleteStatus = (confirm) => {
    if (confirm) {
      Api.Statuses.delete({
        id: deleteSelectedStatus.id,
      })
        .then(() => {
          fetchStatus();
        })
        .catch(() => {})
        .finally(() => {
          setDeleteSelectedStatus({});
          setShowDeleteConfirmation(false);
        });
    }
    if (!confirm) {
      setShowDeleteConfirmation(false);
    }
  };

  return (
    <div>
      <div className="portlet light portlet-fit bordered">
        <div className="portlet-title">
          <div className="caption">
            <span className="caption-subject font-dark sbold uppercase">
              STATUSES
            </span>
          </div>
          <div className="actions">
            <button
              class="btn blue-primary btn-outline mr-5"
              style={{
                paddingTop: 7,
                paddingBottom: 6,
              }}
              onClick={() => {
                setOpensidePanel(true);
                setStatusDetails({});
              }}
            >
              <i class="fa fa-plus"></i> Add New Status
            </button>

            <div className="btn-group pull-right">
              <Dropdown title="Tools" variant="red">
                <Dropdown.ListItem
                  onClick={() => {
                    return false;
                  }}
                >
                  <DownloadImage
                    Data={statuses}
                    headers={downloadPDFHeaders}
                    TableName="Status"
                  />
                </Dropdown.ListItem>
                <Dropdown.ListItem onClick={saveAsPdf}>
                  Save as PDF
                </Dropdown.ListItem>
                <Dropdown.ListItem
                  onlyLI={
                    <DownloadCSV
                      data={statuses}
                      filename="status.csv"
                      headers={downloadCSVHeaders}
                      target="_blank"
                    />
                  }
                  onClick={() => {
                    return false;
                  }}
                ></Dropdown.ListItem>
              </Dropdown>
            </div>
          </div>
        </div>
        <DeleteSweetAlertConfirmation
          show={showDeleteConfirmation}
          clickHandler={deleteStatus}
        />
        <AddNewAndEdit
          openPanel={opensidePanel}
          setOpenPanel={setOpensidePanel}
          statusDetails={statusDetails}
          reloadTable={fetchStatus}
        />
        <div className="portlet-body">
          <div className="table-scrollable  no-border">
            <StatusDatatable
              statuses={statuses}
              isLoading={isLoading}
              error={error}
              setOpensidePanel={setOpensidePanel}
              setStatusDetails={setStatusDetails}
              confirmDelete={confirmDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
