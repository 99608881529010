import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

import '../../assets/css/global/plugins/bootstrap/css/bootstrap.min.css';
import '../../assets/css/global/plugins/bootstrap-daterangepicker/css/daterangepicker.min.css';
import '../../assets/css/global/plugins/bootstrap-daterangepicker/css/custom.css';
import './DatePickerWithOption.css';

const BASE_PICKER_SETTINGS = {
  autoApply: false,
  showWeekNumbers: true,
  linkedCalendars: false,
  locale: {
    cancelLabel: 'Clear'
  },
  showDropdowns: true
};

export function DatePickerWithOption({fromDate, toDate, onDatesChange}) {
  const today = moment().toDate();
  let yesterday = moment()
    .subtract(1, 'days')
    .toDate();
  const lastMonth = moment().subtract(1, 'month');
  const ranges = {
    Today: [today, today],
    Yesterday: [yesterday, yesterday],
    'Last 7 Days': [moment().subtract(6, 'days').toDate(), today],
    'Last 30 Days': [moment().subtract(29, 'days').toDate(), today],
    'This Month': [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate()
    ],
    'Last Month': [
      moment(lastMonth).startOf('month').toDate(),
      moment(lastMonth).endOf('month').toDate()
    ]
  };

  function handleApply(event, picker) {
    const {startDate, endDate} = picker;
    onDatesChange(
      moment(startDate).format('MM/DD/YYYY'),
      moment(endDate).format('MM/DD/YYYY')
    );
  }

  function handleClear() {
    onDatesChange(null, null);
  }

  return (
    <div>
      <DateRangePicker initialSettings={{...BASE_PICKER_SETTINGS, ranges}} onApply={handleApply} onCancel={handleClear}>
        <div className="input-group">
          <span>
            {
              toDate
                ? (<span>{fromDate} - {toDate}</span>)
                : (<span>Select Duration</span>)
            }
          </span>
          <span className="input-group-btn">
            <button className="btn date-range-toggle blue-primary" type="button">
              <i className="fa fa-calendar white"/>
            </button>
          </span>
        </div>
      </DateRangePicker>
    </div>
  );
}
