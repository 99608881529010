import { CompanyTypes as Types } from "./index";

const INITIAL_COMPANY_STATE = {
  members: {
    isLoading: false,
    list: "",
  },
};

export default function CompanyReducer(
  state = INITIAL_COMPANY_STATE,
  { type, payload }
) {
  switch (type) {
    case Types.GET_COMPANY_MEMBERS.START:
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: true,
        },
      };

    case Types.GET_COMPANY_MEMBERS.SUCCESS:
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: false,
          list: payload,
        },
      };

    case Types.GET_COMPANY_MEMBERS.ERROR:
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}
