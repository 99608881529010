import moment from "moment";

export const ranges = {
  today: "today",
  yesterday: "yesterday",
  last7Days: "last7Days",
  last30Days: "last30Days",
  thisMonth: "thisMonth",
  lastMonth: "lastMonth",
  custom: "custom",
};

const getTodayDate = () => new moment();

const getYesterday = () => new moment().subtract(1, "days");

function getCurrentMonthStartDate() {
  return moment().startOf("month");
}

function getLastMonthStartDate() {
  const dateOneMonthAgo = moment().subtract(1, "month");
  const startDateOfMonth = dateOneMonthAgo.startOf("month");
  return startDateOfMonth;
}

function getLastMonthEndDate() {
  const dateOneMonthAgo = moment().subtract(1, "month");
  const lastDateOfMonth = dateOneMonthAgo.endOf("month");
  return lastDateOfMonth;
}

export const getChosenDates = ({ range }) => {
  if (range === ranges.today) {
    const today = getTodayDate();
    return {
      fromDate: today,
      toDate: today,
    };
  }

  if (range === ranges.yesterday) {
    const yesterday = getYesterday();
    return {
      fromDate: yesterday,
      toDate: yesterday,
    };
  }

  if (range === ranges.last7Days) {
    const yesterday = getYesterday();
    const date7DaysAgo = new moment().subtract(7, "days");

    return {
      fromDate: date7DaysAgo,
      toDate: yesterday,
    };
  }

  if (range === ranges.last30Days) {
    const yesterday = getYesterday();
    const date30DaysAgo = new moment().subtract(30, "days");

    return {
      fromDate: date30DaysAgo,
      toDate: yesterday,
    };
  }

  if (range === ranges.thisMonth) {
    const today = getTodayDate();
    const currentMonthStartDate = getCurrentMonthStartDate();
    return {
      fromDate: currentMonthStartDate,
      toDate: today,
    };
  }

  if (range === ranges.lastMonth) {
    const lastMonthStartDate = getLastMonthStartDate();
    const lastMonthEndDate = getLastMonthEndDate();

    return {
      fromDate: lastMonthStartDate,
      toDate: lastMonthEndDate,
    };
  }
};

export function formatDateToMmDdYyyy(date) {
  return date ? moment(date).format("l") : "";
}
