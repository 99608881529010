import React from "react";
import PropTypes from "prop-types";

export function Alert({ variant, title, description }) {
  return (
    <div className={`note note-${variant}`}>
      <h4 className="block">{title}</h4>
      <p>{description}</p>
    </div>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  variant: PropTypes.oneOf(["success", "info", "danger", "warning"]),
};
