import React from "react";
import { SlidingSidePanel } from "../../../_common/SlidingSidePanel";
import * as Api from "../../../../Api";

export function AskForFieldsForDocuments({
  openAskForFieldsModal,
  setOpenAskForFieldsModal,
  askFieldsArray,
  setAskFieldsArray,
  documentId,
  claimId,
  setOpenDocumentViewModal,
  setFileSrcUrl,
  setshowLoading,
  setUserFilledData,
}) {
  const submitFormAndGenrateDoc = () => {
    setshowLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("isasked", "1");

    askFieldsArray.map((af) => {
      bodyFormData.append(af.originalField, af.value);
      return af;
    });

    Api.Document.legacy
      .genrateDocumentAfterFields({
        documentId,
        claimId,
        requestData: bodyFormData,
      })
      .then((data) => {
        setUserFilledData(bodyFormData);
        setFileSrcUrl(data.data.fileName);
        setOpenDocumentViewModal(true);
        setOpenAskForFieldsModal(false);
        setOpenDocumentViewModal(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setshowLoading(false);
      });
  };

  const setValue = (value, index) => {
    let newArray = [...askFieldsArray];
    newArray[index].value = value;
    setAskFieldsArray(newArray);
  };

  return (
    openAskForFieldsModal && (
      <SlidingSidePanel type={"right"} isOpen={openAskForFieldsModal}>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">
              <span className="caption-subject bold font-yellow-casablanca uppercase">
                INFO NEEDED TO GENERATE THE DOCUMENT
              </span>
            </div>
            <div className="tools">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenAskForFieldsModal(false);
                }}
                href="#!"
                className="remove"
                data-original-title=""
                title=""
              >
                &nbsp;
              </a>
            </div>
          </div>
          <div className="portlet-body">
            <div className="note note-info">
              <h4 className="block">Info</h4>
              <p>
                We found some Merge Field Placeholders in your Document Template
                which either point to Empty fields or you wanted to fill in at
                the time of generating the Document. Enter them below or choose
                Skip to generate the documents without these
              </p>
            </div>
            <div className="form-body">
              <form action="#!" method="post">
                {askFieldsArray.length > 0 &&
                  askFieldsArray.map((af, index) => {
                    return (
                      <div className="form-group form-md-line-input ">
                        <input
                          type="text"
                          className="form-control"
                          value={af.value}
                          onChange={(e) => setValue(e.target.value, index)}
                        />
                        <label>{af.originalField}</label>
                        <span className="help-block"></span>
                      </div>
                    );
                  })}
              </form>
            </div>
            <div className="form-actions">
              <div className="row">
                <div className="col-md-offset-3 col-md-9">
                  <button
                    type="button"
                    onClick={() => submitFormAndGenrateDoc()}
                    className="btn btn-lg green-jungle"
                  >
                    Submit &amp; Generate
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    onClick={() => submitFormAndGenrateDoc()}
                    className="btn btn-lg default"
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlidingSidePanel>
    )
  );
}
