import React,{ useState, useEffect, useCallback} from "react";
import {ConfirmUnMapped} from "./confirmUnmapped";
import * as Api from "../../../Api";
import { Notify } from "../../../services/Notify.service";
import { FullPageSpinner } from "../../_common/Spinner";
import { OAMappingFields } from "./oaMappingFields";

export function MapImportedFile({ 
    showMapView,
    setReviewImport,
    setShowMapView,
    uplodedFileId,
    mappedData,
    setMappedData
}){
    const [isLoading, setIsLoading] = useState(false);
    const [showUnmapped, setShowUnmapped] = useState(false);
    const [oaFields, setOaFields] = useState([]);
    const [fieldsInFile, setFieldsInFile] = useState([]);
    const [sampleData1, setSampleData1] = useState([]);
    const [sampleData2, setSampleData2] = useState([]);
    const [allMapped, setAllMapped] = useState(0);
    const [allFields, setAllFields] = useState(0);
    const [allUnMapped, setAllUnmappedFields] = useState(0);
    
    const cancelNext = () => {
        window.location.reload();
    };
    
    const validateNext = () => {
        const firstNameCheck = mappedData.filter(obj => {
            return obj.id === "firstName1"
        });
        if(firstNameCheck.length === 0){
            return "First Name or Buisness name OA field should be mapped to any fields of file.";
        }
        return "";
    };

    const fillAlreadyMappedValue = useCallback(() => {
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fieldsInFile, oaFields]);

    const loadMappingData = useCallback(() => {
        setIsLoading(true)
        Api.Claims.Import.getDataForMapping({
            id: uplodedFileId
        }).then((data) => {
            setFieldsInFile(data.data.fieldsInFile);
            setAllFields(data.data.fieldsInFile.length);
            setSampleData1(data.data.sampleData1);
            setSampleData2(data.data.sampleData2);
            setOaFields(data.data.oaFields);

            setAllMapped(0);
            setAllUnmappedFields(data.data.fieldsInFile.length);
        }).catch(() => {
            Notify.error("Unable to get Data From your Uploded File. Please Upload again.");
        }).finally(() => {
            setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uplodedFileId]);

    const gotoReview = () => {
        const validate = validateNext();
        if(validate !== ""){
            Notify.error(validate);
            return false;
        }
        const total = fieldsInFile.length;
        const totalMap = mappedData.length;
        if(total === totalMap){
            setShowMapView(false);
            setReviewImport(true);
        }
        if(total !== totalMap){
            setShowUnmapped(true);
        }
    };

    useEffect(() => {
        if(uplodedFileId !== 0){
            loadMappingData();
        }
    },[loadMappingData, uplodedFileId]);

    useEffect(() => {
        const total = fieldsInFile.length;
        const totalMap = mappedData.length;
        const totalUnmapped = total - totalMap;
        setAllMapped(totalMap);
        setAllUnmappedFields(totalUnmapped);

    },[fieldsInFile,mappedData]);

    useEffect(() => {
        fillAlreadyMappedValue();
    },[fieldsInFile, fillAlreadyMappedValue]);
    
    return (
        showMapView && 
        <div>
            {isLoading && <FullPageSpinner />}
            <ConfirmUnMapped mappedData={mappedData} fieldsInFile={fieldsInFile} setOpenPanel={setShowUnmapped} openPanel={showUnmapped} setReviewImport={setReviewImport} setShowMapView={setShowMapView}/>
            <div>
                <div className="row" style={{paddingLeft:"5px", paddingRight:"5px"}}>
                    <div className="col-sm-8">
                        <div className="actions">
                            <div className="btn-group btn-group-devided" style={{padding: "8px 19px 8px 19px"}}>
                                <label className="btn dark btn-outline btn-circle btn-sm active" style={{padding:"8px 19px 8px 19px"}}>
                                    All ({allFields})
                                </label>
                                <label className="btn dark btn-outline btn-circle btn-sm" style={{padding:"8px 19px 8px 19px"}}>
                                    Mapped ({allMapped})
                                </label>
                                <label className="btn dark btn-outline btn-circle btn-sm" style={{padding:"8px 19px 8px 19px"}}>
                                    Unmapped ({allUnMapped})
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                    </div>
                </div>
                <div className="table-scrollable" style={{paddingLeft:"5px", paddingRight:"5px"}}>
                    <table className="table table-striped table-bordered table-advance table-hover">
                        <thead>
                            <tr>
                                <th>Fields in File</th>
                                <th className="hidden-xs">Fields on OA</th>
                                <th>Sample Data</th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            fieldsInFile.length > 0 &&
                                <tbody>
                                    {
                                        fieldsInFile.map((f,i) => {
                                            return (
                                                <tr key={`fileFields-${i}`}>
                                                    <td style={{verticalAlign:"middle", width: "25%"}}>{f}</td>
                                                    <td className="hidden-xs" style={{width: "25%"}}>
                                                        <OAMappingFields 
                                                            mappedData= {mappedData}
                                                            setMappedData= {setMappedData}
                                                            oaFields= {oaFields}
                                                            fileField= {f}
                                                        />
                                                    </td>
                                                    <td style={{verticalAlign:"middle", width: "25%"}}>
                                                        {sampleData1[f]}
                                                    </td>
                                                    <td style={{verticalAlign:"middle", width: "25%"}}>
                                                        {sampleData2[f]}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                        }
                    </table>
                </div>
                <form className="form-horizontal" action="#">
                    <div className="form-actions">
                        <div className="col-md-offset-2 col-md-9">
                            <button type="button" onClick={() => gotoReview()} className="btn green" style={{padding:"12px 49px 12px 49px", borderRadius:"4px", marginRight:"5px"}}>
                                Next
                            </button>
                            <button type="button" onClick={() => cancelNext()} className="btn grey-salsa btn-outline" style={{padding: "12px 29px 12px 29px", borderRadius:"4px"}}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}