import { AuthTypes } from "./index";

const INITIAL_STATE = {
  isLoading: false,
  user: null,
  error: "",
};

export default function AuthReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case AuthTypes.GET_AUTH_USER.START:
      return {
        ...state,
        isLoading: true,
      };

    case AuthTypes.GET_AUTH_USER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload,
      };

    case AuthTypes.GET_AUTH_USER.ERROR:
      return {
        ...state,
        isLoading: false,
        user: null,
        error: payload,
      };

    case AuthTypes.GET_AUTH_USER.CLEAR:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
}
