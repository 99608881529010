import React from "react";

import "../claims/Status.css";

export function Status({ name, statusColor }) {
  if(statusColor){
    return <span>{name}</span>;
  }
  return <span>{name}</span>;
}
