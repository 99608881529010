import {useCallback, useState} from 'react';

export default function useLazyFetcher(fetcher) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useCallback(async function _fetch(...args) {
    try {
      setLoading(true);
      setError(null);
      const {data} = await fetcher(...args);
      setData(data);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData, setError, fetcher]);

  return [fetch, {data, loading, error}];
}