import React, {useEffect} from "react";
import { useLocation, useHistory } from 'react-router-dom';
import {auth} from './companyCamApi';
import config from '../../../config';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function CompanyCamAuth() {
    const { appUrl } = config;
    const query = useQuery();
    const code = query.get('code');
    const claim = query.get('claim');
    // const navigate = useNavigate(); //TODO update react-router to 6
    const history = useHistory();

    useEffect(() => {
        const getToken = async () => {
            // const response = await auth.getToken(code, 'http://localhost:3010/app/integrations/company-cam?claim=28574');
            const response = await auth.getToken(code, `${appUrl}/app/integrations/company-cam${(claim) ? '?claim=' + claim : ''}`);
            console.log(response.data);
            localStorage.setItem('companyCamToken', JSON.stringify(response.data));
            history.replace(`/claims/${claim}`);
        }
        getToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>CompanyCam Auth</div>
    )
}