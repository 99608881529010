import React from 'react';

import {Spinner} from './Spinner';
import {Alert} from './Alert';

export function ListRenderer({
                               isLoading,
                               data,
                               error,
                               emptyStateMessage,
                               children,
                               isTable,
                               tableColomns
                             }) {
  if (isLoading) {
    return (
      <tr>
        <td>
          <Spinner/>
        </td>
      </tr>
    );
  }

  if (error) {
    return (
      <Alert
        variant="danger"
        title="Something went wrong!"
        description={error}
      />
    );
  }

  const hasData = data && data.length;

  if (!hasData) {
    if (isTable) {
      return (
        <tr>
          <td colSpan={tableColomns}>
            <Alert
              variant="info"
              title="Nothing to show!"
              description={emptyStateMessage}
            />
          </td>
        </tr>
      );
    }
    return (
      <Alert
        variant="info"
        title="Nothing to show!"
        description={emptyStateMessage}
      />
    );
  }

  return children;
}
