import React from "react";

import "./SectionHeading.css";

export const SectionHeading = ({ children, ...props }) => {
  return (
    <h3 className="form__section--heading" {...props}>
      {children}
    </h3>
  );
};
