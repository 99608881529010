import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';

import * as Api from '../../Api';
import BillingContext from './BillingContext';
import {useClaims} from './ClaimsTable.hooks';
import useClaim from './useClaim';
import useClaimTableQueryParams from './useClaimTableQueryParams';
import useFetcher from '../../hooks/useFetcher';
import useBillingStatus from '../../hooks/billing/useBillingStatus';

import '../../assets/css/global/css/plugins.min.css';
import '../../assets/css/global/plugins/datatables/datatables.min.css';
import './ClaimsTable.css';

import {Error} from '../_common/Error';
import {ListRenderer} from '../_common/ListRenderer';
import {FullPageSpinner} from '../_common/Spinner';
import {DeleteSweetAlertConfirmation, SuccessMessage} from '../_common/Sweetalert';
import {ArchiveClaims} from './ArchiveClaims';
import {Preview} from './claimPreview';

import {ClaimsTableHeader} from './ClaimsTableHeader';
import {FilterHead} from './filters/FilterHead';
import {LetterToolbar} from './LetterToolbar';
import {ManageLabel} from './manageLabels';
import {Note} from './Note';
import {PaginationPanel} from './PaginationPanel';
import {TableRow} from './TableRow';
import {ToolBar} from './ToolBar';
import { ClaimActions } from "../../store/claims";

const DEFAULT_TABLE_FILTERS = {
  fileNumber: '',
  clientName: '',
  claimNumber: '',
  assignedAdjusters: [],
  cities: [],
  zipcodes: [],
  street: '',
  insurers: [],
  status: '',
  createdAtFromDate: '',
  createdAtToDate: '',
  dolFromDate: '',
  dolToDate: ''
};

export function ClaimsTable() {
  const [query, setQuery] = useClaimTableQueryParams();
  const {isLoading, claims, error, total, reloadClaims} = useClaims();
  const [getClaim, {data: claimData}] = useClaim();
  const {data: labelsData, reFetch: reloadLabels} = useFetcher(Api.Claims.Label.list);
  const billingContext = useBillingStatus();

  const [filter, setFilter] = useState({...DEFAULT_TABLE_FILTERS, ...query});
  const [openNotePanel, setOpenNotePanel] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState({});
  const [openManageLabelPanel, setOpenManageLabelPanel] = useState(false);
  const [openArchiveClaims, setOpenArchiveClaims] = useState(false);
  const [fullPageSpinner, setFullPageSpinner] = useState(false);
  const [showClaimPreview, setShowClaimPreview] = useState(false);
  const [selectedArchiveClaims, setSelectedArchiveClaims] = useState([]);
  const [archivedError, setArchivedError] = useState('');
  const [archivedSuccessMessage, setArchivedSuccessMessage] = useState('');

  const {claim, notes} = claimData || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( ClaimActions.clearClaim());
  }, [dispatch]);

  async function deleteLabel() {
    try {
      await Api.Claims.Label.delete({labelData: selectedLabel});
      setShowDeleteConfirmation(false);
      reloadLabels();
      reloadClaims();
    } catch (error) {
      console.log(error);
    }
  }

  function checkAllCheckbox(isCheck) {
    if (isCheck) {
      setSelectedArchiveClaims(claims.map((c) => c.id));
    }
    if (!isCheck) {
      setSelectedArchiveClaims([]);
    }
  }

  function successMessageClickHandler() {
    setOpenArchiveClaims(false);
    setArchivedSuccessMessage('');
    reloadClaims();
  }

  function handleSubmit(e) {
    e.preventDefault();
    setQuery({...query, ...filter});
  }

  function handleReset() {
    setFilter(DEFAULT_TABLE_FILTERS);
    setQuery({...query, ...DEFAULT_TABLE_FILTERS, currentPage: 1});
  }

  return (
    <BillingContext.Provider value={billingContext}>
      <div className="row">
        <div className="col-md-12">
          <div className="portlet light portlet-fit portlet-datatable bordered">
            <ToolBar claims={claims} setOpenArchiveClaims={setOpenArchiveClaims}
                     selectedArchiveClaims={selectedArchiveClaims} setArchivedError={setArchivedError}
                     setFullPageSpinner={setFullPageSpinner}/>
            <div className="portlet-body">
              {archivedError !== '' && <Error message={archivedError}/>}
              <LetterToolbar/>
              <Note openPanel={openNotePanel} setOpenPanel={setOpenNotePanel} selectedClaimId={selectedClaimId}/>
              <ManageLabel labels={labelsData || []} selectedClaimId={selectedClaimId} reloadLabels={reloadLabels}
                           reloadClaims={reloadClaims} openManageLabelPanel={openManageLabelPanel}
                           setOpenManageLabelPanel={setOpenManageLabelPanel}
                           setShowDeleteConfirmation={setShowDeleteConfirmation} setSelectedLabel={setSelectedLabel}/>
              <DeleteSweetAlertConfirmation show={showDeleteConfirmation} clickHandler={deleteLabel}/>
              <ArchiveClaims openPanel={openArchiveClaims} setOpenPanel={setOpenArchiveClaims}
                             selectedArchiveClaims={selectedArchiveClaims}
                             setArchivedSuccessMessage={setArchivedSuccessMessage}/>
              <SuccessMessage heading={archivedSuccessMessage} show={archivedSuccessMessage !== ''}
                              clickHandler={successMessageClickHandler}/>
              <Preview selectedClaimId={selectedClaimId} selectedClaimData={claim || {}}
                       selectedClaimNotes={notes || []} openPanel={showClaimPreview}
                       setOpenPanel={setShowClaimPreview}/>

              {fullPageSpinner && <FullPageSpinner/>}

              <div className="table-container">
                <div className="table-scrollable claims-table">
                  <div className="dataTables_wrapper dataTables_extended_wrapper no-footer">
                    <PaginationPanel total={total}/>
                    <form onSubmit={handleSubmit}>
                      <table
                        className="table table-striped table-bordered  table-checkable dataTable no-footer claims-table"
                        role="grid" data-testid="claims-table">
                        <thead>
                        <ClaimsTableHeader checkAllCheckbox={checkAllCheckbox}/>
                        <FilterHead filter={filter} setFilter={setFilter} onReset={handleReset}/>
                        </thead>
                        <tbody>
                        <ListRenderer tableColomns={10} data={claims} emptyStateMessage="No claims found."
                                      isTable={true} isLoading={isLoading} error={error}>
                          {
                            claims.map((claim, index) => (
                              <TableRow key={index} claim={claim} labelsData={labelsData || []}
                                        reloadClaims={reloadClaims} getSelectedClaimData={getClaim}
                                        setOpenNotePanel={setOpenNotePanel} setSelectedClaimId={setSelectedClaimId}
                                        setOpenManageLabelPanel={setOpenManageLabelPanel}
                                        selectedArchiveClaims={selectedArchiveClaims}
                                        setSelectedArchiveClaims={setSelectedArchiveClaims}
                                        setArchivedError={setArchivedError} setFullPageSpinner={setFullPageSpinner}
                                        setShowClaimPreview={setShowClaimPreview}/>
                            ))
                          }
                        </ListRenderer>
                        </tbody>
                      </table>
                    </form>

                    <PaginationPanel total={total}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BillingContext.Provider>
  );
}
