import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {PhoneNumberMask} from '../../../_common/MaskedInput';
import {Form} from '../../../_common/form/Form';
import {Button} from '../../../_common/buttons/Button';
import {ClaimActions, ClaimInitialState} from '../../../../store/claims';

const CONTACT_TYPES = {primary: 'primary', secondary: 'secondary'};

function detectSecondaryContact(secondary) {
  const {email, phone, phoneExt} = secondary || {};
  return Boolean(email || phone || phoneExt);
}

function AddSecondaryContact({setIsShowSecondaryContact, isSharedView}) {
  if (isSharedView) {
    return null;
  }

  return (
    <Form.Group>
      <div className="row">
        <div className="col-md-12">
          <Button variant="light" onClick={() => setIsShowSecondaryContact(true)}>
            <i className="fa fa-plus"/> Add Secondary Client's Contact
          </Button>
        </div>
      </div>
    </Form.Group>
  );
}

export function ClientContact() {
  const dispatch = useDispatch();
  const {isSharedView} = useSelector(state => state.claims.claim);
  const {contact} = useSelector(state => state.claims.claim.data.client);

  const {SetOnClaim} = ClaimActions;
  const {secondary} = contact;
  const hasSecondaryContact = detectSecondaryContact(secondary);

  const [isShowSecondaryContact, setIsShowSecondaryContact] = useState(hasSecondaryContact);

  useEffect(() => {
    setIsShowSecondaryContact(hasSecondaryContact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setPrimaryContact(data) {
    dispatch(SetOnClaim.contact({contactType: CONTACT_TYPES.primary, ...data}));
  }

  function setSecondaryContact(data) {
    dispatch(SetOnClaim.contact({contactType: CONTACT_TYPES.secondary, ...data}));
  }

  function handleSecondaryContactDeleteClick() {
    Object.entries(ClaimInitialState.INITIAL_CLAIM_STATE.client.contact.secondary)
      .forEach(entry => setSecondaryContact({name: entry[0], value: entry[1]}));
    setIsShowSecondaryContact(false);
  }

  return (
    <>
      <Form.Group>
        <div className="row">
          <div className="col-md-6">
            <Form.Label>Email</Form.Label>
            <Form.Input data-testid="email" type="email" value={contact?.primary?.email} placeholder="Email"
                        disabled={isSharedView}
                        onChange={(e) => setPrimaryContact({name: 'email', value: e.target.value})}/>
          </div>
          <div className="col-md-5">
            <Form.Label>PH Phone Number</Form.Label>
            <PhoneNumberMask data-testid="phone" value={contact?.primary?.phone} placeholderText="PH Phone Number"
                             disabled={isSharedView}
                             handleChange={e => setPrimaryContact({name: 'phone', value: e.target.value})}/>
          </div>
          <div className="col-md-1">
            <Form.Label>&nbsp;&nbsp;&nbsp;</Form.Label>
            <Form.Input data-testid="phone-ext" type="text" value={contact?.primary?.phoneExt} placeholder="Ext."
                        disabled={isSharedView}
                        onChange={e => setPrimaryContact({name: 'phoneExt', value: e.target.value})}/>
          </div>
        </div>
      </Form.Group>

      <Form.Group>
        <div className="row">
          <div className="col-md-6">
            <Form.Label>PH Mobile Phone Number</Form.Label>
            <PhoneNumberMask data-testid="mobile" value={contact?.primary?.mobile}
                             placeholderText="PH Mobile Phone Number"
                             handleChange={e => setPrimaryContact({name: 'mobile', value: e.target.value})}/>
          </div>
          <div className="col-md-5">
            <Form.Label>PH Work Phone Number</Form.Label>
            <PhoneNumberMask data-testid="work-phone" value={contact?.primary?.workPhone}
                             placeholderText="PH Work Phone Number" disabled={isSharedView}
                             handleChange={e => setPrimaryContact({name: 'workPhone', value: e.target.value})}/>
          </div>
          <div className="col-md-1">
            <Form.Label>&nbsp;&nbsp;&nbsp;</Form.Label>
            <Form.Input data-testid="work-phone-ext" type="text" value={contact?.primary?.workPhoneExt}
                        placeholder="Ext." disabled={isSharedView}
                        onChange={e => setPrimaryContact({name: 'workPhoneExt', value: e.target.value})}/>
          </div>
        </div>
      </Form.Group>

      {!isShowSecondaryContact && (
        <AddSecondaryContact setIsShowSecondaryContact={setIsShowSecondaryContact} isSharedView={isSharedView}/>
      )}

      {isShowSecondaryContact && (
        <Form.Group>
          <div className="row">
            <div className="col-md-6">
              <Form.Label>Secondary Email</Form.Label>
              <Form.Input data-testid="secondary-email" type="email" value={contact?.secondary?.email}
                          placeholder="Secondary Email" disabled={isSharedView}
                          onChange={e => setSecondaryContact({name: 'email', value: e.target.value})}/>
            </div>
            <div className="col-md-4">
              <Form.Label>Secondary PH Phone Number</Form.Label>
              <PhoneNumberMask data-testid="secondary-phone" value={contact?.secondary?.phone}
                               placeholderText="Secondary PH Phone Number" disabled={isSharedView}
                               handleChange={e => setSecondaryContact({name: 'phone', value: e.target.value})}/>
            </div>
            <div className="col-md-1">
              <Form.Label>&nbsp;&nbsp;&nbsp;</Form.Label>
              <Form.Input data-testid="secondary-phone-ext" type="text" value={contact?.secondary?.phoneExt}
                          placeholder="Ext." disabled={isSharedView}
                          onChange={e => setSecondaryContact({name: 'phoneExt', value: e.target.value})}/>
            </div>
            {!isSharedView && (
              <div className="col-md-1" style={{textAlign: 'right'}}>
                <Button style={{marginTop: '25px'}} variant="danger" onClick={handleSecondaryContactDeleteClick}>
                  <i className="fa fa-close"/>
                </Button>
              </div>
            )}
          </div>
        </Form.Group>
      )}
    </>
  );
}
