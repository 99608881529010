import {useCallback, useEffect, useState} from 'react';

export default function useFetcher(fetcher) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const reFetch = useCallback(async function _reFetch() {
    try {
      setLoading(true);
      setError(null);
      const {data} = await fetcher();
      setData(data);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData, setError, fetcher]);

  useEffect(() => {
    if (data || loading || error) {
      return;
    }

    reFetch();
  }, [data, loading, error, reFetch]);

  return {data, loading, error, reFetch};
}