import { takeLatest, takeEvery, put, fork, select } from "redux-saga/effects";

import {
  ClaimActions as Actions,
  ClaimTypes as Types,
  ClaimUtils as Utils,
  ClaimDataMapper,
} from "./index";
import * as Api from "../../Api";
import { CustomFieldActions } from "../custom-fields";
import { Notify } from "../../services/Notify.service";

export async function getDuplicates(data) {
  const duplicates = await Api.Claims.checkForDuplicates(data);
  return duplicates;
}

function getClientAndValidate(client, lastSavedClient) {
  const { type, businessName, residence } = client || {};
  const { firstName, lastName } = residence?.client1 || {};

  let isValid = true;

  // must have client type
  if (!type) isValid = false;

  // if business, must have business name
  const isBusiness = type === Types.CLIENT_TYPES.business;
  const hasBusinessName = Boolean(businessName);
  if (isBusiness && !hasBusinessName) isValid = false;

  // if residence, must have first and last name of  client1
  const isResidence = type === Types.CLIENT_TYPES.residence;
  const hasNames = firstName && lastName;
  if (isResidence && !hasNames) isValid = false;

  // ok, so we have all the data to run the duplicate check now
  const formattedClient = {
    clientType: type,
    businessName: businessName,
    client1FirstName: firstName,
    client1LastName: lastName,
  };

  const formattedLastSavedClient = {
    clientType: lastSavedClient?.type,
    businessName: lastSavedClient?.businessName,
    client1FirstName: lastSavedClient?.residence?.client1?.firstName,
    client1LastName: lastSavedClient?.residence?.client1?.lastName,
  };

  const { isChanged } = Utils.detectChanges(
    formattedClient,
    formattedLastSavedClient
  );

  return { isValid, isChanged, formattedClient };
}

function* checkForDuplicates() {
  try {
    const { client } = yield select((state) => state.claims.claim?.data) || {};
    const { client: lastSavedClient } = yield select(
      (state) => state.claims.claim?.lastSavedData
    ) || {};

    const { isValid, isChanged, formattedClient } = getClientAndValidate(
      client,
      lastSavedClient
    );

    // if not valid, stop
    if (!isValid) return {};

    // if has no changes since last save, stop
    if (!isChanged) return {};

    const { data } = yield getDuplicates(formattedClient);

    // filter out any claim with same id
    const { id } = yield select((state) => state.claims.claim);
    const filtered = data.filter((claim) => String(claim.id) !== String(id));

    return { duplicates: filtered, params: formattedClient };
  } catch (err) {
    console.error("Error in checkForDuplicates", err.message, err);
    const error = Api.getError(err);
    yield put(Actions.CreateOrUpdateClaim.duplicateCheckError(error));
  }
}

function* watchCheckForDuplicatesStart() {
  yield takeLatest(
    Types.CREATE_OR_UPDATE_CLAIM.DUPLICATE_CHECK_START,
    checkForDuplicates
  );
}

function* createOrUpdateClaim() {
  try {
    const {
      id: claimId,
      isSaveAndClose,
      isFormSubmitted,
      isNew,
    } = yield select((state) => state.claims.claim);

    const { data: claim, isCreating } = yield select(
      (state) => state.claims.claim
    );

    const { customFields } = claim;

    // format claim to send to api
    const claimData = Utils.mapCreateOrUpdateClaimData({ claim, customFields });

    const redirectRoute = (id) => {
      if (isSaveAndClose) {
        window.open("/app/claims", "_self");
        return;
      }

      // if we are no client new page and
      // user clicks on Save button
      // then we redirect to client edit route
      if (isNew && isFormSubmitted) {
        window.open(`/app/claims/${id}`, "_self");
      }
    };

    const shouldUpdateClaim = Boolean(claimId); // update if there is a claimid

    const shouldCreateNewClaim = !Boolean(claimId); // this breaks if claimId is ever 0

    const { list: claimAdjusters } = yield select(
      (state) => state.claims.claim.claimAdjusters
    );

    const formattedAdjusters = ClaimDataMapper.ClaimFieldMappers.ClaimAdjusters.mapForApi(
      claimAdjusters
    );

    // update existing claim
    if (shouldUpdateClaim) {
      claimData.insurerAdjuster = formattedAdjusters;
      yield Api.Claims.edit({
        id: claimId,
        claimData,
      });

      yield put(
        Actions.CreateOrUpdateClaim.success({
          id: claimId,
          lastSavedAt: new Date(),
        })
      );

      const { data } = yield Api.Claims.Adjusters.getForClaim({ claimId });

      yield put(Actions.ClaimAdjusters.getSuccess(data));

      yield redirectRoute(claimId);
    }

    // if it's a new claim creation is already in progress,
    // then exit this task, other duplicate claims will get created

    if (shouldCreateNewClaim && isCreating) {
      console.info(
        "Claim creation already in progress. Exiting to avoid duplicate creation..."
      );
      return;
    }

    if (shouldCreateNewClaim) {
      // update state to isCreating
      yield put(Actions.CreateOrUpdateClaim.isCreating());

      claimData.insurerAdjuster = formattedAdjusters;
      // create new claim
      const { data } = yield Api.Claims.save({ claimData });
      const id = data?.claim?.id;

      yield put(
        Actions.CreateOrUpdateClaim.success({ id, lastSavedAt: new Date() })
      );

      Notify.dark("Claim created!");

      yield redirectRoute(id);
    }
  } catch (err) {
    const error = Api.getError(err);
    yield put(Actions.CreateOrUpdateClaim.error(error));
  }
}

function* watchCreateOrUpdateClaimsStart() {
  yield takeEvery(Types.CREATE_OR_UPDATE_CLAIM.START, createOrUpdateClaim);
}

function* createOrUpdateClaimPreCheck() {
  try {
    const { isAutoSaveClaimsOn } = yield select(
      (state) => state.profile.settings
    );

    if (isAutoSaveClaimsOn) {
      console.debug("isAutoSaveClaimsOn is on, so exiting duplicate check...");
      yield put(Actions.CreateOrUpdateClaim.start());
      return;
    }

    // check if duplicate creation is already set to allowed
    const { allowDuplicateStatus, list } = yield select(
      (state) => state.claims.claim.duplicateClients
    );

    const isUnspecified =
      allowDuplicateStatus === Types.ALLOW_DUPLICATE_STATUSES.UNSPECIFIED;

    const hasDuplicatesAlready = list && list.length;
    // we have fetched duplicates and are
    // now waiting on the user to specify what to do
    if (hasDuplicatesAlready && isUnspecified) {
      console.debug(
        "hasDuplicatesAlready, so starting claim creation/updation"
      );
      return;
    }

    if (allowDuplicateStatus === Types.ALLOW_DUPLICATE_STATUSES.ALLOW) {
      console.debug(
        "allowDuplicateStatus = allowed, so starting claim creation/updation"
      );
      yield put(Actions.CreateOrUpdateClaim.start());
      return;
    }

    if (allowDuplicateStatus === Types.ALLOW_DUPLICATE_STATUSES.NOT_ALLOWED) {
      yield Notify.error("You opted to not create this duplicate claim");
      return;
    }

    if (isUnspecified) {
      console.debug(
        "allowDuplicateStatus = unspecified, so fetching duplicates"
      );
      // get duplicates
      const {
        duplicates,
        params: checkedForParams,
      } = yield checkForDuplicates();
      const hasDuplicates = Boolean(duplicates && duplicates.length);
      // if there are no duplicates
      // save claim
      if (!hasDuplicates) {
        yield put(Actions.CreateOrUpdateClaim.start());
        return;
      }

      // if there are duplicates
      // save to state and cancel claim creation/updation
      console.debug(
        "Duplicates found. Saving them to state and cancelling claim creation/updation"
      );
      yield put(
        Actions.CreateOrUpdateClaim.duplicateCheckSuccess({
          duplicates,
          checkedForParams,
        })
      );
      yield put(Actions.CreateOrUpdateClaim.cancel());
      return;
    }
  } catch (err) {
    console.error(err.message, err);
  }
}

function* watchCreateOrUpdateClaimPrecheckStart() {
  yield takeEvery(
    Types.CREATE_OR_UPDATE_CLAIM.PRE_CHECK,
    createOrUpdateClaimPreCheck
  );
}

function* getClaim({ payload }) {
  try {
    const id = payload;
    const { data } = yield Api.Claims.getById({ id });
    const formattedClaim = Utils.formatClaimFromApi(data.claim);

    yield put(Actions.GetClaim.success(formattedClaim));
    yield put(
      CustomFieldActions.GetCustomFields.start({
        claimCustomFields: data.claim.customFields,
        isSetCustomFieldsOnClaimState: true,
      })
    );
  } catch (err) {
    console.error("Error in getClaim", err.message);
    const error = Api.getError(err);
    yield put(Actions.GetClaim.error(error));
  }
}

function* watchGetClaimStart() {
  yield takeEvery(Types.GET_CLAIM.START, getClaim);
}

const ClaimSagas = [
  fork(watchCreateOrUpdateClaimsStart),
  fork(watchGetClaimStart),
  fork(watchCheckForDuplicatesStart),
  fork(watchCreateOrUpdateClaimPrecheckStart),
];

export default ClaimSagas;
