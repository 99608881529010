import React, {useEffect, useState} from 'react';
import {PopupModal} from '../_common/PopupModal';
import * as Api from '../../Api';

const DIALOG_STYLE = {margin: '0px', width: '100%', height: '88vh'};
const PREVIEW_CONTENT_STYLE = {backgroundColor: 'rgb(47, 53, 59)', height: '90vh', display: 'block'};
const HEADER_STYLE = {background: '#2f353b', border: 'none'};
const BODY_STYLE = {height: '66vh', padding: '0px'};
const IFRAME_STYLE = {height: '65vh'};
const FOOTER_STYLE = {background: '#2F353C', border: 'none'};

export function PreviewModal({finalDocId, onClose}) {
  const [previewPdf, setPreviewPdf] = useState('');

  useEffect(() => {
    async function getPreviewPdf() {
      try {
        const {data} = await Api.Document.legacy
          .genratePreview({id: finalDocId});
        setPreviewPdf(data.fileName);
      } catch (err) {
        console.log(err);
      }
    }

    // noinspection JSIgnoredPromiseFromCall
    getPreviewPdf();
  }, [finalDocId, setPreviewPdf]);

  return (
    <div>
      <PopupModal isOpen={true}>
        <div className="modal-dialog" style={DIALOG_STYLE}>
          <div className="modal-content" style={PREVIEW_CONTENT_STYLE}>
            <div className="modal-header font-white" style={HEADER_STYLE}>
              <h4 className="modal-title">
                Import Complete!
                <br/>
                <small className="font-white">
                  To generate this document, go to any Claim and use the Generate Documents option.
                </small>
              </h4>
            </div>
            <div className="modal-body" style={BODY_STYLE}>
              <iframe title="Preview PDF" src={previewPdf} frameBorder="0" width="100%" style={IFRAME_STYLE}/>
            </div>
            <div className="modal-footer" style={FOOTER_STYLE}>
              <div className="m-grid m-grid-flex m-grid-responsive-md m-grid-demo">
                <div className="m-grid-row">
                  <div
                    className="m-grid-col m-grid-col-middle m-grid-col-center m-grid-col-order-1 font-white btn dark sbold uppercase"
                    style={{height: '60px'}} onClick={onClose}>
                    <span className="item-box">
                      <span className="item">
                        <span aria-hidden="true" className="icon-close"/> &nbsp;Close
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopupModal>
    </div>
  );
}
