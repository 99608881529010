import React from 'react';

import * as Api from '../../../../Api';
import {Notify} from '../../../../services/Notify.service';
import {PopupModal} from '../../../_common/PopupModal';

export function DocumentViewModal(
  {
    openDocumentViewModal, setOpenDocumentViewModal, fileSrcUrl, fileName, setshowLoading, documentId, claimId,
    userFilledData
  }
) {

  function downloadPDF() {
    let link = document.createElement('a');
    link.target = '_blank';
    link.href = `${fileSrcUrl}`;
    link.download = `${fileName}`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  function downloadRTF() {
    setshowLoading(true);
    Api.Document.legacy
      .downloadAsRtf({documentId, claimId, requestData: userFilledData})
      .then((data) => {
        window.open(data.data.rtfFileUrl, '_blank');
      })
      .catch(() => {})
      .finally(() => {
        setshowLoading(false);
      });
  }

  function saveToFolder() {
    setshowLoading(true);
    Api.Document.legacy
      .saveToFolder({documentId, claimId, requestData: userFilledData})
      .then((data) => {
        setOpenDocumentViewModal(false);
        Notify.success('Saved to Folder!');
      })
      .catch((err) => {
        Notify.error('Error in saving File into Folder.');
      })
      .finally(() => {
        setshowLoading(false);
      });
  }

  if (!openDocumentViewModal) {
    return null;
  }

  return (
    <PopupModal isOpen={openDocumentViewModal}>
      <div className="modal-dialog" style={{margin: '0px', width: '60vw'}} role="document">
        <div className="modal-content"
             style={{borderRadius: '14px!important', backgroundColor: 'rgb(47, 53, 59)', border: 'none'}}>
          <div className="modal-header font-white"
               style={{background: '#2f353b', border: 'none'}}>
            <h4 className="modal-title">Print Preview</h4>
          </div>
          <div
            className="modal-body"
            style={{padding: '13px 50px 36px 50px!important', borderRadius: '14px!important'}}
          >
            <iframe id="pdfFrame" style={{height: '65vh'}} width="100%" title="preview Iframe" src={fileSrcUrl}
                    frameBorder="0"/>
          </div>
          <div className="modal-footer" style={{border: 'none', textAlign: 'center'}}
          >
            <button className="btn font-white btn dark sbold uppercase genrate-document-button"
                    onClick={downloadPDF}>
              <span className="icon-cloud-download"/> &nbsp;Download PDF
            </button>
            <button className="btn font-white btn dark sbold uppercase genrate-document-button"
                    onClick={downloadRTF}>
              <span className="icon-cloud-download"/> &nbsp;Edit Document
            </button>
            <button className="btn font-white btn dark sbold uppercase genrate-document-button"
                    onClick={saveToFolder}>
              <span className="icon-folder-alt"/> &nbsp;Save to Folder
            </button>
            <button className="btn font-white btn dark sbold uppercase genrate-document-button"
              onClick={() => setOpenDocumentViewModal(false)}>
              <span aria-hidden="true" className="icon-close"/>{' '}
              &nbsp;Close
            </button>
          </div>
        </div>
      </div>
    </PopupModal>
  );
}
