import React from "react";
import { CSVLink } from "react-csv";

const DownloadCSV = ({
    data,
    headers,
    filename,
    text
}) => {
    return  (
    <CSVLink
        headers={headers}
        data={data}
        target="_blank"
        filename={filename}
        
    >
    {(text)? text: "Export to Excel"}
    </CSVLink>
     );
}

export default DownloadCSV;