import React, {useEffect, useState} from 'react';
import {DropzoneWithPreview} from '../_common/DropzoneWithPreview';
import * as Api from '../../Api';
import {LoadingButton} from '../_common/Button';

function ChangeAvatar({profile, reloadProfile}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [profileImageLoading, setProfileImageLoading] = useState('');
  const [companyLogoLoading, setCompanyLogoLoading] = useState('');
  const [removeProfileImageLoading, setRemoveProfileImageLoading] = useState('');
  const [removeCompanyLogoLoading, setRemoveCompanyLogoLoading] = useState('');
  const [pathProfileImage, setPathProfileImage] = useState('');
  const [pathCompanyLogo, setPathCompanyLogo] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const {users, company} = profile;
  const uProfileImagePath = users?.profileImagePath || '';

  let {logoImagePath} = company || {};
  logoImagePath = logoImagePath || '';

  useEffect(() => {
    let {profileImagePath, profileImage} = users || {};
    profileImagePath = profileImagePath || '';
    profileImage = profileImage || '';
    setPathProfileImage(profileImage.includes('gravatar') ? profileImage : profileImagePath);
  }, [users, setPathProfileImage]);

  useEffect(() => {
    const {logoImagePath} = company || {};
    setPathCompanyLogo(logoImagePath || '');
  }, [company]);

  const uploadProfileImage = () => {
    if (profileImage === '') {
      setErrorMessage('Please Select File.');
      return false;
    }
    setProfileImageLoading(true);
    setErrorMessage('');
    const data = new FormData();
    data.append('image', profileImage);
    data.append('type', 'profile');
    return Api.User.uploadImage({
        file: data
      })
      .then((data) => {
        reloadProfile();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].msg
        ) {
          setProfileImageLoading(false);
        }
      })
      .finally(() => {
        setProfileImageLoading(false);
      });
  };

  const uploadCompanyLogo = () => {
    if (companyLogo === '') {
      setErrorMessage('Please Select File.');
      return false;
    }
    setCompanyLogoLoading(true);
    setErrorMessage('');
    const data = new FormData();
    data.append('image', companyLogo);
    data.append('type', 'companyLogo');
    // setbuttonLoading(true);
    return Api.User.uploadImage({
        file: data
      })
      .then((data) => {
        reloadProfile();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].msg
        ) {
          // setbuttonLoading(false);
        }
      })
      .finally(() => {
        setCompanyLogoLoading(false);
      });
  };

  const removeProfileImage = () => {
    setRemoveProfileImageLoading(true);
    const data = {
      type: 'profile'
    };
    return Api.User.removeImage({
        data
      })
      .then((data) => {
        reloadProfile();
        setProfileImage('');
        setPathProfileImage('');
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].msg
        ) {
          setRemoveProfileImageLoading(false);
        }
      })
      .finally(() => {
        setRemoveProfileImageLoading(false);
      });
  };

  const removeCompanyLogo = () => {
    setRemoveCompanyLogoLoading(true);
    const data = {
      type: 'companyLogo'
    };
    return Api.User.removeImage({
        data
      })
      .then((data) => {
        reloadProfile();
        setPathCompanyLogo('');
        setCompanyLogo('');
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors[0] &&
          error.response.data.errors[0].msg
        ) {
          setRemoveCompanyLogoLoading(false);
        }
      })
      .finally(() => {
        setRemoveCompanyLogoLoading(false);
      });
  };

  return (
    <div>
      {
        errorMessage !== ''
          ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            </div>
          )
          : ('')
      }

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label className="dropzoneLabel">Profile Image</label>
            <DropzoneWithPreview
              onFileDrop={(file) => setProfileImage(file)}
              imageUrl={pathProfileImage}
            />
            <br/>
            {profileImageLoading ? (
              <LoadingButton/>
            ) : profileImage ? (
              <button
                type="button"
                className="btn btn-outline blue-primary"
                onClick={() => uploadProfileImage()}
              >
                Save Profile Image
              </button>
            ) : (
              ''
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {uProfileImagePath ? (
              removeProfileImageLoading ? (
                <LoadingButton/>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline red"
                  onClick={() => removeProfileImage()}
                >
                  Remove Profile Image
                </button>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <br/>
          <hr/>
          <br/>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label className="dropzoneLabel">Company Logo</label>
            <DropzoneWithPreview
              onFileDrop={(file) => setCompanyLogo(file)}
              imageUrl={pathCompanyLogo}
            />
            <br/>
            {companyLogoLoading ? (
              <LoadingButton/>
            ) : companyLogo ? (
              <button
                type="button"
                className="btn btn-outline blue-primary"
                onClick={() => uploadCompanyLogo()}
              >
                Save Company Logo
              </button>
            ) : (
              ''
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {logoImagePath ? (
              removeCompanyLogoLoading ? (
                <LoadingButton/>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline red"
                  onClick={() => removeCompanyLogo()}
                >
                  Remove Company Logo
                </button>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export {ChangeAvatar};
