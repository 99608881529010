import React from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { Form } from "../../../../_common/form/Form";
import MultiSelectDropDown from "../../../../_common/MultiSelectDropDown";
import { ClaimActions } from "../../../../../store/claims";

function getAccessAllowedAdjusters(members = []) {
  const { adjusters, admins, superadmin } = members;

  const allowedAdjusters = [
    ...(adjusters ? [...adjusters] : []),
    ...(admins ? [...admins] : []),
    ...(superadmin ? [...superadmin] : []),
  ];

  const sortedAdjusters = _.orderBy(
    allowedAdjusters,
    [(adjuster) => adjuster.name.toLowerCase()],
    ["asc"]
  );
  return sortedAdjusters;
}

export const OtherAdjusterAccess = () => {
  const dispatch = useDispatch();

  const members = useSelector((state) => state.company.members.list);
  const otherAdjustersAccess = useSelector(
    (state) => state.claims.claim.data?.otherAdjustersAccess
  );

  const { isSharedView } = useSelector((state) => state.claims.claim);

  const allAllowedAdjusters = getAccessAllowedAdjusters(members);

  const formattedSelectedAdjusters = otherAdjustersAccess?.map((adjuster) => {
    return allAllowedAdjusters.find((a) => String(a.id) === String(adjuster.id));
  });

  const handleChange = (adjusters) =>
    dispatch(ClaimActions.SetOnClaim.otherAdjustersAllowed(adjusters));

  return (
    <Form.Group>
      {isSharedView ? (
        <>
          <Form.Label>Other Adjuster Access</Form.Label>

          <Form.Input
            disabled
            value={otherAdjustersAccess?.map((adjuster) => adjuster.name).join(", ")}
            data-testid="other-adjuster-access"
          />
        </>
      ) : (
        <MultiSelectDropDown
          dataTestId="other-adjuster-access"
          label={"Other Adjuster Access"}
          options={allAllowedAdjusters}
          optionsLabelField={"name"}
          selectedFields={formattedSelectedAdjusters}
          optionsValueField={"id"}
          handleChange={handleChange}
          disabled={isSharedView}
        />
      )}
    </Form.Group>
  );
};
