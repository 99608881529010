import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import rootReducer from "./rootReducer";

import { rootSaga } from "./rootSaga";

const initialState = {};

const sagaMiddleware = createSagaMiddleware();

export const middlewares = applyMiddleware(...[sagaMiddleware]);

export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(middlewares)
);

sagaMiddleware.run(rootSaga);
