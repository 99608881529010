import { CustomFieldTypes as Types } from "./index";

export const GetCustomFields = {
  start: (payload) => ({ type: Types.GET_CUSTOM_FIELDS.START, payload }),
  success: (customFields) => ({
    type: Types.GET_CUSTOM_FIELDS.SUCCESS,
    payload: customFields,
  }),
  error: (err) => ({ type: Types.GET_CUSTOM_FIELDS.ERROR, payload: err }),
};
