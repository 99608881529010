import { CustomFieldTypes } from "../../custom-fields/index";

export function mergeCustomFields({ allCustomFields, claimCustomFields }) {
  return allCustomFields.map((field) => {
    const claimField = claimCustomFields.find(
      (claimField) => claimField.id === field.id
    );

    const { fieldValue } = claimField;

    const fieldValues = String(fieldValue)
      ?.split(",")
      .map((val) => val?.trim())
      .map((val) => Number(val));

    const formattedFieldValues = fieldValues.join(",");

    if (field.fieldType === CustomFieldTypes.FIELD_TYPES.checkbox) {
      return {
        ...claimField,
        options: field.options.map((option) => ({
          ...option,
          isSelected: fieldValues.includes(option.id) ? true : false,
        })),
        fieldValues: formattedFieldValues,
        fieldValue: undefined,
      };
    }
    if (field.fieldType === CustomFieldTypes.FIELD_TYPES.dropdown) {
      return {
        ...claimField,
        options: field.options,
        fieldValues: formattedFieldValues,
        fieldValue: undefined,
      };
    }

    return {
      ...claimField,
      fieldValues: claimField.fieldValue,
      fieldValue: undefined,
    };
  });
}

export function mapForApi(customFields) {
  return customFields.map((field) => {
    return {
      id: field.id,
      fieldValue: field.fieldValues ?? "",
    };
  });
}
